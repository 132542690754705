:root, [data-bs-theme="light"] {
  --vz-blue: #3577f1;
  --vz-indigo: #405189;
  --vz-purple: #6559cc;
  --vz-pink: #f672a7;
  --vz-red: #f06548;
  --vz-orange: #f1963b;
  --vz-yellow: #f7b84b;
  --vz-green: #0ab39c;
  --vz-teal: #02a8b5;
  --vz-cyan: #299cdb;
  --vz-white: #fff;
  --vz-gray: #878a99;
  --vz-gray-dark: #343a40;
  --vz-gray-100: #f3f6f9;
  --vz-gray-200: #eff2f7;
  --vz-gray-300: #e9ebec;
  --vz-gray-400: #ced4da;
  --vz-gray-500: #adb5bd;
  --vz-gray-600: #878a99;
  --vz-gray-700: #495057;
  --vz-gray-800: #343a40;
  --vz-gray-900: #212529;
  --vz-primary: #405189;
  --vz-secondary: #3577f1;
  --vz-success: #0ab39c;
  --vz-info: #299cdb;
  --vz-warning: #f7b84b;
  --vz-danger: #f06548;
  --vz-light: #f3f6f9;
  --vz-dark: #212529;
  --vz-primary-rgb: 64, 81, 137;
  --vz-secondary-rgb: 53, 119, 241;
  --vz-success-rgb: 10, 179, 156;
  --vz-info-rgb: 41, 156, 219;
  --vz-warning-rgb: 247, 184, 75;
  --vz-danger-rgb: 240, 101, 72;
  --vz-light-rgb: 243, 246, 249;
  --vz-dark-rgb: 33, 37, 41;
  --vz-primary-text-emphasis: #364574;
  --vz-secondary-text-emphasis: #2d65cd;
  --vz-success-text-emphasis: #099885;
  --vz-info-text-emphasis: #2385ba;
  --vz-warning-text-emphasis: #d29c40;
  --vz-danger-text-emphasis: #cc563d;
  --vz-light-text-emphasis: #ced4da;
  --vz-dark-text-emphasis: #343a40;
  --vz-primary-bg-subtle: #e2e5ed;
  --vz-secondary-bg-subtle: #e1ebfd;
  --vz-success-bg-subtle: #daf4f0;
  --vz-info-bg-subtle: #dff0fa;
  --vz-warning-bg-subtle: #fef4e4;
  --vz-danger-bg-subtle: #fde8e4;
  --vz-light-bg-subtle: #f9fbfc;
  --vz-dark-bg-subtle: #e9ebec;
  --vz-primary-border-subtle: #b3b9d0;
  --vz-secondary-border-subtle: #aec9f9;
  --vz-success-border-subtle: #9de1d7;
  --vz-info-border-subtle: #a9d7f1;
  --vz-warning-border-subtle: #fce3b7;
  --vz-danger-border-subtle: #f9c1b6;
  --vz-light-border-subtle: #eff2f7;
  --vz-dark-border-subtle: #adb5bd;
  --vz-white-rgb: 255, 255, 255;
  --vz-black-rgb: 0, 0, 0;
  --vz-font-sans-serif: var(--vz-font-family-secondary);
  --vz-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --vz-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --vz-body-font-family: var(--vz-font-sans-serif);
  --vz-body-font-size: var(--vz-font-base);
  --vz-body-font-weight: var(--vz-font-weight-normal);
  --vz-body-line-height: 1.5;
  --vz-body-color: #212529;
  --vz-body-color-rgb: 33, 37, 41;
  --vz-body-bg: #f3f3f9;
  --vz-body-bg-rgb: 243, 243, 249;
  --vz-emphasis-color: #212529;
  --vz-emphasis-color-rgb: 33, 37, 41;
  --vz-secondary-color: #878a99;
  --vz-secondary-color-rgb: 135, 138, 153;
  --vz-secondary-bg: #fff;
  --vz-secondary-bg-rgb: 255, 255, 255;
  --vz-tertiary-color: #21252980;
  --vz-tertiary-color-rgb: 33, 37, 41;
  --vz-tertiary-bg: #eff2f7;
  --vz-tertiary-bg-rgb: 239, 242, 247;
  --vz-heading-color: #495057;
  --vz-link-color: #405189;
  --vz-link-color-rgb: 64, 81, 137;
  --vz-link-decoration: none;
  --vz-link-hover-color: #405189;
  --vz-link-hover-color-rgb: 64, 81, 137;
  --vz-code-color: #f672a7;
  --vz-highlight-color: #212529;
  --vz-highlight-bg: #fcf8e3;
  --vz-border-width: 1px;
  --vz-border-style: solid;
  --vz-border-color: #e9ebec;
  --vz-border-color-translucent: #ced4da;
  --vz-border-radius: .25rem;
  --vz-border-radius-sm: .2rem;
  --vz-border-radius-lg: .3rem;
  --vz-border-radius-xl: 1rem;
  --vz-border-radius-xxl: 2rem;
  --vz-border-radius-2xl: var(--vz-border-radius-xxl);
  --vz-border-radius-pill: 50rem;
  --vz-box-shadow: 0 1px 2px #38414a26;
  --vz-box-shadow-sm: 0 .125rem .25rem #00000013;
  --vz-box-shadow-lg: 0 5px 10px #1e20251f;
  --vz-box-shadow-inset: inset 0 1px 2px #00000013;
  --vz-focus-ring-width: .25rem;
  --vz-focus-ring-opacity: .25;
  --vz-focus-ring-color: rgba(var(--vz-primary-rgb), .25);
  --vz-form-valid-color: #0ab39c;
  --vz-form-valid-border-color: #0ab39c;
  --vz-form-invalid-color: #f06548;
  --vz-form-invalid-border-color: #f06548;
}

[data-bs-theme="dark"] {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
  --vz-body-color: #ced4da;
  --vz-body-color-rgb: 206, 212, 218;
  --vz-body-bg: #1a1d21;
  --vz-body-bg-rgb: 26, 29, 33;
  --vz-emphasis-color: #fff;
  --vz-emphasis-color-rgb: 255, 255, 255;
  --vz-secondary-color: #878a99;
  --vz-secondary-color-rgb: 135, 138, 153;
  --vz-secondary-bg: #212529;
  --vz-secondary-bg-rgb: 33, 37, 41;
  --vz-tertiary-color: #ced4dabf;
  --vz-tertiary-color-rgb: 206, 212, 218;
  --vz-tertiary-bg: #2a2d31;
  --vz-tertiary-bg-rgb: 42, 45, 49;
  --vz-primary-text-emphasis: #6674a1;
  --vz-secondary-text-emphasis: #5d92f4;
  --vz-success-text-emphasis: #3bc2b0;
  --vz-info-text-emphasis: #54b0e2;
  --vz-warning-text-emphasis: #f9c66f;
  --vz-danger-text-emphasis: #f3846d;
  --vz-light-text-emphasis: #535659;
  --vz-dark-text-emphasis: #5f6164;
  --vz-primary-bg-subtle: #40518926;
  --vz-secondary-bg-subtle: #3577f126;
  --vz-success-bg-subtle: #0ab39c26;
  --vz-info-bg-subtle: #299cdb26;
  --vz-warning-bg-subtle: #f7b84b26;
  --vz-danger-bg-subtle: #f0654826;
  --vz-light-bg-subtle: #25282c;
  --vz-dark-bg-subtle: #313437;
  --vz-primary-border-subtle: #2a3559;
  --vz-secondary-border-subtle: #1b3c79;
  --vz-success-border-subtle: #055a4e;
  --vz-info-border-subtle: #154e6e;
  --vz-warning-border-subtle: #7c5c26;
  --vz-danger-border-subtle: #783324;
  --vz-light-border-subtle: #35383c;
  --vz-dark-border-subtle: #3c3f42;
  --vz-heading-color: #ced4da;
  --vz-link-color: #d9dce7;
  --vz-link-hover-color: #e1e3ec;
  --vz-link-color-rgb: 217, 220, 231;
  --vz-link-hover-color-rgb: 225, 227, 236;
  --vz-code-color: #f672a7;
  --vz-highlight-color: #ced4da;
  --vz-highlight-bg: #634a1e;
  --vz-border-color: #32383e;
  --vz-border-color-translucent: #32383e;
  --vz-form-valid-color: #3bc2b0;
  --vz-form-valid-border-color: #3bc2b0;
  --vz-form-invalid-color: #f3846d;
  --vz-form-invalid-border-color: #f3846d;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--vz-body-font-family);
  font-size: var(--vz-body-font-size);
  font-weight: var(--vz-body-font-weight);
  line-height: var(--vz-body-line-height);
  color: var(--vz-body-color);
  text-align: var(--vz-body-text-align);
  background-color: var(--vz-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--vz-border-width) solid;
  opacity: .25;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  font-family: var(--vz-headings-font-family);
  font-weight: var(--vz-headings-font-weight);
  color: var(--vz-heading-color);
  margin-top: 0;
  margin-bottom: .5rem;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(var(--vz-font-base) * 2.5);
}

h2, .h2 {
  font-size: calc(var(--vz-font-base) * 2);
}

h3, .h3 {
  font-size: calc(var(--vz-font-base) * 1.75);
}

h4, .h4 {
  font-size: calc(var(--vz-font-base) * 1.5);
}

h5, .h5 {
  font-size: calc(var(--vz-font-base) * 1.25);
}

h6, .h6 {
  font-size: var(--vz-font-base);
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: var(--vz-font-weight-semibold);
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .small {
  font-size: .875em;
}

mark, .mark {
  color: var(--vz-highlight-color);
  background-color: var(--vz-highlight-bg);
  padding: .2em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: rgba(var(--vz-link-color-rgb), var(--vz-link-opacity, 1));
  text-decoration: none;
}

a:hover {
  --vz-link-color-rgb: var(--vz-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--vz-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--vz-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--vz-body-bg);
  background-color: var(--vz-body-color);
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--vz-secondary-color);
  text-align: left;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  font-weight: var(--vz-font-weight-semibold);
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  width: 100%;
  margin-bottom: .5rem;
  padding: 0;
}

@media (width >= 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-ms-browse {
  font: inherit;
  -webkit-appearance: button;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(var(--vz-font-base) * 1.25);
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  font-size: calc(var(--vz-font-base) * 1.1);
  margin-bottom: 1rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #878a99;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  background-color: var(--vz-body-bg);
  border: var(--vz-border-width) solid var(--vz-border-color);
  border-radius: var(--vz-border-radius);
  max-width: 100%;
  height: auto;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: var(--vz-secondary-color);
  font-size: .875em;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --vz-gutter-x: calc(var(--vz-grid-gutter-width) * 1);
  --vz-gutter-y: 0;
  padding-right: calc(var(--vz-gutter-x) * .5);
  padding-left: calc(var(--vz-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}

:root {
  --vz-breakpoint-xs: 0;
  --vz-breakpoint-sm: 576px;
  --vz-breakpoint-md: 768px;
  --vz-breakpoint-lg: 992px;
  --vz-breakpoint-xl: 1200px;
  --vz-breakpoint-xxl: 1400px;
}

.row {
  --vz-gutter-x: var(--vz-grid-gutter-width);
  --vz-gutter-y: 0;
  margin-top: calc(-1 * var(--vz-gutter-y));
  margin-right: calc(-.5 * var(--vz-gutter-x));
  margin-left: calc(-.5 * var(--vz-gutter-x));
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -ms-flexbox;
  display: flex;
}

.row > * {
  padding-right: calc(var(--vz-gutter-x) * .5);
  padding-left: calc(var(--vz-gutter-x) * .5);
  margin-top: var(--vz-gutter-y);
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  -ms-flex: 1 0;
  flex: 1 0;
}

.row-cols-auto > * {
  -ms-flex: none;
  flex: none;
  width: auto;
}

.row-cols-1 > * {
  -ms-flex: none;
  flex: none;
  width: 100%;
}

.row-cols-2 > * {
  -ms-flex: none;
  flex: none;
  width: 50%;
}

.row-cols-3 > * {
  -ms-flex: none;
  flex: none;
  width: 33.3333%;
}

.row-cols-4 > * {
  -ms-flex: none;
  flex: none;
  width: 25%;
}

.row-cols-5 > * {
  -ms-flex: none;
  flex: none;
  width: 20%;
}

.row-cols-6 > * {
  -ms-flex: none;
  flex: none;
  width: 16.6667%;
}

.col-auto {
  -ms-flex: none;
  flex: none;
  width: auto;
}

.col-1 {
  -ms-flex: none;
  flex: none;
  width: 8.33333%;
}

.col-2 {
  -ms-flex: none;
  flex: none;
  width: 16.6667%;
}

.col-3 {
  -ms-flex: none;
  flex: none;
  width: 25%;
}

.col-4 {
  -ms-flex: none;
  flex: none;
  width: 33.3333%;
}

.col-5 {
  -ms-flex: none;
  flex: none;
  width: 41.6667%;
}

.col-6 {
  -ms-flex: none;
  flex: none;
  width: 50%;
}

.col-7 {
  -ms-flex: none;
  flex: none;
  width: 58.3333%;
}

.col-8 {
  -ms-flex: none;
  flex: none;
  width: 66.6667%;
}

.col-9 {
  -ms-flex: none;
  flex: none;
  width: 75%;
}

.col-10 {
  -ms-flex: none;
  flex: none;
  width: 83.3333%;
}

.col-11 {
  -ms-flex: none;
  flex: none;
  width: 91.6667%;
}

.col-12 {
  -ms-flex: none;
  flex: none;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --vz-gutter-x: 0;
}

.g-0, .gy-0 {
  --vz-gutter-y: 0;
}

.g-1, .gx-1 {
  --vz-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --vz-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --vz-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --vz-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --vz-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --vz-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --vz-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --vz-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --vz-gutter-x: 3rem;
}

.g-5, .gy-5 {
  --vz-gutter-y: 3rem;
}

@media (width >= 576px) {
  .col-sm {
    -ms-flex: 1 0;
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    -ms-flex: none;
    flex: none;
    width: auto;
  }

  .row-cols-sm-1 > * {
    -ms-flex: none;
    flex: none;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    -ms-flex: none;
    flex: none;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    -ms-flex: none;
    flex: none;
    width: 33.3333%;
  }

  .row-cols-sm-4 > * {
    -ms-flex: none;
    flex: none;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    -ms-flex: none;
    flex: none;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    -ms-flex: none;
    flex: none;
    width: 16.6667%;
  }

  .col-sm-auto {
    -ms-flex: none;
    flex: none;
    width: auto;
  }

  .col-sm-1 {
    -ms-flex: none;
    flex: none;
    width: 8.33333%;
  }

  .col-sm-2 {
    -ms-flex: none;
    flex: none;
    width: 16.6667%;
  }

  .col-sm-3 {
    -ms-flex: none;
    flex: none;
    width: 25%;
  }

  .col-sm-4 {
    -ms-flex: none;
    flex: none;
    width: 33.3333%;
  }

  .col-sm-5 {
    -ms-flex: none;
    flex: none;
    width: 41.6667%;
  }

  .col-sm-6 {
    -ms-flex: none;
    flex: none;
    width: 50%;
  }

  .col-sm-7 {
    -ms-flex: none;
    flex: none;
    width: 58.3333%;
  }

  .col-sm-8 {
    -ms-flex: none;
    flex: none;
    width: 66.6667%;
  }

  .col-sm-9 {
    -ms-flex: none;
    flex: none;
    width: 75%;
  }

  .col-sm-10 {
    -ms-flex: none;
    flex: none;
    width: 83.3333%;
  }

  .col-sm-11 {
    -ms-flex: none;
    flex: none;
    width: 91.6667%;
  }

  .col-sm-12 {
    -ms-flex: none;
    flex: none;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --vz-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --vz-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --vz-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --vz-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --vz-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --vz-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --vz-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --vz-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --vz-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --vz-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --vz-gutter-x: 3rem;
  }

  .g-sm-5, .gy-sm-5 {
    --vz-gutter-y: 3rem;
  }
}

@media (width >= 768px) {
  .col-md {
    -ms-flex: 1 0;
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    -ms-flex: none;
    flex: none;
    width: auto;
  }

  .row-cols-md-1 > * {
    -ms-flex: none;
    flex: none;
    width: 100%;
  }

  .row-cols-md-2 > * {
    -ms-flex: none;
    flex: none;
    width: 50%;
  }

  .row-cols-md-3 > * {
    -ms-flex: none;
    flex: none;
    width: 33.3333%;
  }

  .row-cols-md-4 > * {
    -ms-flex: none;
    flex: none;
    width: 25%;
  }

  .row-cols-md-5 > * {
    -ms-flex: none;
    flex: none;
    width: 20%;
  }

  .row-cols-md-6 > * {
    -ms-flex: none;
    flex: none;
    width: 16.6667%;
  }

  .col-md-auto {
    -ms-flex: none;
    flex: none;
    width: auto;
  }

  .col-md-1 {
    -ms-flex: none;
    flex: none;
    width: 8.33333%;
  }

  .col-md-2 {
    -ms-flex: none;
    flex: none;
    width: 16.6667%;
  }

  .col-md-3 {
    -ms-flex: none;
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    -ms-flex: none;
    flex: none;
    width: 33.3333%;
  }

  .col-md-5 {
    -ms-flex: none;
    flex: none;
    width: 41.6667%;
  }

  .col-md-6 {
    -ms-flex: none;
    flex: none;
    width: 50%;
  }

  .col-md-7 {
    -ms-flex: none;
    flex: none;
    width: 58.3333%;
  }

  .col-md-8 {
    -ms-flex: none;
    flex: none;
    width: 66.6667%;
  }

  .col-md-9 {
    -ms-flex: none;
    flex: none;
    width: 75%;
  }

  .col-md-10 {
    -ms-flex: none;
    flex: none;
    width: 83.3333%;
  }

  .col-md-11 {
    -ms-flex: none;
    flex: none;
    width: 91.6667%;
  }

  .col-md-12 {
    -ms-flex: none;
    flex: none;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --vz-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --vz-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --vz-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --vz-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --vz-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --vz-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --vz-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --vz-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --vz-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --vz-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --vz-gutter-x: 3rem;
  }

  .g-md-5, .gy-md-5 {
    --vz-gutter-y: 3rem;
  }
}

@media (width >= 992px) {
  .col-lg {
    -ms-flex: 1 0;
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    -ms-flex: none;
    flex: none;
    width: auto;
  }

  .row-cols-lg-1 > * {
    -ms-flex: none;
    flex: none;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    -ms-flex: none;
    flex: none;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    -ms-flex: none;
    flex: none;
    width: 33.3333%;
  }

  .row-cols-lg-4 > * {
    -ms-flex: none;
    flex: none;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    -ms-flex: none;
    flex: none;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    -ms-flex: none;
    flex: none;
    width: 16.6667%;
  }

  .col-lg-auto {
    -ms-flex: none;
    flex: none;
    width: auto;
  }

  .col-lg-1 {
    -ms-flex: none;
    flex: none;
    width: 8.33333%;
  }

  .col-lg-2 {
    -ms-flex: none;
    flex: none;
    width: 16.6667%;
  }

  .col-lg-3 {
    -ms-flex: none;
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    -ms-flex: none;
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    -ms-flex: none;
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    -ms-flex: none;
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    -ms-flex: none;
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    -ms-flex: none;
    flex: none;
    width: 66.6667%;
  }

  .col-lg-9 {
    -ms-flex: none;
    flex: none;
    width: 75%;
  }

  .col-lg-10 {
    -ms-flex: none;
    flex: none;
    width: 83.3333%;
  }

  .col-lg-11 {
    -ms-flex: none;
    flex: none;
    width: 91.6667%;
  }

  .col-lg-12 {
    -ms-flex: none;
    flex: none;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --vz-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --vz-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --vz-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --vz-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --vz-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --vz-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --vz-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --vz-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --vz-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --vz-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --vz-gutter-x: 3rem;
  }

  .g-lg-5, .gy-lg-5 {
    --vz-gutter-y: 3rem;
  }
}

@media (width >= 1200px) {
  .col-xl {
    -ms-flex: 1 0;
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    -ms-flex: none;
    flex: none;
    width: auto;
  }

  .row-cols-xl-1 > * {
    -ms-flex: none;
    flex: none;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    -ms-flex: none;
    flex: none;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    -ms-flex: none;
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xl-4 > * {
    -ms-flex: none;
    flex: none;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    -ms-flex: none;
    flex: none;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    -ms-flex: none;
    flex: none;
    width: 16.6667%;
  }

  .col-xl-auto {
    -ms-flex: none;
    flex: none;
    width: auto;
  }

  .col-xl-1 {
    -ms-flex: none;
    flex: none;
    width: 8.33333%;
  }

  .col-xl-2 {
    -ms-flex: none;
    flex: none;
    width: 16.6667%;
  }

  .col-xl-3 {
    -ms-flex: none;
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    -ms-flex: none;
    flex: none;
    width: 33.3333%;
  }

  .col-xl-5 {
    -ms-flex: none;
    flex: none;
    width: 41.6667%;
  }

  .col-xl-6 {
    -ms-flex: none;
    flex: none;
    width: 50%;
  }

  .col-xl-7 {
    -ms-flex: none;
    flex: none;
    width: 58.3333%;
  }

  .col-xl-8 {
    -ms-flex: none;
    flex: none;
    width: 66.6667%;
  }

  .col-xl-9 {
    -ms-flex: none;
    flex: none;
    width: 75%;
  }

  .col-xl-10 {
    -ms-flex: none;
    flex: none;
    width: 83.3333%;
  }

  .col-xl-11 {
    -ms-flex: none;
    flex: none;
    width: 91.6667%;
  }

  .col-xl-12 {
    -ms-flex: none;
    flex: none;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --vz-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --vz-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --vz-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --vz-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --vz-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --vz-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --vz-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --vz-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --vz-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --vz-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --vz-gutter-x: 3rem;
  }

  .g-xl-5, .gy-xl-5 {
    --vz-gutter-y: 3rem;
  }
}

@media (width >= 1400px) {
  .col-xxl {
    -ms-flex: 1 0;
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    -ms-flex: none;
    flex: none;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    -ms-flex: none;
    flex: none;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    -ms-flex: none;
    flex: none;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    -ms-flex: none;
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xxl-4 > * {
    -ms-flex: none;
    flex: none;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    -ms-flex: none;
    flex: none;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    -ms-flex: none;
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-auto {
    -ms-flex: none;
    flex: none;
    width: auto;
  }

  .col-xxl-1 {
    -ms-flex: none;
    flex: none;
    width: 8.33333%;
  }

  .col-xxl-2 {
    -ms-flex: none;
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-3 {
    -ms-flex: none;
    flex: none;
    width: 25%;
  }

  .col-xxl-4 {
    -ms-flex: none;
    flex: none;
    width: 33.3333%;
  }

  .col-xxl-5 {
    -ms-flex: none;
    flex: none;
    width: 41.6667%;
  }

  .col-xxl-6 {
    -ms-flex: none;
    flex: none;
    width: 50%;
  }

  .col-xxl-7 {
    -ms-flex: none;
    flex: none;
    width: 58.3333%;
  }

  .col-xxl-8 {
    -ms-flex: none;
    flex: none;
    width: 66.6667%;
  }

  .col-xxl-9 {
    -ms-flex: none;
    flex: none;
    width: 75%;
  }

  .col-xxl-10 {
    -ms-flex: none;
    flex: none;
    width: 83.3333%;
  }

  .col-xxl-11 {
    -ms-flex: none;
    flex: none;
    width: 91.6667%;
  }

  .col-xxl-12 {
    -ms-flex: none;
    flex: none;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --vz-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --vz-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --vz-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --vz-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --vz-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --vz-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --vz-gutter-x: 1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --vz-gutter-y: 1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --vz-gutter-x: 1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --vz-gutter-y: 1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --vz-gutter-x: 3rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --vz-gutter-y: 3rem;
  }
}

.table {
  --vz-table-color-type: initial;
  --vz-table-bg-type: initial;
  --vz-table-color-state: initial;
  --vz-table-bg-state: initial;
  --vz-table-color: var(--vz-body-color);
  --vz-table-bg: transparent;
  --vz-table-border-color: var(--vz-border-color);
  --vz-table-accent-bg: transparent;
  --vz-table-striped-color: var(--vz-body-color);
  --vz-table-striped-bg: rgba(var(--vz-body-color-rgb), .02);
  --vz-table-active-color: var(--vz-body-color);
  --vz-table-active-bg: rgba(var(--vz-body-color-rgb), .04);
  --vz-table-hover-color: var(--vz-body-color);
  --vz-table-hover-bg: rgba(var(--vz-body-color-rgb), .04);
  vertical-align: top;
  border-color: var(--vz-table-border-color);
  width: 100%;
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  color: var(--vz-table-color-state, var(--vz-table-color-type, var(--vz-table-color)));
  background-color: var(--vz-table-bg);
  border-bottom-width: var(--vz-border-width);
  box-shadow: inset 0 0 0 9999px var(--vz-table-bg-state, var(--vz-table-bg-type, var(--vz-table-accent-bg)));
  padding: .75rem .6rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--vz-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .3rem .25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--vz-border-width) 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--vz-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --vz-table-color-type: var(--vz-table-striped-color);
  --vz-table-bg-type: var(--vz-table-striped-bg);
}

.table-active {
  --vz-table-color-state: var(--vz-table-active-color);
  --vz-table-bg-state: var(--vz-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --vz-table-color-state: var(--vz-table-hover-color);
  --vz-table-bg-state: var(--vz-table-hover-bg);
}

.table-primary {
  --vz-table-color: #000;
  --vz-table-bg: #d9dce7;
  --vz-table-border-color: #c3c6d0;
  --vz-table-striped-bg: #d5d8e2;
  --vz-table-striped-color: #000;
  --vz-table-active-bg: #d0d3de;
  --vz-table-active-color: #000;
  --vz-table-hover-bg: #d0d3de;
  --vz-table-hover-color: #000;
  color: var(--vz-table-color);
  border-color: var(--vz-table-border-color);
}

.table-secondary {
  --vz-table-color: #000;
  --vz-table-bg: #d7e4fc;
  --vz-table-border-color: #c2cde3;
  --vz-table-striped-bg: #d3dff7;
  --vz-table-striped-color: #000;
  --vz-table-active-bg: #cedbf2;
  --vz-table-active-color: #000;
  --vz-table-hover-bg: #cedbf2;
  --vz-table-hover-color: #000;
  color: var(--vz-table-color);
  border-color: var(--vz-table-border-color);
}

.table-success {
  --vz-table-color: #000;
  --vz-table-bg: #cef0eb;
  --vz-table-border-color: #b9d8d4;
  --vz-table-striped-bg: #caebe6;
  --vz-table-striped-color: #000;
  --vz-table-active-bg: #c6e6e2;
  --vz-table-active-color: #000;
  --vz-table-hover-bg: #c6e6e2;
  --vz-table-hover-color: #000;
  color: var(--vz-table-color);
  border-color: var(--vz-table-border-color);
}

.table-info {
  --vz-table-color: #000;
  --vz-table-bg: #d4ebf8;
  --vz-table-border-color: #bfd4df;
  --vz-table-striped-bg: #d0e6f3;
  --vz-table-striped-color: #000;
  --vz-table-active-bg: #cce2ee;
  --vz-table-active-color: #000;
  --vz-table-hover-bg: #cce2ee;
  --vz-table-hover-color: #000;
  color: var(--vz-table-color);
  border-color: var(--vz-table-border-color);
}

.table-warning {
  --vz-table-color: #000;
  --vz-table-bg: #fdf1db;
  --vz-table-border-color: #e4d9c5;
  --vz-table-striped-bg: #f8ecd7;
  --vz-table-striped-color: #000;
  --vz-table-active-bg: #f3e7d2;
  --vz-table-active-color: #000;
  --vz-table-hover-bg: #f3e7d2;
  --vz-table-hover-color: #000;
  color: var(--vz-table-color);
  border-color: var(--vz-table-border-color);
}

.table-danger {
  --vz-table-color: #000;
  --vz-table-bg: #fce0da;
  --vz-table-border-color: #e3cac4;
  --vz-table-striped-bg: #f7dcd6;
  --vz-table-striped-color: #000;
  --vz-table-active-bg: #f2d7d1;
  --vz-table-active-color: #000;
  --vz-table-hover-bg: #f2d7d1;
  --vz-table-hover-color: #000;
  color: var(--vz-table-color);
  border-color: var(--vz-table-border-color);
}

.table-light {
  --vz-table-color: #000;
  --vz-table-bg: #f3f6f9;
  --vz-table-border-color: #dbdde0;
  --vz-table-striped-bg: #eef1f4;
  --vz-table-striped-color: #000;
  --vz-table-active-bg: #e9ecef;
  --vz-table-active-color: #000;
  --vz-table-hover-bg: #e9ecef;
  --vz-table-hover-color: #000;
  color: var(--vz-table-color);
  border-color: var(--vz-table-border-color);
}

.table-dark {
  --vz-table-color: #fff;
  --vz-table-bg: #212529;
  --vz-table-border-color: #373b3e;
  --vz-table-striped-bg: #25292d;
  --vz-table-striped-color: #fff;
  --vz-table-active-bg: #2a2e32;
  --vz-table-active-color: #fff;
  --vz-table-hover-bg: #2a2e32;
  --vz-table-hover-color: #fff;
  color: var(--vz-table-color);
  border-color: var(--vz-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (width <= 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.form-label {
  margin-bottom: .5rem;
}

.col-form-label {
  padding-top: calc(.5rem + var(--vz-border-width));
  padding-bottom: calc(.5rem + var(--vz-border-width));
  font-size: inherit;
  margin-bottom: 0;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.7rem + var(--vz-border-width));
  padding-bottom: calc(.7rem + var(--vz-border-width));
  font-size: calc(var(--vz-font-base) * 1.25);
}

.col-form-label-sm {
  padding-top: calc(.25rem + var(--vz-border-width));
  padding-bottom: calc(.25rem + var(--vz-border-width));
  font-size: calc(var(--vz-font-base) * .875);
}

.form-text {
  color: var(--vz-secondary-color);
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  font-size: var(--vz-font-base);
  font-weight: var(--vz-font-weight-normal);
  color: var(--vz-body-color);
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: var(--vz-input-bg-custom);
  border: var(--vz-border-width) solid var(--vz-input-border-custom);
  border-radius: var(--vz-border-radius);
  background-clip: padding-box;
  width: 100%;
  padding: .5rem .9rem;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--vz-body-color);
  background-color: var(--vz-input-bg-custom);
  border-color: var(--vz-primary-border-subtle);
  box-shadow: 0 0 1 .25rem rgba(var(--vz-primary-rgb), .25);
  outline: 0;
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  padding: 0;
  display: block;
}

.form-control::-moz-placeholder {
  color: var(--vz-secondary-color);
  opacity: 1;
}

.form-control::placeholder {
  color: var(--vz-secondary-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--vz-tertiary-bg);
  opacity: 1;
}

.form-control::-ms-browse {
  color: var(--vz-body-color);
  background-color: var(--vz-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-radius: 0;
  margin: -.5rem -.9rem;
  padding: .5rem .9rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control::file-selector-button {
  color: var(--vz-body-color);
  background-color: var(--vz-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-radius: 0;
  margin: -.5rem -.9rem;
  padding: .5rem .9rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-ms-browse {
  border-right-width: var(--vz-border-width);
  margin-right: .9rem;
}

.form-control:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  border-right-width: var(--vz-border-width);
  margin-right: .9rem;
}

.form-control:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-ms-browse {
  border-left-width: var(--vz-border-width);
  margin-left: .9rem;
}

.form-control:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  border-left-width: var(--vz-border-width);
  margin-left: .9rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-ms-browse {
    transition: none;
  }

  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-ms-browse {
  background-color: var(--vz-secondary-bg);
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--vz-secondary-bg);
}

.form-control-plaintext {
  color: var(--vz-body-color);
  border: solid #0000;
  border-width: var(--vz-border-width) 0;
  background-color: #0000;
  width: 100%;
  margin-bottom: 0;
  padding: .5rem 0;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.5em + .5rem + calc(var(--vz-border-width) * 2));
  font-size: calc(var(--vz-font-base) * .875);
  border-radius: var(--vz-border-radius-sm);
  padding: .25rem .5rem;
}

.form-control-sm::-ms-browse {
  margin: -.25rem -.5rem;
  padding: .25rem .5rem;
}

.form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  padding: .25rem .5rem;
}

.form-control-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-ms-browse {
  margin-right: .5rem;
}

.form-control-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: .5rem;
}

.form-control-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-ms-browse {
  margin-left: .5rem;
}

.form-control-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: .5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.4rem + calc(var(--vz-border-width) * 2));
  font-size: calc(var(--vz-font-base) * 1.25);
  border-radius: var(--vz-border-radius-lg);
  padding: .7rem 1.2rem;
}

.form-control-lg::-ms-browse {
  margin: -.7rem -1.2rem;
  padding: .7rem 1.2rem;
}

.form-control-lg::file-selector-button {
  margin: -.7rem -1.2rem;
  padding: .7rem 1.2rem;
}

.form-control-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-ms-browse {
  margin-right: 1.2rem;
}

.form-control-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: 1.2rem;
}

.form-control-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-ms-browse {
  margin-left: 1.2rem;
}

.form-control-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1.2rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1rem + calc(var(--vz-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + calc(var(--vz-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1.4rem + calc(var(--vz-border-width) * 2));
}

.form-control-color {
  height: calc(1.5em + 1rem + calc(var(--vz-border-width) * 2));
  width: 3rem;
  padding: .5rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: var(--vz-border-radius);
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: var(--vz-border-radius);
  border: 0 !important;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + .5rem + calc(var(--vz-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1.4rem + calc(var(--vz-border-width) * 2));
}

.form-select {
  --vz-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  font-size: var(--vz-font-base);
  font-weight: var(--vz-font-weight-normal);
  color: var(--vz-body-color);
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: var(--vz-input-bg-custom);
  background-image: var(--vz-form-select-bg-img), var(--vz-form-select-bg-icon, none);
  border: var(--vz-border-width) solid var(--vz-input-border-custom);
  border-radius: var(--vz-border-radius);
  background-position: right .9rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  width: 100%;
  padding: .5rem 2.7rem .5rem .9rem;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: var(--vz-primary-border-subtle);
  box-shadow: 0 0 0 .25rem rgba(var(--vz-primary-rgb), .25);
  outline: 0;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .9rem;
}

.form-select:disabled {
  background-color: var(--vz-tertiary-bg);
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 0 var(--vz-body-color);
}

.form-select-sm {
  font-size: calc(var(--vz-font-base) * .875);
  border-radius: var(--vz-border-radius-sm);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
}

.form-select-lg {
  font-size: calc(var(--vz-font-base) * 1.25);
  border-radius: var(--vz-border-radius-lg);
  padding-top: .7rem;
  padding-bottom: .7rem;
  padding-left: 1.2rem;
}

[data-bs-theme="dark"] .form-select {
  --vz-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ced4da' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  min-height: calc(var(--vz-font-base) * 1.5);
  margin-bottom: .125rem;
  padding-left: 1.6em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.6em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.6em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.6em;
}

.form-check-input {
  --vz-form-check-bg: var(--vz-input-bg-custom);
  vertical-align: top;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: var(--vz-form-check-bg);
  background-image: var(--vz-form-check-bg-image);
  border: var(--vz-border-width) solid var(--vz-border-color-translucent);
  print-color-adjust: exact;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 1.1em;
  height: 1.1em;
  margin-top: .2em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: var(--vz-primary-border-subtle);
  box-shadow: 0 0 1 .25rem rgba(var(--vz-primary-rgb), .25);
  outline: 0;
}

.form-check-input:checked {
  background-color: var(--vz-primary);
  border-color: var(--vz-primary);
}

.form-check-input:checked[type="checkbox"] {
  --vz-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --vz-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: var(--vz-primary);
  border-color: var(--vz-primary);
  --vz-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --vz-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28135, 138, 153, 0.55%29'/%3e%3c/svg%3e");
  background-image: var(--vz-form-switch-bg);
  background-position: 0;
  border-radius: 2em;
  width: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --vz-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28135, 138, 153, 0.55%29'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  --vz-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --vz-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #0000;
  width: 100%;
  height: 1.5rem;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f3f3f9, 0 0 1 .25rem rgba(var(--vz-primary-rgb), .25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f3f3f9, 0 0 1 .25rem rgba(var(--vz-primary-rgb), .25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: var(--vz-primary);
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: var(--vz-primary);
}

.form-range::-webkit-slider-runnable-track {
  color: #0000;
  cursor: pointer;
  background-color: var(--vz-tertiary-bg);
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range::-moz-range-thumb {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: var(--vz-primary);
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: var(--vz-primary);
}

.form-range::-moz-range-track {
  color: #0000;
  cursor: pointer;
  background-color: var(--vz-tertiary-bg);
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--vz-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--vz-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + calc(var(--vz-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--vz-border-width) * 2));
  line-height: 1.25;
}

.form-floating > label {
  z-index: 2;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--vz-border-width) solid transparent;
  transform-origin: 0 0;
  height: 100%;
  padding: 1rem .9rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.form-floating > label:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  text-align: left;
}

.form-floating > label:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem .9rem;
}

.form-floating > .form-control::-moz-placeholder {
  color: #0000;
}

.form-floating > .form-control::placeholder {
  color: #0000;
}

.form-floating > .form-control-plaintext::-moz-placeholder {
  color: #0000;
}

.form-floating > .form-control-plaintext::placeholder {
  color: #0000;
}

.form-floating > .form-control:focus, .form-floating > .form-control-plaintext:focus {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
  color: rgba(var(--vz-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control:not(:placeholder-shown) ~ label {
  color: rgba(var(--vz-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control:not(:placeholder-shown) ~ label {
  color: rgba(var(--vz-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control:focus ~ label:after, .form-floating > .form-control-plaintext ~ label:after, .form-floating > .form-select ~ label:after {
  z-index: -1;
  content: "";
  background-color: var(--vz-input-bg-custom);
  border-radius: var(--vz-border-radius);
  height: 1.5em;
  position: absolute;
  inset: 1rem .45rem;
}

.form-floating > .form-control:not(:placeholder-shown) ~ label:after {
  z-index: -1;
  content: "";
  background-color: var(--vz-input-bg-custom);
  border-radius: var(--vz-border-radius);
  height: 1.5em;
  position: absolute;
  inset: 1rem .45rem;
}

.form-floating > .form-control:not(:placeholder-shown) ~ label:after {
  z-index: -1;
  content: "";
  background-color: var(--vz-input-bg-custom);
  border-radius: var(--vz-border-radius);
  height: 1.5em;
  position: absolute;
  inset: 1rem .45rem;
}

.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--vz-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: var(--vz-border-width) 0;
}

.form-floating > :disabled ~ label, .form-floating > .form-control:disabled ~ label {
  color: var(--vz-secondary-color);
}

.form-floating > :disabled ~ label:after, .form-floating > .form-control:disabled ~ label:after {
  background-color: var(--vz-tertiary-bg);
}

.input-group {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  -ms-flex: auto;
  flex: auto;
  width: 1%;
  min-width: 0;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  font-size: var(--vz-font-base);
  font-weight: var(--vz-font-weight-normal);
  color: var(--vz-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--vz-tertiary-bg);
  border: var(--vz-border-width) solid var(--vz-input-border-custom);
  border-radius: var(--vz-border-radius);
  align-items: center;
  padding: .5rem .9rem;
  line-height: 1.5;
  display: -ms-flexbox;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  font-size: calc(var(--vz-font-base) * 1.25);
  border-radius: var(--vz-border-radius-lg);
  padding: .7rem 1.2rem;
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  font-size: calc(var(--vz-font-base) * .875);
  border-radius: var(--vz-border-radius-sm);
  padding: .25rem .5rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3.6rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--vz-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  color: var(--vz-form-valid-color);
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  font-size: calc(var(--vz-font-base) * .875);
  color: #fff;
  background-color: var(--vz-success);
  border-radius: var(--vz-border-radius);
  max-width: 100%;
  margin-top: .1rem;
  padding: .4rem .7rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--vz-form-valid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230ab39c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .25rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .5rem) calc(.75em + .5rem);
  padding-right: calc(1.5em + 1rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--vz-form-valid-border-color);
  box-shadow: 0 0 1 .25rem rgba(var(--vz-success-rgb), .25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .25rem) top calc(.375em + .25rem);
  padding-right: calc(1.5em + 1rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--vz-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --vz-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230ab39c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .9rem center, right 2.7rem center;
  background-size: 16px 12px, calc(.75em + .5rem) calc(.75em + .5rem);
  padding-right: 4.95rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--vz-form-valid-border-color);
  box-shadow: 0 0 1 .25rem rgba(var(--vz-success-rgb), .25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(1.5em + 4rem);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--vz-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--vz-form-valid-color);
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 1 .25rem rgba(var(--vz-success-rgb), .25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--vz-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated .input-group > .form-select:not(:focus):valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  color: var(--vz-form-invalid-color);
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  font-size: calc(var(--vz-font-base) * .875);
  color: #fff;
  background-color: var(--vz-danger);
  border-radius: var(--vz-border-radius);
  max-width: 100%;
  margin-top: .1rem;
  padding: .4rem .7rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--vz-form-invalid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f06548'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f06548' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .25rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .5rem) calc(.75em + .5rem);
  padding-right: calc(1.5em + 1rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--vz-form-invalid-border-color);
  box-shadow: 0 0 1 .25rem rgba(var(--vz-danger-rgb), .25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .25rem) top calc(.375em + .25rem);
  padding-right: calc(1.5em + 1rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--vz-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --vz-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f06548'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f06548' stroke='none'/%3e%3c/svg%3e");
  background-position: right .9rem center, right 2.7rem center;
  background-size: 16px 12px, calc(.75em + .5rem) calc(.75em + .5rem);
  padding-right: 4.95rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--vz-form-invalid-border-color);
  box-shadow: 0 0 1 .25rem rgba(var(--vz-danger-rgb), .25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(1.5em + 4rem);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--vz-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--vz-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 1 .25rem rgba(var(--vz-danger-rgb), .25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--vz-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --vz-btn-padding-x: .9rem;
  --vz-btn-padding-y: .5rem;
  --vz-btn-font-family: ;
  --vz-btn-font-size: var(--vz-font-base);
  --vz-btn-font-weight: var(--vz-btn-font-weight-custom);
  --vz-btn-line-height: 1.5;
  --vz-btn-color: var(--vz-body-color);
  --vz-btn-bg: transparent;
  --vz-btn-border-width: var(--vz-border-width);
  --vz-btn-border-color: transparent;
  --vz-btn-border-radius: var(--vz-border-radius);
  --vz-btn-hover-border-color: transparent;
  --vz-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --vz-btn-disabled-opacity: .65;
  --vz-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--vz-btn-focus-shadow-rgb), .5);
  padding: var(--vz-btn-padding-y) var(--vz-btn-padding-x);
  font-family: var(--vz-btn-font-family);
  font-size: var(--vz-btn-font-size);
  font-weight: var(--vz-btn-font-weight);
  line-height: var(--vz-btn-line-height);
  color: var(--vz-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: var(--vz-btn-border-width) solid var(--vz-btn-border-color);
  border-radius: var(--vz-btn-border-radius);
  background-color: var(--vz-btn-bg);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--vz-btn-hover-color);
  background-color: var(--vz-btn-hover-bg);
  border-color: var(--vz-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--vz-btn-color);
  background-color: var(--vz-btn-bg);
  border-color: var(--vz-btn-border-color);
}

.btn:focus-visible {
  color: var(--vz-btn-hover-color);
  background-color: var(--vz-btn-hover-bg);
  border-color: var(--vz-btn-hover-border-color);
  box-shadow: var(--vz-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:focus-visible + .btn {
  border-color: var(--vz-btn-hover-border-color);
  box-shadow: var(--vz-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--vz-btn-active-color);
  background-color: var(--vz-btn-active-bg);
  border-color: var(--vz-btn-active-border-color);
}

.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--vz-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--vz-btn-disabled-color);
  pointer-events: none;
  background-color: var(--vz-btn-disabled-bg);
  border-color: var(--vz-btn-disabled-border-color);
  opacity: var(--vz-btn-disabled-opacity);
}

.btn-primary {
  --vz-btn-color: #fff;
  --vz-btn-bg: #405189;
  --vz-btn-border-color: #405189;
  --vz-btn-hover-color: #fff;
  --vz-btn-hover-bg: #364574;
  --vz-btn-hover-border-color: #33416e;
  --vz-btn-focus-shadow-rgb: 93, 107, 155;
  --vz-btn-active-color: #fff;
  --vz-btn-active-bg: #33416e;
  --vz-btn-active-border-color: #303d67;
  --vz-btn-active-shadow: inset 0 3px 5px #00000020;
  --vz-btn-disabled-color: #fff;
  --vz-btn-disabled-bg: #405189;
  --vz-btn-disabled-border-color: #405189;
}

.btn-secondary {
  --vz-btn-color: #fff;
  --vz-btn-bg: #3577f1;
  --vz-btn-border-color: #3577f1;
  --vz-btn-hover-color: #fff;
  --vz-btn-hover-bg: #2d65cd;
  --vz-btn-hover-border-color: #2a5fc1;
  --vz-btn-focus-shadow-rgb: 83, 139, 243;
  --vz-btn-active-color: #fff;
  --vz-btn-active-bg: #2a5fc1;
  --vz-btn-active-border-color: #2859b5;
  --vz-btn-active-shadow: inset 0 3px 5px #00000020;
  --vz-btn-disabled-color: #fff;
  --vz-btn-disabled-bg: #3577f1;
  --vz-btn-disabled-border-color: #3577f1;
}

.btn-success {
  --vz-btn-color: #fff;
  --vz-btn-bg: #0ab39c;
  --vz-btn-border-color: #0ab39c;
  --vz-btn-hover-color: #fff;
  --vz-btn-hover-bg: #099885;
  --vz-btn-hover-border-color: #088f7d;
  --vz-btn-focus-shadow-rgb: 47, 190, 171;
  --vz-btn-active-color: #fff;
  --vz-btn-active-bg: #088f7d;
  --vz-btn-active-border-color: #088675;
  --vz-btn-active-shadow: inset 0 3px 5px #00000020;
  --vz-btn-disabled-color: #fff;
  --vz-btn-disabled-bg: #0ab39c;
  --vz-btn-disabled-border-color: #0ab39c;
}

.btn-info {
  --vz-btn-color: #fff;
  --vz-btn-bg: #299cdb;
  --vz-btn-border-color: #299cdb;
  --vz-btn-hover-color: #fff;
  --vz-btn-hover-bg: #2385ba;
  --vz-btn-hover-border-color: #217daf;
  --vz-btn-focus-shadow-rgb: 73, 171, 224;
  --vz-btn-active-color: #fff;
  --vz-btn-active-bg: #217daf;
  --vz-btn-active-border-color: #1f75a4;
  --vz-btn-active-shadow: inset 0 3px 5px #00000020;
  --vz-btn-disabled-color: #fff;
  --vz-btn-disabled-bg: #299cdb;
  --vz-btn-disabled-border-color: #299cdb;
}

.btn-warning {
  --vz-btn-color: #fff;
  --vz-btn-bg: #f7b84b;
  --vz-btn-border-color: #f7b84b;
  --vz-btn-hover-color: #fff;
  --vz-btn-hover-bg: #d29c40;
  --vz-btn-hover-border-color: #c6933c;
  --vz-btn-focus-shadow-rgb: 248, 195, 102;
  --vz-btn-active-color: #fff;
  --vz-btn-active-bg: #c6933c;
  --vz-btn-active-border-color: #b98a38;
  --vz-btn-active-shadow: inset 0 3px 5px #00000020;
  --vz-btn-disabled-color: #fff;
  --vz-btn-disabled-bg: #f7b84b;
  --vz-btn-disabled-border-color: #f7b84b;
}

.btn-danger {
  --vz-btn-color: #fff;
  --vz-btn-bg: #f06548;
  --vz-btn-border-color: #f06548;
  --vz-btn-hover-color: #fff;
  --vz-btn-hover-bg: #cc563d;
  --vz-btn-hover-border-color: #c0513a;
  --vz-btn-focus-shadow-rgb: 242, 124, 99;
  --vz-btn-active-color: #fff;
  --vz-btn-active-bg: #c0513a;
  --vz-btn-active-border-color: #b44c36;
  --vz-btn-active-shadow: inset 0 3px 5px #00000020;
  --vz-btn-disabled-color: #fff;
  --vz-btn-disabled-bg: #f06548;
  --vz-btn-disabled-border-color: #f06548;
}

.btn-light {
  --vz-btn-color: #000;
  --vz-btn-bg: #f3f6f9;
  --vz-btn-border-color: #f3f6f9;
  --vz-btn-hover-color: #000;
  --vz-btn-hover-bg: #cfd1d4;
  --vz-btn-hover-border-color: #c2c5c7;
  --vz-btn-focus-shadow-rgb: 207, 209, 212;
  --vz-btn-active-color: #000;
  --vz-btn-active-bg: #c2c5c7;
  --vz-btn-active-border-color: #b6b9bb;
  --vz-btn-active-shadow: inset 0 3px 5px #00000020;
  --vz-btn-disabled-color: #000;
  --vz-btn-disabled-bg: #f3f6f9;
  --vz-btn-disabled-border-color: #f3f6f9;
}

.btn-dark {
  --vz-btn-color: #fff;
  --vz-btn-bg: #212529;
  --vz-btn-border-color: #212529;
  --vz-btn-hover-color: #fff;
  --vz-btn-hover-bg: #424649;
  --vz-btn-hover-border-color: #373b3e;
  --vz-btn-focus-shadow-rgb: 66, 70, 73;
  --vz-btn-active-color: #fff;
  --vz-btn-active-bg: #4d5154;
  --vz-btn-active-border-color: #373b3e;
  --vz-btn-active-shadow: inset 0 3px 5px #00000020;
  --vz-btn-disabled-color: #fff;
  --vz-btn-disabled-bg: #212529;
  --vz-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --vz-btn-color: #405189;
  --vz-btn-border-color: #405189;
  --vz-btn-hover-color: #fff;
  --vz-btn-hover-bg: #405189;
  --vz-btn-hover-border-color: #405189;
  --vz-btn-focus-shadow-rgb: 64, 81, 137;
  --vz-btn-active-color: #fff;
  --vz-btn-active-bg: #405189;
  --vz-btn-active-border-color: #405189;
  --vz-btn-active-shadow: inset 0 3px 5px #00000020;
  --vz-btn-disabled-color: #405189;
  --vz-btn-disabled-bg: transparent;
  --vz-btn-disabled-border-color: #405189;
  --vz-gradient: none;
}

.btn-outline-secondary {
  --vz-btn-color: #3577f1;
  --vz-btn-border-color: #3577f1;
  --vz-btn-hover-color: #fff;
  --vz-btn-hover-bg: #3577f1;
  --vz-btn-hover-border-color: #3577f1;
  --vz-btn-focus-shadow-rgb: 53, 119, 241;
  --vz-btn-active-color: #fff;
  --vz-btn-active-bg: #3577f1;
  --vz-btn-active-border-color: #3577f1;
  --vz-btn-active-shadow: inset 0 3px 5px #00000020;
  --vz-btn-disabled-color: #3577f1;
  --vz-btn-disabled-bg: transparent;
  --vz-btn-disabled-border-color: #3577f1;
  --vz-gradient: none;
}

.btn-outline-success {
  --vz-btn-color: #0ab39c;
  --vz-btn-border-color: #0ab39c;
  --vz-btn-hover-color: #fff;
  --vz-btn-hover-bg: #0ab39c;
  --vz-btn-hover-border-color: #0ab39c;
  --vz-btn-focus-shadow-rgb: 10, 179, 156;
  --vz-btn-active-color: #fff;
  --vz-btn-active-bg: #0ab39c;
  --vz-btn-active-border-color: #0ab39c;
  --vz-btn-active-shadow: inset 0 3px 5px #00000020;
  --vz-btn-disabled-color: #0ab39c;
  --vz-btn-disabled-bg: transparent;
  --vz-btn-disabled-border-color: #0ab39c;
  --vz-gradient: none;
}

.btn-outline-info {
  --vz-btn-color: #299cdb;
  --vz-btn-border-color: #299cdb;
  --vz-btn-hover-color: #fff;
  --vz-btn-hover-bg: #299cdb;
  --vz-btn-hover-border-color: #299cdb;
  --vz-btn-focus-shadow-rgb: 41, 156, 219;
  --vz-btn-active-color: #fff;
  --vz-btn-active-bg: #299cdb;
  --vz-btn-active-border-color: #299cdb;
  --vz-btn-active-shadow: inset 0 3px 5px #00000020;
  --vz-btn-disabled-color: #299cdb;
  --vz-btn-disabled-bg: transparent;
  --vz-btn-disabled-border-color: #299cdb;
  --vz-gradient: none;
}

.btn-outline-warning {
  --vz-btn-color: #f7b84b;
  --vz-btn-border-color: #f7b84b;
  --vz-btn-hover-color: #fff;
  --vz-btn-hover-bg: #f7b84b;
  --vz-btn-hover-border-color: #f7b84b;
  --vz-btn-focus-shadow-rgb: 247, 184, 75;
  --vz-btn-active-color: #fff;
  --vz-btn-active-bg: #f7b84b;
  --vz-btn-active-border-color: #f7b84b;
  --vz-btn-active-shadow: inset 0 3px 5px #00000020;
  --vz-btn-disabled-color: #f7b84b;
  --vz-btn-disabled-bg: transparent;
  --vz-btn-disabled-border-color: #f7b84b;
  --vz-gradient: none;
}

.btn-outline-danger {
  --vz-btn-color: #f06548;
  --vz-btn-border-color: #f06548;
  --vz-btn-hover-color: #fff;
  --vz-btn-hover-bg: #f06548;
  --vz-btn-hover-border-color: #f06548;
  --vz-btn-focus-shadow-rgb: 240, 101, 72;
  --vz-btn-active-color: #fff;
  --vz-btn-active-bg: #f06548;
  --vz-btn-active-border-color: #f06548;
  --vz-btn-active-shadow: inset 0 3px 5px #00000020;
  --vz-btn-disabled-color: #f06548;
  --vz-btn-disabled-bg: transparent;
  --vz-btn-disabled-border-color: #f06548;
  --vz-gradient: none;
}

.btn-outline-light {
  --vz-btn-color: #f3f6f9;
  --vz-btn-border-color: #f3f6f9;
  --vz-btn-hover-color: #000;
  --vz-btn-hover-bg: #f3f6f9;
  --vz-btn-hover-border-color: #f3f6f9;
  --vz-btn-focus-shadow-rgb: 243, 246, 249;
  --vz-btn-active-color: #000;
  --vz-btn-active-bg: #f3f6f9;
  --vz-btn-active-border-color: #f3f6f9;
  --vz-btn-active-shadow: inset 0 3px 5px #00000020;
  --vz-btn-disabled-color: #f3f6f9;
  --vz-btn-disabled-bg: transparent;
  --vz-btn-disabled-border-color: #f3f6f9;
  --vz-gradient: none;
}

.btn-outline-dark {
  --vz-btn-color: #212529;
  --vz-btn-border-color: #212529;
  --vz-btn-hover-color: #fff;
  --vz-btn-hover-bg: #212529;
  --vz-btn-hover-border-color: #212529;
  --vz-btn-focus-shadow-rgb: 33, 37, 41;
  --vz-btn-active-color: #fff;
  --vz-btn-active-bg: #212529;
  --vz-btn-active-border-color: #212529;
  --vz-btn-active-shadow: inset 0 3px 5px #00000020;
  --vz-btn-disabled-color: #212529;
  --vz-btn-disabled-bg: transparent;
  --vz-btn-disabled-border-color: #212529;
  --vz-gradient: none;
}

.btn-link {
  --vz-btn-font-weight: var(--vz-font-weight-normal);
  --vz-btn-color: var(--vz-link-color);
  --vz-btn-bg: transparent;
  --vz-btn-border-color: transparent;
  --vz-btn-hover-color: var(--vz-link-hover-color);
  --vz-btn-hover-border-color: transparent;
  --vz-btn-active-color: var(--vz-link-hover-color);
  --vz-btn-active-border-color: transparent;
  --vz-btn-disabled-color: #878a99;
  --vz-btn-disabled-border-color: transparent;
  --vz-btn-box-shadow: 0 0 0 #000;
  --vz-btn-focus-shadow-rgb: 93, 107, 155;
  text-decoration: none;
}

.btn-link:focus-visible {
  color: var(--vz-btn-color);
}

.btn-link:hover {
  color: var(--vz-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --vz-btn-padding-y: .7rem;
  --vz-btn-padding-x: 1.2rem;
  --vz-btn-font-size: calc(var(--vz-font-base) * 1.25);
  --vz-btn-border-radius: var(--vz-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --vz-btn-padding-y: .25rem;
  --vz-btn-padding-x: .5rem;
  --vz-btn-font-size: calc(var(--vz-font-base) * .875);
  --vz-btn-border-radius: var(--vz-border-radius-sm);
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  --vz-dropdown-zindex: 1000;
  --vz-dropdown-min-width: 10rem;
  --vz-dropdown-padding-x: 0;
  --vz-dropdown-padding-y: .5rem;
  --vz-dropdown-spacer: .125rem;
  --vz-dropdown-font-size: var(--vz-font-base);
  --vz-dropdown-color: var(--vz-body-color);
  --vz-dropdown-bg: var(--vz-secondary-bg);
  --vz-dropdown-border-color: var(--vz-border-color);
  --vz-dropdown-border-radius: var(--vz-border-radius-lg);
  --vz-dropdown-border-width: 0;
  --vz-dropdown-inner-border-radius: calc(var(--vz-border-radius-lg)  - 0);
  --vz-dropdown-divider-bg: var(--vz-border-color);
  --vz-dropdown-divider-margin-y: .5rem;
  --vz-dropdown-box-shadow: 0 5px 10px #1e20251f;
  --vz-dropdown-link-color: var(--vz-body-color);
  --vz-dropdown-link-hover-color: var(--vz-body-color);
  --vz-dropdown-link-hover-bg: var(--vz-tertiary-bg);
  --vz-dropdown-link-active-color: var(--vz-body-color);
  --vz-dropdown-link-active-bg: var(--vz-tertiary-bg);
  --vz-dropdown-link-disabled-color: var(--vz-tertiary-color);
  --vz-dropdown-item-padding-x: 1.2rem;
  --vz-dropdown-item-padding-y: .35rem;
  --vz-dropdown-header-color: #878a99;
  --vz-dropdown-header-padding-x: 1.2rem;
  --vz-dropdown-header-padding-y: .5rem;
  z-index: var(--vz-dropdown-zindex);
  min-width: var(--vz-dropdown-min-width);
  padding: var(--vz-dropdown-padding-y) var(--vz-dropdown-padding-x);
  font-size: var(--vz-dropdown-font-size);
  color: var(--vz-dropdown-color);
  text-align: left;
  background-color: var(--vz-dropdown-bg);
  border: var(--vz-dropdown-border-width) solid var(--vz-dropdown-border-color);
  border-radius: var(--vz-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--vz-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (width >= 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--vz-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--vz-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--vz-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  margin: var(--vz-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--vz-dropdown-divider-bg);
  opacity: 1;
  height: 0;
  overflow: hidden;
}

.dropdown-item {
  padding: var(--vz-dropdown-item-padding-y) var(--vz-dropdown-item-padding-x);
  clear: both;
  font-weight: var(--vz-font-weight-normal);
  color: var(--vz-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  border-radius: var(--vz-dropdown-item-border-radius, 0);
  background-color: #0000;
  border: 0;
  width: 100%;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--vz-dropdown-link-hover-color);
  background-color: var(--vz-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--vz-dropdown-link-active-color);
  background-color: var(--vz-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--vz-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--vz-dropdown-header-padding-y) var(--vz-dropdown-header-padding-x);
  font-size: calc(var(--vz-font-base) * .875);
  color: var(--vz-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  display: block;
}

.dropdown-item-text {
  padding: var(--vz-dropdown-item-padding-y) var(--vz-dropdown-item-padding-x);
  color: var(--vz-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --vz-dropdown-color: #e9ebec;
  --vz-dropdown-bg: #343a40;
  --vz-dropdown-border-color: var(--vz-border-color);
  --vz-dropdown-box-shadow: ;
  --vz-dropdown-link-color: #e9ebec;
  --vz-dropdown-link-hover-color: #fff;
  --vz-dropdown-divider-bg: var(--vz-border-color);
  --vz-dropdown-link-hover-bg: #ffffff26;
  --vz-dropdown-link-active-color: var(--vz-body-color);
  --vz-dropdown-link-active-bg: var(--vz-tertiary-bg);
  --vz-dropdown-link-disabled-color: #adb5bd;
  --vz-dropdown-header-color: #adb5bd;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  -ms-flex: auto;
  flex: auto;
  position: relative;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: flex-start;
  display: -ms-flexbox;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--vz-border-radius);
}

.btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--vz-border-width) * -1);
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .675rem;
  padding-right: .675rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: .9rem;
  padding-right: .9rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--vz-border-width) * -1);
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --vz-nav-link-padding-x: 1rem;
  --vz-nav-link-padding-y: .5rem;
  --vz-nav-link-font-weight: ;
  --vz-nav-link-color: var(--vz-link-color);
  --vz-nav-link-hover-color: var(--vz-link-hover-color);
  --vz-nav-link-disabled-color: var(--vz-secondary-color);
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
}

.nav-link {
  padding: var(--vz-nav-link-padding-y) var(--vz-nav-link-padding-x);
  font-size: var(--vz-nav-link-font-size);
  font-weight: var(--vz-nav-link-font-weight);
  color: var(--vz-nav-link-color);
  background: none;
  border: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--vz-nav-link-hover-color);
}

.nav-link:focus-visible {
  box-shadow: 0 0 1 .25rem rgba(var(--vz-primary-rgb), .25);
  outline: 0;
}

.nav-link.disabled, .nav-link:disabled {
  color: var(--vz-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --vz-nav-tabs-border-width: var(--vz-border-width);
  --vz-nav-tabs-border-color: var(--vz-border-color);
  --vz-nav-tabs-border-radius: var(--vz-border-radius);
  --vz-nav-tabs-link-hover-border-color: var(--vz-secondary-bg) var(--vz-secondary-bg) var(--vz-border-color);
  --vz-nav-tabs-link-active-color: var(--vz-emphasis-color);
  --vz-nav-tabs-link-active-bg: var(--vz-tertiary-bg);
  --vz-nav-tabs-link-active-border-color: var(--vz-border-color) var(--vz-border-color) var(--vz-tertiary-bg);
  border-bottom: var(--vz-nav-tabs-border-width) solid var(--vz-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--vz-nav-tabs-border-width));
  border: var(--vz-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--vz-nav-tabs-border-radius);
  border-top-right-radius: var(--vz-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--vz-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--vz-nav-tabs-link-active-color);
  background-color: var(--vz-nav-tabs-link-active-bg);
  border-color: var(--vz-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--vz-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --vz-nav-pills-border-radius: var(--vz-border-radius);
  --vz-nav-pills-link-active-color: #fff;
  --vz-nav-pills-link-active-bg: var(--vz-primary);
}

.nav-pills .nav-link {
  border-radius: var(--vz-nav-pills-border-radius);
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--vz-nav-pills-link-active-color);
  background-color: var(--vz-nav-pills-link-active-bg);
}

.nav-underline {
  --vz-nav-underline-gap: 1rem;
  --vz-nav-underline-border-width: .125rem;
  --vz-nav-underline-link-active-color: var(--vz-emphasis-color);
  gap: var(--vz-nav-underline-gap);
}

.nav-underline .nav-link {
  border-bottom: var(--vz-nav-underline-border-width) solid transparent;
  padding-left: 0;
  padding-right: 0;
}

.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentColor;
}

.nav-underline .nav-link.active, .nav-underline .show > .nav-link {
  font-weight: var(--vz-font-weight-bold);
  color: var(--vz-nav-underline-link-active-color);
  border-bottom-color: currentColor;
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  -ms-flex: auto;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  -ms-flex-positive: 1;
  -ms-flex-preferred-size: 0;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --vz-navbar-padding-x: 0;
  --vz-navbar-padding-y: .5rem;
  --vz-navbar-color: rgba(var(--vz-emphasis-color-rgb), .65);
  --vz-navbar-hover-color: rgba(var(--vz-emphasis-color-rgb), .8);
  --vz-navbar-disabled-color: rgba(var(--vz-emphasis-color-rgb), .3);
  --vz-navbar-active-color: rgba(var(--vz-emphasis-color-rgb), 1);
  --vz-navbar-brand-padding-y: calc((var(--vz-font-base) * 1.5 + 1rem - var(--vz-font-base) * 1.25 * 1.5) * .5);
  --vz-navbar-brand-margin-end: 1rem;
  --vz-navbar-brand-font-size: calc(var(--vz-font-base) * 1.25);
  --vz-navbar-brand-color: rgba(var(--vz-emphasis-color-rgb), 1);
  --vz-navbar-brand-hover-color: rgba(var(--vz-emphasis-color-rgb), 1);
  --vz-navbar-nav-link-padding-x: .5rem;
  --vz-navbar-toggler-padding-y: .25rem;
  --vz-navbar-toggler-padding-x: .75rem;
  --vz-navbar-toggler-font-size: calc(var(--vz-font-base) * 1.25);
  --vz-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --vz-navbar-toggler-border-color: rgba(var(--vz-emphasis-color-rgb), .15);
  --vz-navbar-toggler-border-radius: var(--vz-border-radius);
  --vz-navbar-toggler-focus-width: .25rem;
  --vz-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--vz-navbar-padding-y) var(--vz-navbar-padding-x);
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.navbar-brand {
  padding-top: var(--vz-navbar-brand-padding-y);
  padding-bottom: var(--vz-navbar-brand-padding-y);
  margin-right: var(--vz-navbar-brand-margin-end);
  font-size: var(--vz-navbar-brand-font-size);
  color: var(--vz-navbar-brand-color);
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--vz-navbar-brand-hover-color);
}

.navbar-nav {
  --vz-nav-link-padding-x: 0;
  --vz-nav-link-padding-y: .5rem;
  --vz-nav-link-font-weight: ;
  --vz-nav-link-color: var(--vz-navbar-color);
  --vz-nav-link-hover-color: var(--vz-navbar-hover-color);
  --vz-nav-link-disabled-color: var(--vz-navbar-disabled-color);
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--vz-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--vz-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
  color: var(--vz-navbar-active-color);
}

.navbar-collapse {
  -ms-flex-positive: 1;
  -ms-flex-preferred-size: 100%;
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--vz-navbar-toggler-padding-y) var(--vz-navbar-toggler-padding-x);
  font-size: var(--vz-navbar-toggler-font-size);
  color: var(--vz-navbar-color);
  border: var(--vz-border-width) solid var(--vz-navbar-toggler-border-color);
  border-radius: var(--vz-navbar-toggler-border-radius);
  transition: var(--vz-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--vz-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  vertical-align: middle;
  background-image: var(--vz-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--vz-scroll-height, 75vh);
  overflow-y: auto;
}

@media (width >= 576px) {
  .navbar-expand-sm {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--vz-navbar-nav-link-padding-x);
    padding-left: var(--vz-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    -ms-flex-positive: 1;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 768px) {
  .navbar-expand-md {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--vz-navbar-nav-link-padding-x);
    padding-left: var(--vz-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    -ms-flex-positive: 1;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 992px) {
  .navbar-expand-lg {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--vz-navbar-nav-link-padding-x);
    padding-left: var(--vz-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    -ms-flex-positive: 1;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1200px) {
  .navbar-expand-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--vz-navbar-nav-link-padding-x);
    padding-left: var(--vz-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    -ms-flex-positive: 1;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1400px) {
  .navbar-expand-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--vz-navbar-nav-link-padding-x);
    padding-left: var(--vz-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    z-index: auto;
    -ms-flex-positive: 1;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--vz-navbar-nav-link-padding-x);
  padding-left: var(--vz-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  display: -ms-flexbox !important;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  -ms-flex-positive: 1;
  flex-grow: 1;
  transition: none;
  position: static;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  width: auto !important;
  height: auto !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  -ms-flex-positive: 0;
  flex-grow: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  overflow-y: visible;
}

.navbar-dark, .navbar[data-bs-theme="dark"] {
  --vz-navbar-color: #ffffff8c;
  --vz-navbar-hover-color: #ffffffbf;
  --vz-navbar-disabled-color: #ffffff40;
  --vz-navbar-active-color: #fff;
  --vz-navbar-brand-color: #fff;
  --vz-navbar-brand-hover-color: #fff;
  --vz-navbar-toggler-border-color: #ffffff1a;
  --vz-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme="dark"] .navbar-toggler-icon {
  --vz-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --vz-card-spacer-y: 1rem;
  --vz-card-spacer-x: 1rem;
  --vz-card-title-spacer-y: .5rem;
  --vz-card-title-color: var(--vz-heading-color);
  --vz-card-subtitle-color: ;
  --vz-card-border-width: var(--vz-card-border-width-custom);
  --vz-card-border-color: var(--vz-border-color);
  --vz-card-border-radius: var(--vz-border-radius);
  --vz-card-box-shadow: var(--vz-card-shadow);
  --vz-card-inner-border-radius: var(--vz-border-radius);
  --vz-card-cap-padding-y: 1rem;
  --vz-card-cap-padding-x: 1rem;
  --vz-card-cap-bg: var(--vz-secondary-bg);
  --vz-card-cap-color: ;
  --vz-card-height: ;
  --vz-card-color: ;
  --vz-card-bg: var(--vz-secondary-bg);
  --vz-card-img-overlay-padding: 1rem;
  --vz-card-group-margin: calc(var(--vz-grid-gutter-width) * .5);
  height: var(--vz-card-height);
  color: var(--vz-body-color);
  word-wrap: break-word;
  background-color: var(--vz-card-bg);
  border: var(--vz-card-border-width) solid var(--vz-card-border-color);
  border-radius: var(--vz-card-border-radius);
  background-clip: border-box;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--vz-card-inner-border-radius);
  border-top-right-radius: var(--vz-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--vz-card-inner-border-radius);
  border-bottom-left-radius: var(--vz-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--vz-card-spacer-y) var(--vz-card-spacer-x);
  color: var(--vz-card-color);
  -ms-flex: auto;
  flex: auto;
}

.card-title {
  margin-bottom: var(--vz-card-title-spacer-y);
  color: var(--vz-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--vz-card-title-spacer-y));
  color: var(--vz-card-subtitle-color);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--vz-card-spacer-x);
}

.card-header {
  padding: var(--vz-card-cap-padding-y) var(--vz-card-cap-padding-x);
  color: var(--vz-card-cap-color);
  background-color: var(--vz-card-cap-bg);
  border-bottom: var(--vz-card-border-width) solid var(--vz-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--vz-card-inner-border-radius) var(--vz-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--vz-card-cap-padding-y) var(--vz-card-cap-padding-x);
  color: var(--vz-card-cap-color);
  background-color: var(--vz-card-cap-bg);
  border-top: var(--vz-card-border-width) solid var(--vz-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--vz-card-inner-border-radius) var(--vz-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--vz-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--vz-card-cap-padding-y));
  margin-left: calc(-.5 * var(--vz-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--vz-card-bg);
  border-bottom-color: var(--vz-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--vz-card-cap-padding-x));
  margin-left: calc(-.5 * var(--vz-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--vz-card-img-overlay-padding);
  border-radius: var(--vz-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--vz-card-inner-border-radius);
  border-top-right-radius: var(--vz-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--vz-card-inner-border-radius);
  border-bottom-left-radius: var(--vz-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--vz-card-group-margin);
}

@media (width >= 576px) {
  .card-group {
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    display: -ms-flexbox;
    display: flex;
  }

  .card-group > .card {
    -ms-flex: 1 0;
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --vz-accordion-color: var(--vz-body-color);
  --vz-accordion-bg: var(--vz-secondary-bg);
  --vz-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --vz-accordion-border-color: var(--vz-border-color);
  --vz-accordion-border-width: var(--vz-border-width);
  --vz-accordion-border-radius: var(--vz-border-radius);
  --vz-accordion-inner-border-radius: calc(var(--vz-border-radius)  - (var(--vz-border-width)));
  --vz-accordion-btn-padding-x: 1.25rem;
  --vz-accordion-btn-padding-y: 1rem;
  --vz-accordion-btn-color: var(--vz-body-color);
  --vz-accordion-btn-bg: var(--vz-accordion-bg);
  --vz-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--vz-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --vz-accordion-btn-icon-width: .75rem;
  --vz-accordion-btn-icon-transform: rotate(-180deg);
  --vz-accordion-btn-icon-transition: transform .2s ease-in-out;
  --vz-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--vz-primary-text-emphasis%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --vz-accordion-btn-focus-border-color: var(--vz-primary-border-subtle);
  --vz-accordion-btn-focus-box-shadow: 0 0 1 .25rem rgba(var(--vz-primary-rgb), .25);
  --vz-accordion-body-padding-x: 1.25rem;
  --vz-accordion-body-padding-y: 1rem;
  --vz-accordion-active-color: var(--vz-primary-text-emphasis);
  --vz-accordion-active-bg: rgba(var(--vz-primary-rgb), .05);
}

.accordion-button {
  padding: var(--vz-accordion-btn-padding-y) var(--vz-accordion-btn-padding-x);
  font-size: var(--vz-font-base);
  color: var(--vz-accordion-btn-color);
  text-align: left;
  background-color: var(--vz-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--vz-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--vz-accordion-active-color);
  background-color: var(--vz-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--vz-accordion-border-width)) 0 var(--vz-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--vz-accordion-btn-active-icon);
  transform: var(--vz-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--vz-accordion-btn-icon-width);
  height: var(--vz-accordion-btn-icon-width);
  content: "";
  background-image: var(--vz-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--vz-accordion-btn-icon-width);
  transition: var(--vz-accordion-btn-icon-transition);
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--vz-accordion-btn-focus-border-color);
  box-shadow: var(--vz-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--vz-accordion-color);
  background-color: var(--vz-accordion-bg);
  border: var(--vz-accordion-border-width) solid var(--vz-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--vz-accordion-border-radius);
  border-top-right-radius: var(--vz-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--vz-accordion-inner-border-radius);
  border-top-right-radius: var(--vz-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--vz-accordion-border-radius);
  border-bottom-left-radius: var(--vz-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--vz-accordion-inner-border-radius);
  border-bottom-left-radius: var(--vz-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--vz-accordion-border-radius);
  border-bottom-left-radius: var(--vz-accordion-border-radius);
}

.accordion-body {
  padding: var(--vz-accordion-body-padding-y) var(--vz-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button:after {
  --vz-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236674a1'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --vz-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236674a1'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --vz-breadcrumb-padding-x: 0;
  --vz-breadcrumb-padding-y: 0;
  --vz-breadcrumb-margin-bottom: 1rem;
  --vz-breadcrumb-bg: ;
  --vz-breadcrumb-border-radius: ;
  --vz-breadcrumb-divider-color: var(--vz-secondary-color);
  --vz-breadcrumb-item-padding-x: .5rem;
  --vz-breadcrumb-item-active-color: var(--vz-secondary-color);
  padding: var(--vz-breadcrumb-padding-y) var(--vz-breadcrumb-padding-x);
  margin-bottom: var(--vz-breadcrumb-margin-bottom);
  font-size: var(--vz-breadcrumb-font-size);
  background-color: var(--vz-breadcrumb-bg);
  border-radius: var(--vz-breadcrumb-border-radius);
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--vz-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--vz-breadcrumb-item-padding-x);
  color: var(--vz-breadcrumb-divider-color);
  content: var(--vz-breadcrumb-divider, "󰅂");
}

.breadcrumb-item.active {
  color: var(--vz-breadcrumb-item-active-color);
}

.pagination {
  --vz-pagination-padding-x: .75rem;
  --vz-pagination-padding-y: .375rem;
  --vz-pagination-font-size: var(--vz-font-base);
  --vz-pagination-color: var(--vz-link-color);
  --vz-pagination-bg: var(--vz-secondary-bg);
  --vz-pagination-border-width: var(--vz-border-width);
  --vz-pagination-border-color: var(--vz-border-color);
  --vz-pagination-border-radius: var(--vz-border-radius);
  --vz-pagination-hover-color: var(--vz-link-hover-color);
  --vz-pagination-hover-bg: var(--vz-tertiary-bg);
  --vz-pagination-hover-border-color: var(--vz-border-color);
  --vz-pagination-focus-color: var(--vz-link-hover-color);
  --vz-pagination-focus-bg: var(--vz-tertiary-bg);
  --vz-pagination-focus-box-shadow: 0 0 1 .25rem rgba(var(--vz-primary-rgb), .25);
  --vz-pagination-active-color: #fff;
  --vz-pagination-active-bg: var(--vz-primary);
  --vz-pagination-active-border-color: var(--vz-primary);
  --vz-pagination-disabled-color: var(--vz-secondary-color);
  --vz-pagination-disabled-bg: var(--vz-secondary-bg);
  --vz-pagination-disabled-border-color: var(--vz-border-color);
  padding-left: 0;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
}

.page-link {
  padding: var(--vz-pagination-padding-y) var(--vz-pagination-padding-x);
  font-size: var(--vz-pagination-font-size);
  color: var(--vz-pagination-color);
  background-color: var(--vz-pagination-bg);
  border: var(--vz-pagination-border-width) solid var(--vz-pagination-border-color);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--vz-pagination-hover-color);
  background-color: var(--vz-pagination-hover-bg);
  border-color: var(--vz-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--vz-pagination-focus-color);
  background-color: var(--vz-pagination-focus-bg);
  box-shadow: var(--vz-pagination-focus-box-shadow);
  outline: 0;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--vz-pagination-active-color);
  background-color: var(--vz-pagination-active-bg);
  border-color: var(--vz-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--vz-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--vz-pagination-disabled-bg);
  border-color: var(--vz-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--vz-border-width) * -1);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--vz-pagination-border-radius);
  border-bottom-left-radius: var(--vz-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--vz-pagination-border-radius);
  border-bottom-right-radius: var(--vz-pagination-border-radius);
}

.pagination-lg {
  --vz-pagination-padding-x: 1.2rem;
  --vz-pagination-padding-y: .6rem;
  --vz-pagination-font-size: calc(var(--vz-font-base) * 1.25);
  --vz-pagination-border-radius: var(--vz-border-radius-lg);
}

.pagination-sm {
  --vz-pagination-padding-x: .5rem;
  --vz-pagination-padding-y: .25rem;
  --vz-pagination-font-size: calc(var(--vz-font-base) * .875);
  --vz-pagination-border-radius: var(--vz-border-radius-sm);
}

.badge {
  --vz-badge-padding-x: .65em;
  --vz-badge-padding-y: .35em;
  --vz-badge-font-size: .75em;
  --vz-badge-font-weight: var(--vz-font-weight-semibold);
  --vz-badge-color: #fff;
  --vz-badge-border-radius: .25rem;
  padding: var(--vz-badge-padding-y) var(--vz-badge-padding-x);
  font-size: var(--vz-badge-font-size);
  font-weight: var(--vz-badge-font-weight);
  color: var(--vz-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--vz-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --vz-alert-bg: transparent;
  --vz-alert-padding-x: 1rem;
  --vz-alert-padding-y: .8rem;
  --vz-alert-margin-bottom: 1rem;
  --vz-alert-color: inherit;
  --vz-alert-border-color: transparent;
  --vz-alert-border: var(--vz-border-width) solid var(--vz-alert-border-color);
  --vz-alert-border-radius: var(--vz-border-radius);
  --vz-alert-link-color: inherit;
  padding: var(--vz-alert-padding-y) var(--vz-alert-padding-x);
  margin-bottom: var(--vz-alert-margin-bottom);
  color: var(--vz-alert-color);
  background-color: var(--vz-alert-bg);
  border: var(--vz-alert-border);
  border-radius: var(--vz-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: var(--vz-font-weight-semibold);
  color: var(--vz-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --vz-alert-color: var(--vz-primary-text-emphasis);
  --vz-alert-bg: var(--vz-primary-bg-subtle);
  --vz-alert-border-color: var(--vz-primary-border-subtle);
  --vz-alert-link-color: var(--vz-primary-text-emphasis);
}

.alert-secondary {
  --vz-alert-color: var(--vz-secondary-text-emphasis);
  --vz-alert-bg: var(--vz-secondary-bg-subtle);
  --vz-alert-border-color: var(--vz-secondary-border-subtle);
  --vz-alert-link-color: var(--vz-secondary-text-emphasis);
}

.alert-success {
  --vz-alert-color: var(--vz-success-text-emphasis);
  --vz-alert-bg: var(--vz-success-bg-subtle);
  --vz-alert-border-color: var(--vz-success-border-subtle);
  --vz-alert-link-color: var(--vz-success-text-emphasis);
}

.alert-info {
  --vz-alert-color: var(--vz-info-text-emphasis);
  --vz-alert-bg: var(--vz-info-bg-subtle);
  --vz-alert-border-color: var(--vz-info-border-subtle);
  --vz-alert-link-color: var(--vz-info-text-emphasis);
}

.alert-warning {
  --vz-alert-color: var(--vz-warning-text-emphasis);
  --vz-alert-bg: var(--vz-warning-bg-subtle);
  --vz-alert-border-color: var(--vz-warning-border-subtle);
  --vz-alert-link-color: var(--vz-warning-text-emphasis);
}

.alert-danger {
  --vz-alert-color: var(--vz-danger-text-emphasis);
  --vz-alert-bg: var(--vz-danger-bg-subtle);
  --vz-alert-border-color: var(--vz-danger-border-subtle);
  --vz-alert-link-color: var(--vz-danger-text-emphasis);
}

.alert-light {
  --vz-alert-color: var(--vz-light-text-emphasis);
  --vz-alert-bg: var(--vz-light-bg-subtle);
  --vz-alert-border-color: var(--vz-light-border-subtle);
  --vz-alert-link-color: var(--vz-light-text-emphasis);
}

.alert-dark {
  --vz-alert-color: var(--vz-dark-text-emphasis);
  --vz-alert-bg: var(--vz-dark-bg-subtle);
  --vz-alert-border-color: var(--vz-dark-border-subtle);
  --vz-alert-link-color: var(--vz-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: .625rem;
  }
}

.progress, .progress-stacked {
  --vz-progress-height: .625rem;
  --vz-progress-font-size: calc(var(--vz-font-base) * .75);
  --vz-progress-bg: var(--vz-tertiary-bg);
  --vz-progress-border-radius: var(--vz-border-radius);
  --vz-progress-box-shadow: var(--vz-box-shadow-inset);
  --vz-progress-bar-color: #fff;
  --vz-progress-bar-bg: var(--vz-primary);
  --vz-progress-bar-transition: width .6s ease;
  height: var(--vz-progress-height);
  font-size: var(--vz-progress-font-size);
  background-color: var(--vz-progress-bg);
  border-radius: var(--vz-progress-border-radius);
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: var(--vz-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--vz-progress-bar-bg);
  transition: var(--vz-progress-bar-transition);
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--vz-progress-height) var(--vz-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --vz-list-group-color: var(--vz-body-color);
  --vz-list-group-bg: var(--vz-secondary-bg);
  --vz-list-group-border-color: var(--vz-border-color);
  --vz-list-group-border-width: var(--vz-border-width);
  --vz-list-group-border-radius: var(--vz-border-radius);
  --vz-list-group-item-padding-x: 1rem;
  --vz-list-group-item-padding-y: .7rem;
  --vz-list-group-action-color: var(--vz-secondary-color);
  --vz-list-group-action-hover-color: var(--vz-emphasis-color);
  --vz-list-group-action-hover-bg: var(--vz-tertiary-bg);
  --vz-list-group-action-active-color: var(--vz-body-color);
  --vz-list-group-action-active-bg: var(--vz-secondary-bg);
  --vz-list-group-disabled-color: var(--vz-secondary-color);
  --vz-list-group-disabled-bg: var(--vz-secondary-bg);
  --vz-list-group-active-color: #fff;
  --vz-list-group-active-bg: var(--vz-primary);
  --vz-list-group-active-border-color: var(--vz-primary);
  border-radius: var(--vz-list-group-border-radius);
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: -ms-flexbox;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  color: var(--vz-list-group-action-color);
  text-align: inherit;
  width: 100%;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--vz-list-group-action-hover-color);
  background-color: var(--vz-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--vz-list-group-action-active-color);
  background-color: var(--vz-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--vz-list-group-item-padding-y) var(--vz-list-group-item-padding-x);
  color: var(--vz-list-group-color);
  background-color: var(--vz-list-group-bg);
  border: var(--vz-list-group-border-width) solid var(--vz-list-group-border-color);
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--vz-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--vz-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--vz-list-group-active-color);
  background-color: var(--vz-list-group-active-bg);
  border-color: var(--vz-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--vz-list-group-border-width));
  border-top-width: var(--vz-list-group-border-width);
}

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--vz-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--vz-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--vz-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--vz-list-group-border-width));
  border-left-width: var(--vz-list-group-border-width);
}

@media (width >= 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--vz-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--vz-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--vz-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--vz-list-group-border-width));
    border-left-width: var(--vz-list-group-border-width);
  }
}

@media (width >= 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--vz-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--vz-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--vz-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--vz-list-group-border-width));
    border-left-width: var(--vz-list-group-border-width);
  }
}

@media (width >= 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--vz-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--vz-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--vz-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--vz-list-group-border-width));
    border-left-width: var(--vz-list-group-border-width);
  }
}

@media (width >= 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--vz-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--vz-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--vz-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--vz-list-group-border-width));
    border-left-width: var(--vz-list-group-border-width);
  }
}

@media (width >= 1400px) {
  .list-group-horizontal-xxl {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--vz-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--vz-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--vz-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--vz-list-group-border-width));
    border-left-width: var(--vz-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--vz-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --vz-list-group-color: var(--vz-primary-text-emphasis);
  --vz-list-group-bg: var(--vz-primary-bg-subtle);
  --vz-list-group-border-color: var(--vz-primary-border-subtle);
  --vz-list-group-action-hover-color: var(--vz-emphasis-color);
  --vz-list-group-action-hover-bg: var(--vz-primary-border-subtle);
  --vz-list-group-action-active-color: var(--vz-emphasis-color);
  --vz-list-group-action-active-bg: var(--vz-primary-border-subtle);
  --vz-list-group-active-color: var(--vz-primary-bg-subtle);
  --vz-list-group-active-bg: var(--vz-primary-text-emphasis);
  --vz-list-group-active-border-color: var(--vz-primary-text-emphasis);
}

.list-group-item-secondary {
  --vz-list-group-color: var(--vz-secondary-text-emphasis);
  --vz-list-group-bg: var(--vz-secondary-bg-subtle);
  --vz-list-group-border-color: var(--vz-secondary-border-subtle);
  --vz-list-group-action-hover-color: var(--vz-emphasis-color);
  --vz-list-group-action-hover-bg: var(--vz-secondary-border-subtle);
  --vz-list-group-action-active-color: var(--vz-emphasis-color);
  --vz-list-group-action-active-bg: var(--vz-secondary-border-subtle);
  --vz-list-group-active-color: var(--vz-secondary-bg-subtle);
  --vz-list-group-active-bg: var(--vz-secondary-text-emphasis);
  --vz-list-group-active-border-color: var(--vz-secondary-text-emphasis);
}

.list-group-item-success {
  --vz-list-group-color: var(--vz-success-text-emphasis);
  --vz-list-group-bg: var(--vz-success-bg-subtle);
  --vz-list-group-border-color: var(--vz-success-border-subtle);
  --vz-list-group-action-hover-color: var(--vz-emphasis-color);
  --vz-list-group-action-hover-bg: var(--vz-success-border-subtle);
  --vz-list-group-action-active-color: var(--vz-emphasis-color);
  --vz-list-group-action-active-bg: var(--vz-success-border-subtle);
  --vz-list-group-active-color: var(--vz-success-bg-subtle);
  --vz-list-group-active-bg: var(--vz-success-text-emphasis);
  --vz-list-group-active-border-color: var(--vz-success-text-emphasis);
}

.list-group-item-info {
  --vz-list-group-color: var(--vz-info-text-emphasis);
  --vz-list-group-bg: var(--vz-info-bg-subtle);
  --vz-list-group-border-color: var(--vz-info-border-subtle);
  --vz-list-group-action-hover-color: var(--vz-emphasis-color);
  --vz-list-group-action-hover-bg: var(--vz-info-border-subtle);
  --vz-list-group-action-active-color: var(--vz-emphasis-color);
  --vz-list-group-action-active-bg: var(--vz-info-border-subtle);
  --vz-list-group-active-color: var(--vz-info-bg-subtle);
  --vz-list-group-active-bg: var(--vz-info-text-emphasis);
  --vz-list-group-active-border-color: var(--vz-info-text-emphasis);
}

.list-group-item-warning {
  --vz-list-group-color: var(--vz-warning-text-emphasis);
  --vz-list-group-bg: var(--vz-warning-bg-subtle);
  --vz-list-group-border-color: var(--vz-warning-border-subtle);
  --vz-list-group-action-hover-color: var(--vz-emphasis-color);
  --vz-list-group-action-hover-bg: var(--vz-warning-border-subtle);
  --vz-list-group-action-active-color: var(--vz-emphasis-color);
  --vz-list-group-action-active-bg: var(--vz-warning-border-subtle);
  --vz-list-group-active-color: var(--vz-warning-bg-subtle);
  --vz-list-group-active-bg: var(--vz-warning-text-emphasis);
  --vz-list-group-active-border-color: var(--vz-warning-text-emphasis);
}

.list-group-item-danger {
  --vz-list-group-color: var(--vz-danger-text-emphasis);
  --vz-list-group-bg: var(--vz-danger-bg-subtle);
  --vz-list-group-border-color: var(--vz-danger-border-subtle);
  --vz-list-group-action-hover-color: var(--vz-emphasis-color);
  --vz-list-group-action-hover-bg: var(--vz-danger-border-subtle);
  --vz-list-group-action-active-color: var(--vz-emphasis-color);
  --vz-list-group-action-active-bg: var(--vz-danger-border-subtle);
  --vz-list-group-active-color: var(--vz-danger-bg-subtle);
  --vz-list-group-active-bg: var(--vz-danger-text-emphasis);
  --vz-list-group-active-border-color: var(--vz-danger-text-emphasis);
}

.list-group-item-light {
  --vz-list-group-color: var(--vz-light-text-emphasis);
  --vz-list-group-bg: var(--vz-light-bg-subtle);
  --vz-list-group-border-color: var(--vz-light-border-subtle);
  --vz-list-group-action-hover-color: var(--vz-emphasis-color);
  --vz-list-group-action-hover-bg: var(--vz-light-border-subtle);
  --vz-list-group-action-active-color: var(--vz-emphasis-color);
  --vz-list-group-action-active-bg: var(--vz-light-border-subtle);
  --vz-list-group-active-color: var(--vz-light-bg-subtle);
  --vz-list-group-active-bg: var(--vz-light-text-emphasis);
  --vz-list-group-active-border-color: var(--vz-light-text-emphasis);
}

.list-group-item-dark {
  --vz-list-group-color: var(--vz-dark-text-emphasis);
  --vz-list-group-bg: var(--vz-dark-bg-subtle);
  --vz-list-group-border-color: var(--vz-dark-border-subtle);
  --vz-list-group-action-hover-color: var(--vz-emphasis-color);
  --vz-list-group-action-hover-bg: var(--vz-dark-border-subtle);
  --vz-list-group-action-active-color: var(--vz-emphasis-color);
  --vz-list-group-action-active-bg: var(--vz-dark-border-subtle);
  --vz-list-group-active-color: var(--vz-dark-bg-subtle);
  --vz-list-group-active-bg: var(--vz-dark-text-emphasis);
  --vz-list-group-active-border-color: var(--vz-dark-text-emphasis);
}

.btn-close {
  --vz-btn-close-color: #000;
  --vz-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  --vz-btn-close-opacity: .5;
  --vz-btn-close-hover-opacity: .75;
  --vz-btn-close-focus-shadow: 0 0 1 .25rem rgba(var(--vz-primary-rgb), .25);
  --vz-btn-close-focus-opacity: 1;
  --vz-btn-close-disabled-opacity: .25;
  --vz-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  color: var(--vz-btn-close-color);
  background: transparent var(--vz-btn-close-bg) center / 1em auto no-repeat;
  opacity: var(--vz-btn-close-opacity);
  border: 0;
  border-radius: .25rem;
  width: 1em;
  height: 1em;
  padding: .25em;
}

.btn-close:hover {
  color: var(--vz-btn-close-color);
  opacity: var(--vz-btn-close-hover-opacity);
  text-decoration: none;
}

.btn-close:focus {
  box-shadow: var(--vz-btn-close-focus-shadow);
  opacity: var(--vz-btn-close-focus-opacity);
  outline: 0;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: var(--vz-btn-close-disabled-opacity);
}

.btn-close-white, [data-bs-theme="dark"] .btn-close {
  filter: var(--vz-btn-close-white-filter);
}

.toast {
  --vz-toast-zindex: 1090;
  --vz-toast-padding-x: .75rem;
  --vz-toast-padding-y: .5rem;
  --vz-toast-spacing: calc(var(--vz-grid-gutter-width) * 1);
  --vz-toast-max-width: 350px;
  --vz-toast-font-size: .875rem;
  --vz-toast-color: ;
  --vz-toast-bg: var(--vz-secondary-bg);
  --vz-toast-border-width: var(--vz-border-width);
  --vz-toast-border-color: var(--vz-border-color);
  --vz-toast-border-radius: var(--vz-border-radius);
  --vz-toast-box-shadow: var(--vz-box-shadow);
  --vz-toast-header-color: var(--vz-secondary-color);
  --vz-toast-header-bg: var(--vz-secondary-bg);
  --vz-toast-header-border-color: var(--vz-border-color);
  width: var(--vz-toast-max-width);
  font-size: var(--vz-toast-font-size);
  color: var(--vz-toast-color);
  pointer-events: auto;
  background-color: var(--vz-toast-bg);
  border: var(--vz-toast-border-width) solid var(--vz-toast-border-color);
  box-shadow: var(--vz-toast-box-shadow);
  border-radius: var(--vz-toast-border-radius);
  background-clip: padding-box;
  max-width: 100%;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --vz-toast-zindex: 1090;
  z-index: var(--vz-toast-zindex);
  pointer-events: none;
  width: max-content;
  max-width: 100%;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--vz-toast-spacing);
}

.toast-header {
  padding: var(--vz-toast-padding-y) var(--vz-toast-padding-x);
  color: var(--vz-toast-header-color);
  background-color: var(--vz-toast-header-bg);
  border-bottom: var(--vz-toast-border-width) solid var(--vz-toast-header-border-color);
  border-top-left-radius: calc(var(--vz-toast-border-radius)  - var(--vz-toast-border-width));
  border-top-right-radius: calc(var(--vz-toast-border-radius)  - var(--vz-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--vz-toast-padding-x));
  margin-left: var(--vz-toast-padding-x);
}

.toast-body {
  padding: var(--vz-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --vz-modal-zindex: 1055;
  --vz-modal-width: 500px;
  --vz-modal-padding: 1.25rem;
  --vz-modal-margin: .5rem;
  --vz-modal-color: ;
  --vz-modal-bg: var(--vz-secondary-bg);
  --vz-modal-border-color: var(--vz-border-color);
  --vz-modal-border-width: var(--vz-border-width);
  --vz-modal-border-radius: var(--vz-border-radius-lg);
  --vz-modal-box-shadow: 0 .125rem .25rem #00000013;
  --vz-modal-inner-border-radius: calc(var(--vz-border-radius-lg)  - (var(--vz-border-width)));
  --vz-modal-header-padding-x: 1.25rem;
  --vz-modal-header-padding-y: 1.25rem;
  --vz-modal-header-padding: 1.25rem 1.25rem;
  --vz-modal-header-border-color: var(--vz-border-color);
  --vz-modal-header-border-width: 0;
  --vz-modal-title-line-height: 1.5;
  --vz-modal-footer-gap: .5rem;
  --vz-modal-footer-bg: ;
  --vz-modal-footer-border-color: var(--vz-border-color);
  --vz-modal-footer-border-width: 0;
  z-index: var(--vz-modal-zindex);
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

.modal-dialog {
  margin: var(--vz-modal-margin);
  pointer-events: none;
  width: auto;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--vz-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - var(--vz-modal-margin) * 2);
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.modal-content {
  color: var(--vz-modal-color);
  pointer-events: auto;
  background-color: var(--vz-modal-bg);
  border: var(--vz-modal-border-width) solid var(--vz-modal-border-color);
  border-radius: var(--vz-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --vz-backdrop-zindex: 1050;
  --vz-backdrop-bg: #000;
  --vz-backdrop-opacity: .5;
  z-index: var(--vz-backdrop-zindex);
  background-color: var(--vz-backdrop-bg);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--vz-backdrop-opacity);
}

.modal-header {
  padding: var(--vz-modal-header-padding);
  border-bottom: var(--vz-modal-header-border-width) solid var(--vz-modal-header-border-color);
  border-top-left-radius: var(--vz-modal-inner-border-radius);
  border-top-right-radius: var(--vz-modal-inner-border-radius);
  -ms-flex-negative: 0;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--vz-modal-header-padding-y) * .5) calc(var(--vz-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--vz-modal-header-padding-y)) calc(-.5 * var(--vz-modal-header-padding-x)) calc(-.5 * var(--vz-modal-header-padding-y)) auto;
}

.modal-title {
  line-height: var(--vz-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--vz-modal-padding);
  -ms-flex: auto;
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--vz-modal-padding)  - var(--vz-modal-footer-gap) * .5);
  background-color: var(--vz-modal-footer-bg);
  border-top: var(--vz-modal-footer-border-width) solid var(--vz-modal-footer-border-color);
  border-bottom-right-radius: var(--vz-modal-inner-border-radius);
  border-bottom-left-radius: var(--vz-modal-inner-border-radius);
  -ms-flex-negative: 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--vz-modal-footer-gap) * .5);
}

@media (width >= 576px) {
  .modal {
    --vz-modal-margin: 1.75rem;
    --vz-modal-box-shadow: 0 1px 2px #38414a26;
  }

  .modal-dialog {
    max-width: var(--vz-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --vz-modal-width: 300px;
  }
}

@media (width >= 992px) {
  .modal-lg, .modal-xl {
    --vz-modal-width: 800px;
  }
}

@media (width >= 1200px) {
  .modal-xl {
    --vz-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.modal-fullscreen .modal-header, .modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (width <= 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xxl-down .modal-header, .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --vz-tooltip-zindex: 1080;
  --vz-tooltip-max-width: 200px;
  --vz-tooltip-padding-x: .7rem;
  --vz-tooltip-padding-y: .4rem;
  --vz-tooltip-margin: ;
  --vz-tooltip-font-size: calc(var(--vz-font-base) * .875);
  --vz-tooltip-color: var(--vz-body-bg);
  --vz-tooltip-bg: var(--vz-emphasis-color);
  --vz-tooltip-border-radius: var(--vz-border-radius);
  --vz-tooltip-opacity: .9;
  --vz-tooltip-arrow-width: .8rem;
  --vz-tooltip-arrow-height: .4rem;
  z-index: var(--vz-tooltip-zindex);
  margin: var(--vz-tooltip-margin);
  font-family: var(--vz-font-sans-serif);
  font-style: normal;
  font-weight: var(--vz-font-weight-normal);
  text-align: left;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  font-size: var(--vz-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  text-align: left;
}

.tooltip:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.tooltip.show {
  opacity: var(--vz-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  width: var(--vz-tooltip-arrow-width);
  height: var(--vz-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--vz-tooltip-arrow-height));
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: var(--vz-tooltip-arrow-height) calc(var(--vz-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--vz-tooltip-bg);
  top: -1px;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--vz-tooltip-arrow-height));
  width: var(--vz-tooltip-arrow-height);
  height: var(--vz-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: calc(var(--vz-tooltip-arrow-width) * .5) var(--vz-tooltip-arrow-height) calc(var(--vz-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--vz-tooltip-bg);
  right: -1px;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--vz-tooltip-arrow-height));
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 calc(var(--vz-tooltip-arrow-width) * .5) var(--vz-tooltip-arrow-height);
  border-bottom-color: var(--vz-tooltip-bg);
  bottom: -1px;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--vz-tooltip-arrow-height));
  width: var(--vz-tooltip-arrow-height);
  height: var(--vz-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: calc(var(--vz-tooltip-arrow-width) * .5) 0 calc(var(--vz-tooltip-arrow-width) * .5) var(--vz-tooltip-arrow-height);
  border-left-color: var(--vz-tooltip-bg);
  left: -1px;
}

.tooltip-inner {
  max-width: var(--vz-tooltip-max-width);
  padding: var(--vz-tooltip-padding-y) var(--vz-tooltip-padding-x);
  color: var(--vz-tooltip-color);
  text-align: center;
  background-color: var(--vz-tooltip-bg);
  border-radius: var(--vz-tooltip-border-radius);
}

.popover {
  --vz-popover-zindex: 1070;
  --vz-popover-max-width: 276px;
  --vz-popover-font-size: var(--vz-font-base);
  --vz-popover-bg: var(--vz-secondary-bg);
  --vz-popover-border-width: var(--vz-border-width);
  --vz-popover-border-color: var(--vz-border-color);
  --vz-popover-border-radius: var(--vz-border-radius);
  --vz-popover-inner-border-radius: calc(var(--vz-border-radius)  - var(--vz-border-width));
  --vz-popover-box-shadow: 0 5px 10px #1e20251f;
  --vz-popover-header-padding-x: 1rem;
  --vz-popover-header-padding-y: .7rem;
  --vz-popover-header-font-size: var(--vz-font-base);
  --vz-popover-header-color: var(--vz-body-color);
  --vz-popover-header-bg: var(--vz-secondary-bg);
  --vz-popover-body-padding-x: 1rem;
  --vz-popover-body-padding-y: 1rem;
  --vz-popover-body-color: var(--vz-body-color);
  --vz-popover-arrow-width: 1rem;
  --vz-popover-arrow-height: .5rem;
  --vz-popover-arrow-border: var(--vz-popover-border-color);
  z-index: var(--vz-popover-zindex);
  max-width: var(--vz-popover-max-width);
  font-family: var(--vz-font-sans-serif);
  font-style: normal;
  font-weight: var(--vz-font-weight-normal);
  text-align: left;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  font-size: var(--vz-popover-font-size);
  word-wrap: break-word;
  background-color: var(--vz-popover-bg);
  border: var(--vz-popover-border-width) solid var(--vz-popover-border-color);
  border-radius: var(--vz-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  text-align: left;
}

.popover:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.popover .popover-arrow {
  width: var(--vz-popover-arrow-width);
  height: var(--vz-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--vz-popover-arrow-height))  - var(--vz-popover-border-width));
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: var(--vz-popover-arrow-height) calc(var(--vz-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-top-color: var(--vz-popover-arrow-border);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  bottom: var(--vz-popover-border-width);
  border-top-color: var(--vz-popover-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--vz-popover-arrow-height))  - var(--vz-popover-border-width));
  width: var(--vz-popover-arrow-height);
  height: var(--vz-popover-arrow-width);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: calc(var(--vz-popover-arrow-width) * .5) var(--vz-popover-arrow-height) calc(var(--vz-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-right-color: var(--vz-popover-arrow-border);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  left: var(--vz-popover-border-width);
  border-right-color: var(--vz-popover-bg);
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--vz-popover-arrow-height))  - var(--vz-popover-border-width));
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 calc(var(--vz-popover-arrow-width) * .5) var(--vz-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-bottom-color: var(--vz-popover-arrow-border);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  top: var(--vz-popover-border-width);
  border-bottom-color: var(--vz-popover-bg);
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: var(--vz-popover-arrow-width);
  margin-left: calc(-.5 * var(--vz-popover-arrow-width));
  content: "";
  border-bottom: var(--vz-popover-border-width) solid var(--vz-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--vz-popover-arrow-height))  - var(--vz-popover-border-width));
  width: var(--vz-popover-arrow-height);
  height: var(--vz-popover-arrow-width);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: calc(var(--vz-popover-arrow-width) * .5) 0 calc(var(--vz-popover-arrow-width) * .5) var(--vz-popover-arrow-height);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-left-color: var(--vz-popover-arrow-border);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  right: var(--vz-popover-border-width);
  border-left-color: var(--vz-popover-bg);
}

.popover-header {
  padding: var(--vz-popover-header-padding-y) var(--vz-popover-header-padding-x);
  font-size: var(--vz-popover-header-font-size);
  color: var(--vz-popover-header-color);
  background-color: var(--vz-popover-header-bg);
  border-bottom: var(--vz-popover-border-width) solid var(--vz-popover-border-color);
  border-top-left-radius: var(--vz-popover-inner-border-radius);
  border-top-right-radius: var(--vz-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--vz-popover-body-padding-y) var(--vz-popover-body-padding-x);
  color: var(--vz-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  backface-visibility: hidden;
  width: 100%;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  width: 15%;
  padding: 0;
  transition: opacity .15s;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2rem;
  height: 2rem;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  -ms-flex: 0 auto;
  flex: 0 auto;
  width: 30px;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon, [data-bs-theme="dark"] .carousel .carousel-control-next-icon, [data-bs-theme="dark"].carousel .carousel-control-prev-icon, [data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-caption, [data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}

.spinner-grow, .spinner-border {
  width: var(--vz-spinner-width);
  height: var(--vz-spinner-height);
  vertical-align: var(--vz-spinner-vertical-align);
  animation: var(--vz-spinner-animation-speed) linear infinite var(--vz-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --vz-spinner-width: 2rem;
  --vz-spinner-height: 2rem;
  --vz-spinner-vertical-align: -.125em;
  --vz-spinner-border-width: .25em;
  --vz-spinner-animation-speed: .75s;
  --vz-spinner-animation-name: spinner-border;
  border: var(--vz-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.spinner-border-sm {
  --vz-spinner-width: 1rem;
  --vz-spinner-height: 1rem;
  --vz-spinner-border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --vz-spinner-width: 2rem;
  --vz-spinner-height: 2rem;
  --vz-spinner-vertical-align: -.125em;
  --vz-spinner-animation-speed: .75s;
  --vz-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --vz-spinner-width: 1rem;
  --vz-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --vz-spinner-animation-speed: 1.5s;
  }
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --vz-offcanvas-zindex: 1045;
  --vz-offcanvas-width: 400px;
  --vz-offcanvas-height: 30vh;
  --vz-offcanvas-padding-x: 1.25rem;
  --vz-offcanvas-padding-y: 1.25rem;
  --vz-offcanvas-color: var(--vz-body-color);
  --vz-offcanvas-bg: var(--vz-secondary-bg);
  --vz-offcanvas-border-width: var(--vz-border-width);
  --vz-offcanvas-border-color: var(--vz-border-color);
  --vz-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
  --vz-offcanvas-transition: transform .3s ease-in-out;
  --vz-offcanvas-title-line-height: 1.5;
}

@media (width <= 575.98px) {
  .offcanvas-sm {
    z-index: var(--vz-offcanvas-zindex);
    color: var(--vz-offcanvas-color);
    visibility: hidden;
    background-color: var(--vz-offcanvas-bg);
    transition: var(--vz-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (width <= 575.98px) {
  .offcanvas-sm.offcanvas-start {
    width: var(--vz-offcanvas-width);
    border-right: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--vz-offcanvas-width);
    border-left: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    height: var(--vz-offcanvas-height);
    border-bottom: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    height: var(--vz-offcanvas-height);
    border-top: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (width >= 576px) {
  .offcanvas-sm {
    --vz-offcanvas-height: auto;
    --vz-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 767.98px) {
  .offcanvas-md {
    z-index: var(--vz-offcanvas-zindex);
    color: var(--vz-offcanvas-color);
    visibility: hidden;
    background-color: var(--vz-offcanvas-bg);
    transition: var(--vz-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (width <= 767.98px) {
  .offcanvas-md.offcanvas-start {
    width: var(--vz-offcanvas-width);
    border-right: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--vz-offcanvas-width);
    border-left: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    height: var(--vz-offcanvas-height);
    border-bottom: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    height: var(--vz-offcanvas-height);
    border-top: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (width >= 768px) {
  .offcanvas-md {
    --vz-offcanvas-height: auto;
    --vz-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 991.98px) {
  .offcanvas-lg {
    z-index: var(--vz-offcanvas-zindex);
    color: var(--vz-offcanvas-color);
    visibility: hidden;
    background-color: var(--vz-offcanvas-bg);
    transition: var(--vz-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (width <= 991.98px) {
  .offcanvas-lg.offcanvas-start {
    width: var(--vz-offcanvas-width);
    border-right: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--vz-offcanvas-width);
    border-left: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    height: var(--vz-offcanvas-height);
    border-bottom: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    height: var(--vz-offcanvas-height);
    border-top: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (width >= 992px) {
  .offcanvas-lg {
    --vz-offcanvas-height: auto;
    --vz-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1199.98px) {
  .offcanvas-xl {
    z-index: var(--vz-offcanvas-zindex);
    color: var(--vz-offcanvas-color);
    visibility: hidden;
    background-color: var(--vz-offcanvas-bg);
    transition: var(--vz-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (width <= 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    width: var(--vz-offcanvas-width);
    border-right: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--vz-offcanvas-width);
    border-left: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    height: var(--vz-offcanvas-height);
    border-bottom: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    height: var(--vz-offcanvas-height);
    border-top: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (width >= 1200px) {
  .offcanvas-xl {
    --vz-offcanvas-height: auto;
    --vz-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-xxl {
    z-index: var(--vz-offcanvas-zindex);
    color: var(--vz-offcanvas-color);
    visibility: hidden;
    background-color: var(--vz-offcanvas-bg);
    transition: var(--vz-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    width: var(--vz-offcanvas-width);
    border-right: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    width: var(--vz-offcanvas-width);
    border-left: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    height: var(--vz-offcanvas-height);
    border-bottom: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    height: var(--vz-offcanvas-height);
    border-top: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (width >= 1400px) {
  .offcanvas-xxl {
    --vz-offcanvas-height: auto;
    --vz-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.offcanvas {
  z-index: var(--vz-offcanvas-zindex);
  color: var(--vz-offcanvas-color);
  visibility: hidden;
  background-color: var(--vz-offcanvas-bg);
  transition: var(--vz-offcanvas-transition);
  background-clip: padding-box;
  outline: 0;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--vz-offcanvas-width);
  border-right: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--vz-offcanvas-width);
  border-left: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  height: var(--vz-offcanvas-height);
  border-bottom: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  height: var(--vz-offcanvas-height);
  border-top: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
  max-height: 100%;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas-header {
  padding: var(--vz-offcanvas-padding-y) var(--vz-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--vz-offcanvas-padding-y) * .5) calc(var(--vz-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--vz-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--vz-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--vz-offcanvas-padding-y));
}

.offcanvas-title {
  line-height: var(--vz-offcanvas-title-line-height);
  margin-bottom: 0;
}

.offcanvas-body {
  padding: var(--vz-offcanvas-padding-y) var(--vz-offcanvas-padding-x);
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow-y: auto;
}

.placeholder {
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  min-height: 1em;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: 2s ease-in-out infinite placeholder-glow;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  animation: 2s linear infinite placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--vz-primary-rgb), var(--vz-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--vz-secondary-rgb), var(--vz-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--vz-success-rgb), var(--vz-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(var(--vz-info-rgb), var(--vz-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #fff !important;
  background-color: RGBA(var(--vz-warning-rgb), var(--vz-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--vz-danger-rgb), var(--vz-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--vz-light-rgb), var(--vz-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--vz-dark-rgb), var(--vz-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--vz-primary-rgb), var(--vz-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--vz-primary-rgb), var(--vz-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--vz-primary-rgb), var(--vz-link-underline-opacity, 1)) !important;
}

.link-primary:hover, .link-primary:focus {
  color: RGBA(51, 65, 110, var(--vz-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(51, 65, 110, var(--vz-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(51, 65, 110, var(--vz-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--vz-secondary-rgb), var(--vz-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--vz-secondary-rgb), var(--vz-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--vz-secondary-rgb), var(--vz-link-underline-opacity, 1)) !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: RGBA(42, 95, 193, var(--vz-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(42, 95, 193, var(--vz-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(42, 95, 193, var(--vz-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--vz-success-rgb), var(--vz-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--vz-success-rgb), var(--vz-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--vz-success-rgb), var(--vz-link-underline-opacity, 1)) !important;
}

.link-success:hover, .link-success:focus {
  color: RGBA(8, 143, 125, var(--vz-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(8, 143, 125, var(--vz-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(8, 143, 125, var(--vz-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--vz-info-rgb), var(--vz-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--vz-info-rgb), var(--vz-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--vz-info-rgb), var(--vz-link-underline-opacity, 1)) !important;
}

.link-info:hover, .link-info:focus {
  color: RGBA(33, 125, 175, var(--vz-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(33, 125, 175, var(--vz-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(33, 125, 175, var(--vz-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--vz-warning-rgb), var(--vz-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--vz-warning-rgb), var(--vz-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--vz-warning-rgb), var(--vz-link-underline-opacity, 1)) !important;
}

.link-warning:hover, .link-warning:focus {
  color: RGBA(198, 147, 60, var(--vz-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(198, 147, 60, var(--vz-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(198, 147, 60, var(--vz-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--vz-danger-rgb), var(--vz-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--vz-danger-rgb), var(--vz-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--vz-danger-rgb), var(--vz-link-underline-opacity, 1)) !important;
}

.link-danger:hover, .link-danger:focus {
  color: RGBA(192, 81, 58, var(--vz-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(192, 81, 58, var(--vz-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(192, 81, 58, var(--vz-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--vz-light-rgb), var(--vz-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--vz-light-rgb), var(--vz-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--vz-light-rgb), var(--vz-link-underline-opacity, 1)) !important;
}

.link-light:hover, .link-light:focus {
  color: RGBA(245, 248, 250, var(--vz-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(245, 248, 250, var(--vz-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(245, 248, 250, var(--vz-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--vz-dark-rgb), var(--vz-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--vz-dark-rgb), var(--vz-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--vz-dark-rgb), var(--vz-link-underline-opacity, 1)) !important;
}

.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--vz-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(26, 30, 33, var(--vz-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--vz-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--vz-emphasis-color-rgb), var(--vz-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--vz-emphasis-color-rgb), var(--vz-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--vz-emphasis-color-rgb), var(--vz-link-underline-opacity, 1)) !important;
}

.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--vz-emphasis-color-rgb), var(--vz-link-opacity, .75)) !important;
  -webkit-text-decoration-color: RGBA(var(--vz-emphasis-color-rgb), var(--vz-link-underline-opacity, .75)) !important;
  text-decoration-color: RGBA(var(--vz-emphasis-color-rgb), var(--vz-link-underline-opacity, .75)) !important;
}

.focus-ring:focus {
  box-shadow: var(--vz-focus-ring-x, 0) var(--vz-focus-ring-y, 0) var(--vz-focus-ring-blur, 0) var(--vz-focus-ring-width) var(--vz-focus-ring-color);
  outline: 0;
}

.icon-link {
  -webkit-text-decoration-color: rgba(var(--vz-link-color-rgb), var(--vz-link-opacity, .5));
  text-decoration-color: rgba(var(--vz-link-color-rgb), var(--vz-link-opacity, .5));
  text-underline-offset: .25em;
  backface-visibility: hidden;
  align-items: center;
  gap: .375rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.icon-link > .bi {
  fill: currentColor;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  transition: transform .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--vz-icon-link-transform, translate3d(.25em, 0, 0));
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--vz-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --vz-aspect-ratio: 100%;
}

.ratio-4x3 {
  --vz-aspect-ratio: 75%;
}

.ratio-16x9 {
  --vz-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --vz-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (width >= 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  -ms-flex-direction: row;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.vstack {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: auto;
  flex: auto;
  align-self: stretch;
  display: -ms-flexbox;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.visually-hidden:not(caption), .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: var(--bs-border-width);
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  min-height: 1em;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--vz-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--vz-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--vz-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --vz-focus-ring-color: rgba(var(--vz-primary-rgb), var(--vz-focus-ring-opacity));
}

.focus-ring-secondary {
  --vz-focus-ring-color: rgba(var(--vz-secondary-rgb), var(--vz-focus-ring-opacity));
}

.focus-ring-success {
  --vz-focus-ring-color: rgba(var(--vz-success-rgb), var(--vz-focus-ring-opacity));
}

.focus-ring-info {
  --vz-focus-ring-color: rgba(var(--vz-info-rgb), var(--vz-focus-ring-opacity));
}

.focus-ring-warning {
  --vz-focus-ring-color: rgba(var(--vz-warning-rgb), var(--vz-focus-ring-opacity));
}

.focus-ring-danger {
  --vz-focus-ring-color: rgba(var(--vz-danger-rgb), var(--vz-focus-ring-opacity));
}

.focus-ring-light {
  --vz-focus-ring-color: rgba(var(--vz-light-rgb), var(--vz-focus-ring-opacity));
}

.focus-ring-dark {
  --vz-focus-ring-color: rgba(var(--vz-dark-rgb), var(--vz-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--vz-border-width) var(--vz-border-style) var(--vz-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--vz-border-width) var(--vz-border-style) var(--vz-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--vz-border-width) var(--vz-border-style) var(--vz-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--vz-border-width) var(--vz-border-style) var(--vz-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--vz-border-width) var(--vz-border-style) var(--vz-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --vz-border-opacity: 1;
  border-color: rgba(var(--vz-primary-rgb), var(--vz-border-opacity)) !important;
}

.border-secondary {
  --vz-border-opacity: 1;
  border-color: rgba(var(--vz-secondary-rgb), var(--vz-border-opacity)) !important;
}

.border-success {
  --vz-border-opacity: 1;
  border-color: rgba(var(--vz-success-rgb), var(--vz-border-opacity)) !important;
}

.border-info {
  --vz-border-opacity: 1;
  border-color: rgba(var(--vz-info-rgb), var(--vz-border-opacity)) !important;
}

.border-warning {
  --vz-border-opacity: 1;
  border-color: rgba(var(--vz-warning-rgb), var(--vz-border-opacity)) !important;
}

.border-danger {
  --vz-border-opacity: 1;
  border-color: rgba(var(--vz-danger-rgb), var(--vz-border-opacity)) !important;
}

.border-light {
  --vz-border-opacity: 1;
  border-color: rgba(var(--vz-light-rgb), var(--vz-border-opacity)) !important;
}

.border-dark {
  --vz-border-opacity: 1;
  border-color: rgba(var(--vz-dark-rgb), var(--vz-border-opacity)) !important;
}

.border-black {
  --vz-border-opacity: 1;
  border-color: rgba(var(--vz-black-rgb), var(--vz-border-opacity)) !important;
}

.border-white {
  --vz-border-opacity: 1;
  border-color: rgba(var(--vz-white-rgb), var(--vz-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--vz-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--vz-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--vz-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--vz-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--vz-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--vz-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--vz-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--vz-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --vz-border-opacity: .1;
}

.border-opacity-25 {
  --vz-border-opacity: .25;
}

.border-opacity-50 {
  --vz-border-opacity: .5;
}

.border-opacity-75 {
  --vz-border-opacity: .75;
}

.border-opacity-100 {
  --vz-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  -ms-flex: auto !important;
  flex: auto !important;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  -ms-flex-order: -1 !important;
  order: -1 !important;
}

.order-0 {
  -ms-flex-order: 0 !important;
  order: 0 !important;
}

.order-1 {
  -ms-flex-order: 1 !important;
  order: 1 !important;
}

.order-2 {
  -ms-flex-order: 2 !important;
  order: 2 !important;
}

.order-3 {
  -ms-flex-order: 3 !important;
  order: 3 !important;
}

.order-4 {
  -ms-flex-order: 4 !important;
  order: 4 !important;
}

.order-5 {
  -ms-flex-order: 5 !important;
  order: 5 !important;
}

.order-last {
  -ms-flex-order: 6 !important;
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-left: -.25rem !important;
  margin-right: -.25rem !important;
}

.mx-n2 {
  margin-left: -.5rem !important;
  margin-right: -.5rem !important;
}

.mx-n3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.mx-n4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mx-n5 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.my-n1 {
  margin-top: -.25rem !important;
  margin-bottom: -.25rem !important;
}

.my-n2 {
  margin-top: -.5rem !important;
  margin-bottom: -.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -.25rem !important;
}

.mt-n2 {
  margin-top: -.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -.25rem !important;
}

.me-n2 {
  margin-right: -.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -.25rem !important;
}

.mb-n2 {
  margin-bottom: -.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -.25rem !important;
}

.ms-n2 {
  margin-left: -.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: .25rem !important;
}

.row-gap-2 {
  row-gap: .5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: .25rem !important;
}

.column-gap-2 {
  column-gap: .5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--vz-font-monospace) !important;
}

.fs-1 {
  font-size: calc(var(--vz-font-base) * 2.5) !important;
}

.fs-2 {
  font-size: calc(var(--vz-font-base) * 2) !important;
}

.fs-3 {
  font-size: calc(var(--vz-font-base) * 1.75) !important;
}

.fs-4 {
  font-size: calc(var(--vz-font-base) * 1.5) !important;
}

.fs-5 {
  font-size: calc(var(--vz-font-base) * 1.25) !important;
}

.fs-6 {
  font-size: var(--vz-font-base) !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: var(--vz-font-weight-light) !important;
}

.fw-normal {
  font-weight: var(--vz-font-weight-normal) !important;
}

.fw-medium {
  font-weight: var(--vz-font-weight-medium) !important;
}

.fw-semibold {
  font-weight: var(--vz-font-weight-semibold) !important;
}

.fw-bold {
  font-weight: var(--vz-font-weight-bold) !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --vz-text-opacity: 1;
  color: rgba(var(--vz-primary-rgb), var(--vz-text-opacity)) !important;
}

.text-secondary {
  --vz-text-opacity: 1;
  color: rgba(var(--vz-secondary-rgb), var(--vz-text-opacity)) !important;
}

.text-success {
  --vz-text-opacity: 1;
  color: rgba(var(--vz-success-rgb), var(--vz-text-opacity)) !important;
}

.text-info {
  --vz-text-opacity: 1;
  color: rgba(var(--vz-info-rgb), var(--vz-text-opacity)) !important;
}

.text-warning {
  --vz-text-opacity: 1;
  color: rgba(var(--vz-warning-rgb), var(--vz-text-opacity)) !important;
}

.text-danger {
  --vz-text-opacity: 1;
  color: rgba(var(--vz-danger-rgb), var(--vz-text-opacity)) !important;
}

.text-light {
  --vz-text-opacity: 1;
  color: rgba(var(--vz-light-rgb), var(--vz-text-opacity)) !important;
}

.text-dark {
  --vz-text-opacity: 1;
  color: rgba(var(--vz-dark-rgb), var(--vz-text-opacity)) !important;
}

.text-black {
  --vz-text-opacity: 1;
  color: rgba(var(--vz-black-rgb), var(--vz-text-opacity)) !important;
}

.text-white {
  --vz-text-opacity: 1;
  color: rgba(var(--vz-white-rgb), var(--vz-text-opacity)) !important;
}

.text-body {
  --vz-text-opacity: 1;
  color: rgba(var(--vz-body-color-rgb), var(--vz-text-opacity)) !important;
}

.text-muted {
  --vz-text-opacity: 1;
  color: var(--vz-secondary-color) !important;
}

.text-black-50 {
  --vz-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --vz-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-body-secondary {
  --vz-text-opacity: 1;
  color: var(--vz-secondary-color) !important;
}

.text-body-tertiary {
  --vz-text-opacity: 1;
  color: var(--vz-tertiary-color) !important;
}

.text-body-emphasis {
  --vz-text-opacity: 1;
  color: var(--vz-emphasis-color) !important;
}

.text-reset {
  --vz-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --vz-text-opacity: .25;
}

.text-opacity-50 {
  --vz-text-opacity: .5;
}

.text-opacity-75 {
  --vz-text-opacity: .75;
}

.text-opacity-100 {
  --vz-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--vz-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--vz-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--vz-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--vz-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--vz-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--vz-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--vz-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--vz-dark-text-emphasis) !important;
}

.link-opacity-10, .link-opacity-10-hover:hover {
  --vz-link-opacity: .1;
}

.link-opacity-25, .link-opacity-25-hover:hover {
  --vz-link-opacity: .25;
}

.link-opacity-50, .link-opacity-50-hover:hover {
  --vz-link-opacity: .5;
}

.link-opacity-75, .link-opacity-75-hover:hover {
  --vz-link-opacity: .75;
}

.link-opacity-100, .link-opacity-100-hover:hover {
  --vz-link-opacity: 1;
}

.link-offset-1, .link-offset-1-hover:hover {
  text-underline-offset: .125em !important;
}

.link-offset-2, .link-offset-2-hover:hover {
  text-underline-offset: .25em !important;
}

.link-offset-3, .link-offset-3-hover:hover {
  text-underline-offset: .375em !important;
}

.link-underline-primary {
  --vz-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--vz-primary-rgb), var(--vz-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--vz-primary-rgb), var(--vz-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --vz-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--vz-secondary-rgb), var(--vz-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--vz-secondary-rgb), var(--vz-link-underline-opacity)) !important;
}

.link-underline-success {
  --vz-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--vz-success-rgb), var(--vz-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--vz-success-rgb), var(--vz-link-underline-opacity)) !important;
}

.link-underline-info {
  --vz-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--vz-info-rgb), var(--vz-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--vz-info-rgb), var(--vz-link-underline-opacity)) !important;
}

.link-underline-warning {
  --vz-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--vz-warning-rgb), var(--vz-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--vz-warning-rgb), var(--vz-link-underline-opacity)) !important;
}

.link-underline-danger {
  --vz-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--vz-danger-rgb), var(--vz-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--vz-danger-rgb), var(--vz-link-underline-opacity)) !important;
}

.link-underline-light {
  --vz-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--vz-light-rgb), var(--vz-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--vz-light-rgb), var(--vz-link-underline-opacity)) !important;
}

.link-underline-dark {
  --vz-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--vz-dark-rgb), var(--vz-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--vz-dark-rgb), var(--vz-link-underline-opacity)) !important;
}

.link-underline {
  --vz-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--vz-link-color-rgb), var(--vz-link-underline-opacity, 1)) !important;
  text-decoration-color: rgba(var(--vz-link-color-rgb), var(--vz-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0, .link-underline-opacity-0-hover:hover {
  --vz-link-underline-opacity: 0;
}

.link-underline-opacity-10, .link-underline-opacity-10-hover:hover {
  --vz-link-underline-opacity: .1;
}

.link-underline-opacity-25, .link-underline-opacity-25-hover:hover {
  --vz-link-underline-opacity: .25;
}

.link-underline-opacity-50, .link-underline-opacity-50-hover:hover {
  --vz-link-underline-opacity: .5;
}

.link-underline-opacity-75, .link-underline-opacity-75-hover:hover {
  --vz-link-underline-opacity: .75;
}

.link-underline-opacity-100, .link-underline-opacity-100-hover:hover {
  --vz-link-underline-opacity: 1;
}

.bg-primary {
  --vz-bg-opacity: 1;
  background-color: rgba(var(--vz-primary-rgb), var(--vz-bg-opacity)) !important;
}

.bg-secondary {
  --vz-bg-opacity: 1;
  background-color: rgba(var(--vz-secondary-rgb), var(--vz-bg-opacity)) !important;
}

.bg-success {
  --vz-bg-opacity: 1;
  background-color: rgba(var(--vz-success-rgb), var(--vz-bg-opacity)) !important;
}

.bg-info {
  --vz-bg-opacity: 1;
  background-color: rgba(var(--vz-info-rgb), var(--vz-bg-opacity)) !important;
}

.bg-warning {
  --vz-bg-opacity: 1;
  background-color: rgba(var(--vz-warning-rgb), var(--vz-bg-opacity)) !important;
}

.bg-danger {
  --vz-bg-opacity: 1;
  background-color: rgba(var(--vz-danger-rgb), var(--vz-bg-opacity)) !important;
}

.bg-light {
  --vz-bg-opacity: 1;
  background-color: rgba(var(--vz-light-rgb), var(--vz-bg-opacity)) !important;
}

.bg-dark {
  --vz-bg-opacity: 1;
  background-color: rgba(var(--vz-dark-rgb), var(--vz-bg-opacity)) !important;
}

.bg-black {
  --vz-bg-opacity: 1;
  background-color: rgba(var(--vz-black-rgb), var(--vz-bg-opacity)) !important;
}

.bg-white {
  --vz-bg-opacity: 1;
  background-color: rgba(var(--vz-white-rgb), var(--vz-bg-opacity)) !important;
}

.bg-body {
  --vz-bg-opacity: 1;
  background-color: rgba(var(--vz-body-bg-rgb), var(--vz-bg-opacity)) !important;
}

.bg-transparent {
  --vz-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-body-secondary {
  --vz-bg-opacity: 1;
  background-color: rgba(var(--vz-secondary-bg-rgb), var(--vz-bg-opacity)) !important;
}

.bg-body-tertiary {
  --vz-bg-opacity: 1;
  background-color: rgba(var(--vz-tertiary-bg-rgb), var(--vz-bg-opacity)) !important;
}

.bg-opacity-10 {
  --vz-bg-opacity: .1;
}

.bg-opacity-25 {
  --vz-bg-opacity: .25;
}

.bg-opacity-50 {
  --vz-bg-opacity: .5;
}

.bg-opacity-75 {
  --vz-bg-opacity: .75;
}

.bg-opacity-100 {
  --vz-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--vz-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--vz-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--vz-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--vz-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--vz-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--vz-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--vz-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--vz-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--vz-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--vz-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--vz-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--vz-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--vz-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--vz-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--vz-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--vz-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--vz-border-radius) !important;
  border-top-right-radius: var(--vz-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--vz-border-radius-sm) !important;
  border-top-right-radius: var(--vz-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--vz-border-radius) !important;
  border-top-right-radius: var(--vz-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--vz-border-radius-lg) !important;
  border-top-right-radius: var(--vz-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--vz-border-radius-xl) !important;
  border-top-right-radius: var(--vz-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--vz-border-radius-xxl) !important;
  border-top-right-radius: var(--vz-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--vz-border-radius-pill) !important;
  border-top-right-radius: var(--vz-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--vz-border-radius) !important;
  border-bottom-right-radius: var(--vz-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--vz-border-radius-sm) !important;
  border-bottom-right-radius: var(--vz-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--vz-border-radius) !important;
  border-bottom-right-radius: var(--vz-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--vz-border-radius-lg) !important;
  border-bottom-right-radius: var(--vz-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--vz-border-radius-xl) !important;
  border-bottom-right-radius: var(--vz-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--vz-border-radius-xxl) !important;
  border-bottom-right-radius: var(--vz-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--vz-border-radius-pill) !important;
  border-bottom-right-radius: var(--vz-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--vz-border-radius) !important;
  border-bottom-left-radius: var(--vz-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--vz-border-radius-sm) !important;
  border-bottom-left-radius: var(--vz-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--vz-border-radius) !important;
  border-bottom-left-radius: var(--vz-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--vz-border-radius-lg) !important;
  border-bottom-left-radius: var(--vz-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--vz-border-radius-xl) !important;
  border-bottom-left-radius: var(--vz-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--vz-border-radius-xxl) !important;
  border-bottom-left-radius: var(--vz-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--vz-border-radius-pill) !important;
  border-bottom-left-radius: var(--vz-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--vz-border-radius) !important;
  border-top-left-radius: var(--vz-border-radius) !important;
}

.rounded-start-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--vz-border-radius-sm) !important;
  border-top-left-radius: var(--vz-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--vz-border-radius) !important;
  border-top-left-radius: var(--vz-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--vz-border-radius-lg) !important;
  border-top-left-radius: var(--vz-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--vz-border-radius-xl) !important;
  border-top-left-radius: var(--vz-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--vz-border-radius-xxl) !important;
  border-top-left-radius: var(--vz-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--vz-border-radius-pill) !important;
  border-top-left-radius: var(--vz-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.fs-10 {
  font-size: var(--vz-font-10) !important;
}

.fs-11 {
  font-size: var(--vz-font-11) !important;
}

.fs-12 {
  font-size: var(--vz-font-12) !important;
}

.fs-13 {
  font-size: var(--vz-font-13) !important;
}

.fs-14 {
  font-size: var(--vz-font-14) !important;
}

.fs-15 {
  font-size: var(--vz-font-15) !important;
}

.fs-16 {
  font-size: var(--vz-font-16) !important;
}

.fs-17 {
  font-size: var(--vz-font-17) !important;
}

.fs-18 {
  font-size: var(--vz-font-18) !important;
}

.fs-19 {
  font-size: var(--vz-font-19) !important;
}

.fs-20 {
  font-size: var(--vz-font-20) !important;
}

.fs-21 {
  font-size: var(--vz-font-21) !important;
}

.fs-22 {
  font-size: var(--vz-font-22) !important;
}

.fs-23 {
  font-size: var(--vz-font-23) !important;
}

.fs-24 {
  font-size: var(--vz-font-24) !important;
}

.fs-36 {
  font-size: var(--vz-font-36) !important;
}

.fs-48 {
  font-size: var(--vz-font-48) !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-grab {
  cursor: grab !important;
}

@media (width >= 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    -ms-flex: auto !important;
    flex: auto !important;
  }

  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-sm-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-sm-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-sm-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-sm-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-sm-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-sm-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-sm-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mx-sm-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-sm-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-sm-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-sm-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-sm-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -3rem !important;
  }

  .me-sm-n1 {
    margin-right: -.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-sm-n1 {
    margin-left: -.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: .25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: .5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: .25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: .5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .cursor-sm-auto {
    cursor: auto !important;
  }

  .cursor-sm-pointer {
    cursor: pointer !important;
  }

  .cursor-sm-grab {
    cursor: grab !important;
  }
}

@media (width >= 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    -ms-flex: auto !important;
    flex: auto !important;
  }

  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-md-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-md-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-md-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-md-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-md-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-md-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-md-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mx-md-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-md-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-md-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-md-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-md-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-md-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-md-n1 {
    margin-top: -.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -3rem !important;
  }

  .me-md-n1 {
    margin-right: -.25rem !important;
  }

  .me-md-n2 {
    margin-right: -.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-md-n1 {
    margin-left: -.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: .25rem !important;
  }

  .row-gap-md-2 {
    row-gap: .5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 3rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: .25rem !important;
  }

  .column-gap-md-2 {
    column-gap: .5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    column-gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .cursor-md-auto {
    cursor: auto !important;
  }

  .cursor-md-pointer {
    cursor: pointer !important;
  }

  .cursor-md-grab {
    cursor: grab !important;
  }
}

@media (width >= 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    -ms-flex: auto !important;
    flex: auto !important;
  }

  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-lg-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-lg-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-lg-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-lg-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-lg-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-lg-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-lg-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mx-lg-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-lg-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-lg-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-lg-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-lg-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -3rem !important;
  }

  .me-lg-n1 {
    margin-right: -.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-lg-n1 {
    margin-left: -.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: .25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: .5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: .25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: .5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .cursor-lg-auto {
    cursor: auto !important;
  }

  .cursor-lg-pointer {
    cursor: pointer !important;
  }

  .cursor-lg-grab {
    cursor: grab !important;
  }
}

@media (width >= 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    -ms-flex: auto !important;
    flex: auto !important;
  }

  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-xl-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-xl-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-xl-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-xl-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-xl-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-xl-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-xl-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mx-xl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-xl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -3rem !important;
  }

  .me-xl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: .25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: .5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .cursor-xl-auto {
    cursor: auto !important;
  }

  .cursor-xl-pointer {
    cursor: pointer !important;
  }

  .cursor-xl-grab {
    cursor: grab !important;
  }
}

@media (width >= 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    -ms-flex: auto !important;
    flex: auto !important;
  }

  .flex-xxl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xxl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-xxl-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-xxl-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-xxl-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-xxl-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-xxl-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-xxl-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-xxl-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -.25rem !important;
  }

  .m-xxl-n2 {
    margin: -.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mx-xxl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xxl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xxl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-xxl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }

  .me-xxl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: .25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: .5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .cursor-xxl-auto {
    cursor: auto !important;
  }

  .cursor-xxl-pointer {
    cursor: pointer !important;
  }

  .cursor-xxl-grab {
    cursor: grab !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa, .fa-classic, .fa-sharp, .fas, .fa-solid, .far, .fa-regular, .fasr, .fal, .fa-light, .fasl, .fat, .fa-thin, .fast, .fad, .fa-duotone, .fass, .fa-sharp-solid, .fab, .fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
}

.fas, .fa-classic, .fa-solid, .far, .fa-regular, .fal, .fa-light, .fat, .fa-thin {
  font-family: "Font Awesome 6 Pro";
}

.fab, .fa-brands {
  font-family: "Font Awesome 6 Brands";
}

.fad, .fa-classic.fa-duotone, .fa-duotone {
  font-family: "Font Awesome 6 Duotone";
}

.fass, .fasr, .fasl, .fast, .fa-sharp {
  font-family: "Font Awesome 6 Sharp";
}

.fass, .fa-sharp {
  font-weight: 900;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  vertical-align: .225em;
  font-size: .625em;
  line-height: .1em;
}

.fa-xs {
  vertical-align: .125em;
  font-size: .75em;
  line-height: .08333em;
}

.fa-sm {
  vertical-align: .05357em;
  font-size: .875em;
  line-height: .07143em;
}

.fa-lg {
  vertical-align: -.075em;
  font-size: 1.25em;
  line-height: .05em;
}

.fa-xl {
  vertical-align: -.125em;
  font-size: 1.5em;
  line-height: .04167em;
}

.fa-2xl {
  vertical-align: -.1875em;
  font-size: 2em;
  line-height: .03125em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
  position: absolute;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, .1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, .08em);
  padding: var(--fa-border-padding, .2em .25em .15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, .3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, .3em);
}

.fa-beat {
  animation-name: fa-beat;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
  animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse, .fa-spin-pulse {
  animation-name: fa-spin;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat, .fa-bounce, .fa-fade, .fa-beat-fade, .fa-flip, .fa-pulse, .fa-shake, .fa-spin, .fa-spin-pulse {
    transition-duration: 0s;
    transition-delay: 0s;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    animation-delay: -1ms;
  }
}

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }

  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}

@keyframes fa-bounce {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }

  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
  }

  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
    transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
  }

  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
  }

  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
  }

  64% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }

  to {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, .4);
  }
}

@keyframes fa-beat-fade {
  0%, to {
    opacity: var(--fa-beat-fade-opacity, .4);
    transform: scale(1);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}

@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }

  4% {
    transform: rotate(15deg);
  }

  8%, 24% {
    transform: rotate(-18deg);
  }

  12%, 28% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-22deg);
  }

  20% {
    transform: rotate(22deg);
  }

  32% {
    transform: rotate(-12deg);
  }

  36% {
    transform: rotate(12deg);
  }

  40%, to {
    transform: rotate(0);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scaleX(-1);
}

.fa-flip-vertical {
  transform: scaleY(-1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

.fa-rotate-by {
  -webkit-transform: rotate(var(--fa-rotate-angle, 0));
  -webkit-transform: rotate(var(--fa-rotate-angle, 0));
  transform: rotate(var(--fa-rotate-angle, 0));
}

.fa-stack {
  vertical-align: middle;
  width: 2.5em;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  z-index: var(--fa-stack-z-index, auto);
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fa-0:before {
  content: "0";
}

.fa-00:before {
  content: "";
}

.fa-1:before {
  content: "1";
}

.fa-100:before {
  content: "";
}

.fa-2:before {
  content: "2";
}

.fa-3:before {
  content: "3";
}

.fa-360-degrees:before {
  content: "";
}

.fa-4:before {
  content: "4";
}

.fa-5:before {
  content: "5";
}

.fa-6:before {
  content: "6";
}

.fa-7:before {
  content: "7";
}

.fa-8:before {
  content: "8";
}

.fa-9:before {
  content: "9";
}

.fa-a:before {
  content: "A";
}

.fa-abacus:before {
  content: "";
}

.fa-accent-grave:before {
  content: "`";
}

.fa-acorn:before {
  content: "";
}

.fa-ad:before {
  content: "";
}

.fa-add:before {
  content: "+";
}

.fa-address-book:before {
  content: "";
}

.fa-address-card:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-air-conditioner:before {
  content: "";
}

.fa-air-freshener:before {
  content: "";
}

.fa-airplay:before {
  content: "";
}

.fa-alarm-clock:before {
  content: "";
}

.fa-alarm-exclamation:before {
  content: "";
}

.fa-alarm-plus:before {
  content: "";
}

.fa-alarm-snooze:before {
  content: "";
}

.fa-album:before {
  content: "";
}

.fa-album-circle-plus:before {
  content: "";
}

.fa-album-circle-user:before {
  content: "";
}

.fa-album-collection:before {
  content: "";
}

.fa-album-collection-circle-plus:before {
  content: "";
}

.fa-album-collection-circle-user:before {
  content: "";
}

.fa-alicorn:before {
  content: "";
}

.fa-alien:before {
  content: "";
}

.fa-alien-8bit:before, .fa-alien-monster:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-align-slash:before {
  content: "";
}

.fa-allergies:before {
  content: "";
}

.fa-alt:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-american-sign-language-interpreting:before {
  content: "";
}

.fa-amp-guitar:before {
  content: "";
}

.fa-ampersand:before {
  content: "&";
}

.fa-analytics:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-anchor-circle-check:before {
  content: "";
}

.fa-anchor-circle-exclamation:before {
  content: "";
}

.fa-anchor-circle-xmark:before {
  content: "";
}

.fa-anchor-lock:before {
  content: "";
}

.fa-angel:before {
  content: "";
}

.fa-angle:before {
  content: "";
}

.fa-angle-90:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angles-down:before {
  content: "";
}

.fa-angles-left:before {
  content: "";
}

.fa-angles-right:before {
  content: "";
}

.fa-angles-up:before {
  content: "";
}

.fa-angles-up-down:before {
  content: "";
}

.fa-angry:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-ant:before {
  content: "";
}

.fa-apartment:before {
  content: "";
}

.fa-aperture:before {
  content: "";
}

.fa-apostrophe:before {
  content: "'";
}

.fa-apple-alt:before {
  content: "";
}

.fa-apple-core:before {
  content: "";
}

.fa-apple-crate:before {
  content: "";
}

.fa-apple-whole:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-area-chart:before {
  content: "";
}

.fa-arrow-alt-circle-down:before {
  content: "";
}

.fa-arrow-alt-circle-left:before {
  content: "";
}

.fa-arrow-alt-circle-right:before {
  content: "";
}

.fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-arrow-alt-down:before {
  content: "";
}

.fa-arrow-alt-from-bottom:before {
  content: "";
}

.fa-arrow-alt-from-left:before {
  content: "";
}

.fa-arrow-alt-from-right:before {
  content: "";
}

.fa-arrow-alt-from-top:before {
  content: "";
}

.fa-arrow-alt-left:before {
  content: "";
}

.fa-arrow-alt-right:before {
  content: "";
}

.fa-arrow-alt-square-down:before {
  content: "";
}

.fa-arrow-alt-square-left:before {
  content: "";
}

.fa-arrow-alt-square-right:before {
  content: "";
}

.fa-arrow-alt-square-up:before {
  content: "";
}

.fa-arrow-alt-to-bottom:before {
  content: "";
}

.fa-arrow-alt-to-left:before {
  content: "";
}

.fa-arrow-alt-to-right:before {
  content: "";
}

.fa-arrow-alt-to-top:before {
  content: "";
}

.fa-arrow-alt-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-arrow-down-1-9:before {
  content: "";
}

.fa-arrow-down-9-1:before {
  content: "";
}

.fa-arrow-down-a-z:before {
  content: "";
}

.fa-arrow-down-arrow-up:before {
  content: "";
}

.fa-arrow-down-big-small:before {
  content: "";
}

.fa-arrow-down-from-arc:before {
  content: "";
}

.fa-arrow-down-from-bracket:before {
  content: "";
}

.fa-arrow-down-from-dotted-line:before {
  content: "";
}

.fa-arrow-down-from-line:before {
  content: "";
}

.fa-arrow-down-left:before {
  content: "";
}

.fa-arrow-down-left-and-arrow-up-right-to-center:before {
  content: "";
}

.fa-arrow-down-long:before {
  content: "";
}

.fa-arrow-down-right:before {
  content: "";
}

.fa-arrow-down-short-wide:before {
  content: "";
}

.fa-arrow-down-small-big:before {
  content: "";
}

.fa-arrow-down-square-triangle:before {
  content: "";
}

.fa-arrow-down-to-arc:before {
  content: "";
}

.fa-arrow-down-to-bracket:before {
  content: "";
}

.fa-arrow-down-to-dotted-line:before {
  content: "";
}

.fa-arrow-down-to-line:before {
  content: "";
}

.fa-arrow-down-to-square:before {
  content: "";
}

.fa-arrow-down-triangle-square:before {
  content: "";
}

.fa-arrow-down-up-across-line:before {
  content: "";
}

.fa-arrow-down-up-lock:before {
  content: "";
}

.fa-arrow-down-wide-short:before {
  content: "";
}

.fa-arrow-down-z-a:before {
  content: "";
}

.fa-arrow-from-bottom:before {
  content: "";
}

.fa-arrow-from-left:before {
  content: "";
}

.fa-arrow-from-right:before {
  content: "";
}

.fa-arrow-from-top:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-left-from-arc:before {
  content: "";
}

.fa-arrow-left-from-bracket:before {
  content: "";
}

.fa-arrow-left-from-line:before {
  content: "";
}

.fa-arrow-left-long:before {
  content: "";
}

.fa-arrow-left-long-to-line:before {
  content: "";
}

.fa-arrow-left-rotate:before {
  content: "";
}

.fa-arrow-left-to-arc:before {
  content: "";
}

.fa-arrow-left-to-bracket:before {
  content: "";
}

.fa-arrow-left-to-line:before {
  content: "";
}

.fa-arrow-pointer:before {
  content: "";
}

.fa-arrow-progress:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-right-arrow-left:before {
  content: "";
}

.fa-arrow-right-from-arc:before {
  content: "";
}

.fa-arrow-right-from-bracket:before {
  content: "";
}

.fa-arrow-right-from-file:before {
  content: "";
}

.fa-arrow-right-from-line:before {
  content: "";
}

.fa-arrow-right-long:before {
  content: "";
}

.fa-arrow-right-long-to-line:before {
  content: "";
}

.fa-arrow-right-rotate:before {
  content: "";
}

.fa-arrow-right-to-arc:before {
  content: "";
}

.fa-arrow-right-to-bracket:before {
  content: "";
}

.fa-arrow-right-to-city:before {
  content: "";
}

.fa-arrow-right-to-file:before {
  content: "";
}

.fa-arrow-right-to-line:before {
  content: "";
}

.fa-arrow-rotate-back:before, .fa-arrow-rotate-backward:before {
  content: "";
}

.fa-arrow-rotate-forward:before {
  content: "";
}

.fa-arrow-rotate-left:before {
  content: "";
}

.fa-arrow-rotate-right:before {
  content: "";
}

.fa-arrow-square-down:before {
  content: "";
}

.fa-arrow-square-left:before {
  content: "";
}

.fa-arrow-square-right:before {
  content: "";
}

.fa-arrow-square-up:before {
  content: "";
}

.fa-arrow-to-bottom:before {
  content: "";
}

.fa-arrow-to-left:before {
  content: "";
}

.fa-arrow-to-right:before {
  content: "";
}

.fa-arrow-to-top:before {
  content: "";
}

.fa-arrow-trend-down:before {
  content: "";
}

.fa-arrow-trend-up:before {
  content: "";
}

.fa-arrow-turn-down:before {
  content: "";
}

.fa-arrow-turn-down-left:before {
  content: "";
}

.fa-arrow-turn-down-right:before {
  content: "";
}

.fa-arrow-turn-left:before {
  content: "";
}

.fa-arrow-turn-left-down:before {
  content: "";
}

.fa-arrow-turn-left-up:before {
  content: "";
}

.fa-arrow-turn-right:before {
  content: "";
}

.fa-arrow-turn-up:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrow-up-1-9:before {
  content: "";
}

.fa-arrow-up-9-1:before {
  content: "";
}

.fa-arrow-up-a-z:before {
  content: "";
}

.fa-arrow-up-arrow-down:before {
  content: "";
}

.fa-arrow-up-big-small:before {
  content: "";
}

.fa-arrow-up-from-arc:before {
  content: "";
}

.fa-arrow-up-from-bracket:before {
  content: "";
}

.fa-arrow-up-from-dotted-line:before {
  content: "";
}

.fa-arrow-up-from-ground-water:before {
  content: "";
}

.fa-arrow-up-from-line:before {
  content: "";
}

.fa-arrow-up-from-square:before {
  content: "";
}

.fa-arrow-up-from-water-pump:before {
  content: "";
}

.fa-arrow-up-left:before {
  content: "";
}

.fa-arrow-up-left-from-circle:before {
  content: "";
}

.fa-arrow-up-long:before {
  content: "";
}

.fa-arrow-up-right:before {
  content: "";
}

.fa-arrow-up-right-and-arrow-down-left-from-center:before {
  content: "";
}

.fa-arrow-up-right-dots:before {
  content: "";
}

.fa-arrow-up-right-from-square:before {
  content: "";
}

.fa-arrow-up-short-wide:before {
  content: "";
}

.fa-arrow-up-small-big:before {
  content: "";
}

.fa-arrow-up-square-triangle:before {
  content: "";
}

.fa-arrow-up-to-arc:before {
  content: "";
}

.fa-arrow-up-to-bracket:before {
  content: "";
}

.fa-arrow-up-to-dotted-line:before {
  content: "";
}

.fa-arrow-up-to-line:before {
  content: "";
}

.fa-arrow-up-triangle-square:before {
  content: "";
}

.fa-arrow-up-wide-short:before {
  content: "";
}

.fa-arrow-up-z-a:before {
  content: "";
}

.fa-arrows:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-arrows-alt-h:before {
  content: "";
}

.fa-arrows-alt-v:before {
  content: "";
}

.fa-arrows-cross:before {
  content: "";
}

.fa-arrows-down-to-line:before {
  content: "";
}

.fa-arrows-down-to-people:before {
  content: "";
}

.fa-arrows-from-dotted-line:before {
  content: "";
}

.fa-arrows-from-line:before {
  content: "";
}

.fa-arrows-h:before, .fa-arrows-left-right:before {
  content: "";
}

.fa-arrows-left-right-to-line:before {
  content: "";
}

.fa-arrows-maximize:before {
  content: "";
}

.fa-arrows-minimize:before {
  content: "";
}

.fa-arrows-repeat:before {
  content: "";
}

.fa-arrows-repeat-1:before {
  content: "";
}

.fa-arrows-retweet:before {
  content: "";
}

.fa-arrows-rotate:before {
  content: "";
}

.fa-arrows-rotate-reverse:before {
  content: "";
}

.fa-arrows-spin:before {
  content: "";
}

.fa-arrows-split-up-and-left:before {
  content: "";
}

.fa-arrows-to-circle:before {
  content: "";
}

.fa-arrows-to-dot:before {
  content: "";
}

.fa-arrows-to-dotted-line:before {
  content: "";
}

.fa-arrows-to-eye:before {
  content: "";
}

.fa-arrows-to-line:before {
  content: "";
}

.fa-arrows-turn-right:before {
  content: "";
}

.fa-arrows-turn-to-dots:before {
  content: "";
}

.fa-arrows-up-down:before {
  content: "";
}

.fa-arrows-up-down-left-right:before {
  content: "";
}

.fa-arrows-up-to-line:before {
  content: "";
}

.fa-arrows-v:before {
  content: "";
}

.fa-asl-interpreting:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-asterisk:before {
  content: "*";
}

.fa-at:before {
  content: "@";
}

.fa-atlas:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-atom-alt:before, .fa-atom-simple:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-audio-description-slash:before {
  content: "";
}

.fa-austral-sign:before {
  content: "";
}

.fa-automobile:before {
  content: "";
}

.fa-avocado:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-award-simple:before {
  content: "";
}

.fa-axe:before {
  content: "";
}

.fa-axe-battle:before {
  content: "";
}

.fa-b:before {
  content: "B";
}

.fa-baby:before {
  content: "";
}

.fa-baby-carriage:before {
  content: "";
}

.fa-backpack:before {
  content: "";
}

.fa-backspace:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-backward-fast:before {
  content: "";
}

.fa-backward-step:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-bacteria:before {
  content: "";
}

.fa-bacterium:before {
  content: "";
}

.fa-badge:before {
  content: "";
}

.fa-badge-check:before {
  content: "";
}

.fa-badge-dollar:before {
  content: "";
}

.fa-badge-percent:before {
  content: "";
}

.fa-badge-sheriff:before {
  content: "";
}

.fa-badger-honey:before {
  content: "";
}

.fa-badminton:before {
  content: "";
}

.fa-bag-seedling:before {
  content: "";
}

.fa-bag-shopping:before {
  content: "";
}

.fa-bag-shopping-minus:before {
  content: "";
}

.fa-bag-shopping-plus:before {
  content: "";
}

.fa-bagel:before {
  content: "";
}

.fa-bags-shopping:before {
  content: "";
}

.fa-baguette:before {
  content: "";
}

.fa-bahai:before {
  content: "";
}

.fa-baht-sign:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-balance-scale-left:before {
  content: "";
}

.fa-balance-scale-right:before {
  content: "";
}

.fa-ball-pile:before {
  content: "";
}

.fa-balloon:before {
  content: "";
}

.fa-balloons:before {
  content: "";
}

.fa-ballot:before {
  content: "";
}

.fa-ballot-check:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-ban-bug:before {
  content: "";
}

.fa-ban-parking:before {
  content: "";
}

.fa-ban-smoking:before {
  content: "";
}

.fa-banana:before {
  content: "";
}

.fa-band-aid:before, .fa-bandage:before {
  content: "";
}

.fa-bangladeshi-taka-sign:before {
  content: "";
}

.fa-banjo:before {
  content: "";
}

.fa-bank:before {
  content: "";
}

.fa-bar-chart:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-barcode-alt:before {
  content: "";
}

.fa-barcode-read:before {
  content: "";
}

.fa-barcode-scan:before {
  content: "";
}

.fa-barn-silo:before {
  content: "";
}

.fa-bars:before {
  content: "";
}

.fa-bars-filter:before {
  content: "";
}

.fa-bars-progress:before {
  content: "";
}

.fa-bars-sort:before {
  content: "";
}

.fa-bars-staggered:before {
  content: "";
}

.fa-baseball:before, .fa-baseball-ball:before {
  content: "";
}

.fa-baseball-bat-ball:before {
  content: "";
}

.fa-basket-shopping:before {
  content: "";
}

.fa-basket-shopping-minus:before {
  content: "";
}

.fa-basket-shopping-plus:before {
  content: "";
}

.fa-basket-shopping-simple:before {
  content: "";
}

.fa-basketball:before, .fa-basketball-ball:before {
  content: "";
}

.fa-basketball-hoop:before {
  content: "";
}

.fa-bat:before {
  content: "";
}

.fa-bath:before, .fa-bathtub:before {
  content: "";
}

.fa-battery:before {
  content: "";
}

.fa-battery-0:before {
  content: "";
}

.fa-battery-1:before {
  content: "";
}

.fa-battery-2:before {
  content: "";
}

.fa-battery-3:before {
  content: "";
}

.fa-battery-4:before {
  content: "";
}

.fa-battery-5:before {
  content: "";
}

.fa-battery-bolt:before {
  content: "";
}

.fa-battery-car:before {
  content: "";
}

.fa-battery-empty:before {
  content: "";
}

.fa-battery-exclamation:before {
  content: "";
}

.fa-battery-full:before {
  content: "";
}

.fa-battery-half:before {
  content: "";
}

.fa-battery-low:before {
  content: "";
}

.fa-battery-quarter:before {
  content: "";
}

.fa-battery-slash:before {
  content: "";
}

.fa-battery-three-quarters:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-bed-alt:before {
  content: "";
}

.fa-bed-bunk:before {
  content: "";
}

.fa-bed-empty:before {
  content: "";
}

.fa-bed-front:before {
  content: "";
}

.fa-bed-pulse:before {
  content: "";
}

.fa-bee:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-beer-foam:before, .fa-beer-mug:before {
  content: "";
}

.fa-beer-mug-empty:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-bell-concierge:before {
  content: "";
}

.fa-bell-exclamation:before {
  content: "";
}

.fa-bell-on:before {
  content: "";
}

.fa-bell-plus:before {
  content: "";
}

.fa-bell-ring:before {
  content: "";
}

.fa-bell-school:before {
  content: "";
}

.fa-bell-school-slash:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bells:before {
  content: "";
}

.fa-bench-tree:before {
  content: "";
}

.fa-betamax:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-bible:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-biking:before {
  content: "";
}

.fa-biking-mountain:before {
  content: "";
}

.fa-billboard:before {
  content: "";
}

.fa-bin-bottles:before {
  content: "";
}

.fa-bin-bottles-recycle:before {
  content: "";
}

.fa-bin-recycle:before {
  content: "";
}

.fa-binary:before {
  content: "";
}

.fa-binary-circle-check:before {
  content: "";
}

.fa-binary-lock:before {
  content: "";
}

.fa-binary-slash:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-bird:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-bitcoin-sign:before {
  content: "";
}

.fa-blackboard:before {
  content: "";
}

.fa-blanket:before {
  content: "";
}

.fa-blanket-fire:before {
  content: "";
}

.fa-blender:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-blinds:before {
  content: "";
}

.fa-blinds-open:before {
  content: "";
}

.fa-blinds-raised:before {
  content: "";
}

.fa-block:before {
  content: "";
}

.fa-block-brick:before {
  content: "";
}

.fa-block-brick-fire:before {
  content: "";
}

.fa-block-question:before {
  content: "";
}

.fa-block-quote:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-blueberries:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-bolt:before {
  content: "";
}

.fa-bolt-auto:before {
  content: "";
}

.fa-bolt-lightning:before {
  content: "";
}

.fa-bolt-slash:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-bone-break:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-book-alt:before {
  content: "";
}

.fa-book-arrow-right:before {
  content: "";
}

.fa-book-arrow-up:before {
  content: "";
}

.fa-book-atlas:before {
  content: "";
}

.fa-book-bible:before {
  content: "";
}

.fa-book-blank:before {
  content: "";
}

.fa-book-bookmark:before {
  content: "";
}

.fa-book-circle:before {
  content: "";
}

.fa-book-circle-arrow-right:before {
  content: "";
}

.fa-book-circle-arrow-up:before {
  content: "";
}

.fa-book-copy:before {
  content: "";
}

.fa-book-dead:before {
  content: "";
}

.fa-book-font:before {
  content: "";
}

.fa-book-heart:before {
  content: "";
}

.fa-book-journal-whills:before {
  content: "";
}

.fa-book-law:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-open-alt:before, .fa-book-open-cover:before {
  content: "";
}

.fa-book-open-reader:before {
  content: "";
}

.fa-book-quran:before {
  content: "";
}

.fa-book-reader:before {
  content: "";
}

.fa-book-section:before {
  content: "";
}

.fa-book-skull:before {
  content: "";
}

.fa-book-sparkles:before, .fa-book-spells:before {
  content: "";
}

.fa-book-tanakh:before {
  content: "";
}

.fa-book-user:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-bookmark-circle:before {
  content: "";
}

.fa-bookmark-slash:before {
  content: "";
}

.fa-books:before {
  content: "";
}

.fa-books-medical:before {
  content: "";
}

.fa-boombox:before {
  content: "";
}

.fa-boot:before {
  content: "";
}

.fa-boot-heeled:before {
  content: "";
}

.fa-booth-curtain:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-border-bottom:before {
  content: "";
}

.fa-border-bottom-right:before {
  content: "";
}

.fa-border-center-h:before {
  content: "";
}

.fa-border-center-v:before {
  content: "";
}

.fa-border-inner:before {
  content: "";
}

.fa-border-left:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-border-outer:before {
  content: "";
}

.fa-border-right:before {
  content: "";
}

.fa-border-style:before {
  content: "";
}

.fa-border-style-alt:before {
  content: "";
}

.fa-border-top:before {
  content: "";
}

.fa-border-top-left:before {
  content: "";
}

.fa-bore-hole:before {
  content: "";
}

.fa-bottle-baby:before {
  content: "";
}

.fa-bottle-droplet:before {
  content: "";
}

.fa-bottle-water:before {
  content: "";
}

.fa-bow-arrow:before {
  content: "";
}

.fa-bowl-chopsticks:before {
  content: "";
}

.fa-bowl-chopsticks-noodles:before {
  content: "";
}

.fa-bowl-food:before {
  content: "";
}

.fa-bowl-hot:before {
  content: "";
}

.fa-bowl-rice:before {
  content: "";
}

.fa-bowl-salad:before {
  content: "";
}

.fa-bowl-scoop:before {
  content: "";
}

.fa-bowl-scoops:before {
  content: "";
}

.fa-bowl-shaved-ice:before {
  content: "";
}

.fa-bowl-soft-serve:before {
  content: "";
}

.fa-bowl-spoon:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-bowling-ball-pin:before {
  content: "";
}

.fa-bowling-pins:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-box-alt:before {
  content: "";
}

.fa-box-archive:before {
  content: "";
}

.fa-box-ballot:before {
  content: "";
}

.fa-box-check:before {
  content: "";
}

.fa-box-circle-check:before {
  content: "";
}

.fa-box-dollar:before {
  content: "";
}

.fa-box-fragile:before {
  content: "";
}

.fa-box-full:before {
  content: "";
}

.fa-box-heart:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-box-open-full:before {
  content: "";
}

.fa-box-taped:before {
  content: "";
}

.fa-box-tissue:before {
  content: "";
}

.fa-box-up:before {
  content: "";
}

.fa-box-usd:before {
  content: "";
}

.fa-boxes:before, .fa-boxes-alt:before {
  content: "";
}

.fa-boxes-packing:before {
  content: "";
}

.fa-boxes-stacked:before {
  content: "";
}

.fa-boxing-glove:before {
  content: "";
}

.fa-bracket:before {
  content: "[";
}

.fa-bracket-curly:before, .fa-bracket-curly-left:before {
  content: "{";
}

.fa-bracket-curly-right:before {
  content: "}";
}

.fa-bracket-left:before {
  content: "[";
}

.fa-bracket-round:before {
  content: "(";
}

.fa-bracket-round-right:before {
  content: ")";
}

.fa-bracket-square:before {
  content: "[";
}

.fa-bracket-square-right:before {
  content: "]";
}

.fa-brackets:before {
  content: "";
}

.fa-brackets-curly:before {
  content: "";
}

.fa-brackets-round:before {
  content: "";
}

.fa-brackets-square:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-brain-arrow-curved-right:before {
  content: "";
}

.fa-brain-circuit:before {
  content: "";
}

.fa-brake-warning:before {
  content: "";
}

.fa-brazilian-real-sign:before {
  content: "";
}

.fa-bread-loaf:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-bread-slice-butter:before {
  content: "";
}

.fa-bridge:before {
  content: "";
}

.fa-bridge-circle-check:before {
  content: "";
}

.fa-bridge-circle-exclamation:before {
  content: "";
}

.fa-bridge-circle-xmark:before {
  content: "";
}

.fa-bridge-lock:before {
  content: "";
}

.fa-bridge-suspension:before {
  content: "";
}

.fa-bridge-water:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-briefcase-arrow-right:before {
  content: "";
}

.fa-briefcase-blank:before {
  content: "";
}

.fa-briefcase-clock:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-brightness:before {
  content: "";
}

.fa-brightness-low:before {
  content: "";
}

.fa-bring-forward:before {
  content: "";
}

.fa-bring-front:before {
  content: "";
}

.fa-broadcast-tower:before {
  content: "";
}

.fa-broccoli:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-broom-ball:before {
  content: "";
}

.fa-broom-wide:before {
  content: "";
}

.fa-browser:before {
  content: "";
}

.fa-browsers:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-bucket:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-bug-slash:before {
  content: "";
}

.fa-bugs:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-building-circle-arrow-right:before {
  content: "";
}

.fa-building-circle-check:before {
  content: "";
}

.fa-building-circle-exclamation:before {
  content: "";
}

.fa-building-circle-xmark:before {
  content: "";
}

.fa-building-columns:before {
  content: "";
}

.fa-building-flag:before {
  content: "";
}

.fa-building-lock:before {
  content: "";
}

.fa-building-magnifying-glass:before {
  content: "";
}

.fa-building-memo:before {
  content: "";
}

.fa-building-ngo:before {
  content: "";
}

.fa-building-shield:before {
  content: "";
}

.fa-building-un:before {
  content: "";
}

.fa-building-user:before {
  content: "";
}

.fa-building-wheat:before {
  content: "";
}

.fa-buildings:before {
  content: "";
}

.fa-bulldozer:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-bullseye-arrow:before {
  content: "";
}

.fa-bullseye-pointer:before {
  content: "";
}

.fa-buoy:before {
  content: "";
}

.fa-buoy-mooring:before {
  content: "";
}

.fa-burger:before {
  content: "";
}

.fa-burger-cheese:before {
  content: "";
}

.fa-burger-fries:before {
  content: "";
}

.fa-burger-glass:before {
  content: "";
}

.fa-burger-lettuce:before {
  content: "";
}

.fa-burger-soda:before {
  content: "";
}

.fa-burn:before {
  content: "";
}

.fa-burrito:before {
  content: "";
}

.fa-burst:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-bus-alt:before {
  content: "";
}

.fa-bus-school:before {
  content: "";
}

.fa-bus-simple:before {
  content: "";
}

.fa-business-front:before {
  content: "";
}

.fa-business-time:before {
  content: "";
}

.fa-butter:before {
  content: "";
}

.fa-c:before {
  content: "C";
}

.fa-cab:before {
  content: "";
}

.fa-cabin:before {
  content: "";
}

.fa-cabinet-filing:before {
  content: "";
}

.fa-cable-car:before {
  content: "";
}

.fa-cactus:before {
  content: "";
}

.fa-caduceus:before {
  content: "";
}

.fa-cake:before, .fa-cake-candles:before {
  content: "";
}

.fa-cake-slice:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-calculator-alt:before, .fa-calculator-simple:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-calendar-alt:before {
  content: "";
}

.fa-calendar-arrow-down:before {
  content: "";
}

.fa-calendar-arrow-up:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-calendar-circle:before {
  content: "";
}

.fa-calendar-circle-exclamation:before {
  content: "";
}

.fa-calendar-circle-minus:before {
  content: "";
}

.fa-calendar-circle-plus:before {
  content: "";
}

.fa-calendar-circle-user:before {
  content: "";
}

.fa-calendar-clock:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-calendar-days:before {
  content: "";
}

.fa-calendar-download:before {
  content: "";
}

.fa-calendar-edit:before {
  content: "";
}

.fa-calendar-exclamation:before {
  content: "";
}

.fa-calendar-heart:before {
  content: "";
}

.fa-calendar-image:before {
  content: "";
}

.fa-calendar-lines:before {
  content: "";
}

.fa-calendar-lines-pen:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-calendar-note:before {
  content: "";
}

.fa-calendar-pen:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-calendar-range:before {
  content: "";
}

.fa-calendar-star:before {
  content: "";
}

.fa-calendar-time:before {
  content: "";
}

.fa-calendar-times:before {
  content: "";
}

.fa-calendar-upload:before {
  content: "";
}

.fa-calendar-users:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-calendar-xmark:before {
  content: "";
}

.fa-calendars:before {
  content: "";
}

.fa-camcorder:before {
  content: "";
}

.fa-camera:before, .fa-camera-alt:before {
  content: "";
}

.fa-camera-cctv:before {
  content: "";
}

.fa-camera-circle:before {
  content: "";
}

.fa-camera-home:before {
  content: "";
}

.fa-camera-movie:before {
  content: "";
}

.fa-camera-polaroid:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-camera-rotate:before {
  content: "";
}

.fa-camera-security:before {
  content: "";
}

.fa-camera-slash:before {
  content: "";
}

.fa-camera-viewfinder:before {
  content: "";
}

.fa-camera-web:before {
  content: "";
}

.fa-camera-web-slash:before {
  content: "";
}

.fa-campfire:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-can-food:before {
  content: "";
}

.fa-cancel:before {
  content: "";
}

.fa-candle-holder:before {
  content: "";
}

.fa-candy:before {
  content: "";
}

.fa-candy-bar:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-candy-corn:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-cannon:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-car:before {
  content: "";
}

.fa-car-alt:before {
  content: "";
}

.fa-car-battery:before {
  content: "";
}

.fa-car-bolt:before {
  content: "";
}

.fa-car-building:before {
  content: "";
}

.fa-car-bump:before {
  content: "";
}

.fa-car-burst:before {
  content: "";
}

.fa-car-bus:before {
  content: "";
}

.fa-car-circle-bolt:before {
  content: "";
}

.fa-car-crash:before {
  content: "";
}

.fa-car-garage:before {
  content: "";
}

.fa-car-mechanic:before {
  content: "";
}

.fa-car-mirrors:before {
  content: "";
}

.fa-car-on:before {
  content: "";
}

.fa-car-rear:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-car-side-bolt:before {
  content: "";
}

.fa-car-tilt:before {
  content: "";
}

.fa-car-tunnel:before {
  content: "";
}

.fa-car-wash:before {
  content: "";
}

.fa-car-wrench:before {
  content: "";
}

.fa-caravan:before {
  content: "";
}

.fa-caravan-alt:before, .fa-caravan-simple:before {
  content: "";
}

.fa-card-club:before {
  content: "";
}

.fa-card-diamond:before {
  content: "";
}

.fa-card-heart:before {
  content: "";
}

.fa-card-spade:before {
  content: "";
}

.fa-cards:before {
  content: "";
}

.fa-cards-blank:before {
  content: "";
}

.fa-caret-circle-down:before {
  content: "";
}

.fa-caret-circle-left:before {
  content: "";
}

.fa-caret-circle-right:before {
  content: "";
}

.fa-caret-circle-up:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-caret-square-down:before {
  content: "";
}

.fa-caret-square-left:before {
  content: "";
}

.fa-caret-square-right:before {
  content: "";
}

.fa-caret-square-up:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-carriage-baby:before {
  content: "";
}

.fa-carrot:before {
  content: "";
}

.fa-cars:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-cart-arrow-up:before {
  content: "";
}

.fa-cart-circle-arrow-down:before {
  content: "";
}

.fa-cart-circle-arrow-up:before {
  content: "";
}

.fa-cart-circle-check:before {
  content: "";
}

.fa-cart-circle-exclamation:before {
  content: "";
}

.fa-cart-circle-plus:before {
  content: "";
}

.fa-cart-circle-xmark:before {
  content: "";
}

.fa-cart-flatbed:before {
  content: "";
}

.fa-cart-flatbed-boxes:before {
  content: "";
}

.fa-cart-flatbed-empty:before {
  content: "";
}

.fa-cart-flatbed-suitcase:before {
  content: "";
}

.fa-cart-minus:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cart-shopping:before {
  content: "";
}

.fa-cart-shopping-fast:before {
  content: "";
}

.fa-cart-xmark:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-cassette-betamax:before {
  content: "";
}

.fa-cassette-tape:before {
  content: "";
}

.fa-cassette-vhs:before {
  content: "";
}

.fa-castle:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-cat-space:before {
  content: "";
}

.fa-cauldron:before {
  content: "";
}

.fa-cctv:before {
  content: "";
}

.fa-cedi-sign:before {
  content: "";
}

.fa-cent-sign:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-chain:before {
  content: "";
}

.fa-chain-broken:before {
  content: "";
}

.fa-chain-horizontal:before {
  content: "";
}

.fa-chain-horizontal-slash:before {
  content: "";
}

.fa-chain-slash:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-chair-office:before {
  content: "";
}

.fa-chalkboard:before {
  content: "";
}

.fa-chalkboard-teacher:before, .fa-chalkboard-user:before {
  content: "";
}

.fa-champagne-glass:before {
  content: "";
}

.fa-champagne-glasses:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-chart-area:before {
  content: "";
}

.fa-chart-bar:before {
  content: "";
}

.fa-chart-bullet:before {
  content: "";
}

.fa-chart-candlestick:before {
  content: "";
}

.fa-chart-column:before {
  content: "";
}

.fa-chart-gantt:before {
  content: "";
}

.fa-chart-kanban:before {
  content: "";
}

.fa-chart-line:before {
  content: "";
}

.fa-chart-line-down:before {
  content: "";
}

.fa-chart-line-up:before {
  content: "";
}

.fa-chart-line-up-down:before {
  content: "";
}

.fa-chart-mixed:before {
  content: "";
}

.fa-chart-mixed-up-circle-currency:before {
  content: "";
}

.fa-chart-mixed-up-circle-dollar:before {
  content: "";
}

.fa-chart-network:before {
  content: "";
}

.fa-chart-pie:before {
  content: "";
}

.fa-chart-pie-alt:before, .fa-chart-pie-simple:before {
  content: "";
}

.fa-chart-pie-simple-circle-currency:before {
  content: "";
}

.fa-chart-pie-simple-circle-dollar:before {
  content: "";
}

.fa-chart-pyramid:before {
  content: "";
}

.fa-chart-radar:before {
  content: "";
}

.fa-chart-scatter:before {
  content: "";
}

.fa-chart-scatter-3d:before {
  content: "";
}

.fa-chart-scatter-bubble:before {
  content: "";
}

.fa-chart-simple:before {
  content: "";
}

.fa-chart-simple-horizontal:before {
  content: "";
}

.fa-chart-tree-map:before {
  content: "";
}

.fa-chart-user:before {
  content: "";
}

.fa-chart-waterfall:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-check-to-slot:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-cheese-swiss:before {
  content: "";
}

.fa-cheeseburger:before {
  content: "";
}

.fa-cherries:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-chess-bishop-alt:before, .fa-chess-bishop-piece:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-chess-clock:before {
  content: "";
}

.fa-chess-clock-alt:before, .fa-chess-clock-flip:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-chess-king-alt:before, .fa-chess-king-piece:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-chess-knight-alt:before, .fa-chess-knight-piece:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-chess-pawn-alt:before, .fa-chess-pawn-piece:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-chess-queen-alt:before, .fa-chess-queen-piece:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-chess-rook-alt:before, .fa-chess-rook-piece:before {
  content: "";
}

.fa-chestnut:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-double-down:before {
  content: "";
}

.fa-chevron-double-left:before {
  content: "";
}

.fa-chevron-double-right:before {
  content: "";
}

.fa-chevron-double-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-chevron-square-down:before {
  content: "";
}

.fa-chevron-square-left:before {
  content: "";
}

.fa-chevron-square-right:before {
  content: "";
}

.fa-chevron-square-up:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-chevrons-down:before {
  content: "";
}

.fa-chevrons-left:before {
  content: "";
}

.fa-chevrons-right:before {
  content: "";
}

.fa-chevrons-up:before {
  content: "";
}

.fa-chf-sign:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-child-combatant:before {
  content: "";
}

.fa-child-dress:before {
  content: "";
}

.fa-child-reaching:before {
  content: "";
}

.fa-child-rifle:before {
  content: "";
}

.fa-children:before {
  content: "";
}

.fa-chimney:before {
  content: "";
}

.fa-chocolate-bar:before {
  content: "";
}

.fa-chopsticks:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-circle-0:before {
  content: "";
}

.fa-circle-1:before {
  content: "";
}

.fa-circle-2:before {
  content: "";
}

.fa-circle-3:before {
  content: "";
}

.fa-circle-4:before {
  content: "";
}

.fa-circle-5:before {
  content: "";
}

.fa-circle-6:before {
  content: "";
}

.fa-circle-7:before {
  content: "";
}

.fa-circle-8:before {
  content: "";
}

.fa-circle-9:before {
  content: "";
}

.fa-circle-a:before {
  content: "";
}

.fa-circle-ampersand:before {
  content: "";
}

.fa-circle-arrow-down:before {
  content: "";
}

.fa-circle-arrow-down-left:before {
  content: "";
}

.fa-circle-arrow-down-right:before {
  content: "";
}

.fa-circle-arrow-left:before {
  content: "";
}

.fa-circle-arrow-right:before {
  content: "";
}

.fa-circle-arrow-up:before {
  content: "";
}

.fa-circle-arrow-up-left:before {
  content: "";
}

.fa-circle-arrow-up-right:before {
  content: "";
}

.fa-circle-b:before {
  content: "";
}

.fa-circle-bolt:before {
  content: "";
}

.fa-circle-book-open:before {
  content: "";
}

.fa-circle-bookmark:before {
  content: "";
}

.fa-circle-c:before {
  content: "";
}

.fa-circle-calendar:before {
  content: "";
}

.fa-circle-camera:before {
  content: "";
}

.fa-circle-caret-down:before {
  content: "";
}

.fa-circle-caret-left:before {
  content: "";
}

.fa-circle-caret-right:before {
  content: "";
}

.fa-circle-caret-up:before {
  content: "";
}

.fa-circle-check:before {
  content: "";
}

.fa-circle-chevron-down:before {
  content: "";
}

.fa-circle-chevron-left:before {
  content: "";
}

.fa-circle-chevron-right:before {
  content: "";
}

.fa-circle-chevron-up:before {
  content: "";
}

.fa-circle-d:before {
  content: "";
}

.fa-circle-dashed:before {
  content: "";
}

.fa-circle-divide:before {
  content: "";
}

.fa-circle-dollar:before {
  content: "";
}

.fa-circle-dollar-to-slot:before {
  content: "";
}

.fa-circle-dot:before {
  content: "";
}

.fa-circle-down:before {
  content: "";
}

.fa-circle-down-left:before {
  content: "";
}

.fa-circle-down-right:before {
  content: "";
}

.fa-circle-e:before {
  content: "";
}

.fa-circle-ellipsis:before {
  content: "";
}

.fa-circle-ellipsis-vertical:before {
  content: "";
}

.fa-circle-envelope:before {
  content: "";
}

.fa-circle-euro:before {
  content: "";
}

.fa-circle-exclamation:before {
  content: "";
}

.fa-circle-exclamation-check:before {
  content: "";
}

.fa-circle-f:before {
  content: "";
}

.fa-circle-g:before {
  content: "";
}

.fa-circle-gf:before {
  content: "";
}

.fa-circle-h:before {
  content: "";
}

.fa-circle-half:before {
  content: "";
}

.fa-circle-half-stroke:before {
  content: "";
}

.fa-circle-heart:before {
  content: "";
}

.fa-circle-i:before {
  content: "";
}

.fa-circle-info:before {
  content: "";
}

.fa-circle-j:before {
  content: "";
}

.fa-circle-k:before {
  content: "";
}

.fa-circle-l:before {
  content: "";
}

.fa-circle-left:before {
  content: "";
}

.fa-circle-location-arrow:before {
  content: "";
}

.fa-circle-m:before {
  content: "";
}

.fa-circle-microphone:before {
  content: "";
}

.fa-circle-microphone-lines:before {
  content: "";
}

.fa-circle-minus:before {
  content: "";
}

.fa-circle-n:before {
  content: "";
}

.fa-circle-nodes:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-circle-o:before {
  content: "";
}

.fa-circle-p:before {
  content: "";
}

.fa-circle-parking:before {
  content: "";
}

.fa-circle-pause:before {
  content: "";
}

.fa-circle-phone:before {
  content: "";
}

.fa-circle-phone-flip:before {
  content: "";
}

.fa-circle-phone-hangup:before {
  content: "";
}

.fa-circle-play:before {
  content: "";
}

.fa-circle-plus:before {
  content: "";
}

.fa-circle-q:before {
  content: "";
}

.fa-circle-quarter:before {
  content: "";
}

.fa-circle-quarter-stroke:before {
  content: "";
}

.fa-circle-quarters:before {
  content: "";
}

.fa-circle-question:before {
  content: "";
}

.fa-circle-r:before {
  content: "";
}

.fa-circle-radiation:before {
  content: "";
}

.fa-circle-right:before {
  content: "";
}

.fa-circle-s:before {
  content: "";
}

.fa-circle-small:before {
  content: "";
}

.fa-circle-sort:before {
  content: "";
}

.fa-circle-sort-down:before {
  content: "";
}

.fa-circle-sort-up:before {
  content: "";
}

.fa-circle-star:before {
  content: "";
}

.fa-circle-sterling:before {
  content: "";
}

.fa-circle-stop:before {
  content: "";
}

.fa-circle-t:before {
  content: "";
}

.fa-circle-three-quarters:before {
  content: "";
}

.fa-circle-three-quarters-stroke:before {
  content: "";
}

.fa-circle-trash:before {
  content: "";
}

.fa-circle-u:before {
  content: "";
}

.fa-circle-up:before {
  content: "";
}

.fa-circle-up-left:before {
  content: "";
}

.fa-circle-up-right:before {
  content: "";
}

.fa-circle-user:before {
  content: "";
}

.fa-circle-v:before {
  content: "";
}

.fa-circle-video:before {
  content: "";
}

.fa-circle-w:before {
  content: "";
}

.fa-circle-waveform-lines:before {
  content: "";
}

.fa-circle-wifi:before {
  content: "";
}

.fa-circle-wifi-circle-wifi:before, .fa-circle-wifi-group:before {
  content: "";
}

.fa-circle-x:before {
  content: "";
}

.fa-circle-xmark:before {
  content: "";
}

.fa-circle-y:before {
  content: "";
}

.fa-circle-yen:before {
  content: "";
}

.fa-circle-z:before {
  content: "";
}

.fa-circles-overlap:before {
  content: "";
}

.fa-citrus:before {
  content: "";
}

.fa-citrus-slice:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-clapperboard:before {
  content: "";
}

.fa-clapperboard-play:before {
  content: "";
}

.fa-clarinet:before {
  content: "";
}

.fa-claw-marks:before {
  content: "";
}

.fa-clinic-medical:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-clipboard-list-check:before {
  content: "";
}

.fa-clipboard-medical:before {
  content: "";
}

.fa-clipboard-prescription:before {
  content: "";
}

.fa-clipboard-question:before {
  content: "";
}

.fa-clipboard-user:before {
  content: "";
}

.fa-clock:before {
  content: "";
}

.fa-clock-desk:before {
  content: "";
}

.fa-clock-eight:before {
  content: "";
}

.fa-clock-eight-thirty:before {
  content: "";
}

.fa-clock-eleven:before {
  content: "";
}

.fa-clock-eleven-thirty:before {
  content: "";
}

.fa-clock-five:before {
  content: "";
}

.fa-clock-five-thirty:before {
  content: "";
}

.fa-clock-four:before {
  content: "";
}

.fa-clock-four-thirty:before {
  content: "";
}

.fa-clock-nine:before {
  content: "";
}

.fa-clock-nine-thirty:before {
  content: "";
}

.fa-clock-one:before {
  content: "";
}

.fa-clock-one-thirty:before {
  content: "";
}

.fa-clock-rotate-left:before {
  content: "";
}

.fa-clock-seven:before {
  content: "";
}

.fa-clock-seven-thirty:before {
  content: "";
}

.fa-clock-six:before {
  content: "";
}

.fa-clock-six-thirty:before {
  content: "";
}

.fa-clock-ten:before {
  content: "";
}

.fa-clock-ten-thirty:before {
  content: "";
}

.fa-clock-three:before {
  content: "";
}

.fa-clock-three-thirty:before {
  content: "";
}

.fa-clock-twelve:before {
  content: "";
}

.fa-clock-twelve-thirty:before {
  content: "";
}

.fa-clock-two:before {
  content: "";
}

.fa-clock-two-thirty:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-close:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-closed-captioning-slash:before {
  content: "";
}

.fa-clothes-hanger:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-cloud-arrow-down:before {
  content: "";
}

.fa-cloud-arrow-up:before {
  content: "";
}

.fa-cloud-binary:before {
  content: "";
}

.fa-cloud-bolt:before {
  content: "";
}

.fa-cloud-bolt-moon:before {
  content: "";
}

.fa-cloud-bolt-sun:before {
  content: "";
}

.fa-cloud-check:before {
  content: "";
}

.fa-cloud-download:before, .fa-cloud-download-alt:before {
  content: "";
}

.fa-cloud-drizzle:before {
  content: "";
}

.fa-cloud-exclamation:before {
  content: "";
}

.fa-cloud-fog:before {
  content: "";
}

.fa-cloud-hail:before {
  content: "";
}

.fa-cloud-hail-mixed:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-cloud-minus:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-cloud-music:before {
  content: "";
}

.fa-cloud-plus:before {
  content: "";
}

.fa-cloud-question:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-cloud-rainbow:before {
  content: "";
}

.fa-cloud-showers:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-cloud-showers-water:before {
  content: "";
}

.fa-cloud-slash:before {
  content: "";
}

.fa-cloud-sleet:before {
  content: "";
}

.fa-cloud-snow:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-cloud-upload:before, .fa-cloud-upload-alt:before {
  content: "";
}

.fa-cloud-word:before {
  content: "";
}

.fa-cloud-xmark:before {
  content: "";
}

.fa-clouds:before {
  content: "";
}

.fa-clouds-moon:before {
  content: "";
}

.fa-clouds-sun:before {
  content: "";
}

.fa-clover:before {
  content: "";
}

.fa-club:before {
  content: "";
}

.fa-cny:before {
  content: "";
}

.fa-cocktail:before {
  content: "";
}

.fa-coconut:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-code-commit:before {
  content: "";
}

.fa-code-compare:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-code-merge:before {
  content: "";
}

.fa-code-pull-request:before {
  content: "";
}

.fa-code-pull-request-closed:before {
  content: "";
}

.fa-code-pull-request-draft:before {
  content: "";
}

.fa-code-simple:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-coffee-bean:before {
  content: "";
}

.fa-coffee-beans:before {
  content: "";
}

.fa-coffee-pot:before {
  content: "";
}

.fa-coffee-togo:before {
  content: "";
}

.fa-coffin:before {
  content: "";
}

.fa-coffin-cross:before {
  content: "";
}

.fa-cog:before {
  content: "";
}

.fa-cogs:before {
  content: "";
}

.fa-coin:before {
  content: "";
}

.fa-coin-blank:before {
  content: "";
}

.fa-coin-front:before {
  content: "";
}

.fa-coin-vertical:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-colon:before {
  content: ":";
}

.fa-colon-sign:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-columns-3:before {
  content: "";
}

.fa-comet:before {
  content: "";
}

.fa-comma:before {
  content: ",";
}

.fa-command:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-comment-alt:before {
  content: "";
}

.fa-comment-alt-arrow-down:before {
  content: "";
}

.fa-comment-alt-arrow-up:before {
  content: "";
}

.fa-comment-alt-captions:before {
  content: "";
}

.fa-comment-alt-check:before {
  content: "";
}

.fa-comment-alt-dollar:before {
  content: "";
}

.fa-comment-alt-dots:before {
  content: "";
}

.fa-comment-alt-edit:before {
  content: "";
}

.fa-comment-alt-exclamation:before {
  content: "";
}

.fa-comment-alt-image:before {
  content: "";
}

.fa-comment-alt-lines:before {
  content: "";
}

.fa-comment-alt-medical:before {
  content: "";
}

.fa-comment-alt-minus:before {
  content: "";
}

.fa-comment-alt-music:before {
  content: "";
}

.fa-comment-alt-plus:before {
  content: "";
}

.fa-comment-alt-quote:before {
  content: "";
}

.fa-comment-alt-slash:before {
  content: "";
}

.fa-comment-alt-smile:before {
  content: "";
}

.fa-comment-alt-text:before {
  content: "";
}

.fa-comment-alt-times:before {
  content: "";
}

.fa-comment-arrow-down:before {
  content: "";
}

.fa-comment-arrow-up:before {
  content: "";
}

.fa-comment-arrow-up-right:before {
  content: "";
}

.fa-comment-captions:before {
  content: "";
}

.fa-comment-check:before {
  content: "";
}

.fa-comment-code:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-comment-dots:before {
  content: "";
}

.fa-comment-edit:before {
  content: "";
}

.fa-comment-exclamation:before {
  content: "";
}

.fa-comment-heart:before {
  content: "";
}

.fa-comment-image:before {
  content: "";
}

.fa-comment-lines:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-comment-middle:before {
  content: "";
}

.fa-comment-middle-alt:before {
  content: "";
}

.fa-comment-middle-top:before {
  content: "";
}

.fa-comment-middle-top-alt:before {
  content: "";
}

.fa-comment-minus:before {
  content: "";
}

.fa-comment-music:before {
  content: "";
}

.fa-comment-pen:before {
  content: "";
}

.fa-comment-plus:before {
  content: "";
}

.fa-comment-question:before {
  content: "";
}

.fa-comment-quote:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-comment-smile:before {
  content: "";
}

.fa-comment-sms:before {
  content: "";
}

.fa-comment-text:before {
  content: "";
}

.fa-comment-times:before, .fa-comment-xmark:before {
  content: "";
}

.fa-commenting:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-comments-alt:before {
  content: "";
}

.fa-comments-alt-dollar:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-comments-question:before {
  content: "";
}

.fa-comments-question-check:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-compass-drafting:before {
  content: "";
}

.fa-compass-slash:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-compress-alt:before {
  content: "";
}

.fa-compress-arrows:before {
  content: "";
}

.fa-compress-arrows-alt:before {
  content: "";
}

.fa-compress-wide:before {
  content: "";
}

.fa-computer:before {
  content: "";
}

.fa-computer-classic:before {
  content: "";
}

.fa-computer-mouse:before {
  content: "";
}

.fa-computer-mouse-scrollwheel:before {
  content: "";
}

.fa-computer-speaker:before {
  content: "";
}

.fa-concierge-bell:before {
  content: "";
}

.fa-construction:before {
  content: "";
}

.fa-contact-book:before {
  content: "";
}

.fa-contact-card:before {
  content: "";
}

.fa-container-storage:before {
  content: "";
}

.fa-conveyor-belt:before {
  content: "";
}

.fa-conveyor-belt-alt:before {
  content: "";
}

.fa-conveyor-belt-arm:before {
  content: "";
}

.fa-conveyor-belt-boxes:before {
  content: "";
}

.fa-conveyor-belt-empty:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-corn:before {
  content: "";
}

.fa-corner:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-couch-small:before {
  content: "";
}

.fa-court-sport:before {
  content: "";
}

.fa-cow:before {
  content: "";
}

.fa-cowbell:before {
  content: "";
}

.fa-cowbell-circle-plus:before, .fa-cowbell-more:before {
  content: "";
}

.fa-crab:before {
  content: "";
}

.fa-crate-apple:before {
  content: "";
}

.fa-crate-empty:before {
  content: "";
}

.fa-credit-card:before, .fa-credit-card-alt:before {
  content: "";
}

.fa-credit-card-blank:before {
  content: "";
}

.fa-credit-card-front:before {
  content: "";
}

.fa-creemee:before {
  content: "";
}

.fa-cricket:before, .fa-cricket-bat-ball:before {
  content: "";
}

.fa-croissant:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-crop-alt:before, .fa-crop-simple:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-crosshairs-simple:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-crutches:before {
  content: "";
}

.fa-cruzeiro-sign:before {
  content: "";
}

.fa-crystal-ball:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-cubes-stacked:before {
  content: "";
}

.fa-cucumber:before {
  content: "";
}

.fa-cup-straw:before {
  content: "";
}

.fa-cup-straw-swoosh:before {
  content: "";
}

.fa-cup-togo:before {
  content: "";
}

.fa-cupcake:before {
  content: "";
}

.fa-curling:before, .fa-curling-stone:before {
  content: "";
}

.fa-custard:before {
  content: "";
}

.fa-cut:before {
  content: "";
}

.fa-cutlery:before {
  content: "";
}

.fa-d:before {
  content: "D";
}

.fa-dagger:before {
  content: "";
}

.fa-dash:before {
  content: "";
}

.fa-dashboard:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-deaf:before, .fa-deafness:before {
  content: "";
}

.fa-debug:before {
  content: "";
}

.fa-dedent:before {
  content: "";
}

.fa-deer:before {
  content: "";
}

.fa-deer-rudolph:before {
  content: "";
}

.fa-delete-left:before {
  content: "";
}

.fa-delete-right:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-desktop:before, .fa-desktop-alt:before {
  content: "";
}

.fa-desktop-arrow-down:before {
  content: "";
}

.fa-desktop-code:before {
  content: "";
}

.fa-desktop-medical:before {
  content: "";
}

.fa-desktop-slash:before {
  content: "";
}

.fa-dewpoint:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-diagnoses:before {
  content: "";
}

.fa-diagram-cells:before {
  content: "";
}

.fa-diagram-lean-canvas:before {
  content: "";
}

.fa-diagram-nested:before {
  content: "";
}

.fa-diagram-next:before {
  content: "";
}

.fa-diagram-predecessor:before {
  content: "";
}

.fa-diagram-previous:before {
  content: "";
}

.fa-diagram-project:before {
  content: "";
}

.fa-diagram-sankey:before {
  content: "";
}

.fa-diagram-subtask:before {
  content: "";
}

.fa-diagram-successor:before {
  content: "";
}

.fa-diagram-venn:before {
  content: "";
}

.fa-dial:before {
  content: "";
}

.fa-dial-high:before {
  content: "";
}

.fa-dial-low:before {
  content: "";
}

.fa-dial-max:before {
  content: "";
}

.fa-dial-med:before {
  content: "";
}

.fa-dial-med-high:before {
  content: "";
}

.fa-dial-med-low:before {
  content: "";
}

.fa-dial-min:before {
  content: "";
}

.fa-dial-off:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-diamond-exclamation:before {
  content: "";
}

.fa-diamond-half:before {
  content: "";
}

.fa-diamond-half-stroke:before {
  content: "";
}

.fa-diamond-turn-right:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-dice-d10:before {
  content: "";
}

.fa-dice-d12:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-dice-d4:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-dice-d8:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-digging:before {
  content: "";
}

.fa-digital-tachograph:before {
  content: "";
}

.fa-dinosaur:before {
  content: "";
}

.fa-diploma:before {
  content: "";
}

.fa-directions:before {
  content: "";
}

.fa-disc-drive:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-display:before {
  content: "";
}

.fa-display-arrow-down:before {
  content: "";
}

.fa-display-chart-up:before {
  content: "";
}

.fa-display-chart-up-circle-currency:before {
  content: "";
}

.fa-display-chart-up-circle-dollar:before {
  content: "";
}

.fa-display-code:before {
  content: "";
}

.fa-display-medical:before {
  content: "";
}

.fa-display-slash:before {
  content: "";
}

.fa-distribute-spacing-horizontal:before {
  content: "";
}

.fa-distribute-spacing-vertical:before {
  content: "";
}

.fa-ditto:before {
  content: "\"";
}

.fa-divide:before {
  content: "";
}

.fa-dizzy:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-do-not-enter:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-dog-leashed:before {
  content: "";
}

.fa-dollar:before {
  content: "$";
}

.fa-dollar-circle:before {
  content: "";
}

.fa-dollar-sign:before {
  content: "$";
}

.fa-dollar-square:before {
  content: "";
}

.fa-dolly:before, .fa-dolly-box:before {
  content: "";
}

.fa-dolly-empty:before {
  content: "";
}

.fa-dolly-flatbed:before {
  content: "";
}

.fa-dolly-flatbed-alt:before {
  content: "";
}

.fa-dolly-flatbed-empty:before {
  content: "";
}

.fa-dolphin:before {
  content: "";
}

.fa-donate:before {
  content: "";
}

.fa-dong-sign:before {
  content: "";
}

.fa-donut:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-dot-circle:before {
  content: "";
}

.fa-doughnut:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-down:before {
  content: "";
}

.fa-down-from-bracket:before {
  content: "";
}

.fa-down-from-dotted-line:before {
  content: "";
}

.fa-down-from-line:before {
  content: "";
}

.fa-down-left:before {
  content: "";
}

.fa-down-left-and-up-right-to-center:before {
  content: "";
}

.fa-down-long:before {
  content: "";
}

.fa-down-right:before {
  content: "";
}

.fa-down-to-bracket:before {
  content: "";
}

.fa-down-to-dotted-line:before {
  content: "";
}

.fa-down-to-line:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-drafting-compass:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-draw-circle:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-draw-square:before {
  content: "";
}

.fa-dreidel:before {
  content: "";
}

.fa-drivers-license:before {
  content: "";
}

.fa-drone:before {
  content: "";
}

.fa-drone-alt:before, .fa-drone-front:before {
  content: "";
}

.fa-droplet:before {
  content: "";
}

.fa-droplet-degree:before {
  content: "";
}

.fa-droplet-percent:before {
  content: "";
}

.fa-droplet-slash:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-drumstick:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-dryer:before {
  content: "";
}

.fa-dryer-alt:before, .fa-dryer-heat:before {
  content: "";
}

.fa-duck:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-e:before {
  content: "E";
}

.fa-ear:before {
  content: "";
}

.fa-ear-deaf:before {
  content: "";
}

.fa-ear-listen:before {
  content: "";
}

.fa-ear-muffs:before {
  content: "";
}

.fa-earth:before {
  content: "";
}

.fa-earth-africa:before {
  content: "";
}

.fa-earth-america:before, .fa-earth-americas:before {
  content: "";
}

.fa-earth-asia:before {
  content: "";
}

.fa-earth-europe:before {
  content: "";
}

.fa-earth-oceania:before {
  content: "";
}

.fa-eclipse:before {
  content: "";
}

.fa-eclipse-alt:before {
  content: "";
}

.fa-edit:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-egg-fried:before {
  content: "";
}

.fa-eggplant:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-elephant:before {
  content: "";
}

.fa-elevator:before {
  content: "";
}

.fa-ellipsis:before, .fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-h-alt:before, .fa-ellipsis-stroke:before {
  content: "";
}

.fa-ellipsis-stroke-vertical:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-ellipsis-v-alt:before {
  content: "";
}

.fa-ellipsis-vertical:before {
  content: "";
}

.fa-empty-set:before {
  content: "";
}

.fa-engine:before {
  content: "";
}

.fa-engine-exclamation:before, .fa-engine-warning:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-envelope-badge:before {
  content: "";
}

.fa-envelope-circle:before {
  content: "";
}

.fa-envelope-circle-check:before {
  content: "";
}

.fa-envelope-dot:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-envelope-open-dollar:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-envelopes:before {
  content: "";
}

.fa-envelopes-bulk:before {
  content: "";
}

.fa-equals:before {
  content: "=";
}

.fa-eraser:before {
  content: "";
}

.fa-escalator:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-eur:before, .fa-euro:before, .fa-euro-sign:before {
  content: "";
}

.fa-excavator:before {
  content: "";
}

.fa-exchange:before {
  content: "";
}

.fa-exchange-alt:before {
  content: "";
}

.fa-exclamation:before {
  content: "!";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-exclamation-square:before {
  content: "";
}

.fa-exclamation-triangle:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-expand-alt:before {
  content: "";
}

.fa-expand-arrows:before {
  content: "";
}

.fa-expand-arrows-alt:before {
  content: "";
}

.fa-expand-wide:before {
  content: "";
}

.fa-exploding-head:before {
  content: "";
}

.fa-explosion:before {
  content: "";
}

.fa-external-link:before {
  content: "";
}

.fa-external-link-alt:before {
  content: "";
}

.fa-external-link-square:before {
  content: "";
}

.fa-external-link-square-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-dropper:before, .fa-eye-dropper-empty:before {
  content: "";
}

.fa-eye-dropper-full:before {
  content: "";
}

.fa-eye-dropper-half:before {
  content: "";
}

.fa-eye-evil:before {
  content: "";
}

.fa-eye-low-vision:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-eyedropper:before {
  content: "";
}

.fa-eyes:before {
  content: "";
}

.fa-f:before {
  content: "F";
}

.fa-face-angry:before {
  content: "";
}

.fa-face-angry-horns:before {
  content: "";
}

.fa-face-anguished:before {
  content: "";
}

.fa-face-anxious-sweat:before {
  content: "";
}

.fa-face-astonished:before {
  content: "";
}

.fa-face-awesome:before {
  content: "";
}

.fa-face-beam-hand-over-mouth:before {
  content: "";
}

.fa-face-clouds:before {
  content: "";
}

.fa-face-confounded:before {
  content: "";
}

.fa-face-confused:before {
  content: "";
}

.fa-face-cowboy-hat:before {
  content: "";
}

.fa-face-diagonal-mouth:before {
  content: "";
}

.fa-face-disappointed:before {
  content: "";
}

.fa-face-disguise:before {
  content: "";
}

.fa-face-dizzy:before {
  content: "";
}

.fa-face-dotted:before {
  content: "";
}

.fa-face-downcast-sweat:before {
  content: "";
}

.fa-face-drooling:before {
  content: "";
}

.fa-face-exhaling:before {
  content: "";
}

.fa-face-explode:before {
  content: "";
}

.fa-face-expressionless:before {
  content: "";
}

.fa-face-eyes-xmarks:before {
  content: "";
}

.fa-face-fearful:before {
  content: "";
}

.fa-face-flushed:before {
  content: "";
}

.fa-face-frown:before {
  content: "";
}

.fa-face-frown-open:before {
  content: "";
}

.fa-face-frown-slight:before {
  content: "";
}

.fa-face-glasses:before {
  content: "";
}

.fa-face-grimace:before {
  content: "";
}

.fa-face-grin:before {
  content: "";
}

.fa-face-grin-beam:before {
  content: "";
}

.fa-face-grin-beam-sweat:before {
  content: "";
}

.fa-face-grin-hearts:before {
  content: "";
}

.fa-face-grin-squint:before {
  content: "";
}

.fa-face-grin-squint-tears:before {
  content: "";
}

.fa-face-grin-stars:before {
  content: "";
}

.fa-face-grin-tears:before {
  content: "";
}

.fa-face-grin-tongue:before {
  content: "";
}

.fa-face-grin-tongue-squint:before {
  content: "";
}

.fa-face-grin-tongue-wink:before {
  content: "";
}

.fa-face-grin-wide:before {
  content: "";
}

.fa-face-grin-wink:before {
  content: "";
}

.fa-face-hand-over-mouth:before {
  content: "";
}

.fa-face-hand-peeking:before {
  content: "";
}

.fa-face-hand-yawn:before {
  content: "";
}

.fa-face-head-bandage:before {
  content: "";
}

.fa-face-holding-back-tears:before {
  content: "";
}

.fa-face-hushed:before {
  content: "";
}

.fa-face-icicles:before {
  content: "";
}

.fa-face-kiss:before {
  content: "";
}

.fa-face-kiss-beam:before {
  content: "";
}

.fa-face-kiss-closed-eyes:before {
  content: "";
}

.fa-face-kiss-wink-heart:before {
  content: "";
}

.fa-face-laugh:before {
  content: "";
}

.fa-face-laugh-beam:before {
  content: "";
}

.fa-face-laugh-squint:before {
  content: "";
}

.fa-face-laugh-wink:before {
  content: "";
}

.fa-face-lying:before {
  content: "";
}

.fa-face-mask:before {
  content: "";
}

.fa-face-meh:before {
  content: "";
}

.fa-face-meh-blank:before {
  content: "";
}

.fa-face-melting:before {
  content: "";
}

.fa-face-monocle:before {
  content: "";
}

.fa-face-nauseated:before {
  content: "";
}

.fa-face-nose-steam:before {
  content: "";
}

.fa-face-party:before {
  content: "";
}

.fa-face-pensive:before {
  content: "";
}

.fa-face-persevering:before {
  content: "";
}

.fa-face-pleading:before {
  content: "";
}

.fa-face-pouting:before {
  content: "";
}

.fa-face-raised-eyebrow:before {
  content: "";
}

.fa-face-relieved:before {
  content: "";
}

.fa-face-rolling-eyes:before {
  content: "";
}

.fa-face-sad-cry:before {
  content: "";
}

.fa-face-sad-sweat:before {
  content: "";
}

.fa-face-sad-tear:before {
  content: "";
}

.fa-face-saluting:before {
  content: "";
}

.fa-face-scream:before {
  content: "";
}

.fa-face-shush:before {
  content: "";
}

.fa-face-sleeping:before {
  content: "";
}

.fa-face-sleepy:before {
  content: "";
}

.fa-face-smile:before {
  content: "";
}

.fa-face-smile-beam:before {
  content: "";
}

.fa-face-smile-halo:before {
  content: "";
}

.fa-face-smile-hearts:before {
  content: "";
}

.fa-face-smile-horns:before {
  content: "";
}

.fa-face-smile-plus:before {
  content: "";
}

.fa-face-smile-relaxed:before {
  content: "";
}

.fa-face-smile-tear:before {
  content: "";
}

.fa-face-smile-tongue:before {
  content: "";
}

.fa-face-smile-upside-down:before {
  content: "";
}

.fa-face-smile-wink:before {
  content: "";
}

.fa-face-smiling-hands:before {
  content: "";
}

.fa-face-smirking:before {
  content: "";
}

.fa-face-spiral-eyes:before {
  content: "";
}

.fa-face-sunglasses:before {
  content: "";
}

.fa-face-surprise:before {
  content: "";
}

.fa-face-swear:before {
  content: "";
}

.fa-face-thermometer:before {
  content: "";
}

.fa-face-thinking:before {
  content: "";
}

.fa-face-tired:before {
  content: "";
}

.fa-face-tissue:before {
  content: "";
}

.fa-face-tongue-money:before {
  content: "";
}

.fa-face-tongue-sweat:before {
  content: "";
}

.fa-face-unamused:before {
  content: "";
}

.fa-face-viewfinder:before {
  content: "";
}

.fa-face-vomit:before {
  content: "";
}

.fa-face-weary:before {
  content: "";
}

.fa-face-woozy:before {
  content: "";
}

.fa-face-worried:before {
  content: "";
}

.fa-face-zany:before {
  content: "";
}

.fa-face-zipper:before {
  content: "";
}

.fa-falafel:before {
  content: "";
}

.fa-family:before {
  content: "";
}

.fa-family-dress:before {
  content: "";
}

.fa-family-pants:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-fan-table:before {
  content: "";
}

.fa-farm:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-faucet:before {
  content: "";
}

.fa-faucet-drip:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-feather-alt:before, .fa-feather-pointed:before {
  content: "";
}

.fa-feed:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-fence:before {
  content: "";
}

.fa-ferris-wheel:before {
  content: "";
}

.fa-ferry:before {
  content: "";
}

.fa-field-hockey:before, .fa-field-hockey-stick-ball:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-alt:before {
  content: "";
}

.fa-file-archive:before {
  content: "";
}

.fa-file-arrow-down:before {
  content: "";
}

.fa-file-arrow-up:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-file-award:before {
  content: "";
}

.fa-file-binary:before {
  content: "";
}

.fa-file-cad:before {
  content: "";
}

.fa-file-caret-down:before {
  content: "";
}

.fa-file-caret-up:before {
  content: "";
}

.fa-file-certificate:before {
  content: "";
}

.fa-file-chart-column:before, .fa-file-chart-line:before {
  content: "";
}

.fa-file-chart-pie:before {
  content: "";
}

.fa-file-check:before {
  content: "";
}

.fa-file-circle-check:before {
  content: "";
}

.fa-file-circle-exclamation:before {
  content: "";
}

.fa-file-circle-info:before {
  content: "";
}

.fa-file-circle-minus:before {
  content: "";
}

.fa-file-circle-plus:before {
  content: "";
}

.fa-file-circle-question:before {
  content: "";
}

.fa-file-circle-xmark:before {
  content: "";
}

.fa-file-clipboard:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-file-dashed-line:before {
  content: "";
}

.fa-file-doc:before {
  content: "";
}

.fa-file-download:before {
  content: "";
}

.fa-file-edit:before {
  content: "";
}

.fa-file-eps:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-exclamation:before {
  content: "";
}

.fa-file-export:before {
  content: "";
}

.fa-file-gif:before {
  content: "";
}

.fa-file-heart:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-file-import:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-file-jpg:before {
  content: "";
}

.fa-file-lines:before {
  content: "";
}

.fa-file-lock:before {
  content: "";
}

.fa-file-magnifying-glass:before {
  content: "";
}

.fa-file-medical:before {
  content: "";
}

.fa-file-medical-alt:before {
  content: "";
}

.fa-file-minus:before {
  content: "";
}

.fa-file-mov:before {
  content: "";
}

.fa-file-mp3:before {
  content: "";
}

.fa-file-mp4:before {
  content: "";
}

.fa-file-music:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-file-pen:before {
  content: "";
}

.fa-file-plus:before {
  content: "";
}

.fa-file-plus-minus:before {
  content: "";
}

.fa-file-png:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-file-ppt:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-file-search:before {
  content: "";
}

.fa-file-shield:before {
  content: "";
}

.fa-file-signature:before {
  content: "";
}

.fa-file-slash:before {
  content: "";
}

.fa-file-spreadsheet:before {
  content: "";
}

.fa-file-svg:before {
  content: "";
}

.fa-file-text:before {
  content: "";
}

.fa-file-times:before {
  content: "";
}

.fa-file-upload:before {
  content: "";
}

.fa-file-user:before {
  content: "";
}

.fa-file-vector:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-file-waveform:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-file-xls:before {
  content: "";
}

.fa-file-xmark:before {
  content: "";
}

.fa-file-xml:before {
  content: "";
}

.fa-file-zip:before {
  content: "";
}

.fa-file-zipper:before {
  content: "";
}

.fa-files:before {
  content: "";
}

.fa-files-medical:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-fill-drip:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-film-alt:before {
  content: "";
}

.fa-film-canister:before, .fa-film-cannister:before {
  content: "";
}

.fa-film-simple:before {
  content: "";
}

.fa-film-slash:before {
  content: "";
}

.fa-films:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-filter-circle-dollar:before {
  content: "";
}

.fa-filter-circle-xmark:before {
  content: "";
}

.fa-filter-list:before {
  content: "";
}

.fa-filter-slash:before {
  content: "";
}

.fa-filters:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-fire-alt:before {
  content: "";
}

.fa-fire-burner:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-fire-flame:before {
  content: "";
}

.fa-fire-flame-curved:before {
  content: "";
}

.fa-fire-flame-simple:before {
  content: "";
}

.fa-fire-hydrant:before {
  content: "";
}

.fa-fire-smoke:before {
  content: "";
}

.fa-fireplace:before {
  content: "";
}

.fa-firewall:before {
  content: "";
}

.fa-first-aid:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-fish-bones:before {
  content: "";
}

.fa-fish-cooked:before {
  content: "";
}

.fa-fish-fins:before {
  content: "";
}

.fa-fishing-rod:before {
  content: "";
}

.fa-fist-raised:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-flag-alt:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-flag-pennant:before {
  content: "";
}

.fa-flag-swallowtail:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-flame:before {
  content: "";
}

.fa-flashlight:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-flask-gear:before {
  content: "";
}

.fa-flask-poison:before {
  content: "";
}

.fa-flask-potion:before {
  content: "";
}

.fa-flask-round-poison:before {
  content: "";
}

.fa-flask-round-potion:before {
  content: "";
}

.fa-flask-vial:before {
  content: "";
}

.fa-flatbread:before {
  content: "";
}

.fa-flatbread-stuffed:before {
  content: "";
}

.fa-floppy-disk:before {
  content: "";
}

.fa-floppy-disk-circle-arrow-right:before {
  content: "";
}

.fa-floppy-disk-circle-xmark:before {
  content: "";
}

.fa-floppy-disk-pen:before {
  content: "";
}

.fa-floppy-disk-times:before {
  content: "";
}

.fa-floppy-disks:before {
  content: "";
}

.fa-florin-sign:before {
  content: "";
}

.fa-flower:before {
  content: "";
}

.fa-flower-daffodil:before {
  content: "";
}

.fa-flower-tulip:before {
  content: "";
}

.fa-flushed:before {
  content: "";
}

.fa-flute:before {
  content: "";
}

.fa-flux-capacitor:before {
  content: "";
}

.fa-flying-disc:before {
  content: "";
}

.fa-fog:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-arrow-down:before {
  content: "";
}

.fa-folder-arrow-up:before {
  content: "";
}

.fa-folder-blank:before {
  content: "";
}

.fa-folder-bookmark:before {
  content: "";
}

.fa-folder-check:before {
  content: "";
}

.fa-folder-closed:before {
  content: "";
}

.fa-folder-cog:before {
  content: "";
}

.fa-folder-download:before {
  content: "";
}

.fa-folder-gear:before {
  content: "";
}

.fa-folder-grid:before {
  content: "";
}

.fa-folder-heart:before {
  content: "";
}

.fa-folder-image:before {
  content: "";
}

.fa-folder-magnifying-glass:before {
  content: "";
}

.fa-folder-medical:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-folder-music:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-folder-search:before {
  content: "";
}

.fa-folder-times:before {
  content: "";
}

.fa-folder-tree:before {
  content: "";
}

.fa-folder-upload:before {
  content: "";
}

.fa-folder-user:before {
  content: "";
}

.fa-folder-xmark:before {
  content: "";
}

.fa-folders:before {
  content: "";
}

.fa-fondue-pot:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-font-case:before {
  content: "";
}

.fa-football:before, .fa-football-ball:before {
  content: "";
}

.fa-football-helmet:before {
  content: "";
}

.fa-fork:before {
  content: "";
}

.fa-fork-knife:before {
  content: "";
}

.fa-forklift:before {
  content: "";
}

.fa-fort:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-forward-fast:before {
  content: "";
}

.fa-forward-step:before {
  content: "";
}

.fa-fragile:before {
  content: "";
}

.fa-frame:before {
  content: "";
}

.fa-franc-sign:before {
  content: "";
}

.fa-french-fries:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-frosty-head:before {
  content: "";
}

.fa-frown:before {
  content: "";
}

.fa-frown-open:before {
  content: "";
}

.fa-function:before {
  content: "";
}

.fa-funnel-dollar:before {
  content: "";
}

.fa-futbol:before, .fa-futbol-ball:before {
  content: "";
}

.fa-g:before {
  content: "G";
}

.fa-galaxy:before {
  content: "";
}

.fa-gallery-thumbnails:before {
  content: "";
}

.fa-game-board:before {
  content: "";
}

.fa-game-board-alt:before, .fa-game-board-simple:before {
  content: "";
}

.fa-game-console-handheld:before {
  content: "";
}

.fa-game-console-handheld-crank:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-gamepad-alt:before, .fa-gamepad-modern:before {
  content: "";
}

.fa-garage:before {
  content: "";
}

.fa-garage-car:before {
  content: "";
}

.fa-garage-open:before {
  content: "";
}

.fa-garlic:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-gas-pump-slash:before {
  content: "";
}

.fa-gauge:before {
  content: "";
}

.fa-gauge-circle-bolt:before {
  content: "";
}

.fa-gauge-circle-minus:before {
  content: "";
}

.fa-gauge-circle-plus:before {
  content: "";
}

.fa-gauge-high:before {
  content: "";
}

.fa-gauge-low:before {
  content: "";
}

.fa-gauge-max:before {
  content: "";
}

.fa-gauge-med:before {
  content: "";
}

.fa-gauge-min:before {
  content: "";
}

.fa-gauge-simple:before {
  content: "";
}

.fa-gauge-simple-high:before {
  content: "";
}

.fa-gauge-simple-low:before {
  content: "";
}

.fa-gauge-simple-max:before {
  content: "";
}

.fa-gauge-simple-med:before {
  content: "";
}

.fa-gauge-simple-min:before {
  content: "";
}

.fa-gave-dandy:before {
  content: "";
}

.fa-gavel:before {
  content: "";
}

.fa-gbp:before {
  content: "";
}

.fa-gear:before {
  content: "";
}

.fa-gear-code:before {
  content: "";
}

.fa-gear-complex:before {
  content: "";
}

.fa-gear-complex-code:before {
  content: "";
}

.fa-gears:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-gif:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-gift-card:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-gingerbread-man:before {
  content: "";
}

.fa-glass:before {
  content: "";
}

.fa-glass-champagne:before {
  content: "";
}

.fa-glass-cheers:before {
  content: "";
}

.fa-glass-citrus:before {
  content: "";
}

.fa-glass-empty:before {
  content: "";
}

.fa-glass-half:before, .fa-glass-half-empty:before, .fa-glass-half-full:before {
  content: "";
}

.fa-glass-martini:before {
  content: "";
}

.fa-glass-martini-alt:before {
  content: "";
}

.fa-glass-water:before {
  content: "";
}

.fa-glass-water-droplet:before {
  content: "";
}

.fa-glass-whiskey:before {
  content: "";
}

.fa-glass-whiskey-rocks:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-glasses-alt:before, .fa-glasses-round:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-globe-africa:before {
  content: "";
}

.fa-globe-americas:before {
  content: "";
}

.fa-globe-asia:before {
  content: "";
}

.fa-globe-europe:before {
  content: "";
}

.fa-globe-oceania:before {
  content: "";
}

.fa-globe-pointer:before {
  content: "";
}

.fa-globe-snow:before {
  content: "";
}

.fa-globe-stand:before {
  content: "";
}

.fa-glove-boxing:before {
  content: "";
}

.fa-goal-net:before {
  content: "";
}

.fa-golf-ball:before, .fa-golf-ball-tee:before {
  content: "";
}

.fa-golf-club:before {
  content: "";
}

.fa-golf-flag-hole:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-graduation-cap:before {
  content: "";
}

.fa-gramophone:before {
  content: "";
}

.fa-grapes:before {
  content: "";
}

.fa-grate:before {
  content: "";
}

.fa-grate-droplet:before {
  content: "";
}

.fa-greater-than:before {
  content: ">";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-grid:before {
  content: "";
}

.fa-grid-2:before {
  content: "";
}

.fa-grid-2-plus:before {
  content: "";
}

.fa-grid-3:before {
  content: "";
}

.fa-grid-4:before {
  content: "";
}

.fa-grid-5:before {
  content: "";
}

.fa-grid-dividers:before {
  content: "";
}

.fa-grid-horizontal:before {
  content: "";
}

.fa-grid-round:before {
  content: "";
}

.fa-grid-round-2:before {
  content: "";
}

.fa-grid-round-2-plus:before {
  content: "";
}

.fa-grid-round-4:before {
  content: "";
}

.fa-grid-round-5:before {
  content: "";
}

.fa-grill:before {
  content: "";
}

.fa-grill-fire:before {
  content: "";
}

.fa-grill-hot:before {
  content: "";
}

.fa-grimace:before {
  content: "";
}

.fa-grin:before {
  content: "";
}

.fa-grin-alt:before {
  content: "";
}

.fa-grin-beam:before {
  content: "";
}

.fa-grin-beam-sweat:before {
  content: "";
}

.fa-grin-hearts:before {
  content: "";
}

.fa-grin-squint:before {
  content: "";
}

.fa-grin-squint-tears:before {
  content: "";
}

.fa-grin-stars:before {
  content: "";
}

.fa-grin-tears:before {
  content: "";
}

.fa-grin-tongue:before {
  content: "";
}

.fa-grin-tongue-squint:before {
  content: "";
}

.fa-grin-tongue-wink:before {
  content: "";
}

.fa-grin-wink:before {
  content: "";
}

.fa-grip:before {
  content: "";
}

.fa-grip-dots:before {
  content: "";
}

.fa-grip-dots-vertical:before {
  content: "";
}

.fa-grip-horizontal:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-group-arrows-rotate:before {
  content: "";
}

.fa-guarani-sign:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-guitar-electric:before {
  content: "";
}

.fa-guitars:before {
  content: "";
}

.fa-gun:before {
  content: "";
}

.fa-gun-slash:before {
  content: "";
}

.fa-gun-squirt:before {
  content: "";
}

.fa-h:before {
  content: "H";
}

.fa-h-square:before {
  content: "";
}

.fa-h1:before {
  content: "";
}

.fa-h2:before {
  content: "";
}

.fa-h3:before {
  content: "";
}

.fa-h4:before {
  content: "";
}

.fa-h5:before {
  content: "";
}

.fa-h6:before {
  content: "";
}

.fa-hamburger:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-hammer-brush:before {
  content: "";
}

.fa-hammer-crash:before {
  content: "";
}

.fa-hammer-war:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-hand:before {
  content: "";
}

.fa-hand-back-fist:before {
  content: "";
}

.fa-hand-back-point-down:before {
  content: "";
}

.fa-hand-back-point-left:before {
  content: "";
}

.fa-hand-back-point-ribbon:before {
  content: "";
}

.fa-hand-back-point-right:before {
  content: "";
}

.fa-hand-back-point-up:before {
  content: "";
}

.fa-hand-dots:before {
  content: "";
}

.fa-hand-fingers-crossed:before {
  content: "";
}

.fa-hand-fist:before {
  content: "";
}

.fa-hand-heart:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-hand-holding-box:before {
  content: "";
}

.fa-hand-holding-circle-dollar:before {
  content: "";
}

.fa-hand-holding-dollar:before {
  content: "";
}

.fa-hand-holding-droplet:before {
  content: "";
}

.fa-hand-holding-hand:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-hand-holding-magic:before {
  content: "";
}

.fa-hand-holding-medical:before {
  content: "";
}

.fa-hand-holding-seedling:before {
  content: "";
}

.fa-hand-holding-skull:before {
  content: "";
}

.fa-hand-holding-usd:before {
  content: "";
}

.fa-hand-holding-water:before {
  content: "";
}

.fa-hand-horns:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-hand-love:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-hand-paper:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-hand-point-ribbon:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-hand-receiving:before {
  content: "";
}

.fa-hand-rock:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hand-sparkles:before {
  content: "";
}

.fa-hand-spock:before {
  content: "";
}

.fa-hand-wave:before {
  content: "";
}

.fa-handcuffs:before {
  content: "";
}

.fa-hands:before {
  content: "";
}

.fa-hands-american-sign-language-interpreting:before, .fa-hands-asl-interpreting:before {
  content: "";
}

.fa-hands-bound:before {
  content: "";
}

.fa-hands-bubbles:before {
  content: "";
}

.fa-hands-clapping:before {
  content: "";
}

.fa-hands-heart:before {
  content: "";
}

.fa-hands-helping:before {
  content: "";
}

.fa-hands-holding:before {
  content: "";
}

.fa-hands-holding-child:before {
  content: "";
}

.fa-hands-holding-circle:before {
  content: "";
}

.fa-hands-holding-diamond:before {
  content: "";
}

.fa-hands-holding-dollar:before {
  content: "";
}

.fa-hands-holding-heart:before {
  content: "";
}

.fa-hands-praying:before {
  content: "";
}

.fa-hands-usd:before {
  content: "";
}

.fa-hands-wash:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-handshake-alt:before {
  content: "";
}

.fa-handshake-alt-slash:before {
  content: "";
}

.fa-handshake-angle:before {
  content: "";
}

.fa-handshake-simple:before {
  content: "";
}

.fa-handshake-simple-slash:before {
  content: "";
}

.fa-handshake-slash:before {
  content: "";
}

.fa-hanukiah:before {
  content: "";
}

.fa-hard-drive:before {
  content: "";
}

.fa-hard-hat:before {
  content: "";
}

.fa-hard-of-hearing:before {
  content: "";
}

.fa-hashtag:before {
  content: "#";
}

.fa-hashtag-lock:before {
  content: "";
}

.fa-hat-beach:before {
  content: "";
}

.fa-hat-chef:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-hat-hard:before {
  content: "";
}

.fa-hat-santa:before {
  content: "";
}

.fa-hat-winter:before {
  content: "";
}

.fa-hat-witch:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-haykal:before {
  content: "";
}

.fa-hdd:before {
  content: "";
}

.fa-head-side:before {
  content: "";
}

.fa-head-side-brain:before {
  content: "";
}

.fa-head-side-cough:before {
  content: "";
}

.fa-head-side-cough-slash:before {
  content: "";
}

.fa-head-side-gear:before {
  content: "";
}

.fa-head-side-goggles:before {
  content: "";
}

.fa-head-side-headphones:before {
  content: "";
}

.fa-head-side-heart:before {
  content: "";
}

.fa-head-side-mask:before {
  content: "";
}

.fa-head-side-medical:before {
  content: "";
}

.fa-head-side-virus:before {
  content: "";
}

.fa-head-vr:before {
  content: "";
}

.fa-header:before, .fa-heading:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-headphones-alt:before, .fa-headphones-simple:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-heart-broken:before {
  content: "";
}

.fa-heart-circle:before {
  content: "";
}

.fa-heart-circle-bolt:before {
  content: "";
}

.fa-heart-circle-check:before {
  content: "";
}

.fa-heart-circle-exclamation:before {
  content: "";
}

.fa-heart-circle-minus:before {
  content: "";
}

.fa-heart-circle-plus:before {
  content: "";
}

.fa-heart-circle-xmark:before {
  content: "";
}

.fa-heart-crack:before {
  content: "";
}

.fa-heart-half:before {
  content: "";
}

.fa-heart-half-alt:before, .fa-heart-half-stroke:before {
  content: "";
}

.fa-heart-music-camera-bolt:before {
  content: "";
}

.fa-heart-pulse:before {
  content: "";
}

.fa-heart-rate:before {
  content: "";
}

.fa-heart-square:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-heat:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-helicopter-symbol:before {
  content: "";
}

.fa-helmet-battle:before {
  content: "";
}

.fa-helmet-safety:before {
  content: "";
}

.fa-helmet-un:before {
  content: "";
}

.fa-hexagon:before {
  content: "";
}

.fa-hexagon-check:before {
  content: "";
}

.fa-hexagon-divide:before {
  content: "";
}

.fa-hexagon-exclamation:before {
  content: "";
}

.fa-hexagon-image:before {
  content: "";
}

.fa-hexagon-minus:before {
  content: "";
}

.fa-hexagon-plus:before {
  content: "";
}

.fa-hexagon-vertical-nft:before {
  content: "";
}

.fa-hexagon-vertical-nft-slanted:before {
  content: "";
}

.fa-hexagon-xmark:before {
  content: "";
}

.fa-high-definition:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-highlighter-line:before {
  content: "";
}

.fa-hiking:before {
  content: "";
}

.fa-hill-avalanche:before {
  content: "";
}

.fa-hill-rockslide:before {
  content: "";
}

.fa-hippo:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-hockey-mask:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-hockey-stick-puck:before {
  content: "";
}

.fa-hockey-sticks:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-home:before, .fa-home-alt:before {
  content: "";
}

.fa-home-blank:before {
  content: "";
}

.fa-home-heart:before {
  content: "";
}

.fa-home-lg:before {
  content: "";
}

.fa-home-lg-alt:before {
  content: "";
}

.fa-home-user:before {
  content: "";
}

.fa-honey-pot:before {
  content: "";
}

.fa-hood-cloak:before {
  content: "";
}

.fa-horizontal-rule:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-horse-saddle:before {
  content: "";
}

.fa-hose:before {
  content: "";
}

.fa-hose-reel:before {
  content: "";
}

.fa-hospital:before, .fa-hospital-alt:before {
  content: "";
}

.fa-hospital-symbol:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-hospital-wide:before {
  content: "";
}

.fa-hospitals:before {
  content: "";
}

.fa-hot-tub:before, .fa-hot-tub-person:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hourglass-1:before {
  content: "";
}

.fa-hourglass-2:before {
  content: "";
}

.fa-hourglass-3:before {
  content: "";
}

.fa-hourglass-clock:before {
  content: "";
}

.fa-hourglass-empty:before {
  content: "";
}

.fa-hourglass-end:before {
  content: "";
}

.fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-start:before {
  content: "";
}

.fa-house:before {
  content: "";
}

.fa-house-blank:before {
  content: "";
}

.fa-house-building:before {
  content: "";
}

.fa-house-chimney:before {
  content: "";
}

.fa-house-chimney-blank:before {
  content: "";
}

.fa-house-chimney-crack:before {
  content: "";
}

.fa-house-chimney-heart:before {
  content: "";
}

.fa-house-chimney-medical:before {
  content: "";
}

.fa-house-chimney-user:before {
  content: "";
}

.fa-house-chimney-window:before {
  content: "";
}

.fa-house-circle-check:before {
  content: "";
}

.fa-house-circle-exclamation:before {
  content: "";
}

.fa-house-circle-xmark:before {
  content: "";
}

.fa-house-crack:before {
  content: "";
}

.fa-house-damage:before {
  content: "";
}

.fa-house-day:before {
  content: "";
}

.fa-house-fire:before {
  content: "";
}

.fa-house-flag:before {
  content: "";
}

.fa-house-flood:before {
  content: "";
}

.fa-house-flood-water:before {
  content: "";
}

.fa-house-flood-water-circle-arrow-right:before {
  content: "";
}

.fa-house-heart:before {
  content: "";
}

.fa-house-laptop:before {
  content: "";
}

.fa-house-leave:before {
  content: "";
}

.fa-house-lock:before {
  content: "";
}

.fa-house-medical:before {
  content: "";
}

.fa-house-medical-circle-check:before {
  content: "";
}

.fa-house-medical-circle-exclamation:before {
  content: "";
}

.fa-house-medical-circle-xmark:before {
  content: "";
}

.fa-house-medical-flag:before {
  content: "";
}

.fa-house-night:before {
  content: "";
}

.fa-house-person-arrive:before {
  content: "";
}

.fa-house-person-depart:before, .fa-house-person-leave:before {
  content: "";
}

.fa-house-person-return:before, .fa-house-return:before {
  content: "";
}

.fa-house-signal:before {
  content: "";
}

.fa-house-tree:before {
  content: "";
}

.fa-house-tsunami:before {
  content: "";
}

.fa-house-turret:before {
  content: "";
}

.fa-house-user:before {
  content: "";
}

.fa-house-water:before {
  content: "";
}

.fa-house-window:before {
  content: "";
}

.fa-hryvnia:before, .fa-hryvnia-sign:before {
  content: "";
}

.fa-humidity:before {
  content: "";
}

.fa-hundred-points:before {
  content: "";
}

.fa-hurricane:before {
  content: "";
}

.fa-hyphen:before {
  content: "-";
}

.fa-i:before {
  content: "I";
}

.fa-i-cursor:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-ice-skate:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-icons:before {
  content: "";
}

.fa-icons-alt:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-id-card:before {
  content: "";
}

.fa-id-card-alt:before, .fa-id-card-clip:before {
  content: "";
}

.fa-igloo:before {
  content: "";
}

.fa-ils:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-image-landscape:before {
  content: "";
}

.fa-image-polaroid:before {
  content: "";
}

.fa-image-polaroid-user:before {
  content: "";
}

.fa-image-portrait:before {
  content: "";
}

.fa-image-slash:before {
  content: "";
}

.fa-image-user:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-images-user:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-inbox-arrow-down:before {
  content: "";
}

.fa-inbox-arrow-up:before {
  content: "";
}

.fa-inbox-full:before {
  content: "";
}

.fa-inbox-in:before {
  content: "";
}

.fa-inbox-out:before {
  content: "";
}

.fa-inboxes:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-indian-rupee:before, .fa-indian-rupee-sign:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-industry-alt:before, .fa-industry-windows:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-info-square:before {
  content: "";
}

.fa-inhaler:before {
  content: "";
}

.fa-input-numeric:before {
  content: "";
}

.fa-input-pipe:before {
  content: "";
}

.fa-input-text:before {
  content: "";
}

.fa-inr:before {
  content: "";
}

.fa-institution:before {
  content: "";
}

.fa-integral:before {
  content: "";
}

.fa-interrobang:before {
  content: "";
}

.fa-intersection:before {
  content: "";
}

.fa-inventory:before {
  content: "";
}

.fa-island-tree-palm:before, .fa-island-tropical:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-j:before {
  content: "J";
}

.fa-jack-o-lantern:before {
  content: "";
}

.fa-jar:before {
  content: "";
}

.fa-jar-wheat:before {
  content: "";
}

.fa-jedi:before {
  content: "";
}

.fa-jet-fighter:before {
  content: "";
}

.fa-jet-fighter-up:before {
  content: "";
}

.fa-joint:before {
  content: "";
}

.fa-journal-whills:before {
  content: "";
}

.fa-joystick:before {
  content: "";
}

.fa-jpy:before {
  content: "";
}

.fa-jug:before {
  content: "";
}

.fa-jug-bottle:before {
  content: "";
}

.fa-jug-detergent:before {
  content: "";
}

.fa-k:before {
  content: "K";
}

.fa-kaaba:before {
  content: "";
}

.fa-kazoo:before {
  content: "";
}

.fa-kerning:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-key-skeleton:before {
  content: "";
}

.fa-key-skeleton-left-right:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-keyboard-brightness:before {
  content: "";
}

.fa-keyboard-brightness-low:before {
  content: "";
}

.fa-keyboard-down:before {
  content: "";
}

.fa-keyboard-left:before {
  content: "";
}

.fa-keynote:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-kidneys:before {
  content: "";
}

.fa-kip-sign:before {
  content: "";
}

.fa-kiss:before {
  content: "";
}

.fa-kiss-beam:before {
  content: "";
}

.fa-kiss-wink-heart:before {
  content: "";
}

.fa-kit-medical:before {
  content: "";
}

.fa-kitchen-set:before {
  content: "";
}

.fa-kite:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-kiwi-fruit:before {
  content: "";
}

.fa-knife:before {
  content: "";
}

.fa-knife-kitchen:before {
  content: "";
}

.fa-krw:before {
  content: "";
}

.fa-l:before {
  content: "L";
}

.fa-lacrosse-stick:before {
  content: "";
}

.fa-lacrosse-stick-ball:before {
  content: "";
}

.fa-ladder-water:before {
  content: "";
}

.fa-lambda:before {
  content: "";
}

.fa-lamp:before {
  content: "";
}

.fa-lamp-desk:before {
  content: "";
}

.fa-lamp-floor:before {
  content: "";
}

.fa-lamp-street:before {
  content: "";
}

.fa-land-mine-on:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-landmark-alt:before, .fa-landmark-dome:before {
  content: "";
}

.fa-landmark-flag:before {
  content: "";
}

.fa-landmark-magnifying-glass:before {
  content: "";
}

.fa-landscape:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-laptop-arrow-down:before {
  content: "";
}

.fa-laptop-binary:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-laptop-file:before {
  content: "";
}

.fa-laptop-house:before {
  content: "";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-laptop-mobile:before {
  content: "";
}

.fa-laptop-slash:before {
  content: "";
}

.fa-lari-sign:before {
  content: "";
}

.fa-lasso:before {
  content: "";
}

.fa-lasso-sparkles:before {
  content: "";
}

.fa-laugh:before {
  content: "";
}

.fa-laugh-beam:before {
  content: "";
}

.fa-laugh-squint:before {
  content: "";
}

.fa-laugh-wink:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-layer-group-minus:before {
  content: "";
}

.fa-layer-group-plus:before {
  content: "";
}

.fa-layer-minus:before {
  content: "";
}

.fa-layer-plus:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-leaf-heart:before {
  content: "";
}

.fa-leaf-maple:before {
  content: "";
}

.fa-leaf-oak:before {
  content: "";
}

.fa-leafy-green:before {
  content: "";
}

.fa-left:before {
  content: "";
}

.fa-left-from-bracket:before {
  content: "";
}

.fa-left-from-line:before {
  content: "";
}

.fa-left-long:before {
  content: "";
}

.fa-left-long-to-line:before {
  content: "";
}

.fa-left-right:before {
  content: "";
}

.fa-left-to-bracket:before {
  content: "";
}

.fa-left-to-line:before {
  content: "";
}

.fa-legal:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-less-than:before {
  content: "<";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-level-down:before {
  content: "";
}

.fa-level-down-alt:before {
  content: "";
}

.fa-level-up:before {
  content: "";
}

.fa-level-up-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-light-ceiling:before {
  content: "";
}

.fa-light-emergency:before {
  content: "";
}

.fa-light-emergency-on:before {
  content: "";
}

.fa-light-switch:before {
  content: "";
}

.fa-light-switch-off:before {
  content: "";
}

.fa-light-switch-on:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-lightbulb-cfl:before {
  content: "";
}

.fa-lightbulb-cfl-on:before {
  content: "";
}

.fa-lightbulb-dollar:before {
  content: "";
}

.fa-lightbulb-exclamation:before {
  content: "";
}

.fa-lightbulb-exclamation-on:before {
  content: "";
}

.fa-lightbulb-gear:before {
  content: "";
}

.fa-lightbulb-on:before {
  content: "";
}

.fa-lightbulb-slash:before {
  content: "";
}

.fa-lighthouse:before {
  content: "";
}

.fa-lights-holiday:before {
  content: "";
}

.fa-line-chart:before {
  content: "";
}

.fa-line-columns:before {
  content: "";
}

.fa-line-height:before {
  content: "";
}

.fa-lines-leaning:before {
  content: "";
}

.fa-link:before {
  content: "";
}

.fa-link-horizontal:before {
  content: "";
}

.fa-link-horizontal-slash:before {
  content: "";
}

.fa-link-simple:before {
  content: "";
}

.fa-link-simple-slash:before {
  content: "";
}

.fa-link-slash:before {
  content: "";
}

.fa-lips:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-list-1-2:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-list-check:before {
  content: "";
}

.fa-list-dots:before {
  content: "";
}

.fa-list-dropdown:before {
  content: "";
}

.fa-list-music:before {
  content: "";
}

.fa-list-numeric:before, .fa-list-ol:before {
  content: "";
}

.fa-list-radio:before {
  content: "";
}

.fa-list-squares:before {
  content: "";
}

.fa-list-timeline:before {
  content: "";
}

.fa-list-tree:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-litecoin-sign:before {
  content: "";
}

.fa-loader:before {
  content: "";
}

.fa-lobster:before {
  content: "";
}

.fa-location:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-location-arrow-up:before {
  content: "";
}

.fa-location-check:before {
  content: "";
}

.fa-location-circle:before {
  content: "";
}

.fa-location-crosshairs:before {
  content: "";
}

.fa-location-crosshairs-slash:before {
  content: "";
}

.fa-location-dot:before {
  content: "";
}

.fa-location-dot-slash:before {
  content: "";
}

.fa-location-exclamation:before {
  content: "";
}

.fa-location-minus:before {
  content: "";
}

.fa-location-pen:before {
  content: "";
}

.fa-location-pin:before {
  content: "";
}

.fa-location-pin-lock:before {
  content: "";
}

.fa-location-pin-slash:before {
  content: "";
}

.fa-location-plus:before {
  content: "";
}

.fa-location-question:before {
  content: "";
}

.fa-location-slash:before {
  content: "";
}

.fa-location-smile:before {
  content: "";
}

.fa-location-xmark:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-lock-a:before {
  content: "";
}

.fa-lock-alt:before {
  content: "";
}

.fa-lock-hashtag:before {
  content: "";
}

.fa-lock-keyhole:before {
  content: "";
}

.fa-lock-keyhole-open:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-lock-open-alt:before {
  content: "";
}

.fa-locust:before {
  content: "";
}

.fa-lollipop:before, .fa-lollypop:before {
  content: "";
}

.fa-long-arrow-alt-down:before {
  content: "";
}

.fa-long-arrow-alt-left:before {
  content: "";
}

.fa-long-arrow-alt-right:before {
  content: "";
}

.fa-long-arrow-alt-up:before {
  content: "";
}

.fa-long-arrow-down:before {
  content: "";
}

.fa-long-arrow-left:before {
  content: "";
}

.fa-long-arrow-right:before {
  content: "";
}

.fa-long-arrow-up:before {
  content: "";
}

.fa-loveseat:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-luchador:before, .fa-luchador-mask:before {
  content: "";
}

.fa-luggage-cart:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-lungs-virus:before {
  content: "";
}

.fa-m:before {
  content: "M";
}

.fa-mace:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-magic-wand-sparkles:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-magnifying-glass:before {
  content: "";
}

.fa-magnifying-glass-arrow-right:before {
  content: "";
}

.fa-magnifying-glass-arrows-rotate:before {
  content: "";
}

.fa-magnifying-glass-chart:before {
  content: "";
}

.fa-magnifying-glass-dollar:before {
  content: "";
}

.fa-magnifying-glass-location:before {
  content: "";
}

.fa-magnifying-glass-minus:before {
  content: "";
}

.fa-magnifying-glass-music:before {
  content: "";
}

.fa-magnifying-glass-play:before {
  content: "";
}

.fa-magnifying-glass-plus:before {
  content: "";
}

.fa-magnifying-glass-waveform:before {
  content: "";
}

.fa-mail-bulk:before {
  content: "";
}

.fa-mail-forward:before {
  content: "";
}

.fa-mail-reply:before {
  content: "";
}

.fa-mail-reply-all:before {
  content: "";
}

.fa-mailbox:before {
  content: "";
}

.fa-mailbox-flag-up:before {
  content: "";
}

.fa-maki-roll:before, .fa-makizushi:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-manat-sign:before {
  content: "";
}

.fa-mandolin:before {
  content: "";
}

.fa-mango:before {
  content: "";
}

.fa-manhole:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-map-location:before {
  content: "";
}

.fa-map-location-dot:before {
  content: "";
}

.fa-map-marked:before {
  content: "";
}

.fa-map-marked-alt:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-map-marker-alt:before {
  content: "";
}

.fa-map-marker-alt-slash:before {
  content: "";
}

.fa-map-marker-check:before {
  content: "";
}

.fa-map-marker-edit:before {
  content: "";
}

.fa-map-marker-exclamation:before {
  content: "";
}

.fa-map-marker-minus:before {
  content: "";
}

.fa-map-marker-plus:before {
  content: "";
}

.fa-map-marker-question:before {
  content: "";
}

.fa-map-marker-slash:before {
  content: "";
}

.fa-map-marker-smile:before {
  content: "";
}

.fa-map-marker-times:before, .fa-map-marker-xmark:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mars-and-venus:before {
  content: "";
}

.fa-mars-and-venus-burst:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-h:before, .fa-mars-stroke-right:before {
  content: "";
}

.fa-mars-stroke-up:before, .fa-mars-stroke-v:before {
  content: "";
}

.fa-martini-glass:before {
  content: "";
}

.fa-martini-glass-citrus:before {
  content: "";
}

.fa-martini-glass-empty:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-mask-face:before {
  content: "";
}

.fa-mask-luchador:before {
  content: "";
}

.fa-mask-snorkel:before {
  content: "";
}

.fa-mask-ventilator:before {
  content: "";
}

.fa-masks-theater:before {
  content: "";
}

.fa-mattress-pillow:before {
  content: "";
}

.fa-maximize:before {
  content: "";
}

.fa-meat:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-megaphone:before {
  content: "";
}

.fa-meh:before {
  content: "";
}

.fa-meh-blank:before {
  content: "";
}

.fa-meh-rolling-eyes:before {
  content: "";
}

.fa-melon:before {
  content: "";
}

.fa-melon-slice:before {
  content: "";
}

.fa-memo:before {
  content: "";
}

.fa-memo-circle-check:before {
  content: "";
}

.fa-memo-circle-info:before {
  content: "";
}

.fa-memo-pad:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-merge:before {
  content: "";
}

.fa-message:before {
  content: "";
}

.fa-message-arrow-down:before {
  content: "";
}

.fa-message-arrow-up:before {
  content: "";
}

.fa-message-arrow-up-right:before {
  content: "";
}

.fa-message-bot:before {
  content: "";
}

.fa-message-captions:before {
  content: "";
}

.fa-message-check:before {
  content: "";
}

.fa-message-code:before {
  content: "";
}

.fa-message-dollar:before {
  content: "";
}

.fa-message-dots:before {
  content: "";
}

.fa-message-edit:before {
  content: "";
}

.fa-message-exclamation:before {
  content: "";
}

.fa-message-heart:before {
  content: "";
}

.fa-message-image:before {
  content: "";
}

.fa-message-lines:before {
  content: "";
}

.fa-message-medical:before {
  content: "";
}

.fa-message-middle:before {
  content: "";
}

.fa-message-middle-top:before {
  content: "";
}

.fa-message-minus:before {
  content: "";
}

.fa-message-music:before {
  content: "";
}

.fa-message-pen:before {
  content: "";
}

.fa-message-plus:before {
  content: "";
}

.fa-message-question:before {
  content: "";
}

.fa-message-quote:before {
  content: "";
}

.fa-message-slash:before {
  content: "";
}

.fa-message-smile:before {
  content: "";
}

.fa-message-sms:before {
  content: "";
}

.fa-message-text:before {
  content: "";
}

.fa-message-times:before, .fa-message-xmark:before {
  content: "";
}

.fa-messages:before {
  content: "";
}

.fa-messages-dollar:before {
  content: "";
}

.fa-messages-question:before {
  content: "";
}

.fa-messaging:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-meter:before {
  content: "";
}

.fa-meter-bolt:before {
  content: "";
}

.fa-meter-droplet:before {
  content: "";
}

.fa-meter-fire:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-microchip-ai:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-alt:before {
  content: "";
}

.fa-microphone-alt-slash:before {
  content: "";
}

.fa-microphone-circle:before {
  content: "";
}

.fa-microphone-circle-alt:before {
  content: "";
}

.fa-microphone-lines:before {
  content: "";
}

.fa-microphone-lines-slash:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-microphone-stand:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-microwave:before {
  content: "";
}

.fa-mill-sign:before {
  content: "";
}

.fa-mind-share:before {
  content: "";
}

.fa-minimize:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-minus-hexagon:before {
  content: "";
}

.fa-minus-large:before {
  content: "";
}

.fa-minus-octagon:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-mistletoe:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-mobile:before {
  content: "";
}

.fa-mobile-alt:before {
  content: "";
}

.fa-mobile-android:before {
  content: "";
}

.fa-mobile-android-alt:before {
  content: "";
}

.fa-mobile-button:before {
  content: "";
}

.fa-mobile-iphone:before, .fa-mobile-notch:before {
  content: "";
}

.fa-mobile-phone:before {
  content: "";
}

.fa-mobile-retro:before {
  content: "";
}

.fa-mobile-screen:before {
  content: "";
}

.fa-mobile-screen-button:before {
  content: "";
}

.fa-mobile-signal:before {
  content: "";
}

.fa-mobile-signal-out:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-money-bill-1:before {
  content: "";
}

.fa-money-bill-1-wave:before {
  content: "";
}

.fa-money-bill-alt:before {
  content: "";
}

.fa-money-bill-simple:before {
  content: "";
}

.fa-money-bill-simple-wave:before {
  content: "";
}

.fa-money-bill-transfer:before {
  content: "";
}

.fa-money-bill-trend-up:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-money-bill-wave-alt:before {
  content: "";
}

.fa-money-bill-wheat:before {
  content: "";
}

.fa-money-bills:before {
  content: "";
}

.fa-money-bills-alt:before, .fa-money-bills-simple:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-money-check-alt:before, .fa-money-check-dollar:before {
  content: "";
}

.fa-money-check-dollar-pen:before {
  content: "";
}

.fa-money-check-edit:before {
  content: "";
}

.fa-money-check-edit-alt:before {
  content: "";
}

.fa-money-check-pen:before {
  content: "";
}

.fa-money-from-bracket:before {
  content: "";
}

.fa-money-simple-from-bracket:before {
  content: "";
}

.fa-monitor-heart-rate:before, .fa-monitor-waveform:before {
  content: "";
}

.fa-monkey:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-moon-cloud:before {
  content: "";
}

.fa-moon-over-sun:before {
  content: "";
}

.fa-moon-stars:before {
  content: "";
}

.fa-moped:before {
  content: "";
}

.fa-mortar-board:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-mosquito:before {
  content: "";
}

.fa-mosquito-net:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-mound:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-mountain-city:before {
  content: "";
}

.fa-mountain-sun:before {
  content: "";
}

.fa-mountains:before {
  content: "";
}

.fa-mouse:before {
  content: "";
}

.fa-mouse-alt:before {
  content: "";
}

.fa-mouse-field:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-mp3-player:before {
  content: "";
}

.fa-mug:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-mug-marshmallows:before {
  content: "";
}

.fa-mug-saucer:before {
  content: "";
}

.fa-mug-tea:before {
  content: "";
}

.fa-mug-tea-saucer:before {
  content: "";
}

.fa-multiply:before {
  content: "";
}

.fa-museum:before {
  content: "";
}

.fa-mushroom:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-music-alt:before {
  content: "";
}

.fa-music-alt-slash:before {
  content: "";
}

.fa-music-magnifying-glass:before {
  content: "";
}

.fa-music-note:before {
  content: "";
}

.fa-music-note-slash:before {
  content: "";
}

.fa-music-slash:before {
  content: "";
}

.fa-mustache:before {
  content: "";
}

.fa-n:before {
  content: "N";
}

.fa-naira-sign:before {
  content: "";
}

.fa-narwhal:before {
  content: "";
}

.fa-navicon:before {
  content: "";
}

.fa-nesting-dolls:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-nfc:before {
  content: "";
}

.fa-nfc-lock:before {
  content: "";
}

.fa-nfc-magnifying-glass:before {
  content: "";
}

.fa-nfc-pen:before {
  content: "";
}

.fa-nfc-signal:before {
  content: "";
}

.fa-nfc-slash:before {
  content: "";
}

.fa-nfc-trash:before {
  content: "";
}

.fa-nigiri:before {
  content: "";
}

.fa-nose:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-notdef:before {
  content: "";
}

.fa-note:before {
  content: "";
}

.fa-note-medical:before {
  content: "";
}

.fa-note-sticky:before {
  content: "";
}

.fa-notebook:before {
  content: "";
}

.fa-notes:before {
  content: "";
}

.fa-notes-medical:before {
  content: "";
}

.fa-o:before {
  content: "O";
}

.fa-object-exclude:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-intersect:before {
  content: "";
}

.fa-object-subtract:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-object-union:before {
  content: "";
}

.fa-objects-align-bottom:before {
  content: "";
}

.fa-objects-align-center-horizontal:before {
  content: "";
}

.fa-objects-align-center-vertical:before {
  content: "";
}

.fa-objects-align-left:before {
  content: "";
}

.fa-objects-align-right:before {
  content: "";
}

.fa-objects-align-top:before {
  content: "";
}

.fa-objects-column:before {
  content: "";
}

.fa-octagon:before {
  content: "";
}

.fa-octagon-check:before {
  content: "";
}

.fa-octagon-divide:before {
  content: "";
}

.fa-octagon-exclamation:before {
  content: "";
}

.fa-octagon-minus:before {
  content: "";
}

.fa-octagon-plus:before {
  content: "";
}

.fa-octagon-xmark:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-oil-can-drip:before {
  content: "";
}

.fa-oil-temp:before, .fa-oil-temperature:before {
  content: "";
}

.fa-oil-well:before {
  content: "";
}

.fa-olive:before {
  content: "";
}

.fa-olive-branch:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-omega:before {
  content: "";
}

.fa-onion:before {
  content: "";
}

.fa-option:before {
  content: "";
}

.fa-ornament:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-outdent:before {
  content: "";
}

.fa-outlet:before {
  content: "";
}

.fa-oven:before {
  content: "";
}

.fa-overline:before {
  content: "";
}

.fa-p:before {
  content: "P";
}

.fa-page:before {
  content: "";
}

.fa-page-break:before {
  content: "";
}

.fa-page-caret-down:before {
  content: "";
}

.fa-page-caret-up:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-paint-brush-alt:before, .fa-paint-brush-fine:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-paintbrush:before {
  content: "";
}

.fa-paintbrush-alt:before, .fa-paintbrush-fine:before {
  content: "";
}

.fa-paintbrush-pencil:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-palette-boxes:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-pallet-alt:before {
  content: "";
}

.fa-pallet-box:before {
  content: "";
}

.fa-pallet-boxes:before {
  content: "";
}

.fa-pan-food:before {
  content: "";
}

.fa-pan-frying:before {
  content: "";
}

.fa-pancakes:before {
  content: "";
}

.fa-panel-ews:before {
  content: "";
}

.fa-panel-fire:before {
  content: "";
}

.fa-panorama:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-paper-plane-alt:before, .fa-paper-plane-top:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-paperclip-vertical:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-paragraph-left:before, .fa-paragraph-rtl:before {
  content: "";
}

.fa-parentheses:before {
  content: "";
}

.fa-parenthesis:before {
  content: "(";
}

.fa-parking:before {
  content: "";
}

.fa-parking-circle:before {
  content: "";
}

.fa-parking-circle-slash:before {
  content: "";
}

.fa-parking-slash:before {
  content: "";
}

.fa-party-back:before {
  content: "";
}

.fa-party-bell:before {
  content: "";
}

.fa-party-horn:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-pastafarianism:before {
  content: "";
}

.fa-paste:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-paw-alt:before {
  content: "";
}

.fa-paw-claws:before {
  content: "";
}

.fa-paw-simple:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-peach:before {
  content: "";
}

.fa-peanut:before {
  content: "";
}

.fa-peanuts:before {
  content: "";
}

.fa-peapod:before {
  content: "";
}

.fa-pear:before {
  content: "";
}

.fa-pedestal:before {
  content: "";
}

.fa-pegasus:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-pen-alt:before {
  content: "";
}

.fa-pen-alt-slash:before {
  content: "";
}

.fa-pen-circle:before {
  content: "";
}

.fa-pen-clip:before {
  content: "";
}

.fa-pen-clip-slash:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-pen-fancy-slash:before {
  content: "";
}

.fa-pen-field:before {
  content: "";
}

.fa-pen-line:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-pen-nib-slash:before {
  content: "";
}

.fa-pen-paintbrush:before {
  content: "";
}

.fa-pen-ruler:before {
  content: "";
}

.fa-pen-slash:before {
  content: "";
}

.fa-pen-square:before {
  content: "";
}

.fa-pen-swirl:before {
  content: "";
}

.fa-pen-to-square:before {
  content: "";
}

.fa-pencil:before, .fa-pencil-alt:before {
  content: "";
}

.fa-pencil-mechanical:before {
  content: "";
}

.fa-pencil-paintbrush:before {
  content: "";
}

.fa-pencil-ruler:before {
  content: "";
}

.fa-pencil-slash:before {
  content: "";
}

.fa-pencil-square:before {
  content: "";
}

.fa-pennant:before {
  content: "";
}

.fa-people:before {
  content: "";
}

.fa-people-arrows:before, .fa-people-arrows-left-right:before {
  content: "";
}

.fa-people-carry:before, .fa-people-carry-box:before {
  content: "";
}

.fa-people-dress:before {
  content: "";
}

.fa-people-dress-simple:before {
  content: "";
}

.fa-people-group:before {
  content: "";
}

.fa-people-line:before {
  content: "";
}

.fa-people-pants:before {
  content: "";
}

.fa-people-pants-simple:before {
  content: "";
}

.fa-people-pulling:before {
  content: "";
}

.fa-people-robbery:before {
  content: "";
}

.fa-people-roof:before {
  content: "";
}

.fa-people-simple:before {
  content: "";
}

.fa-pepper:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-percent:before, .fa-percentage:before {
  content: "%";
}

.fa-period:before {
  content: ".";
}

.fa-person:before {
  content: "";
}

.fa-person-arrow-down-to-line:before {
  content: "";
}

.fa-person-arrow-up-from-line:before {
  content: "";
}

.fa-person-biking:before {
  content: "";
}

.fa-person-biking-mountain:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-person-breastfeeding:before {
  content: "";
}

.fa-person-burst:before {
  content: "";
}

.fa-person-cane:before {
  content: "";
}

.fa-person-carry:before, .fa-person-carry-box:before {
  content: "";
}

.fa-person-chalkboard:before {
  content: "";
}

.fa-person-circle-check:before {
  content: "";
}

.fa-person-circle-exclamation:before {
  content: "";
}

.fa-person-circle-minus:before {
  content: "";
}

.fa-person-circle-plus:before {
  content: "";
}

.fa-person-circle-question:before {
  content: "";
}

.fa-person-circle-xmark:before {
  content: "";
}

.fa-person-digging:before {
  content: "";
}

.fa-person-dolly:before {
  content: "";
}

.fa-person-dolly-empty:before {
  content: "";
}

.fa-person-dots-from-line:before {
  content: "";
}

.fa-person-dress:before {
  content: "";
}

.fa-person-dress-burst:before {
  content: "";
}

.fa-person-dress-fairy:before {
  content: "";
}

.fa-person-dress-simple:before {
  content: "";
}

.fa-person-drowning:before {
  content: "";
}

.fa-person-fairy:before {
  content: "";
}

.fa-person-falling:before {
  content: "";
}

.fa-person-falling-burst:before {
  content: "";
}

.fa-person-from-portal:before {
  content: "";
}

.fa-person-half-dress:before {
  content: "";
}

.fa-person-harassing:before {
  content: "";
}

.fa-person-hiking:before {
  content: "";
}

.fa-person-military-pointing:before {
  content: "";
}

.fa-person-military-rifle:before {
  content: "";
}

.fa-person-military-to-person:before {
  content: "";
}

.fa-person-pinball:before {
  content: "";
}

.fa-person-praying:before {
  content: "";
}

.fa-person-pregnant:before {
  content: "";
}

.fa-person-rays:before {
  content: "";
}

.fa-person-rifle:before {
  content: "";
}

.fa-person-running:before {
  content: "";
}

.fa-person-running-fast:before {
  content: "";
}

.fa-person-seat:before {
  content: "";
}

.fa-person-seat-reclined:before {
  content: "";
}

.fa-person-shelter:before {
  content: "";
}

.fa-person-sign:before {
  content: "";
}

.fa-person-simple:before {
  content: "";
}

.fa-person-skating:before {
  content: "";
}

.fa-person-ski-jumping:before {
  content: "";
}

.fa-person-ski-lift:before {
  content: "";
}

.fa-person-skiing:before {
  content: "";
}

.fa-person-skiing-nordic:before {
  content: "";
}

.fa-person-sledding:before {
  content: "";
}

.fa-person-snowboarding:before {
  content: "";
}

.fa-person-snowmobiling:before {
  content: "";
}

.fa-person-swimming:before {
  content: "";
}

.fa-person-through-window:before {
  content: "";
}

.fa-person-to-door:before {
  content: "";
}

.fa-person-to-portal:before {
  content: "";
}

.fa-person-walking:before {
  content: "";
}

.fa-person-walking-arrow-loop-left:before {
  content: "";
}

.fa-person-walking-arrow-right:before {
  content: "";
}

.fa-person-walking-dashed-line-arrow-right:before {
  content: "";
}

.fa-person-walking-luggage:before {
  content: "";
}

.fa-person-walking-with-cane:before {
  content: "";
}

.fa-peseta-sign:before {
  content: "";
}

.fa-peso-sign:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-phone-alt:before {
  content: "";
}

.fa-phone-arrow-down:before, .fa-phone-arrow-down-left:before {
  content: "";
}

.fa-phone-arrow-right:before {
  content: "";
}

.fa-phone-arrow-up:before, .fa-phone-arrow-up-right:before {
  content: "";
}

.fa-phone-circle:before {
  content: "";
}

.fa-phone-circle-alt:before {
  content: "";
}

.fa-phone-circle-down:before {
  content: "";
}

.fa-phone-flip:before {
  content: "";
}

.fa-phone-hangup:before {
  content: "";
}

.fa-phone-incoming:before {
  content: "";
}

.fa-phone-intercom:before {
  content: "";
}

.fa-phone-laptop:before {
  content: "";
}

.fa-phone-missed:before {
  content: "";
}

.fa-phone-office:before {
  content: "";
}

.fa-phone-outgoing:before {
  content: "";
}

.fa-phone-plus:before {
  content: "";
}

.fa-phone-rotary:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-phone-square-alt:before {
  content: "";
}

.fa-phone-square-down:before {
  content: "";
}

.fa-phone-volume:before {
  content: "";
}

.fa-phone-xmark:before {
  content: "";
}

.fa-photo-film:before {
  content: "";
}

.fa-photo-film-music:before {
  content: "";
}

.fa-photo-video:before {
  content: "";
}

.fa-pi:before {
  content: "";
}

.fa-piano:before {
  content: "";
}

.fa-piano-keyboard:before {
  content: "";
}

.fa-pickaxe:before {
  content: "";
}

.fa-pickleball:before {
  content: "";
}

.fa-pie:before {
  content: "";
}

.fa-pie-chart:before {
  content: "";
}

.fa-pig:before {
  content: "";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-pinata:before {
  content: "";
}

.fa-pinball:before {
  content: "";
}

.fa-pineapple:before {
  content: "";
}

.fa-ping-pong-paddle-ball:before {
  content: "";
}

.fa-pipe:before {
  content: "|";
}

.fa-pipe-circle-check:before {
  content: "";
}

.fa-pipe-collar:before {
  content: "";
}

.fa-pipe-section:before {
  content: "";
}

.fa-pipe-smoking:before {
  content: "";
}

.fa-pipe-valve:before {
  content: "";
}

.fa-pizza:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-plane-alt:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-plane-circle-check:before {
  content: "";
}

.fa-plane-circle-exclamation:before {
  content: "";
}

.fa-plane-circle-xmark:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-plane-engines:before {
  content: "";
}

.fa-plane-lock:before {
  content: "";
}

.fa-plane-prop:before {
  content: "";
}

.fa-plane-slash:before {
  content: "";
}

.fa-plane-tail:before {
  content: "";
}

.fa-plane-up:before {
  content: "";
}

.fa-plane-up-slash:before {
  content: "";
}

.fa-planet-moon:before {
  content: "";
}

.fa-planet-ringed:before {
  content: "";
}

.fa-plant-wilt:before {
  content: "";
}

.fa-plate-utensils:before {
  content: "";
}

.fa-plate-wheat:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-play-pause:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-plug-circle-bolt:before {
  content: "";
}

.fa-plug-circle-check:before {
  content: "";
}

.fa-plug-circle-exclamation:before {
  content: "";
}

.fa-plug-circle-minus:before {
  content: "";
}

.fa-plug-circle-plus:before {
  content: "";
}

.fa-plug-circle-xmark:before {
  content: "";
}

.fa-plus:before {
  content: "+";
}

.fa-plus-circle:before {
  content: "";
}

.fa-plus-hexagon:before {
  content: "";
}

.fa-plus-large:before {
  content: "";
}

.fa-plus-minus:before {
  content: "";
}

.fa-plus-octagon:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-podium:before {
  content: "";
}

.fa-podium-star:before {
  content: "";
}

.fa-police-box:before {
  content: "";
}

.fa-poll:before {
  content: "";
}

.fa-poll-h:before {
  content: "";
}

.fa-poll-people:before {
  content: "";
}

.fa-pompebled:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-poo-bolt:before, .fa-poo-storm:before {
  content: "";
}

.fa-pool-8-ball:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-popcorn:before {
  content: "";
}

.fa-popsicle:before {
  content: "";
}

.fa-portal-enter:before {
  content: "";
}

.fa-portal-exit:before {
  content: "";
}

.fa-portrait:before {
  content: "";
}

.fa-pot-food:before {
  content: "";
}

.fa-potato:before {
  content: "";
}

.fa-pound-sign:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-pray:before {
  content: "";
}

.fa-praying-hands:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-prescription-bottle-alt:before, .fa-prescription-bottle-medical:before {
  content: "";
}

.fa-prescription-bottle-pill:before {
  content: "";
}

.fa-presentation:before, .fa-presentation-screen:before {
  content: "";
}

.fa-pretzel:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-print-magnifying-glass:before, .fa-print-search:before {
  content: "";
}

.fa-print-slash:before {
  content: "";
}

.fa-pro:before {
  content: "";
}

.fa-procedures:before {
  content: "";
}

.fa-project-diagram:before {
  content: "";
}

.fa-projector:before {
  content: "";
}

.fa-pump:before {
  content: "";
}

.fa-pump-medical:before {
  content: "";
}

.fa-pump-soap:before {
  content: "";
}

.fa-pumpkin:before {
  content: "";
}

.fa-puzzle:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-puzzle-piece-alt:before, .fa-puzzle-piece-simple:before {
  content: "";
}

.fa-q:before {
  content: "Q";
}

.fa-qrcode:before {
  content: "";
}

.fa-question:before {
  content: "?";
}

.fa-question-circle:before {
  content: "";
}

.fa-question-square:before {
  content: "";
}

.fa-quidditch:before, .fa-quidditch-broom-ball:before {
  content: "";
}

.fa-quote-left:before, .fa-quote-left-alt:before {
  content: "";
}

.fa-quote-right:before, .fa-quote-right-alt:before {
  content: "";
}

.fa-quotes:before {
  content: "";
}

.fa-quran:before {
  content: "";
}

.fa-r:before {
  content: "R";
}

.fa-rabbit:before {
  content: "";
}

.fa-rabbit-fast:before, .fa-rabbit-running:before {
  content: "";
}

.fa-raccoon:before {
  content: "";
}

.fa-racquet:before {
  content: "";
}

.fa-radar:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-radiation-alt:before {
  content: "";
}

.fa-radio:before {
  content: "";
}

.fa-radio-alt:before, .fa-radio-tuner:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-raindrops:before {
  content: "";
}

.fa-ram:before {
  content: "";
}

.fa-ramp-loading:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-ranking-star:before {
  content: "";
}

.fa-raygun:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-rectangle:before {
  content: "";
}

.fa-rectangle-ad:before {
  content: "";
}

.fa-rectangle-barcode:before {
  content: "";
}

.fa-rectangle-code:before {
  content: "";
}

.fa-rectangle-hd:before {
  content: "";
}

.fa-rectangle-history:before {
  content: "";
}

.fa-rectangle-history-circle-plus:before {
  content: "";
}

.fa-rectangle-history-circle-user:before {
  content: "";
}

.fa-rectangle-landscape:before {
  content: "";
}

.fa-rectangle-list:before {
  content: "";
}

.fa-rectangle-portrait:before {
  content: "";
}

.fa-rectangle-pro:before {
  content: "";
}

.fa-rectangle-sd:before {
  content: "";
}

.fa-rectangle-terminal:before {
  content: "";
}

.fa-rectangle-times:before {
  content: "";
}

.fa-rectangle-vertical:before {
  content: "";
}

.fa-rectangle-vertical-history:before {
  content: "";
}

.fa-rectangle-wide:before {
  content: "";
}

.fa-rectangle-xmark:before {
  content: "";
}

.fa-rectangles-mixed:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-redo:before {
  content: "";
}

.fa-redo-alt:before {
  content: "";
}

.fa-reel:before {
  content: "";
}

.fa-reflect-both:before {
  content: "";
}

.fa-reflect-horizontal:before {
  content: "";
}

.fa-reflect-vertical:before {
  content: "";
}

.fa-refresh:before {
  content: "";
}

.fa-refrigerator:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-remove:before {
  content: "";
}

.fa-remove-format:before {
  content: "";
}

.fa-reorder:before {
  content: "";
}

.fa-repeat:before {
  content: "";
}

.fa-repeat-1:before {
  content: "";
}

.fa-repeat-1-alt:before {
  content: "";
}

.fa-repeat-alt:before {
  content: "";
}

.fa-reply:before {
  content: "";
}

.fa-reply-all:before {
  content: "";
}

.fa-reply-clock:before, .fa-reply-time:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-restroom-simple:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-retweet-alt:before {
  content: "";
}

.fa-rhombus:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-right:before {
  content: "";
}

.fa-right-from-bracket:before {
  content: "";
}

.fa-right-from-line:before {
  content: "";
}

.fa-right-left:before {
  content: "";
}

.fa-right-left-large:before {
  content: "";
}

.fa-right-long:before {
  content: "";
}

.fa-right-long-to-line:before {
  content: "";
}

.fa-right-to-bracket:before {
  content: "";
}

.fa-right-to-line:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-ring-diamond:before {
  content: "";
}

.fa-rings-wedding:before {
  content: "";
}

.fa-rmb:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-road-barrier:before {
  content: "";
}

.fa-road-bridge:before {
  content: "";
}

.fa-road-circle-check:before {
  content: "";
}

.fa-road-circle-exclamation:before {
  content: "";
}

.fa-road-circle-xmark:before {
  content: "";
}

.fa-road-lock:before {
  content: "";
}

.fa-road-spikes:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-robot-astromech:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-rocket-launch:before {
  content: "";
}

.fa-rod-asclepius:before, .fa-rod-snake:before {
  content: "";
}

.fa-roller-coaster:before {
  content: "";
}

.fa-rotate:before {
  content: "";
}

.fa-rotate-back:before, .fa-rotate-backward:before {
  content: "";
}

.fa-rotate-exclamation:before {
  content: "";
}

.fa-rotate-forward:before {
  content: "";
}

.fa-rotate-left:before {
  content: "";
}

.fa-rotate-reverse:before {
  content: "";
}

.fa-rotate-right:before {
  content: "";
}

.fa-rouble:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-route-highway:before {
  content: "";
}

.fa-route-interstate:before {
  content: "";
}

.fa-router:before {
  content: "";
}

.fa-rows:before {
  content: "";
}

.fa-rss:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-rub:before, .fa-ruble:before, .fa-ruble-sign:before {
  content: "";
}

.fa-rug:before {
  content: "";
}

.fa-rugby-ball:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-ruler-triangle:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-running:before {
  content: "";
}

.fa-rupee:before, .fa-rupee-sign:before {
  content: "";
}

.fa-rupiah-sign:before {
  content: "";
}

.fa-rv:before {
  content: "";
}

.fa-s:before {
  content: "S";
}

.fa-sack:before {
  content: "";
}

.fa-sack-dollar:before {
  content: "";
}

.fa-sack-xmark:before {
  content: "";
}

.fa-sad-cry:before {
  content: "";
}

.fa-sad-tear:before {
  content: "";
}

.fa-sailboat:before {
  content: "";
}

.fa-salad:before {
  content: "";
}

.fa-salt-shaker:before {
  content: "";
}

.fa-sandwich:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-sausage:before {
  content: "";
}

.fa-save:before {
  content: "";
}

.fa-save-circle-arrow-right:before {
  content: "";
}

.fa-save-circle-xmark:before, .fa-save-times:before {
  content: "";
}

.fa-sax-hot:before {
  content: "";
}

.fa-saxophone:before {
  content: "";
}

.fa-saxophone-fire:before {
  content: "";
}

.fa-scale-balanced:before {
  content: "";
}

.fa-scale-unbalanced:before {
  content: "";
}

.fa-scale-unbalanced-flip:before {
  content: "";
}

.fa-scalpel:before {
  content: "";
}

.fa-scalpel-line-dashed:before, .fa-scalpel-path:before {
  content: "";
}

.fa-scanner:before, .fa-scanner-gun:before {
  content: "";
}

.fa-scanner-image:before {
  content: "";
}

.fa-scanner-keyboard:before {
  content: "";
}

.fa-scanner-touchscreen:before {
  content: "";
}

.fa-scarecrow:before {
  content: "";
}

.fa-scarf:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-school-circle-check:before {
  content: "";
}

.fa-school-circle-exclamation:before {
  content: "";
}

.fa-school-circle-xmark:before {
  content: "";
}

.fa-school-flag:before {
  content: "";
}

.fa-school-lock:before {
  content: "";
}

.fa-scissors:before {
  content: "";
}

.fa-screen-users:before {
  content: "";
}

.fa-screencast:before {
  content: "";
}

.fa-screenshot:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-screwdriver-wrench:before {
  content: "";
}

.fa-scribble:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-scroll-old:before {
  content: "";
}

.fa-scroll-ribbon:before {
  content: "";
}

.fa-scroll-torah:before {
  content: "";
}

.fa-scrubber:before {
  content: "";
}

.fa-scythe:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-sd-cards:before {
  content: "";
}

.fa-seal:before {
  content: "";
}

.fa-seal-exclamation:before {
  content: "";
}

.fa-seal-question:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-search-dollar:before {
  content: "";
}

.fa-search-location:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-seat-airline:before {
  content: "";
}

.fa-section:before {
  content: "";
}

.fa-seedling:before {
  content: "";
}

.fa-semicolon:before {
  content: ";";
}

.fa-send:before {
  content: "";
}

.fa-send-back:before {
  content: "";
}

.fa-send-backward:before {
  content: "";
}

.fa-sensor:before {
  content: "";
}

.fa-sensor-alert:before {
  content: "";
}

.fa-sensor-cloud:before {
  content: "";
}

.fa-sensor-fire:before {
  content: "";
}

.fa-sensor-on:before {
  content: "";
}

.fa-sensor-smoke:before {
  content: "";
}

.fa-sensor-triangle-exclamation:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-shapes:before {
  content: "";
}

.fa-share:before {
  content: "";
}

.fa-share-all:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-share-from-square:before {
  content: "";
}

.fa-share-nodes:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-sheep:before {
  content: "";
}

.fa-sheet-plastic:before {
  content: "";
}

.fa-shekel:before, .fa-shekel-sign:before {
  content: "";
}

.fa-shelves:before {
  content: "";
}

.fa-shelves-empty:before {
  content: "";
}

.fa-sheqel:before, .fa-sheqel-sign:before {
  content: "";
}

.fa-shield:before {
  content: "";
}

.fa-shield-alt:before {
  content: "";
}

.fa-shield-blank:before {
  content: "";
}

.fa-shield-cat:before {
  content: "";
}

.fa-shield-check:before {
  content: "";
}

.fa-shield-cross:before {
  content: "";
}

.fa-shield-dog:before {
  content: "";
}

.fa-shield-exclamation:before {
  content: "";
}

.fa-shield-halved:before {
  content: "";
}

.fa-shield-heart:before {
  content: "";
}

.fa-shield-keyhole:before {
  content: "";
}

.fa-shield-minus:before {
  content: "";
}

.fa-shield-plus:before {
  content: "";
}

.fa-shield-quartered:before {
  content: "";
}

.fa-shield-slash:before {
  content: "";
}

.fa-shield-times:before {
  content: "";
}

.fa-shield-virus:before {
  content: "";
}

.fa-shield-xmark:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-shipping-fast:before {
  content: "";
}

.fa-shipping-timed:before {
  content: "";
}

.fa-shirt:before {
  content: "";
}

.fa-shirt-long-sleeve:before {
  content: "";
}

.fa-shirt-running:before {
  content: "";
}

.fa-shirt-tank-top:before {
  content: "";
}

.fa-shish-kebab:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-shop:before {
  content: "";
}

.fa-shop-lock:before {
  content: "";
}

.fa-shop-slash:before {
  content: "";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-shopping-basket-alt:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-shortcake:before {
  content: "";
}

.fa-shovel:before {
  content: "";
}

.fa-shovel-snow:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-shower-alt:before, .fa-shower-down:before {
  content: "";
}

.fa-shredder:before {
  content: "";
}

.fa-shrimp:before {
  content: "";
}

.fa-shuffle:before {
  content: "";
}

.fa-shutters:before {
  content: "";
}

.fa-shuttle-space:before {
  content: "";
}

.fa-shuttle-van:before {
  content: "";
}

.fa-shuttlecock:before {
  content: "";
}

.fa-sickle:before {
  content: "";
}

.fa-sidebar:before {
  content: "";
}

.fa-sidebar-flip:before {
  content: "";
}

.fa-sigma:before {
  content: "";
}

.fa-sign:before, .fa-sign-hanging:before {
  content: "";
}

.fa-sign-in:before {
  content: "";
}

.fa-sign-in-alt:before {
  content: "";
}

.fa-sign-language:before {
  content: "";
}

.fa-sign-out:before {
  content: "";
}

.fa-sign-out-alt:before {
  content: "";
}

.fa-sign-post:before {
  content: "";
}

.fa-sign-posts:before {
  content: "";
}

.fa-sign-posts-wrench:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-signal-1:before {
  content: "";
}

.fa-signal-2:before {
  content: "";
}

.fa-signal-3:before {
  content: "";
}

.fa-signal-4:before {
  content: "";
}

.fa-signal-5:before {
  content: "";
}

.fa-signal-alt:before {
  content: "";
}

.fa-signal-alt-1:before {
  content: "";
}

.fa-signal-alt-2:before {
  content: "";
}

.fa-signal-alt-3:before {
  content: "";
}

.fa-signal-alt-4:before {
  content: "";
}

.fa-signal-alt-slash:before {
  content: "";
}

.fa-signal-bars:before {
  content: "";
}

.fa-signal-bars-fair:before {
  content: "";
}

.fa-signal-bars-good:before {
  content: "";
}

.fa-signal-bars-slash:before {
  content: "";
}

.fa-signal-bars-strong:before {
  content: "";
}

.fa-signal-bars-weak:before {
  content: "";
}

.fa-signal-fair:before {
  content: "";
}

.fa-signal-good:before {
  content: "";
}

.fa-signal-perfect:before {
  content: "";
}

.fa-signal-slash:before {
  content: "";
}

.fa-signal-stream:before {
  content: "";
}

.fa-signal-stream-slash:before {
  content: "";
}

.fa-signal-strong:before {
  content: "";
}

.fa-signal-weak:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-signature-lock:before {
  content: "";
}

.fa-signature-slash:before {
  content: "";
}

.fa-signing:before {
  content: "";
}

.fa-signs-post:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-sim-cards:before {
  content: "";
}

.fa-sink:before {
  content: "";
}

.fa-siren:before {
  content: "";
}

.fa-siren-on:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-skating:before {
  content: "";
}

.fa-skeleton:before {
  content: "";
}

.fa-skeleton-ribs:before {
  content: "";
}

.fa-ski-boot:before {
  content: "";
}

.fa-ski-boot-ski:before {
  content: "";
}

.fa-ski-jump:before {
  content: "";
}

.fa-ski-lift:before {
  content: "";
}

.fa-skiing:before {
  content: "";
}

.fa-skiing-nordic:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-skull-cow:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-slash-back:before {
  content: "\\";
}

.fa-slash-forward:before {
  content: "/";
}

.fa-sledding:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-slider:before {
  content: "";
}

.fa-sliders:before, .fa-sliders-h:before {
  content: "";
}

.fa-sliders-h-square:before {
  content: "";
}

.fa-sliders-simple:before {
  content: "";
}

.fa-sliders-up:before, .fa-sliders-v:before {
  content: "";
}

.fa-sliders-v-square:before {
  content: "";
}

.fa-slot-machine:before {
  content: "";
}

.fa-smile:before {
  content: "";
}

.fa-smile-beam:before {
  content: "";
}

.fa-smile-plus:before {
  content: "";
}

.fa-smile-wink:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-smoke:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-smoking-ban:before {
  content: "";
}

.fa-sms:before {
  content: "";
}

.fa-snake:before {
  content: "";
}

.fa-snooze:before {
  content: "";
}

.fa-snow-blowing:before {
  content: "";
}

.fa-snowboarding:before {
  content: "";
}

.fa-snowflake:before {
  content: "";
}

.fa-snowflake-droplets:before {
  content: "";
}

.fa-snowflakes:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-snowman-head:before {
  content: "";
}

.fa-snowmobile:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-soap:before {
  content: "";
}

.fa-soccer-ball:before {
  content: "";
}

.fa-socks:before {
  content: "";
}

.fa-soft-serve:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-solar-system:before {
  content: "";
}

.fa-sort:before {
  content: "";
}

.fa-sort-alpha-asc:before {
  content: "";
}

.fa-sort-alpha-desc:before {
  content: "";
}

.fa-sort-alpha-down:before {
  content: "";
}

.fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-sort-alpha-up:before {
  content: "";
}

.fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-sort-alt:before {
  content: "";
}

.fa-sort-amount-asc:before {
  content: "";
}

.fa-sort-amount-desc:before {
  content: "";
}

.fa-sort-amount-down:before {
  content: "";
}

.fa-sort-amount-down-alt:before {
  content: "";
}

.fa-sort-amount-up:before {
  content: "";
}

.fa-sort-amount-up-alt:before {
  content: "";
}

.fa-sort-asc:before {
  content: "";
}

.fa-sort-circle:before {
  content: "";
}

.fa-sort-circle-down:before {
  content: "";
}

.fa-sort-circle-up:before {
  content: "";
}

.fa-sort-desc:before, .fa-sort-down:before {
  content: "";
}

.fa-sort-numeric-asc:before {
  content: "";
}

.fa-sort-numeric-desc:before {
  content: "";
}

.fa-sort-numeric-down:before {
  content: "";
}

.fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-sort-numeric-up:before {
  content: "";
}

.fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-sort-shapes-down:before {
  content: "";
}

.fa-sort-shapes-down-alt:before {
  content: "";
}

.fa-sort-shapes-up:before {
  content: "";
}

.fa-sort-shapes-up-alt:before {
  content: "";
}

.fa-sort-size-down:before {
  content: "";
}

.fa-sort-size-down-alt:before {
  content: "";
}

.fa-sort-size-up:before {
  content: "";
}

.fa-sort-size-up-alt:before {
  content: "";
}

.fa-sort-up:before {
  content: "";
}

.fa-sort-up-down:before {
  content: "";
}

.fa-soup:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-space-station-moon:before {
  content: "";
}

.fa-space-station-moon-alt:before, .fa-space-station-moon-construction:before {
  content: "";
}

.fa-spade:before {
  content: "";
}

.fa-spaghetti-monster-flying:before {
  content: "";
}

.fa-sparkle:before {
  content: "";
}

.fa-sparkles:before {
  content: "";
}

.fa-speaker:before {
  content: "";
}

.fa-speakers:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-spider:before {
  content: "";
}

.fa-spider-black-widow:before {
  content: "";
}

.fa-spider-web:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-spinner-scale:before {
  content: "";
}

.fa-spinner-third:before {
  content: "";
}

.fa-split:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-spoon:before {
  content: "";
}

.fa-sportsball:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-spray-can-sparkles:before {
  content: "";
}

.fa-sprinkler:before {
  content: "";
}

.fa-sprinkler-ceiling:before {
  content: "";
}

.fa-sprout:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-square-0:before {
  content: "";
}

.fa-square-1:before {
  content: "";
}

.fa-square-2:before {
  content: "";
}

.fa-square-3:before {
  content: "";
}

.fa-square-4:before {
  content: "";
}

.fa-square-5:before {
  content: "";
}

.fa-square-6:before {
  content: "";
}

.fa-square-7:before {
  content: "";
}

.fa-square-8:before {
  content: "";
}

.fa-square-9:before {
  content: "";
}

.fa-square-a:before {
  content: "";
}

.fa-square-a-lock:before {
  content: "";
}

.fa-square-ampersand:before {
  content: "";
}

.fa-square-arrow-down:before {
  content: "";
}

.fa-square-arrow-down-left:before {
  content: "";
}

.fa-square-arrow-down-right:before {
  content: "";
}

.fa-square-arrow-left:before {
  content: "";
}

.fa-square-arrow-right:before {
  content: "";
}

.fa-square-arrow-up:before {
  content: "";
}

.fa-square-arrow-up-left:before {
  content: "";
}

.fa-square-arrow-up-right:before {
  content: "";
}

.fa-square-b:before {
  content: "";
}

.fa-square-bolt:before {
  content: "";
}

.fa-square-c:before {
  content: "";
}

.fa-square-caret-down:before {
  content: "";
}

.fa-square-caret-left:before {
  content: "";
}

.fa-square-caret-right:before {
  content: "";
}

.fa-square-caret-up:before {
  content: "";
}

.fa-square-check:before {
  content: "";
}

.fa-square-chevron-down:before {
  content: "";
}

.fa-square-chevron-left:before {
  content: "";
}

.fa-square-chevron-right:before {
  content: "";
}

.fa-square-chevron-up:before {
  content: "";
}

.fa-square-code:before {
  content: "";
}

.fa-square-d:before {
  content: "";
}

.fa-square-dashed:before {
  content: "";
}

.fa-square-dashed-circle-plus:before {
  content: "";
}

.fa-square-divide:before {
  content: "";
}

.fa-square-dollar:before {
  content: "";
}

.fa-square-down:before {
  content: "";
}

.fa-square-down-left:before {
  content: "";
}

.fa-square-down-right:before {
  content: "";
}

.fa-square-e:before {
  content: "";
}

.fa-square-ellipsis:before {
  content: "";
}

.fa-square-ellipsis-vertical:before {
  content: "";
}

.fa-square-envelope:before {
  content: "";
}

.fa-square-exclamation:before {
  content: "";
}

.fa-square-f:before {
  content: "";
}

.fa-square-fragile:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-square-g:before {
  content: "";
}

.fa-square-h:before {
  content: "";
}

.fa-square-heart:before {
  content: "";
}

.fa-square-i:before {
  content: "";
}

.fa-square-info:before {
  content: "";
}

.fa-square-j:before {
  content: "";
}

.fa-square-k:before {
  content: "";
}

.fa-square-kanban:before {
  content: "";
}

.fa-square-l:before {
  content: "";
}

.fa-square-left:before {
  content: "";
}

.fa-square-list:before {
  content: "";
}

.fa-square-m:before {
  content: "";
}

.fa-square-minus:before {
  content: "";
}

.fa-square-n:before {
  content: "";
}

.fa-square-nfi:before {
  content: "";
}

.fa-square-o:before {
  content: "";
}

.fa-square-p:before {
  content: "";
}

.fa-square-parking:before {
  content: "";
}

.fa-square-parking-slash:before {
  content: "";
}

.fa-square-pen:before {
  content: "";
}

.fa-square-person-confined:before {
  content: "";
}

.fa-square-phone:before {
  content: "";
}

.fa-square-phone-flip:before {
  content: "";
}

.fa-square-phone-hangup:before {
  content: "";
}

.fa-square-plus:before {
  content: "";
}

.fa-square-poll-horizontal:before {
  content: "";
}

.fa-square-poll-vertical:before {
  content: "";
}

.fa-square-q:before {
  content: "";
}

.fa-square-quarters:before {
  content: "";
}

.fa-square-question:before {
  content: "";
}

.fa-square-quote:before {
  content: "";
}

.fa-square-r:before {
  content: "";
}

.fa-square-right:before {
  content: "";
}

.fa-square-ring:before {
  content: "";
}

.fa-square-root:before {
  content: "";
}

.fa-square-root-alt:before, .fa-square-root-variable:before {
  content: "";
}

.fa-square-rss:before {
  content: "";
}

.fa-square-s:before {
  content: "";
}

.fa-square-share-nodes:before {
  content: "";
}

.fa-square-sliders:before {
  content: "";
}

.fa-square-sliders-vertical:before {
  content: "";
}

.fa-square-small:before {
  content: "";
}

.fa-square-star:before {
  content: "";
}

.fa-square-t:before {
  content: "";
}

.fa-square-terminal:before {
  content: "";
}

.fa-square-this-way-up:before {
  content: "";
}

.fa-square-u:before {
  content: "";
}

.fa-square-up:before {
  content: "";
}

.fa-square-up-left:before {
  content: "";
}

.fa-square-up-right:before {
  content: "";
}

.fa-square-user:before {
  content: "";
}

.fa-square-v:before {
  content: "";
}

.fa-square-virus:before {
  content: "";
}

.fa-square-w:before {
  content: "";
}

.fa-square-wine-glass-crack:before {
  content: "";
}

.fa-square-x:before {
  content: "";
}

.fa-square-xmark:before {
  content: "";
}

.fa-square-y:before {
  content: "";
}

.fa-square-z:before {
  content: "";
}

.fa-squid:before {
  content: "";
}

.fa-squirrel:before {
  content: "";
}

.fa-staff:before {
  content: "";
}

.fa-staff-aesculapius:before, .fa-staff-snake:before {
  content: "";
}

.fa-stairs:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-standard-definition:before {
  content: "";
}

.fa-stapler:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-star-christmas:before {
  content: "";
}

.fa-star-circle:before {
  content: "";
}

.fa-star-exclamation:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-star-half-alt:before, .fa-star-half-stroke:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-star-sharp:before {
  content: "";
}

.fa-star-sharp-half:before {
  content: "";
}

.fa-star-sharp-half-alt:before, .fa-star-sharp-half-stroke:before {
  content: "";
}

.fa-star-shooting:before {
  content: "";
}

.fa-starfighter:before {
  content: "";
}

.fa-starfighter-alt:before {
  content: "";
}

.fa-starfighter-alt-advanced:before {
  content: "";
}

.fa-starfighter-twin-ion-engine:before {
  content: "";
}

.fa-starfighter-twin-ion-engine-advanced:before {
  content: "";
}

.fa-stars:before {
  content: "";
}

.fa-starship:before {
  content: "";
}

.fa-starship-freighter:before {
  content: "";
}

.fa-steak:before {
  content: "";
}

.fa-steering-wheel:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-sterling-sign:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-stocking:before {
  content: "";
}

.fa-stomach:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-stopwatch-20:before {
  content: "";
}

.fa-store:before {
  content: "";
}

.fa-store-alt:before {
  content: "";
}

.fa-store-alt-slash:before {
  content: "";
}

.fa-store-lock:before {
  content: "";
}

.fa-store-slash:before {
  content: "";
}

.fa-strawberry:before {
  content: "";
}

.fa-stream:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-stretcher:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-subtitles:before {
  content: "";
}

.fa-subtitles-slash:before {
  content: "";
}

.fa-subtract:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-subway-tunnel:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-suitcase-medical:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-sun-alt:before, .fa-sun-bright:before {
  content: "";
}

.fa-sun-cloud:before {
  content: "";
}

.fa-sun-dust:before {
  content: "";
}

.fa-sun-haze:before {
  content: "";
}

.fa-sun-plant-wilt:before {
  content: "";
}

.fa-sunglasses:before {
  content: "";
}

.fa-sunrise:before {
  content: "";
}

.fa-sunset:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-surprise:before {
  content: "";
}

.fa-sushi:before {
  content: "";
}

.fa-sushi-roll:before {
  content: "";
}

.fa-swap:before {
  content: "";
}

.fa-swap-arrows:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-swimmer:before {
  content: "";
}

.fa-swimming-pool:before {
  content: "";
}

.fa-sword:before {
  content: "";
}

.fa-sword-laser:before {
  content: "";
}

.fa-sword-laser-alt:before {
  content: "";
}

.fa-swords:before {
  content: "";
}

.fa-swords-laser:before {
  content: "";
}

.fa-symbols:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-sync:before {
  content: "";
}

.fa-sync-alt:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-t:before {
  content: "T";
}

.fa-t-rex:before {
  content: "";
}

.fa-t-shirt:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-table-cells:before {
  content: "";
}

.fa-table-cells-column-lock:before {
  content: "";
}

.fa-table-cells-large:before {
  content: "";
}

.fa-table-cells-lock:before {
  content: "";
}

.fa-table-cells-row-lock:before {
  content: "";
}

.fa-table-columns:before {
  content: "";
}

.fa-table-layout:before {
  content: "";
}

.fa-table-list:before {
  content: "";
}

.fa-table-picnic:before {
  content: "";
}

.fa-table-pivot:before {
  content: "";
}

.fa-table-rows:before {
  content: "";
}

.fa-table-tennis:before, .fa-table-tennis-paddle-ball:before {
  content: "";
}

.fa-table-tree:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-tablet-alt:before {
  content: "";
}

.fa-tablet-android:before {
  content: "";
}

.fa-tablet-android-alt:before {
  content: "";
}

.fa-tablet-button:before {
  content: "";
}

.fa-tablet-rugged:before {
  content: "";
}

.fa-tablet-screen:before {
  content: "";
}

.fa-tablet-screen-button:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-tachograph-digital:before {
  content: "";
}

.fa-tachometer:before {
  content: "";
}

.fa-tachometer-alt:before {
  content: "";
}

.fa-tachometer-alt-average:before {
  content: "";
}

.fa-tachometer-alt-fast:before {
  content: "";
}

.fa-tachometer-alt-fastest:before {
  content: "";
}

.fa-tachometer-alt-slow:before {
  content: "";
}

.fa-tachometer-alt-slowest:before {
  content: "";
}

.fa-tachometer-average:before {
  content: "";
}

.fa-tachometer-fast:before {
  content: "";
}

.fa-tachometer-fastest:before {
  content: "";
}

.fa-tachometer-slow:before {
  content: "";
}

.fa-tachometer-slowest:before {
  content: "";
}

.fa-taco:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-tally:before {
  content: "";
}

.fa-tally-1:before {
  content: "";
}

.fa-tally-2:before {
  content: "";
}

.fa-tally-3:before {
  content: "";
}

.fa-tally-4:before {
  content: "";
}

.fa-tally-5:before {
  content: "";
}

.fa-tamale:before {
  content: "";
}

.fa-tanakh:before {
  content: "";
}

.fa-tank-water:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-tarp:before {
  content: "";
}

.fa-tarp-droplet:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-tasks-alt:before {
  content: "";
}

.fa-taxi:before {
  content: "";
}

.fa-taxi-bus:before {
  content: "";
}

.fa-teddy-bear:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-telescope:before {
  content: "";
}

.fa-teletype:before {
  content: "";
}

.fa-teletype-answer:before {
  content: "";
}

.fa-television:before {
  content: "";
}

.fa-temperature-0:before {
  content: "";
}

.fa-temperature-1:before {
  content: "";
}

.fa-temperature-2:before {
  content: "";
}

.fa-temperature-3:before {
  content: "";
}

.fa-temperature-4:before {
  content: "";
}

.fa-temperature-arrow-down:before {
  content: "";
}

.fa-temperature-arrow-up:before {
  content: "";
}

.fa-temperature-down:before {
  content: "";
}

.fa-temperature-empty:before {
  content: "";
}

.fa-temperature-frigid:before {
  content: "";
}

.fa-temperature-full:before {
  content: "";
}

.fa-temperature-half:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-temperature-hot:before {
  content: "";
}

.fa-temperature-list:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-temperature-quarter:before {
  content: "";
}

.fa-temperature-snow:before {
  content: "";
}

.fa-temperature-sun:before {
  content: "";
}

.fa-temperature-three-quarters:before {
  content: "";
}

.fa-temperature-up:before {
  content: "";
}

.fa-tenge:before, .fa-tenge-sign:before {
  content: "";
}

.fa-tennis-ball:before {
  content: "";
}

.fa-tent:before {
  content: "";
}

.fa-tent-arrow-down-to-line:before {
  content: "";
}

.fa-tent-arrow-left-right:before {
  content: "";
}

.fa-tent-arrow-turn-left:before {
  content: "";
}

.fa-tent-arrows-down:before {
  content: "";
}

.fa-tent-double-peak:before {
  content: "";
}

.fa-tents:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-text:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-size:before {
  content: "";
}

.fa-text-slash:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-theater-masks:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-thermometer-0:before {
  content: "";
}

.fa-thermometer-1:before {
  content: "";
}

.fa-thermometer-2:before {
  content: "";
}

.fa-thermometer-3:before {
  content: "";
}

.fa-thermometer-4:before {
  content: "";
}

.fa-thermometer-empty:before {
  content: "";
}

.fa-thermometer-full:before {
  content: "";
}

.fa-thermometer-half:before {
  content: "";
}

.fa-thermometer-quarter:before {
  content: "";
}

.fa-thermometer-three-quarters:before {
  content: "";
}

.fa-theta:before {
  content: "";
}

.fa-thought-bubble:before {
  content: "";
}

.fa-thumb-tack:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbtack:before {
  content: "";
}

.fa-thunderstorm:before {
  content: "";
}

.fa-thunderstorm-moon:before {
  content: "";
}

.fa-thunderstorm-sun:before {
  content: "";
}

.fa-tick:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-ticket-airline:before {
  content: "";
}

.fa-ticket-alt:before {
  content: "";
}

.fa-ticket-perforated:before {
  content: "";
}

.fa-ticket-perforated-plane:before, .fa-ticket-plane:before {
  content: "";
}

.fa-ticket-simple:before {
  content: "";
}

.fa-tickets:before {
  content: "";
}

.fa-tickets-airline:before {
  content: "";
}

.fa-tickets-perforated:before {
  content: "";
}

.fa-tickets-perforated-plane:before, .fa-tickets-plane:before {
  content: "";
}

.fa-tickets-simple:before {
  content: "";
}

.fa-tilde:before {
  content: "~";
}

.fa-timeline:before {
  content: "";
}

.fa-timeline-arrow:before {
  content: "";
}

.fa-timer:before {
  content: "";
}

.fa-times:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-times-hexagon:before {
  content: "";
}

.fa-times-octagon:before {
  content: "";
}

.fa-times-rectangle:before {
  content: "";
}

.fa-times-square:before {
  content: "";
}

.fa-times-to-slot:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-tint-slash:before {
  content: "";
}

.fa-tire:before {
  content: "";
}

.fa-tire-flat:before {
  content: "";
}

.fa-tire-pressure-warning:before {
  content: "";
}

.fa-tire-rugged:before {
  content: "";
}

.fa-tired:before {
  content: "";
}

.fa-toggle-large-off:before {
  content: "";
}

.fa-toggle-large-on:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-toilet-paper-alt:before, .fa-toilet-paper-blank:before {
  content: "";
}

.fa-toilet-paper-blank-under:before {
  content: "";
}

.fa-toilet-paper-check:before {
  content: "";
}

.fa-toilet-paper-reverse:before {
  content: "";
}

.fa-toilet-paper-reverse-alt:before {
  content: "";
}

.fa-toilet-paper-reverse-slash:before {
  content: "";
}

.fa-toilet-paper-slash:before {
  content: "";
}

.fa-toilet-paper-under:before {
  content: "";
}

.fa-toilet-paper-under-slash:before {
  content: "";
}

.fa-toilet-paper-xmark:before {
  content: "";
}

.fa-toilet-portable:before {
  content: "";
}

.fa-toilets-portable:before {
  content: "";
}

.fa-tomato:before {
  content: "";
}

.fa-tombstone:before {
  content: "";
}

.fa-tombstone-alt:before, .fa-tombstone-blank:before {
  content: "";
}

.fa-toolbox:before {
  content: "";
}

.fa-tools:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-toothbrush:before {
  content: "";
}

.fa-torah:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-tornado:before {
  content: "";
}

.fa-tower-broadcast:before {
  content: "";
}

.fa-tower-cell:before {
  content: "";
}

.fa-tower-control:before {
  content: "";
}

.fa-tower-observation:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-traffic-cone:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-traffic-light-go:before {
  content: "";
}

.fa-traffic-light-slow:before {
  content: "";
}

.fa-traffic-light-stop:before {
  content: "";
}

.fa-trailer:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-train-subway:before {
  content: "";
}

.fa-train-subway-tunnel:before {
  content: "";
}

.fa-train-track:before {
  content: "";
}

.fa-train-tram:before {
  content: "";
}

.fa-train-tunnel:before {
  content: "";
}

.fa-tram:before {
  content: "";
}

.fa-transformer-bolt:before {
  content: "";
}

.fa-transgender:before, .fa-transgender-alt:before {
  content: "";
}

.fa-transporter:before {
  content: "";
}

.fa-transporter-1:before {
  content: "";
}

.fa-transporter-2:before {
  content: "";
}

.fa-transporter-3:before {
  content: "";
}

.fa-transporter-4:before {
  content: "";
}

.fa-transporter-5:before {
  content: "";
}

.fa-transporter-6:before {
  content: "";
}

.fa-transporter-7:before {
  content: "";
}

.fa-transporter-empty:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-trash-alt:before {
  content: "";
}

.fa-trash-alt-slash:before {
  content: "";
}

.fa-trash-arrow-turn-left:before {
  content: "";
}

.fa-trash-arrow-up:before {
  content: "";
}

.fa-trash-can:before {
  content: "";
}

.fa-trash-can-arrow-turn-left:before {
  content: "";
}

.fa-trash-can-arrow-up:before {
  content: "";
}

.fa-trash-can-check:before {
  content: "";
}

.fa-trash-can-clock:before {
  content: "";
}

.fa-trash-can-list:before {
  content: "";
}

.fa-trash-can-plus:before {
  content: "";
}

.fa-trash-can-slash:before {
  content: "";
}

.fa-trash-can-undo:before {
  content: "";
}

.fa-trash-can-xmark:before {
  content: "";
}

.fa-trash-check:before {
  content: "";
}

.fa-trash-circle:before {
  content: "";
}

.fa-trash-clock:before {
  content: "";
}

.fa-trash-list:before {
  content: "";
}

.fa-trash-plus:before {
  content: "";
}

.fa-trash-restore:before {
  content: "";
}

.fa-trash-restore-alt:before {
  content: "";
}

.fa-trash-slash:before {
  content: "";
}

.fa-trash-undo:before {
  content: "";
}

.fa-trash-undo-alt:before {
  content: "";
}

.fa-trash-xmark:before {
  content: "";
}

.fa-treasure-chest:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-tree-alt:before {
  content: "";
}

.fa-tree-christmas:before {
  content: "";
}

.fa-tree-city:before {
  content: "";
}

.fa-tree-deciduous:before {
  content: "";
}

.fa-tree-decorated:before {
  content: "";
}

.fa-tree-large:before {
  content: "";
}

.fa-tree-palm:before {
  content: "";
}

.fa-trees:before {
  content: "";
}

.fa-trian-balbot:before {
  content: "";
}

.fa-triangle:before {
  content: "";
}

.fa-triangle-circle-square:before {
  content: "";
}

.fa-triangle-exclamation:before {
  content: "";
}

.fa-triangle-instrument:before, .fa-triangle-music:before {
  content: "";
}

.fa-triangle-person-digging:before {
  content: "";
}

.fa-tricycle:before {
  content: "";
}

.fa-tricycle-adult:before {
  content: "";
}

.fa-trillium:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-trophy-alt:before, .fa-trophy-star:before {
  content: "";
}

.fa-trowel:before {
  content: "";
}

.fa-trowel-bricks:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-truck-arrow-right:before {
  content: "";
}

.fa-truck-bolt:before {
  content: "";
}

.fa-truck-clock:before {
  content: "";
}

.fa-truck-container:before {
  content: "";
}

.fa-truck-container-empty:before {
  content: "";
}

.fa-truck-couch:before {
  content: "";
}

.fa-truck-droplet:before {
  content: "";
}

.fa-truck-fast:before {
  content: "";
}

.fa-truck-field:before {
  content: "";
}

.fa-truck-field-un:before {
  content: "";
}

.fa-truck-fire:before {
  content: "";
}

.fa-truck-flatbed:before {
  content: "";
}

.fa-truck-front:before {
  content: "";
}

.fa-truck-ladder:before {
  content: "";
}

.fa-truck-loading:before {
  content: "";
}

.fa-truck-medical:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-truck-moving:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-truck-plane:before {
  content: "";
}

.fa-truck-plow:before {
  content: "";
}

.fa-truck-ramp:before {
  content: "";
}

.fa-truck-ramp-box:before {
  content: "";
}

.fa-truck-ramp-couch:before {
  content: "";
}

.fa-truck-tow:before {
  content: "";
}

.fa-truck-utensils:before {
  content: "";
}

.fa-trumpet:before {
  content: "";
}

.fa-try:before {
  content: "";
}

.fa-tshirt:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-tty-answer:before {
  content: "";
}

.fa-tugrik-sign:before {
  content: "";
}

.fa-turkey:before {
  content: "";
}

.fa-turkish-lira:before, .fa-turkish-lira-sign:before {
  content: "";
}

.fa-turn-down:before {
  content: "";
}

.fa-turn-down-left:before {
  content: "";
}

.fa-turn-down-right:before {
  content: "";
}

.fa-turn-left:before {
  content: "";
}

.fa-turn-left-down:before {
  content: "";
}

.fa-turn-left-up:before {
  content: "";
}

.fa-turn-right:before {
  content: "";
}

.fa-turn-up:before {
  content: "";
}

.fa-turntable:before {
  content: "";
}

.fa-turtle:before {
  content: "";
}

.fa-tv:before, .fa-tv-alt:before {
  content: "";
}

.fa-tv-music:before {
  content: "";
}

.fa-tv-retro:before {
  content: "";
}

.fa-typewriter:before {
  content: "";
}

.fa-u:before {
  content: "U";
}

.fa-ufo:before {
  content: "";
}

.fa-ufo-beam:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-umbrella-alt:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-umbrella-simple:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-undo:before {
  content: "";
}

.fa-undo-alt:before {
  content: "";
}

.fa-unicorn:before {
  content: "";
}

.fa-uniform-martial-arts:before {
  content: "";
}

.fa-union:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-university:before {
  content: "";
}

.fa-unlink:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-unlock-alt:before, .fa-unlock-keyhole:before {
  content: "";
}

.fa-unsorted:before {
  content: "";
}

.fa-up:before {
  content: "";
}

.fa-up-down:before {
  content: "";
}

.fa-up-down-left-right:before {
  content: "";
}

.fa-up-from-bracket:before {
  content: "";
}

.fa-up-from-dotted-line:before {
  content: "";
}

.fa-up-from-line:before {
  content: "";
}

.fa-up-left:before {
  content: "";
}

.fa-up-long:before {
  content: "";
}

.fa-up-right:before {
  content: "";
}

.fa-up-right-and-down-left-from-center:before {
  content: "";
}

.fa-up-right-from-square:before {
  content: "";
}

.fa-up-to-bracket:before {
  content: "";
}

.fa-up-to-dotted-line:before {
  content: "";
}

.fa-up-to-line:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-usb-drive:before {
  content: "";
}

.fa-usd:before {
  content: "$";
}

.fa-usd-circle:before {
  content: "";
}

.fa-usd-square:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-user-alien:before {
  content: "";
}

.fa-user-alt:before {
  content: "";
}

.fa-user-alt-slash:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-user-bounty-hunter:before {
  content: "";
}

.fa-user-chart:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-user-chef:before {
  content: "";
}

.fa-user-circle:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-user-cog:before {
  content: "";
}

.fa-user-construction:before {
  content: "";
}

.fa-user-cowboy:before {
  content: "";
}

.fa-user-crown:before {
  content: "";
}

.fa-user-doctor:before {
  content: "";
}

.fa-user-doctor-hair:before {
  content: "";
}

.fa-user-doctor-hair-long:before {
  content: "";
}

.fa-user-doctor-message:before {
  content: "";
}

.fa-user-edit:before {
  content: "";
}

.fa-user-friends:before {
  content: "";
}

.fa-user-gear:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-user-group:before {
  content: "";
}

.fa-user-group-crown:before {
  content: "";
}

.fa-user-group-simple:before {
  content: "";
}

.fa-user-hair:before {
  content: "";
}

.fa-user-hair-buns:before {
  content: "";
}

.fa-user-hair-long:before {
  content: "";
}

.fa-user-hair-mullet:before {
  content: "";
}

.fa-user-hard-hat:before {
  content: "";
}

.fa-user-headset:before {
  content: "";
}

.fa-user-helmet-safety:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-user-large:before {
  content: "";
}

.fa-user-large-slash:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-user-magnifying-glass:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-user-md-chat:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-user-music:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-user-nurse-hair:before {
  content: "";
}

.fa-user-nurse-hair-long:before {
  content: "";
}

.fa-user-pen:before {
  content: "";
}

.fa-user-pilot:before {
  content: "";
}

.fa-user-pilot-tie:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-police:before {
  content: "";
}

.fa-user-police-tie:before {
  content: "";
}

.fa-user-robot:before {
  content: "";
}

.fa-user-robot-xmarks:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-user-shakespeare:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-user-tie-hair:before {
  content: "";
}

.fa-user-tie-hair-long:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-user-unlock:before {
  content: "";
}

.fa-user-visor:before {
  content: "";
}

.fa-user-vneck:before {
  content: "";
}

.fa-user-vneck-hair:before {
  content: "";
}

.fa-user-vneck-hair-long:before {
  content: "";
}

.fa-user-xmark:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-users-between-lines:before {
  content: "";
}

.fa-users-class:before {
  content: "";
}

.fa-users-cog:before {
  content: "";
}

.fa-users-crown:before {
  content: "";
}

.fa-users-gear:before {
  content: "";
}

.fa-users-line:before {
  content: "";
}

.fa-users-medical:before {
  content: "";
}

.fa-users-rays:before {
  content: "";
}

.fa-users-rectangle:before {
  content: "";
}

.fa-users-slash:before {
  content: "";
}

.fa-users-viewfinder:before {
  content: "";
}

.fa-utensil-fork:before {
  content: "";
}

.fa-utensil-knife:before {
  content: "";
}

.fa-utensil-spoon:before {
  content: "";
}

.fa-utensils:before {
  content: "";
}

.fa-utensils-alt:before {
  content: "";
}

.fa-utensils-slash:before {
  content: "";
}

.fa-utility-pole:before {
  content: "";
}

.fa-utility-pole-double:before {
  content: "";
}

.fa-v:before {
  content: "V";
}

.fa-vacuum:before {
  content: "";
}

.fa-vacuum-robot:before {
  content: "";
}

.fa-value-absolute:before {
  content: "";
}

.fa-van-shuttle:before {
  content: "";
}

.fa-vault:before {
  content: "";
}

.fa-vcard:before {
  content: "";
}

.fa-vector-circle:before {
  content: "";
}

.fa-vector-polygon:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-vent-damper:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-vest:before {
  content: "";
}

.fa-vest-patches:before {
  content: "";
}

.fa-vhs:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-vial-circle-check:before {
  content: "";
}

.fa-vial-virus:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-video:before {
  content: "";
}

.fa-video-arrow-down-left:before {
  content: "";
}

.fa-video-arrow-up-right:before {
  content: "";
}

.fa-video-camera:before {
  content: "";
}

.fa-video-circle:before {
  content: "";
}

.fa-video-handheld:before {
  content: "";
}

.fa-video-plus:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-violin:before {
  content: "";
}

.fa-virus:before {
  content: "";
}

.fa-virus-covid:before {
  content: "";
}

.fa-virus-covid-slash:before {
  content: "";
}

.fa-virus-slash:before {
  content: "";
}

.fa-viruses:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-volcano:before {
  content: "";
}

.fa-volleyball:before, .fa-volleyball-ball:before {
  content: "";
}

.fa-volume:before {
  content: "";
}

.fa-volume-control-phone:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-high:before {
  content: "";
}

.fa-volume-low:before {
  content: "";
}

.fa-volume-medium:before {
  content: "";
}

.fa-volume-mute:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-slash:before {
  content: "";
}

.fa-volume-times:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-volume-xmark:before {
  content: "";
}

.fa-vote-nay:before {
  content: "";
}

.fa-vote-yea:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-w:before {
  content: "W";
}

.fa-waffle:before {
  content: "";
}

.fa-wagon-covered:before {
  content: "";
}

.fa-walker:before {
  content: "";
}

.fa-walkie-talkie:before {
  content: "";
}

.fa-walking:before {
  content: "";
}

.fa-wall-brick:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-wand:before {
  content: "";
}

.fa-wand-magic:before {
  content: "";
}

.fa-wand-magic-sparkles:before {
  content: "";
}

.fa-wand-sparkles:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-warehouse-alt:before, .fa-warehouse-full:before {
  content: "";
}

.fa-warning:before {
  content: "";
}

.fa-washer:before, .fa-washing-machine:before {
  content: "";
}

.fa-watch:before {
  content: "";
}

.fa-watch-apple:before {
  content: "";
}

.fa-watch-calculator:before {
  content: "";
}

.fa-watch-fitness:before {
  content: "";
}

.fa-watch-smart:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-water-arrow-down:before {
  content: "";
}

.fa-water-arrow-up:before {
  content: "";
}

.fa-water-ladder:before {
  content: "";
}

.fa-water-lower:before {
  content: "";
}

.fa-water-rise:before {
  content: "";
}

.fa-watermelon-slice:before {
  content: "";
}

.fa-wave:before {
  content: "";
}

.fa-wave-pulse:before {
  content: "";
}

.fa-wave-sine:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-wave-triangle:before {
  content: "";
}

.fa-waveform:before {
  content: "";
}

.fa-waveform-circle:before {
  content: "";
}

.fa-waveform-lines:before, .fa-waveform-path:before {
  content: "";
}

.fa-waves-sine:before {
  content: "";
}

.fa-webcam:before {
  content: "";
}

.fa-webcam-slash:before {
  content: "";
}

.fa-webhook:before {
  content: "";
}

.fa-weight:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-weight-scale:before {
  content: "";
}

.fa-whale:before {
  content: "";
}

.fa-wheat:before {
  content: "";
}

.fa-wheat-alt:before, .fa-wheat-awn:before {
  content: "";
}

.fa-wheat-awn-circle-exclamation:before {
  content: "";
}

.fa-wheat-awn-slash:before {
  content: "";
}

.fa-wheat-slash:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-wheelchair-alt:before, .fa-wheelchair-move:before {
  content: "";
}

.fa-whiskey-glass:before {
  content: "";
}

.fa-whiskey-glass-ice:before {
  content: "";
}

.fa-whistle:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-wifi-1:before {
  content: "";
}

.fa-wifi-2:before {
  content: "";
}

.fa-wifi-3:before {
  content: "";
}

.fa-wifi-exclamation:before {
  content: "";
}

.fa-wifi-fair:before {
  content: "";
}

.fa-wifi-slash:before {
  content: "";
}

.fa-wifi-strong:before {
  content: "";
}

.fa-wifi-weak:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-wind-circle-exclamation:before {
  content: "";
}

.fa-wind-turbine:before {
  content: "";
}

.fa-wind-warning:before {
  content: "";
}

.fa-window:before {
  content: "";
}

.fa-window-alt:before {
  content: "";
}

.fa-window-close:before {
  content: "";
}

.fa-window-flip:before {
  content: "";
}

.fa-window-frame:before {
  content: "";
}

.fa-window-frame-open:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-windsock:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-wine-glass-alt:before {
  content: "";
}

.fa-wine-glass-crack:before {
  content: "";
}

.fa-wine-glass-empty:before {
  content: "";
}

.fa-won:before, .fa-won-sign:before {
  content: "";
}

.fa-worm:before {
  content: "";
}

.fa-wreath:before {
  content: "";
}

.fa-wreath-laurel:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-wrench-simple:before {
  content: "";
}

.fa-x:before {
  content: "X";
}

.fa-x-ray:before {
  content: "";
}

.fa-xmark:before {
  content: "";
}

.fa-xmark-circle:before {
  content: "";
}

.fa-xmark-hexagon:before {
  content: "";
}

.fa-xmark-large:before {
  content: "";
}

.fa-xmark-octagon:before {
  content: "";
}

.fa-xmark-square:before {
  content: "";
}

.fa-xmark-to-slot:before {
  content: "";
}

.fa-xmarks-lines:before {
  content: "";
}

.fa-y:before {
  content: "Y";
}

.fa-yen:before, .fa-yen-sign:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-z:before {
  content: "Z";
}

.fa-zap:before {
  content: "";
}

.fa-zzz:before {
  content: "";
}

.sr-only, .fa-sr-only, .sr-only-focusable:not(:focus), .fa-sr-only-focusable:not(:focus) {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

:root, :host {
  --fa-style-family-brands: "Font Awesome 6 Brands";
  --fa-font-brands: normal 400 1em / 1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.ttf") format("truetype");
}

.fab, .fa-brands {
  font-weight: 400;
}

.fa-monero:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-cloudflare:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-pixiv:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-guilded:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-square-js:before, .fa-js-square:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-orcid:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-jxl:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-unity:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-square-reddit:before, .fa-reddit-square:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-square-font-awesome:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-brave:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-square-instagram:before, .fa-instagram-square:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-square-hacker-news:before, .fa-hacker-news-square:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-threads:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-square-snapchat:before, .fa-snapchat-square:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-square-font-awesome-stroke:before, .fa-font-awesome-alt:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-watchman-monitoring:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-lyft:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-square-viadeo:before, .fa-viadeo-square:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-opensuse:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-square-dribbble:before, .fa-dribbble-square:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-debian:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-instalod:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-square-twitter:before, .fa-twitter-square:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-golang:before {
  content: "";
}

.fa-kickstarter:before, .fa-square-kickstarter:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-uncharted:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-square-youtube:before, .fa-youtube-square:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-wpressr:before, .fa-rendact:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-nfc-directional:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-meta:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-square-letterboxd:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-shoelace:before {
  content: "";
}

.fa-mdb:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-unsplash:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-square-steam:before, .fa-steam-square:before {
  content: "";
}

.fa-500px:before {
  content: "";
}

.fa-square-vimeo:before, .fa-vimeo-square:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-font-awesome:before, .fa-font-awesome-flag:before, .fa-font-awesome-logo-full:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-hive:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-padlet:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-square-github:before, .fa-github-square:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-shopify:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-square-threads:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-swift:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-google-scholar:before {
  content: "";
}

.fa-square-gitlab:before, .fa-gitlab-square:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-square-odnoklassniki:before, .fa-odnoklassniki-square:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-hashnode:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-umbraco:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-square-pinterest:before, .fa-pinterest-square:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-signal-messenger:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-dailymotion:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-nfc-symbol:before {
  content: "";
}

.fa-mintbit:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-brave-reverse:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-square-google-plus:before, .fa-google-plus-square:before {
  content: "";
}

.fa-web-awesome:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-mizuni:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-square-xing:before, .fa-xing-square:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-letterboxd:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-bilibili:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-x-twitter:before {
  content: "";
}

.fa-cotton-bureau:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-42-group:before, .fa-innosoft:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-square-pied-piper:before, .fa-pied-piper-square:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-square-web-awesome-stroke:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-tiktok:before {
  content: "";
}

.fa-square-facebook:before, .fa-facebook-square:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-mixer:before {
  content: "";
}

.fa-square-lastfm:before, .fa-lastfm-square:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-cmplid:before {
  content: "";
}

.fa-upwork:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-square-upwork:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-deezer:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-screenpal:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-microblog:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-square-web-awesome:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-wirsindhandwerk:before, .fa-wsh:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-octopus-deploy:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-bluesky:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-snapchat:before, .fa-snapchat-ghost:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-rust:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-square-behance:before, .fa-behance-square:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-webflow:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-space-awesome:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-square-git:before, .fa-git-square:before {
  content: "";
}

.fa-square-tumblr:before, .fa-tumblr-square:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-perbyte:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-bots:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-ideal:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-firefox-browser:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-stubber:before {
  content: "";
}

.fa-telegram:before, .fa-telegram-plane:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-odysee:before {
  content: "";
}

.fa-square-whatsapp:before, .fa-whatsapp-square:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-edge-legacy:before {
  content: "";
}

.fa-slack:before, .fa-slack-hash:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-square-x-twitter:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-medium:before, .fa-medium-m:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-sitrox:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-buy-n-large:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-strava:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-pushed:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-wodu:before {
  content: "";
}

.fa-google-pay:before {
  content: "";
}

.fa-intercom:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-pix:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-light: normal 300 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.ttf") format("truetype");
}

.fal, .fa-light {
  font-weight: 300;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-regular: normal 400 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.ttf") format("truetype");
}

.far, .fa-regular {
  font-weight: 400;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-solid: normal 900 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.ttf") format("truetype");
}

.fas, .fa-solid {
  font-weight: 900;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-thin: normal 100 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../fonts/fa-thin-100.woff2") format("woff2"), url("../fonts/fa-thin-100.ttf") format("truetype");
}

.fat, .fa-thin {
  font-weight: 100;
}

:root, :host {
  --fa-style-family-duotone: "Font Awesome 6 Duotone";
  --fa-font-duotone: normal 900 1em / 1 "Font Awesome 6 Duotone";
}

@font-face {
  font-family: "Font Awesome 6 Duotone";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-duotone-900.woff2") format("woff2"), url("../fonts/fa-duotone-900.ttf") format("truetype");
}

.fad, .fa-duotone {
  letter-spacing: normal;
  font-weight: 900;
  position: relative;
}

.fad:before, .fa-duotone:before {
  color: var(--fa-primary-color, inherit);
  opacity: var(--fa-primary-opacity, 1);
  position: absolute;
}

.fad:after, .fa-duotone:after {
  color: var(--fa-secondary-color, inherit);
  opacity: var(--fa-secondary-opacity, .4);
}

.fa-swap-opacity .fad:before, .fa-swap-opacity .fa-duotone:before, .fad.fa-swap-opacity:before, .fa-duotone.fa-swap-opacity:before {
  opacity: var(--fa-secondary-opacity, .4);
}

.fa-swap-opacity .fad:after, .fa-swap-opacity .fa-duotone:after, .fad.fa-swap-opacity:after, .fa-duotone.fa-swap-opacity:after {
  opacity: var(--fa-primary-opacity, 1);
}

.fad.fa-inverse, .fa-duotone.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fad.fa-stack-1x, .fad.fa-stack-2x, .fa-duotone.fa-stack-1x, .fa-duotone.fa-stack-2x {
  position: absolute;
}

.fad.fa-0:after, .fa-duotone.fa-0:after {
  content: "00";
}

.fad.fa-1:after, .fa-duotone.fa-1:after {
  content: "11";
}

.fad.fa-2:after, .fa-duotone.fa-2:after {
  content: "22";
}

.fad.fa-3:after, .fa-duotone.fa-3:after {
  content: "33";
}

.fad.fa-4:after, .fa-duotone.fa-4:after {
  content: "44";
}

.fad.fa-5:after, .fa-duotone.fa-5:after {
  content: "55";
}

.fad.fa-6:after, .fa-duotone.fa-6:after {
  content: "66";
}

.fad.fa-7:after, .fa-duotone.fa-7:after {
  content: "77";
}

.fad.fa-8:after, .fa-duotone.fa-8:after {
  content: "88";
}

.fad.fa-9:after, .fa-duotone.fa-9:after {
  content: "99";
}

.fad.fa-fill-drip:after, .fa-duotone.fa-fill-drip:after {
  content: "";
}

.fad.fa-arrows-to-circle:after, .fa-duotone.fa-arrows-to-circle:after {
  content: "";
}

.fad.fa-circle-chevron-right:after, .fa-duotone.fa-circle-chevron-right:after, .fad.fa-chevron-circle-right:after, .fa-duotone.fa-chevron-circle-right:after {
  content: "";
}

.fad.fa-wagon-covered:after, .fa-duotone.fa-wagon-covered:after {
  content: "";
}

.fad.fa-line-height:after, .fa-duotone.fa-line-height:after {
  content: "";
}

.fad.fa-bagel:after, .fa-duotone.fa-bagel:after {
  content: "";
}

.fad.fa-transporter-7:after, .fa-duotone.fa-transporter-7:after {
  content: "";
}

.fad.fa-at:after, .fa-duotone.fa-at:after {
  content: "@@";
}

.fad.fa-rectangles-mixed:after, .fa-duotone.fa-rectangles-mixed:after {
  content: "";
}

.fad.fa-phone-arrow-up-right:after, .fa-duotone.fa-phone-arrow-up-right:after, .fad.fa-phone-arrow-up:after, .fa-duotone.fa-phone-arrow-up:after, .fad.fa-phone-outgoing:after, .fa-duotone.fa-phone-outgoing:after {
  content: "";
}

.fad.fa-trash-can:after, .fa-duotone.fa-trash-can:after, .fad.fa-trash-alt:after, .fa-duotone.fa-trash-alt:after {
  content: "";
}

.fad.fa-circle-l:after, .fa-duotone.fa-circle-l:after {
  content: "";
}

.fad.fa-head-side-goggles:after, .fa-duotone.fa-head-side-goggles:after, .fad.fa-head-vr:after, .fa-duotone.fa-head-vr:after {
  content: "";
}

.fad.fa-text-height:after, .fa-duotone.fa-text-height:after {
  content: "";
}

.fad.fa-user-xmark:after, .fa-duotone.fa-user-xmark:after, .fad.fa-user-times:after, .fa-duotone.fa-user-times:after {
  content: "";
}

.fad.fa-face-hand-yawn:after, .fa-duotone.fa-face-hand-yawn:after {
  content: "";
}

.fad.fa-gauge-simple-min:after, .fa-duotone.fa-gauge-simple-min:after, .fad.fa-tachometer-slowest:after, .fa-duotone.fa-tachometer-slowest:after {
  content: "";
}

.fad.fa-stethoscope:after, .fa-duotone.fa-stethoscope:after {
  content: "";
}

.fad.fa-coffin:after, .fa-duotone.fa-coffin:after {
  content: "";
}

.fad.fa-message:after, .fa-duotone.fa-message:after, .fad.fa-comment-alt:after, .fa-duotone.fa-comment-alt:after {
  content: "";
}

.fad.fa-salad:after, .fa-duotone.fa-salad:after, .fad.fa-bowl-salad:after, .fa-duotone.fa-bowl-salad:after {
  content: "";
}

.fad.fa-info:after, .fa-duotone.fa-info:after {
  content: "";
}

.fad.fa-robot-astromech:after, .fa-duotone.fa-robot-astromech:after {
  content: "";
}

.fad.fa-ring-diamond:after, .fa-duotone.fa-ring-diamond:after {
  content: "";
}

.fad.fa-fondue-pot:after, .fa-duotone.fa-fondue-pot:after {
  content: "";
}

.fad.fa-theta:after, .fa-duotone.fa-theta:after {
  content: "";
}

.fad.fa-face-hand-peeking:after, .fa-duotone.fa-face-hand-peeking:after {
  content: "";
}

.fad.fa-square-user:after, .fa-duotone.fa-square-user:after {
  content: "";
}

.fad.fa-down-left-and-up-right-to-center:after, .fa-duotone.fa-down-left-and-up-right-to-center:after, .fad.fa-compress-alt:after, .fa-duotone.fa-compress-alt:after {
  content: "";
}

.fad.fa-explosion:after, .fa-duotone.fa-explosion:after {
  content: "";
}

.fad.fa-file-lines:after, .fa-duotone.fa-file-lines:after, .fad.fa-file-alt:after, .fa-duotone.fa-file-alt:after, .fad.fa-file-text:after, .fa-duotone.fa-file-text:after {
  content: "";
}

.fad.fa-wave-square:after, .fa-duotone.fa-wave-square:after {
  content: "";
}

.fad.fa-ring:after, .fa-duotone.fa-ring:after {
  content: "";
}

.fad.fa-building-un:after, .fa-duotone.fa-building-un:after {
  content: "";
}

.fad.fa-dice-three:after, .fa-duotone.fa-dice-three:after {
  content: "";
}

.fad.fa-tire-pressure-warning:after, .fa-duotone.fa-tire-pressure-warning:after {
  content: "";
}

.fad.fa-wifi-fair:after, .fa-duotone.fa-wifi-fair:after, .fad.fa-wifi-2:after, .fa-duotone.fa-wifi-2:after {
  content: "";
}

.fad.fa-calendar-days:after, .fa-duotone.fa-calendar-days:after, .fad.fa-calendar-alt:after, .fa-duotone.fa-calendar-alt:after {
  content: "";
}

.fad.fa-mp3-player:after, .fa-duotone.fa-mp3-player:after {
  content: "";
}

.fad.fa-anchor-circle-check:after, .fa-duotone.fa-anchor-circle-check:after {
  content: "";
}

.fad.fa-tally-4:after, .fa-duotone.fa-tally-4:after {
  content: "";
}

.fad.fa-rectangle-history:after, .fa-duotone.fa-rectangle-history:after {
  content: "";
}

.fad.fa-building-circle-arrow-right:after, .fa-duotone.fa-building-circle-arrow-right:after {
  content: "";
}

.fad.fa-volleyball:after, .fa-duotone.fa-volleyball:after, .fad.fa-volleyball-ball:after, .fa-duotone.fa-volleyball-ball:after {
  content: "";
}

.fad.fa-sun-haze:after, .fa-duotone.fa-sun-haze:after {
  content: "";
}

.fad.fa-text-size:after, .fa-duotone.fa-text-size:after {
  content: "";
}

.fad.fa-ufo:after, .fa-duotone.fa-ufo:after {
  content: "";
}

.fad.fa-fork:after, .fa-duotone.fa-fork:after, .fad.fa-utensil-fork:after, .fa-duotone.fa-utensil-fork:after {
  content: "";
}

.fad.fa-arrows-up-to-line:after, .fa-duotone.fa-arrows-up-to-line:after {
  content: "";
}

.fad.fa-mobile-signal:after, .fa-duotone.fa-mobile-signal:after {
  content: "";
}

.fad.fa-barcode-scan:after, .fa-duotone.fa-barcode-scan:after {
  content: "";
}

.fad.fa-sort-down:after, .fa-duotone.fa-sort-down:after, .fad.fa-sort-desc:after, .fa-duotone.fa-sort-desc:after {
  content: "";
}

.fad.fa-folder-arrow-down:after, .fa-duotone.fa-folder-arrow-down:after, .fad.fa-folder-download:after, .fa-duotone.fa-folder-download:after {
  content: "";
}

.fad.fa-circle-minus:after, .fa-duotone.fa-circle-minus:after, .fad.fa-minus-circle:after, .fa-duotone.fa-minus-circle:after {
  content: "";
}

.fad.fa-face-icicles:after, .fa-duotone.fa-face-icicles:after {
  content: "";
}

.fad.fa-shovel:after, .fa-duotone.fa-shovel:after {
  content: "";
}

.fad.fa-door-open:after, .fa-duotone.fa-door-open:after {
  content: "";
}

.fad.fa-films:after, .fa-duotone.fa-films:after {
  content: "";
}

.fad.fa-right-from-bracket:after, .fa-duotone.fa-right-from-bracket:after, .fad.fa-sign-out-alt:after, .fa-duotone.fa-sign-out-alt:after {
  content: "";
}

.fad.fa-face-glasses:after, .fa-duotone.fa-face-glasses:after {
  content: "";
}

.fad.fa-nfc:after, .fa-duotone.fa-nfc:after {
  content: "";
}

.fad.fa-atom:after, .fa-duotone.fa-atom:after {
  content: "";
}

.fad.fa-soap:after, .fa-duotone.fa-soap:after {
  content: "";
}

.fad.fa-icons:after, .fa-duotone.fa-icons:after, .fad.fa-heart-music-camera-bolt:after, .fa-duotone.fa-heart-music-camera-bolt:after {
  content: "";
}

.fad.fa-microphone-lines-slash:after, .fa-duotone.fa-microphone-lines-slash:after, .fad.fa-microphone-alt-slash:after, .fa-duotone.fa-microphone-alt-slash:after {
  content: "";
}

.fad.fa-closed-captioning-slash:after, .fa-duotone.fa-closed-captioning-slash:after {
  content: "";
}

.fad.fa-calculator-simple:after, .fa-duotone.fa-calculator-simple:after, .fad.fa-calculator-alt:after, .fa-duotone.fa-calculator-alt:after {
  content: "";
}

.fad.fa-bridge-circle-check:after, .fa-duotone.fa-bridge-circle-check:after {
  content: "";
}

.fad.fa-sliders-up:after, .fa-duotone.fa-sliders-up:after, .fad.fa-sliders-v:after, .fa-duotone.fa-sliders-v:after {
  content: "";
}

.fad.fa-location-minus:after, .fa-duotone.fa-location-minus:after, .fad.fa-map-marker-minus:after, .fa-duotone.fa-map-marker-minus:after {
  content: "";
}

.fad.fa-pump-medical:after, .fa-duotone.fa-pump-medical:after {
  content: "";
}

.fad.fa-fingerprint:after, .fa-duotone.fa-fingerprint:after {
  content: "";
}

.fad.fa-ski-boot:after, .fa-duotone.fa-ski-boot:after {
  content: "";
}

.fad.fa-standard-definition:after, .fa-duotone.fa-standard-definition:after, .fad.fa-rectangle-sd:after, .fa-duotone.fa-rectangle-sd:after {
  content: "";
}

.fad.fa-h1:after, .fa-duotone.fa-h1:after {
  content: "";
}

.fad.fa-hand-point-right:after, .fa-duotone.fa-hand-point-right:after {
  content: "";
}

.fad.fa-magnifying-glass-location:after, .fa-duotone.fa-magnifying-glass-location:after, .fad.fa-search-location:after, .fa-duotone.fa-search-location:after {
  content: "";
}

.fad.fa-message-bot:after, .fa-duotone.fa-message-bot:after {
  content: "";
}

.fad.fa-forward-step:after, .fa-duotone.fa-forward-step:after, .fad.fa-step-forward:after, .fa-duotone.fa-step-forward:after {
  content: "";
}

.fad.fa-face-smile-beam:after, .fa-duotone.fa-face-smile-beam:after, .fad.fa-smile-beam:after, .fa-duotone.fa-smile-beam:after {
  content: "";
}

.fad.fa-light-ceiling:after, .fa-duotone.fa-light-ceiling:after {
  content: "";
}

.fad.fa-message-exclamation:after, .fa-duotone.fa-message-exclamation:after, .fad.fa-comment-alt-exclamation:after, .fa-duotone.fa-comment-alt-exclamation:after {
  content: "";
}

.fad.fa-bowl-scoop:after, .fa-duotone.fa-bowl-scoop:after, .fad.fa-bowl-shaved-ice:after, .fa-duotone.fa-bowl-shaved-ice:after {
  content: "";
}

.fad.fa-square-x:after, .fa-duotone.fa-square-x:after {
  content: "";
}

.fad.fa-building-memo:after, .fa-duotone.fa-building-memo:after {
  content: "";
}

.fad.fa-utility-pole-double:after, .fa-duotone.fa-utility-pole-double:after {
  content: "";
}

.fad.fa-flag-checkered:after, .fa-duotone.fa-flag-checkered:after {
  content: "";
}

.fad.fa-chevrons-up:after, .fa-duotone.fa-chevrons-up:after, .fad.fa-chevron-double-up:after, .fa-duotone.fa-chevron-double-up:after {
  content: "";
}

.fad.fa-football:after, .fa-duotone.fa-football:after, .fad.fa-football-ball:after, .fa-duotone.fa-football-ball:after {
  content: "";
}

.fad.fa-user-vneck:after, .fa-duotone.fa-user-vneck:after {
  content: "";
}

.fad.fa-school-circle-exclamation:after, .fa-duotone.fa-school-circle-exclamation:after {
  content: "";
}

.fad.fa-crop:after, .fa-duotone.fa-crop:after {
  content: "";
}

.fad.fa-angles-down:after, .fa-duotone.fa-angles-down:after, .fad.fa-angle-double-down:after, .fa-duotone.fa-angle-double-down:after {
  content: "";
}

.fad.fa-users-rectangle:after, .fa-duotone.fa-users-rectangle:after {
  content: "";
}

.fad.fa-people-roof:after, .fa-duotone.fa-people-roof:after {
  content: "";
}

.fad.fa-square-arrow-right:after, .fa-duotone.fa-square-arrow-right:after, .fad.fa-arrow-square-right:after, .fa-duotone.fa-arrow-square-right:after {
  content: "";
}

.fad.fa-location-plus:after, .fa-duotone.fa-location-plus:after, .fad.fa-map-marker-plus:after, .fa-duotone.fa-map-marker-plus:after {
  content: "";
}

.fad.fa-lightbulb-exclamation-on:after, .fa-duotone.fa-lightbulb-exclamation-on:after {
  content: "";
}

.fad.fa-people-line:after, .fa-duotone.fa-people-line:after {
  content: "";
}

.fad.fa-beer-mug-empty:after, .fa-duotone.fa-beer-mug-empty:after, .fad.fa-beer:after, .fa-duotone.fa-beer:after {
  content: "";
}

.fad.fa-crate-empty:after, .fa-duotone.fa-crate-empty:after {
  content: "";
}

.fad.fa-diagram-predecessor:after, .fa-duotone.fa-diagram-predecessor:after {
  content: "";
}

.fad.fa-transporter:after, .fa-duotone.fa-transporter:after {
  content: "";
}

.fad.fa-calendar-circle-user:after, .fa-duotone.fa-calendar-circle-user:after {
  content: "";
}

.fad.fa-arrow-up-long:after, .fa-duotone.fa-arrow-up-long:after, .fad.fa-long-arrow-up:after, .fa-duotone.fa-long-arrow-up:after {
  content: "";
}

.fad.fa-person-carry-box:after, .fa-duotone.fa-person-carry-box:after, .fad.fa-person-carry:after, .fa-duotone.fa-person-carry:after {
  content: "";
}

.fad.fa-fire-flame-simple:after, .fa-duotone.fa-fire-flame-simple:after, .fad.fa-burn:after, .fa-duotone.fa-burn:after {
  content: "";
}

.fad.fa-person:after, .fa-duotone.fa-person:after, .fad.fa-male:after, .fa-duotone.fa-male:after {
  content: "";
}

.fad.fa-laptop:after, .fa-duotone.fa-laptop:after {
  content: "";
}

.fad.fa-file-csv:after, .fa-duotone.fa-file-csv:after {
  content: "";
}

.fad.fa-menorah:after, .fa-duotone.fa-menorah:after {
  content: "";
}

.fad.fa-union:after, .fa-duotone.fa-union:after {
  content: "";
}

.fad.fa-chevrons-left:after, .fa-duotone.fa-chevrons-left:after, .fad.fa-chevron-double-left:after, .fa-duotone.fa-chevron-double-left:after {
  content: "";
}

.fad.fa-circle-heart:after, .fa-duotone.fa-circle-heart:after, .fad.fa-heart-circle:after, .fa-duotone.fa-heart-circle:after {
  content: "";
}

.fad.fa-truck-plane:after, .fa-duotone.fa-truck-plane:after {
  content: "";
}

.fad.fa-record-vinyl:after, .fa-duotone.fa-record-vinyl:after {
  content: "";
}

.fad.fa-bring-forward:after, .fa-duotone.fa-bring-forward:after {
  content: "";
}

.fad.fa-square-p:after, .fa-duotone.fa-square-p:after {
  content: "";
}

.fad.fa-face-grin-stars:after, .fa-duotone.fa-face-grin-stars:after, .fad.fa-grin-stars:after, .fa-duotone.fa-grin-stars:after {
  content: "";
}

.fad.fa-sigma:after, .fa-duotone.fa-sigma:after {
  content: "";
}

.fad.fa-camera-movie:after, .fa-duotone.fa-camera-movie:after {
  content: "";
}

.fad.fa-bong:after, .fa-duotone.fa-bong:after {
  content: "";
}

.fad.fa-clarinet:after, .fa-duotone.fa-clarinet:after {
  content: "";
}

.fad.fa-truck-flatbed:after, .fa-duotone.fa-truck-flatbed:after {
  content: "";
}

.fad.fa-spaghetti-monster-flying:after, .fa-duotone.fa-spaghetti-monster-flying:after, .fad.fa-pastafarianism:after, .fa-duotone.fa-pastafarianism:after {
  content: "";
}

.fad.fa-arrow-down-up-across-line:after, .fa-duotone.fa-arrow-down-up-across-line:after {
  content: "";
}

.fad.fa-arrows-rotate-reverse:after, .fa-duotone.fa-arrows-rotate-reverse:after {
  content: "";
}

.fad.fa-leaf-heart:after, .fa-duotone.fa-leaf-heart:after {
  content: "";
}

.fad.fa-house-building:after, .fa-duotone.fa-house-building:after {
  content: "";
}

.fad.fa-cheese-swiss:after, .fa-duotone.fa-cheese-swiss:after {
  content: "";
}

.fad.fa-spoon:after, .fa-duotone.fa-spoon:after, .fad.fa-utensil-spoon:after, .fa-duotone.fa-utensil-spoon:after {
  content: "";
}

.fad.fa-jar-wheat:after, .fa-duotone.fa-jar-wheat:after {
  content: "";
}

.fad.fa-envelopes-bulk:after, .fa-duotone.fa-envelopes-bulk:after, .fad.fa-mail-bulk:after, .fa-duotone.fa-mail-bulk:after {
  content: "";
}

.fad.fa-file-circle-exclamation:after, .fa-duotone.fa-file-circle-exclamation:after {
  content: "";
}

.fad.fa-bow-arrow:after, .fa-duotone.fa-bow-arrow:after {
  content: "";
}

.fad.fa-cart-xmark:after, .fa-duotone.fa-cart-xmark:after {
  content: "";
}

.fad.fa-hexagon-xmark:after, .fa-duotone.fa-hexagon-xmark:after, .fad.fa-times-hexagon:after, .fa-duotone.fa-times-hexagon:after, .fad.fa-xmark-hexagon:after, .fa-duotone.fa-xmark-hexagon:after {
  content: "";
}

.fad.fa-circle-h:after, .fa-duotone.fa-circle-h:after, .fad.fa-hospital-symbol:after, .fa-duotone.fa-hospital-symbol:after {
  content: "";
}

.fad.fa-merge:after, .fa-duotone.fa-merge:after {
  content: "";
}

.fad.fa-pager:after, .fa-duotone.fa-pager:after {
  content: "";
}

.fad.fa-cart-minus:after, .fa-duotone.fa-cart-minus:after {
  content: "";
}

.fad.fa-address-book:after, .fa-duotone.fa-address-book:after, .fad.fa-contact-book:after, .fa-duotone.fa-contact-book:after {
  content: "";
}

.fad.fa-pan-frying:after, .fa-duotone.fa-pan-frying:after {
  content: "";
}

.fad.fa-grid:after, .fa-duotone.fa-grid:after, .fad.fa-grid-3:after, .fa-duotone.fa-grid-3:after {
  content: "";
}

.fad.fa-football-helmet:after, .fa-duotone.fa-football-helmet:after {
  content: "";
}

.fad.fa-hand-love:after, .fa-duotone.fa-hand-love:after {
  content: "";
}

.fad.fa-trees:after, .fa-duotone.fa-trees:after {
  content: "";
}

.fad.fa-strikethrough:after, .fa-duotone.fa-strikethrough:after {
  content: "";
}

.fad.fa-page:after, .fa-duotone.fa-page:after {
  content: "";
}

.fad.fa-k:after, .fa-duotone.fa-k:after {
  content: "KK";
}

.fad.fa-diagram-previous:after, .fa-duotone.fa-diagram-previous:after {
  content: "";
}

.fad.fa-gauge-min:after, .fa-duotone.fa-gauge-min:after, .fad.fa-tachometer-alt-slowest:after, .fa-duotone.fa-tachometer-alt-slowest:after {
  content: "";
}

.fad.fa-folder-grid:after, .fa-duotone.fa-folder-grid:after {
  content: "";
}

.fad.fa-eggplant:after, .fa-duotone.fa-eggplant:after {
  content: "";
}

.fad.fa-excavator:after, .fa-duotone.fa-excavator:after {
  content: "";
}

.fad.fa-ram:after, .fa-duotone.fa-ram:after {
  content: "";
}

.fad.fa-landmark-flag:after, .fa-duotone.fa-landmark-flag:after {
  content: "";
}

.fad.fa-lips:after, .fa-duotone.fa-lips:after {
  content: "";
}

.fad.fa-pencil:after, .fa-duotone.fa-pencil:after, .fad.fa-pencil-alt:after, .fa-duotone.fa-pencil-alt:after {
  content: "";
}

.fad.fa-backward:after, .fa-duotone.fa-backward:after {
  content: "";
}

.fad.fa-caret-right:after, .fa-duotone.fa-caret-right:after {
  content: "";
}

.fad.fa-comments:after, .fa-duotone.fa-comments:after {
  content: "";
}

.fad.fa-paste:after, .fa-duotone.fa-paste:after, .fad.fa-file-clipboard:after, .fa-duotone.fa-file-clipboard:after {
  content: "";
}

.fad.fa-desktop-arrow-down:after, .fa-duotone.fa-desktop-arrow-down:after {
  content: "";
}

.fad.fa-code-pull-request:after, .fa-duotone.fa-code-pull-request:after {
  content: "";
}

.fad.fa-pumpkin:after, .fa-duotone.fa-pumpkin:after {
  content: "";
}

.fad.fa-clipboard-list:after, .fa-duotone.fa-clipboard-list:after {
  content: "";
}

.fad.fa-pen-field:after, .fa-duotone.fa-pen-field:after {
  content: "";
}

.fad.fa-blueberries:after, .fa-duotone.fa-blueberries:after {
  content: "";
}

.fad.fa-truck-ramp-box:after, .fa-duotone.fa-truck-ramp-box:after, .fad.fa-truck-loading:after, .fa-duotone.fa-truck-loading:after {
  content: "";
}

.fad.fa-note:after, .fa-duotone.fa-note:after {
  content: "";
}

.fad.fa-arrow-down-to-square:after, .fa-duotone.fa-arrow-down-to-square:after {
  content: "";
}

.fad.fa-user-check:after, .fa-duotone.fa-user-check:after {
  content: "";
}

.fad.fa-cloud-xmark:after, .fa-duotone.fa-cloud-xmark:after {
  content: "";
}

.fad.fa-vial-virus:after, .fa-duotone.fa-vial-virus:after {
  content: "";
}

.fad.fa-book-blank:after, .fa-duotone.fa-book-blank:after, .fad.fa-book-alt:after, .fa-duotone.fa-book-alt:after {
  content: "";
}

.fad.fa-golf-flag-hole:after, .fa-duotone.fa-golf-flag-hole:after {
  content: "";
}

.fad.fa-message-arrow-down:after, .fa-duotone.fa-message-arrow-down:after, .fad.fa-comment-alt-arrow-down:after, .fa-duotone.fa-comment-alt-arrow-down:after {
  content: "";
}

.fad.fa-face-unamused:after, .fa-duotone.fa-face-unamused:after {
  content: "";
}

.fad.fa-sheet-plastic:after, .fa-duotone.fa-sheet-plastic:after {
  content: "";
}

.fad.fa-circle-9:after, .fa-duotone.fa-circle-9:after {
  content: "";
}

.fad.fa-blog:after, .fa-duotone.fa-blog:after {
  content: "";
}

.fad.fa-user-ninja:after, .fa-duotone.fa-user-ninja:after {
  content: "";
}

.fad.fa-pencil-slash:after, .fa-duotone.fa-pencil-slash:after {
  content: "";
}

.fad.fa-bowling-pins:after, .fa-duotone.fa-bowling-pins:after {
  content: "";
}

.fad.fa-person-arrow-up-from-line:after, .fa-duotone.fa-person-arrow-up-from-line:after {
  content: "";
}

.fad.fa-down-right:after, .fa-duotone.fa-down-right:after {
  content: "";
}

.fad.fa-scroll-torah:after, .fa-duotone.fa-scroll-torah:after, .fad.fa-torah:after, .fa-duotone.fa-torah:after {
  content: "";
}

.fad.fa-webhook:after, .fa-duotone.fa-webhook:after {
  content: "";
}

.fad.fa-blinds-open:after, .fa-duotone.fa-blinds-open:after {
  content: "";
}

.fad.fa-fence:after, .fa-duotone.fa-fence:after {
  content: "";
}

.fad.fa-up:after, .fa-duotone.fa-up:after, .fad.fa-arrow-alt-up:after, .fa-duotone.fa-arrow-alt-up:after {
  content: "";
}

.fad.fa-broom-ball:after, .fa-duotone.fa-broom-ball:after, .fad.fa-quidditch:after, .fa-duotone.fa-quidditch:after, .fad.fa-quidditch-broom-ball:after, .fa-duotone.fa-quidditch-broom-ball:after {
  content: "";
}

.fad.fa-drumstick:after, .fa-duotone.fa-drumstick:after {
  content: "";
}

.fad.fa-square-v:after, .fa-duotone.fa-square-v:after {
  content: "";
}

.fad.fa-face-awesome:after, .fa-duotone.fa-face-awesome:after, .fad.fa-gave-dandy:after, .fa-duotone.fa-gave-dandy:after {
  content: "";
}

.fad.fa-dial-off:after, .fa-duotone.fa-dial-off:after {
  content: "";
}

.fad.fa-toggle-off:after, .fa-duotone.fa-toggle-off:after {
  content: "";
}

.fad.fa-face-smile-horns:after, .fa-duotone.fa-face-smile-horns:after {
  content: "";
}

.fad.fa-box-archive:after, .fa-duotone.fa-box-archive:after, .fad.fa-archive:after, .fa-duotone.fa-archive:after {
  content: "";
}

.fad.fa-grapes:after, .fa-duotone.fa-grapes:after {
  content: "";
}

.fad.fa-person-drowning:after, .fa-duotone.fa-person-drowning:after {
  content: "";
}

.fad.fa-dial-max:after, .fa-duotone.fa-dial-max:after {
  content: "";
}

.fad.fa-circle-m:after, .fa-duotone.fa-circle-m:after {
  content: "";
}

.fad.fa-calendar-image:after, .fa-duotone.fa-calendar-image:after {
  content: "";
}

.fad.fa-circle-caret-down:after, .fa-duotone.fa-circle-caret-down:after, .fad.fa-caret-circle-down:after, .fa-duotone.fa-caret-circle-down:after {
  content: "";
}

.fad.fa-arrow-down-9-1:after, .fa-duotone.fa-arrow-down-9-1:after, .fad.fa-sort-numeric-desc:after, .fa-duotone.fa-sort-numeric-desc:after, .fad.fa-sort-numeric-down-alt:after, .fa-duotone.fa-sort-numeric-down-alt:after {
  content: "";
}

.fad.fa-face-grin-tongue-squint:after, .fa-duotone.fa-face-grin-tongue-squint:after, .fad.fa-grin-tongue-squint:after, .fa-duotone.fa-grin-tongue-squint:after {
  content: "";
}

.fad.fa-shish-kebab:after, .fa-duotone.fa-shish-kebab:after {
  content: "";
}

.fad.fa-spray-can:after, .fa-duotone.fa-spray-can:after {
  content: "";
}

.fad.fa-alarm-snooze:after, .fa-duotone.fa-alarm-snooze:after {
  content: "";
}

.fad.fa-scarecrow:after, .fa-duotone.fa-scarecrow:after {
  content: "";
}

.fad.fa-truck-monster:after, .fa-duotone.fa-truck-monster:after {
  content: "";
}

.fad.fa-gift-card:after, .fa-duotone.fa-gift-card:after {
  content: "";
}

.fad.fa-w:after, .fa-duotone.fa-w:after {
  content: "WW";
}

.fad.fa-code-pull-request-draft:after, .fa-duotone.fa-code-pull-request-draft:after {
  content: "";
}

.fad.fa-square-b:after, .fa-duotone.fa-square-b:after {
  content: "";
}

.fad.fa-elephant:after, .fa-duotone.fa-elephant:after {
  content: "";
}

.fad.fa-earth-africa:after, .fa-duotone.fa-earth-africa:after, .fad.fa-globe-africa:after, .fa-duotone.fa-globe-africa:after {
  content: "";
}

.fad.fa-rainbow:after, .fa-duotone.fa-rainbow:after {
  content: "";
}

.fad.fa-circle-notch:after, .fa-duotone.fa-circle-notch:after {
  content: "";
}

.fad.fa-tablet-screen-button:after, .fa-duotone.fa-tablet-screen-button:after, .fad.fa-tablet-alt:after, .fa-duotone.fa-tablet-alt:after {
  content: "";
}

.fad.fa-paw:after, .fa-duotone.fa-paw:after {
  content: "";
}

.fad.fa-message-question:after, .fa-duotone.fa-message-question:after {
  content: "";
}

.fad.fa-cloud:after, .fa-duotone.fa-cloud:after {
  content: "";
}

.fad.fa-trowel-bricks:after, .fa-duotone.fa-trowel-bricks:after {
  content: "";
}

.fad.fa-square-3:after, .fa-duotone.fa-square-3:after {
  content: "";
}

.fad.fa-face-flushed:after, .fa-duotone.fa-face-flushed:after, .fad.fa-flushed:after, .fa-duotone.fa-flushed:after {
  content: "";
}

.fad.fa-hospital-user:after, .fa-duotone.fa-hospital-user:after {
  content: "";
}

.fad.fa-microwave:after, .fa-duotone.fa-microwave:after {
  content: "";
}

.fad.fa-chf-sign:after, .fa-duotone.fa-chf-sign:after {
  content: "";
}

.fad.fa-tent-arrow-left-right:after, .fa-duotone.fa-tent-arrow-left-right:after {
  content: "";
}

.fad.fa-cart-circle-arrow-up:after, .fa-duotone.fa-cart-circle-arrow-up:after {
  content: "";
}

.fad.fa-trash-clock:after, .fa-duotone.fa-trash-clock:after {
  content: "";
}

.fad.fa-reflect-both:after, .fa-duotone.fa-reflect-both:after {
  content: "";
}

.fad.fa-gavel:after, .fa-duotone.fa-gavel:after, .fad.fa-legal:after, .fa-duotone.fa-legal:after {
  content: "";
}

.fad.fa-sprinkler-ceiling:after, .fa-duotone.fa-sprinkler-ceiling:after {
  content: "";
}

.fad.fa-browsers:after, .fa-duotone.fa-browsers:after {
  content: "";
}

.fad.fa-trillium:after, .fa-duotone.fa-trillium:after {
  content: "";
}

.fad.fa-music-slash:after, .fa-duotone.fa-music-slash:after {
  content: "";
}

.fad.fa-truck-ramp:after, .fa-duotone.fa-truck-ramp:after {
  content: "";
}

.fad.fa-binoculars:after, .fa-duotone.fa-binoculars:after {
  content: "";
}

.fad.fa-microphone-slash:after, .fa-duotone.fa-microphone-slash:after {
  content: "";
}

.fad.fa-box-tissue:after, .fa-duotone.fa-box-tissue:after {
  content: "";
}

.fad.fa-circle-c:after, .fa-duotone.fa-circle-c:after {
  content: "";
}

.fad.fa-star-christmas:after, .fa-duotone.fa-star-christmas:after {
  content: "";
}

.fad.fa-chart-bullet:after, .fa-duotone.fa-chart-bullet:after {
  content: "";
}

.fad.fa-motorcycle:after, .fa-duotone.fa-motorcycle:after {
  content: "";
}

.fad.fa-tree-christmas:after, .fa-duotone.fa-tree-christmas:after {
  content: "";
}

.fad.fa-tire-flat:after, .fa-duotone.fa-tire-flat:after {
  content: "";
}

.fad.fa-sunglasses:after, .fa-duotone.fa-sunglasses:after {
  content: "";
}

.fad.fa-badge:after, .fa-duotone.fa-badge:after {
  content: "";
}

.fad.fa-message-pen:after, .fa-duotone.fa-message-pen:after, .fad.fa-comment-alt-edit:after, .fa-duotone.fa-comment-alt-edit:after, .fad.fa-message-edit:after, .fa-duotone.fa-message-edit:after {
  content: "";
}

.fad.fa-bell-concierge:after, .fa-duotone.fa-bell-concierge:after, .fad.fa-concierge-bell:after, .fa-duotone.fa-concierge-bell:after {
  content: "";
}

.fad.fa-pen-ruler:after, .fa-duotone.fa-pen-ruler:after, .fad.fa-pencil-ruler:after, .fa-duotone.fa-pencil-ruler:after {
  content: "";
}

.fad.fa-file-mp3:after, .fa-duotone.fa-file-mp3:after {
  content: "";
}

.fad.fa-arrow-progress:after, .fa-duotone.fa-arrow-progress:after {
  content: "";
}

.fad.fa-chess-rook-piece:after, .fa-duotone.fa-chess-rook-piece:after, .fad.fa-chess-rook-alt:after, .fa-duotone.fa-chess-rook-alt:after {
  content: "";
}

.fad.fa-square-root:after, .fa-duotone.fa-square-root:after {
  content: "";
}

.fad.fa-album-collection-circle-plus:after, .fa-duotone.fa-album-collection-circle-plus:after {
  content: "";
}

.fad.fa-people-arrows:after, .fa-duotone.fa-people-arrows:after, .fad.fa-people-arrows-left-right:after, .fa-duotone.fa-people-arrows-left-right:after {
  content: "";
}

.fad.fa-sign-post:after, .fa-duotone.fa-sign-post:after {
  content: "";
}

.fad.fa-face-angry-horns:after, .fa-duotone.fa-face-angry-horns:after {
  content: "";
}

.fad.fa-mars-and-venus-burst:after, .fa-duotone.fa-mars-and-venus-burst:after {
  content: "";
}

.fad.fa-tombstone:after, .fa-duotone.fa-tombstone:after {
  content: "";
}

.fad.fa-square-caret-right:after, .fa-duotone.fa-square-caret-right:after, .fad.fa-caret-square-right:after, .fa-duotone.fa-caret-square-right:after {
  content: "";
}

.fad.fa-scissors:after, .fa-duotone.fa-scissors:after, .fad.fa-cut:after, .fa-duotone.fa-cut:after {
  content: "";
}

.fad.fa-list-music:after, .fa-duotone.fa-list-music:after {
  content: "";
}

.fad.fa-sun-plant-wilt:after, .fa-duotone.fa-sun-plant-wilt:after {
  content: "";
}

.fad.fa-toilets-portable:after, .fa-duotone.fa-toilets-portable:after {
  content: "";
}

.fad.fa-hockey-puck:after, .fa-duotone.fa-hockey-puck:after {
  content: "";
}

.fad.fa-mustache:after, .fa-duotone.fa-mustache:after {
  content: "";
}

.fad.fa-hyphen:after, .fa-duotone.fa-hyphen:after {
  content: "--";
}

.fad.fa-table:after, .fa-duotone.fa-table:after {
  content: "";
}

.fad.fa-user-chef:after, .fa-duotone.fa-user-chef:after {
  content: "";
}

.fad.fa-message-image:after, .fa-duotone.fa-message-image:after, .fad.fa-comment-alt-image:after, .fa-duotone.fa-comment-alt-image:after {
  content: "";
}

.fad.fa-users-medical:after, .fa-duotone.fa-users-medical:after {
  content: "";
}

.fad.fa-sensor-triangle-exclamation:after, .fa-duotone.fa-sensor-triangle-exclamation:after, .fad.fa-sensor-alert:after, .fa-duotone.fa-sensor-alert:after {
  content: "";
}

.fad.fa-magnifying-glass-arrow-right:after, .fa-duotone.fa-magnifying-glass-arrow-right:after {
  content: "";
}

.fad.fa-tachograph-digital:after, .fa-duotone.fa-tachograph-digital:after, .fad.fa-digital-tachograph:after, .fa-duotone.fa-digital-tachograph:after {
  content: "";
}

.fad.fa-face-mask:after, .fa-duotone.fa-face-mask:after {
  content: "";
}

.fad.fa-pickleball:after, .fa-duotone.fa-pickleball:after {
  content: "";
}

.fad.fa-star-sharp-half:after, .fa-duotone.fa-star-sharp-half:after {
  content: "";
}

.fad.fa-users-slash:after, .fa-duotone.fa-users-slash:after {
  content: "";
}

.fad.fa-clover:after, .fa-duotone.fa-clover:after {
  content: "";
}

.fad.fa-meat:after, .fa-duotone.fa-meat:after {
  content: "";
}

.fad.fa-reply:after, .fa-duotone.fa-reply:after, .fad.fa-mail-reply:after, .fa-duotone.fa-mail-reply:after {
  content: "";
}

.fad.fa-star-and-crescent:after, .fa-duotone.fa-star-and-crescent:after {
  content: "";
}

.fad.fa-empty-set:after, .fa-duotone.fa-empty-set:after {
  content: "";
}

.fad.fa-house-fire:after, .fa-duotone.fa-house-fire:after {
  content: "";
}

.fad.fa-square-minus:after, .fa-duotone.fa-square-minus:after, .fad.fa-minus-square:after, .fa-duotone.fa-minus-square:after {
  content: "";
}

.fad.fa-helicopter:after, .fa-duotone.fa-helicopter:after {
  content: "";
}

.fad.fa-bird:after, .fa-duotone.fa-bird:after {
  content: "";
}

.fad.fa-compass:after, .fa-duotone.fa-compass:after {
  content: "";
}

.fad.fa-square-caret-down:after, .fa-duotone.fa-square-caret-down:after, .fad.fa-caret-square-down:after, .fa-duotone.fa-caret-square-down:after {
  content: "";
}

.fad.fa-heart-half-stroke:after, .fa-duotone.fa-heart-half-stroke:after, .fad.fa-heart-half-alt:after, .fa-duotone.fa-heart-half-alt:after {
  content: "";
}

.fad.fa-file-circle-question:after, .fa-duotone.fa-file-circle-question:after {
  content: "";
}

.fad.fa-truck-utensils:after, .fa-duotone.fa-truck-utensils:after {
  content: "";
}

.fad.fa-laptop-code:after, .fa-duotone.fa-laptop-code:after {
  content: "";
}

.fad.fa-joystick:after, .fa-duotone.fa-joystick:after {
  content: "";
}

.fad.fa-grill-fire:after, .fa-duotone.fa-grill-fire:after {
  content: "";
}

.fad.fa-rectangle-vertical-history:after, .fa-duotone.fa-rectangle-vertical-history:after {
  content: "";
}

.fad.fa-swatchbook:after, .fa-duotone.fa-swatchbook:after {
  content: "";
}

.fad.fa-prescription-bottle:after, .fa-duotone.fa-prescription-bottle:after {
  content: "";
}

.fad.fa-bars:after, .fa-duotone.fa-bars:after, .fad.fa-navicon:after, .fa-duotone.fa-navicon:after {
  content: "";
}

.fad.fa-keyboard-left:after, .fa-duotone.fa-keyboard-left:after {
  content: "";
}

.fad.fa-people-group:after, .fa-duotone.fa-people-group:after {
  content: "";
}

.fad.fa-hourglass-end:after, .fa-duotone.fa-hourglass-end:after, .fad.fa-hourglass-3:after, .fa-duotone.fa-hourglass-3:after {
  content: "";
}

.fad.fa-heart-crack:after, .fa-duotone.fa-heart-crack:after, .fad.fa-heart-broken:after, .fa-duotone.fa-heart-broken:after {
  content: "";
}

.fad.fa-face-beam-hand-over-mouth:after, .fa-duotone.fa-face-beam-hand-over-mouth:after {
  content: "";
}

.fad.fa-droplet-percent:after, .fa-duotone.fa-droplet-percent:after, .fad.fa-humidity:after, .fa-duotone.fa-humidity:after {
  content: "";
}

.fad.fa-square-up-right:after, .fa-duotone.fa-square-up-right:after, .fad.fa-external-link-square-alt:after, .fa-duotone.fa-external-link-square-alt:after {
  content: "";
}

.fad.fa-face-kiss-beam:after, .fa-duotone.fa-face-kiss-beam:after, .fad.fa-kiss-beam:after, .fa-duotone.fa-kiss-beam:after {
  content: "";
}

.fad.fa-corn:after, .fa-duotone.fa-corn:after {
  content: "";
}

.fad.fa-roller-coaster:after, .fa-duotone.fa-roller-coaster:after {
  content: "";
}

.fad.fa-photo-film-music:after, .fa-duotone.fa-photo-film-music:after {
  content: "";
}

.fad.fa-radar:after, .fa-duotone.fa-radar:after {
  content: "";
}

.fad.fa-sickle:after, .fa-duotone.fa-sickle:after {
  content: "";
}

.fad.fa-film:after, .fa-duotone.fa-film:after {
  content: "";
}

.fad.fa-coconut:after, .fa-duotone.fa-coconut:after {
  content: "";
}

.fad.fa-ruler-horizontal:after, .fa-duotone.fa-ruler-horizontal:after {
  content: "";
}

.fad.fa-shield-cross:after, .fa-duotone.fa-shield-cross:after {
  content: "";
}

.fad.fa-cassette-tape:after, .fa-duotone.fa-cassette-tape:after {
  content: "";
}

.fad.fa-square-terminal:after, .fa-duotone.fa-square-terminal:after {
  content: "";
}

.fad.fa-people-robbery:after, .fa-duotone.fa-people-robbery:after {
  content: "";
}

.fad.fa-lightbulb:after, .fa-duotone.fa-lightbulb:after {
  content: "";
}

.fad.fa-caret-left:after, .fa-duotone.fa-caret-left:after {
  content: "";
}

.fad.fa-comment-middle:after, .fa-duotone.fa-comment-middle:after {
  content: "";
}

.fad.fa-trash-can-list:after, .fa-duotone.fa-trash-can-list:after {
  content: "";
}

.fad.fa-block:after, .fa-duotone.fa-block:after {
  content: "";
}

.fad.fa-circle-exclamation:after, .fa-duotone.fa-circle-exclamation:after, .fad.fa-exclamation-circle:after, .fa-duotone.fa-exclamation-circle:after {
  content: "";
}

.fad.fa-school-circle-xmark:after, .fa-duotone.fa-school-circle-xmark:after {
  content: "";
}

.fad.fa-arrow-right-from-bracket:after, .fa-duotone.fa-arrow-right-from-bracket:after, .fad.fa-sign-out:after, .fa-duotone.fa-sign-out:after {
  content: "";
}

.fad.fa-face-frown-slight:after, .fa-duotone.fa-face-frown-slight:after {
  content: "";
}

.fad.fa-circle-chevron-down:after, .fa-duotone.fa-circle-chevron-down:after, .fad.fa-chevron-circle-down:after, .fa-duotone.fa-chevron-circle-down:after {
  content: "";
}

.fad.fa-sidebar-flip:after, .fa-duotone.fa-sidebar-flip:after {
  content: "";
}

.fad.fa-unlock-keyhole:after, .fa-duotone.fa-unlock-keyhole:after, .fad.fa-unlock-alt:after, .fa-duotone.fa-unlock-alt:after {
  content: "";
}

.fad.fa-temperature-list:after, .fa-duotone.fa-temperature-list:after {
  content: "";
}

.fad.fa-cloud-showers-heavy:after, .fa-duotone.fa-cloud-showers-heavy:after {
  content: "";
}

.fad.fa-headphones-simple:after, .fa-duotone.fa-headphones-simple:after, .fad.fa-headphones-alt:after, .fa-duotone.fa-headphones-alt:after {
  content: "";
}

.fad.fa-sitemap:after, .fa-duotone.fa-sitemap:after {
  content: "";
}

.fad.fa-pipe-section:after, .fa-duotone.fa-pipe-section:after {
  content: "";
}

.fad.fa-space-station-moon-construction:after, .fa-duotone.fa-space-station-moon-construction:after, .fad.fa-space-station-moon-alt:after, .fa-duotone.fa-space-station-moon-alt:after {
  content: "";
}

.fad.fa-circle-dollar-to-slot:after, .fa-duotone.fa-circle-dollar-to-slot:after, .fad.fa-donate:after, .fa-duotone.fa-donate:after {
  content: "";
}

.fad.fa-memory:after, .fa-duotone.fa-memory:after {
  content: "";
}

.fad.fa-face-sleeping:after, .fa-duotone.fa-face-sleeping:after {
  content: "";
}

.fad.fa-road-spikes:after, .fa-duotone.fa-road-spikes:after {
  content: "";
}

.fad.fa-fire-burner:after, .fa-duotone.fa-fire-burner:after {
  content: "";
}

.fad.fa-squirrel:after, .fa-duotone.fa-squirrel:after {
  content: "";
}

.fad.fa-arrow-up-to-line:after, .fa-duotone.fa-arrow-up-to-line:after, .fad.fa-arrow-to-top:after, .fa-duotone.fa-arrow-to-top:after {
  content: "";
}

.fad.fa-flag:after, .fa-duotone.fa-flag:after {
  content: "";
}

.fad.fa-face-cowboy-hat:after, .fa-duotone.fa-face-cowboy-hat:after {
  content: "";
}

.fad.fa-hanukiah:after, .fa-duotone.fa-hanukiah:after {
  content: "";
}

.fad.fa-chart-scatter-3d:after, .fa-duotone.fa-chart-scatter-3d:after {
  content: "";
}

.fad.fa-display-chart-up:after, .fa-duotone.fa-display-chart-up:after {
  content: "";
}

.fad.fa-square-code:after, .fa-duotone.fa-square-code:after {
  content: "";
}

.fad.fa-feather:after, .fa-duotone.fa-feather:after {
  content: "";
}

.fad.fa-volume-low:after, .fa-duotone.fa-volume-low:after, .fad.fa-volume-down:after, .fa-duotone.fa-volume-down:after {
  content: "";
}

.fad.fa-xmark-to-slot:after, .fa-duotone.fa-xmark-to-slot:after, .fad.fa-times-to-slot:after, .fa-duotone.fa-times-to-slot:after, .fad.fa-vote-nay:after, .fa-duotone.fa-vote-nay:after {
  content: "";
}

.fad.fa-box-taped:after, .fa-duotone.fa-box-taped:after, .fad.fa-box-alt:after, .fa-duotone.fa-box-alt:after {
  content: "";
}

.fad.fa-comment-slash:after, .fa-duotone.fa-comment-slash:after {
  content: "";
}

.fad.fa-swords:after, .fa-duotone.fa-swords:after {
  content: "";
}

.fad.fa-cloud-sun-rain:after, .fa-duotone.fa-cloud-sun-rain:after {
  content: "";
}

.fad.fa-album:after, .fa-duotone.fa-album:after {
  content: "";
}

.fad.fa-circle-n:after, .fa-duotone.fa-circle-n:after {
  content: "";
}

.fad.fa-compress:after, .fa-duotone.fa-compress:after {
  content: "";
}

.fad.fa-wheat-awn:after, .fa-duotone.fa-wheat-awn:after, .fad.fa-wheat-alt:after, .fa-duotone.fa-wheat-alt:after {
  content: "";
}

.fad.fa-ankh:after, .fa-duotone.fa-ankh:after {
  content: "";
}

.fad.fa-hands-holding-child:after, .fa-duotone.fa-hands-holding-child:after {
  content: "";
}

.fad.fa-asterisk:after, .fa-duotone.fa-asterisk:after {
  content: "**";
}

.fad.fa-key-skeleton-left-right:after, .fa-duotone.fa-key-skeleton-left-right:after {
  content: "";
}

.fad.fa-comment-lines:after, .fa-duotone.fa-comment-lines:after {
  content: "";
}

.fad.fa-luchador-mask:after, .fa-duotone.fa-luchador-mask:after, .fad.fa-luchador:after, .fa-duotone.fa-luchador:after, .fad.fa-mask-luchador:after, .fa-duotone.fa-mask-luchador:after {
  content: "";
}

.fad.fa-square-check:after, .fa-duotone.fa-square-check:after, .fad.fa-check-square:after, .fa-duotone.fa-check-square:after {
  content: "";
}

.fad.fa-shredder:after, .fa-duotone.fa-shredder:after {
  content: "";
}

.fad.fa-book-open-cover:after, .fa-duotone.fa-book-open-cover:after, .fad.fa-book-open-alt:after, .fa-duotone.fa-book-open-alt:after {
  content: "";
}

.fad.fa-sandwich:after, .fa-duotone.fa-sandwich:after {
  content: "";
}

.fad.fa-peseta-sign:after, .fa-duotone.fa-peseta-sign:after {
  content: "";
}

.fad.fa-square-parking-slash:after, .fa-duotone.fa-square-parking-slash:after, .fad.fa-parking-slash:after, .fa-duotone.fa-parking-slash:after {
  content: "";
}

.fad.fa-train-tunnel:after, .fa-duotone.fa-train-tunnel:after {
  content: "";
}

.fad.fa-heading:after, .fa-duotone.fa-heading:after, .fad.fa-header:after, .fa-duotone.fa-header:after {
  content: "";
}

.fad.fa-ghost:after, .fa-duotone.fa-ghost:after {
  content: "";
}

.fad.fa-face-anguished:after, .fa-duotone.fa-face-anguished:after {
  content: "";
}

.fad.fa-hockey-sticks:after, .fa-duotone.fa-hockey-sticks:after {
  content: "";
}

.fad.fa-abacus:after, .fa-duotone.fa-abacus:after {
  content: "";
}

.fad.fa-film-simple:after, .fa-duotone.fa-film-simple:after, .fad.fa-film-alt:after, .fa-duotone.fa-film-alt:after {
  content: "";
}

.fad.fa-list:after, .fa-duotone.fa-list:after, .fad.fa-list-squares:after, .fa-duotone.fa-list-squares:after {
  content: "";
}

.fad.fa-tree-palm:after, .fa-duotone.fa-tree-palm:after {
  content: "";
}

.fad.fa-square-phone-flip:after, .fa-duotone.fa-square-phone-flip:after, .fad.fa-phone-square-alt:after, .fa-duotone.fa-phone-square-alt:after {
  content: "";
}

.fad.fa-cart-plus:after, .fa-duotone.fa-cart-plus:after {
  content: "";
}

.fad.fa-gamepad:after, .fa-duotone.fa-gamepad:after {
  content: "";
}

.fad.fa-border-center-v:after, .fa-duotone.fa-border-center-v:after {
  content: "";
}

.fad.fa-circle-dot:after, .fa-duotone.fa-circle-dot:after, .fad.fa-dot-circle:after, .fa-duotone.fa-dot-circle:after {
  content: "";
}

.fad.fa-clipboard-medical:after, .fa-duotone.fa-clipboard-medical:after {
  content: "";
}

.fad.fa-face-dizzy:after, .fa-duotone.fa-face-dizzy:after, .fad.fa-dizzy:after, .fa-duotone.fa-dizzy:after {
  content: "";
}

.fad.fa-egg:after, .fa-duotone.fa-egg:after {
  content: "";
}

.fad.fa-up-to-line:after, .fa-duotone.fa-up-to-line:after, .fad.fa-arrow-alt-to-top:after, .fa-duotone.fa-arrow-alt-to-top:after {
  content: "";
}

.fad.fa-house-medical-circle-xmark:after, .fa-duotone.fa-house-medical-circle-xmark:after {
  content: "";
}

.fad.fa-watch-fitness:after, .fa-duotone.fa-watch-fitness:after {
  content: "";
}

.fad.fa-clock-nine-thirty:after, .fa-duotone.fa-clock-nine-thirty:after {
  content: "";
}

.fad.fa-campground:after, .fa-duotone.fa-campground:after {
  content: "";
}

.fad.fa-folder-plus:after, .fa-duotone.fa-folder-plus:after {
  content: "";
}

.fad.fa-jug:after, .fa-duotone.fa-jug:after {
  content: "";
}

.fad.fa-futbol:after, .fa-duotone.fa-futbol:after, .fad.fa-futbol-ball:after, .fa-duotone.fa-futbol-ball:after, .fad.fa-soccer-ball:after, .fa-duotone.fa-soccer-ball:after {
  content: "";
}

.fad.fa-snow-blowing:after, .fa-duotone.fa-snow-blowing:after {
  content: "";
}

.fad.fa-paintbrush:after, .fa-duotone.fa-paintbrush:after, .fad.fa-paint-brush:after, .fa-duotone.fa-paint-brush:after {
  content: "";
}

.fad.fa-lock:after, .fa-duotone.fa-lock:after {
  content: "";
}

.fad.fa-arrow-down-from-line:after, .fa-duotone.fa-arrow-down-from-line:after, .fad.fa-arrow-from-top:after, .fa-duotone.fa-arrow-from-top:after {
  content: "";
}

.fad.fa-gas-pump:after, .fa-duotone.fa-gas-pump:after {
  content: "";
}

.fad.fa-signal-bars-slash:after, .fa-duotone.fa-signal-bars-slash:after, .fad.fa-signal-alt-slash:after, .fa-duotone.fa-signal-alt-slash:after {
  content: "";
}

.fad.fa-monkey:after, .fa-duotone.fa-monkey:after {
  content: "";
}

.fad.fa-rectangle-pro:after, .fa-duotone.fa-rectangle-pro:after, .fad.fa-pro:after, .fa-duotone.fa-pro:after {
  content: "";
}

.fad.fa-house-night:after, .fa-duotone.fa-house-night:after {
  content: "";
}

.fad.fa-hot-tub-person:after, .fa-duotone.fa-hot-tub-person:after, .fad.fa-hot-tub:after, .fa-duotone.fa-hot-tub:after {
  content: "";
}

.fad.fa-globe-pointer:after, .fa-duotone.fa-globe-pointer:after {
  content: "";
}

.fad.fa-blanket:after, .fa-duotone.fa-blanket:after {
  content: "";
}

.fad.fa-map-location:after, .fa-duotone.fa-map-location:after, .fad.fa-map-marked:after, .fa-duotone.fa-map-marked:after {
  content: "";
}

.fad.fa-house-flood-water:after, .fa-duotone.fa-house-flood-water:after {
  content: "";
}

.fad.fa-comments-question-check:after, .fa-duotone.fa-comments-question-check:after {
  content: "";
}

.fad.fa-tree:after, .fa-duotone.fa-tree:after {
  content: "";
}

.fad.fa-arrows-cross:after, .fa-duotone.fa-arrows-cross:after {
  content: "";
}

.fad.fa-backpack:after, .fa-duotone.fa-backpack:after {
  content: "";
}

.fad.fa-square-small:after, .fa-duotone.fa-square-small:after {
  content: "";
}

.fad.fa-folder-arrow-up:after, .fa-duotone.fa-folder-arrow-up:after, .fad.fa-folder-upload:after, .fa-duotone.fa-folder-upload:after {
  content: "";
}

.fad.fa-bridge-lock:after, .fa-duotone.fa-bridge-lock:after {
  content: "";
}

.fad.fa-crosshairs-simple:after, .fa-duotone.fa-crosshairs-simple:after {
  content: "";
}

.fad.fa-sack-dollar:after, .fa-duotone.fa-sack-dollar:after {
  content: "";
}

.fad.fa-pen-to-square:after, .fa-duotone.fa-pen-to-square:after, .fad.fa-edit:after, .fa-duotone.fa-edit:after {
  content: "";
}

.fad.fa-square-sliders:after, .fa-duotone.fa-square-sliders:after, .fad.fa-sliders-h-square:after, .fa-duotone.fa-sliders-h-square:after {
  content: "";
}

.fad.fa-car-side:after, .fa-duotone.fa-car-side:after {
  content: "";
}

.fad.fa-message-middle-top:after, .fa-duotone.fa-message-middle-top:after, .fad.fa-comment-middle-top-alt:after, .fa-duotone.fa-comment-middle-top-alt:after {
  content: "";
}

.fad.fa-lightbulb-on:after, .fa-duotone.fa-lightbulb-on:after {
  content: "";
}

.fad.fa-knife:after, .fa-duotone.fa-knife:after, .fad.fa-utensil-knife:after, .fa-duotone.fa-utensil-knife:after {
  content: "";
}

.fad.fa-share-nodes:after, .fa-duotone.fa-share-nodes:after, .fad.fa-share-alt:after, .fa-duotone.fa-share-alt:after {
  content: "";
}

.fad.fa-display-chart-up-circle-dollar:after, .fa-duotone.fa-display-chart-up-circle-dollar:after {
  content: "";
}

.fad.fa-wave-sine:after, .fa-duotone.fa-wave-sine:after {
  content: "";
}

.fad.fa-heart-circle-minus:after, .fa-duotone.fa-heart-circle-minus:after {
  content: "";
}

.fad.fa-circle-w:after, .fa-duotone.fa-circle-w:after {
  content: "";
}

.fad.fa-circle-calendar:after, .fa-duotone.fa-circle-calendar:after, .fad.fa-calendar-circle:after, .fa-duotone.fa-calendar-circle:after {
  content: "";
}

.fad.fa-hourglass-half:after, .fa-duotone.fa-hourglass-half:after, .fad.fa-hourglass-2:after, .fa-duotone.fa-hourglass-2:after {
  content: "";
}

.fad.fa-microscope:after, .fa-duotone.fa-microscope:after {
  content: "";
}

.fad.fa-sunset:after, .fa-duotone.fa-sunset:after {
  content: "";
}

.fad.fa-sink:after, .fa-duotone.fa-sink:after {
  content: "";
}

.fad.fa-calendar-exclamation:after, .fa-duotone.fa-calendar-exclamation:after {
  content: "";
}

.fad.fa-truck-container-empty:after, .fa-duotone.fa-truck-container-empty:after {
  content: "";
}

.fad.fa-hand-heart:after, .fa-duotone.fa-hand-heart:after {
  content: "";
}

.fad.fa-bag-shopping:after, .fa-duotone.fa-bag-shopping:after, .fad.fa-shopping-bag:after, .fa-duotone.fa-shopping-bag:after {
  content: "";
}

.fad.fa-arrow-down-z-a:after, .fa-duotone.fa-arrow-down-z-a:after, .fad.fa-sort-alpha-desc:after, .fa-duotone.fa-sort-alpha-desc:after, .fad.fa-sort-alpha-down-alt:after, .fa-duotone.fa-sort-alpha-down-alt:after {
  content: "";
}

.fad.fa-mitten:after, .fa-duotone.fa-mitten:after {
  content: "";
}

.fad.fa-reply-clock:after, .fa-duotone.fa-reply-clock:after, .fad.fa-reply-time:after, .fa-duotone.fa-reply-time:after {
  content: "";
}

.fad.fa-person-rays:after, .fa-duotone.fa-person-rays:after {
  content: "";
}

.fad.fa-right:after, .fa-duotone.fa-right:after, .fad.fa-arrow-alt-right:after, .fa-duotone.fa-arrow-alt-right:after {
  content: "";
}

.fad.fa-circle-f:after, .fa-duotone.fa-circle-f:after {
  content: "";
}

.fad.fa-users:after, .fa-duotone.fa-users:after {
  content: "";
}

.fad.fa-face-pleading:after, .fa-duotone.fa-face-pleading:after {
  content: "";
}

.fad.fa-eye-slash:after, .fa-duotone.fa-eye-slash:after {
  content: "";
}

.fad.fa-flask-vial:after, .fa-duotone.fa-flask-vial:after {
  content: "";
}

.fad.fa-police-box:after, .fa-duotone.fa-police-box:after {
  content: "";
}

.fad.fa-cucumber:after, .fa-duotone.fa-cucumber:after {
  content: "";
}

.fad.fa-head-side-brain:after, .fa-duotone.fa-head-side-brain:after {
  content: "";
}

.fad.fa-hand:after, .fa-duotone.fa-hand:after, .fad.fa-hand-paper:after, .fa-duotone.fa-hand-paper:after {
  content: "";
}

.fad.fa-person-biking-mountain:after, .fa-duotone.fa-person-biking-mountain:after, .fad.fa-biking-mountain:after, .fa-duotone.fa-biking-mountain:after {
  content: "";
}

.fad.fa-utensils-slash:after, .fa-duotone.fa-utensils-slash:after {
  content: "";
}

.fad.fa-print-magnifying-glass:after, .fa-duotone.fa-print-magnifying-glass:after, .fad.fa-print-search:after, .fa-duotone.fa-print-search:after {
  content: "";
}

.fad.fa-turn-right:after, .fa-duotone.fa-turn-right:after {
  content: "";
}

.fad.fa-folder-bookmark:after, .fa-duotone.fa-folder-bookmark:after {
  content: "";
}

.fad.fa-arrow-turn-left-down:after, .fa-duotone.fa-arrow-turn-left-down:after {
  content: "";
}

.fad.fa-om:after, .fa-duotone.fa-om:after {
  content: "";
}

.fad.fa-pi:after, .fa-duotone.fa-pi:after {
  content: "";
}

.fad.fa-flask-round-potion:after, .fa-duotone.fa-flask-round-potion:after, .fad.fa-flask-potion:after, .fa-duotone.fa-flask-potion:after {
  content: "";
}

.fad.fa-face-shush:after, .fa-duotone.fa-face-shush:after {
  content: "";
}

.fad.fa-worm:after, .fa-duotone.fa-worm:after {
  content: "";
}

.fad.fa-house-circle-xmark:after, .fa-duotone.fa-house-circle-xmark:after {
  content: "";
}

.fad.fa-plug:after, .fa-duotone.fa-plug:after {
  content: "";
}

.fad.fa-calendar-circle-exclamation:after, .fa-duotone.fa-calendar-circle-exclamation:after {
  content: "";
}

.fad.fa-square-i:after, .fa-duotone.fa-square-i:after {
  content: "";
}

.fad.fa-chevron-up:after, .fa-duotone.fa-chevron-up:after {
  content: "";
}

.fad.fa-face-saluting:after, .fa-duotone.fa-face-saluting:after {
  content: "";
}

.fad.fa-gauge-simple-low:after, .fa-duotone.fa-gauge-simple-low:after, .fad.fa-tachometer-slow:after, .fa-duotone.fa-tachometer-slow:after {
  content: "";
}

.fad.fa-face-persevering:after, .fa-duotone.fa-face-persevering:after {
  content: "";
}

.fad.fa-circle-camera:after, .fa-duotone.fa-circle-camera:after, .fad.fa-camera-circle:after, .fa-duotone.fa-camera-circle:after {
  content: "";
}

.fad.fa-hand-spock:after, .fa-duotone.fa-hand-spock:after {
  content: "";
}

.fad.fa-spider-web:after, .fa-duotone.fa-spider-web:after {
  content: "";
}

.fad.fa-circle-microphone:after, .fa-duotone.fa-circle-microphone:after, .fad.fa-microphone-circle:after, .fa-duotone.fa-microphone-circle:after {
  content: "";
}

.fad.fa-book-arrow-up:after, .fa-duotone.fa-book-arrow-up:after {
  content: "";
}

.fad.fa-popsicle:after, .fa-duotone.fa-popsicle:after {
  content: "";
}

.fad.fa-command:after, .fa-duotone.fa-command:after {
  content: "";
}

.fad.fa-blinds:after, .fa-duotone.fa-blinds:after {
  content: "";
}

.fad.fa-stopwatch:after, .fa-duotone.fa-stopwatch:after {
  content: "";
}

.fad.fa-saxophone:after, .fa-duotone.fa-saxophone:after {
  content: "";
}

.fad.fa-square-2:after, .fa-duotone.fa-square-2:after {
  content: "";
}

.fad.fa-field-hockey-stick-ball:after, .fa-duotone.fa-field-hockey-stick-ball:after, .fad.fa-field-hockey:after, .fa-duotone.fa-field-hockey:after {
  content: "";
}

.fad.fa-arrow-up-square-triangle:after, .fa-duotone.fa-arrow-up-square-triangle:after, .fad.fa-sort-shapes-up-alt:after, .fa-duotone.fa-sort-shapes-up-alt:after {
  content: "";
}

.fad.fa-face-scream:after, .fa-duotone.fa-face-scream:after {
  content: "";
}

.fad.fa-square-m:after, .fa-duotone.fa-square-m:after {
  content: "";
}

.fad.fa-camera-web:after, .fa-duotone.fa-camera-web:after, .fad.fa-webcam:after, .fa-duotone.fa-webcam:after {
  content: "";
}

.fad.fa-comment-arrow-down:after, .fa-duotone.fa-comment-arrow-down:after {
  content: "";
}

.fad.fa-lightbulb-cfl:after, .fa-duotone.fa-lightbulb-cfl:after {
  content: "";
}

.fad.fa-window-frame-open:after, .fa-duotone.fa-window-frame-open:after {
  content: "";
}

.fad.fa-face-kiss:after, .fa-duotone.fa-face-kiss:after, .fad.fa-kiss:after, .fa-duotone.fa-kiss:after {
  content: "";
}

.fad.fa-bridge-circle-xmark:after, .fa-duotone.fa-bridge-circle-xmark:after {
  content: "";
}

.fad.fa-period:after, .fa-duotone.fa-period:after {
  content: "..";
}

.fad.fa-face-grin-tongue:after, .fa-duotone.fa-face-grin-tongue:after, .fad.fa-grin-tongue:after, .fa-duotone.fa-grin-tongue:after {
  content: "";
}

.fad.fa-up-to-dotted-line:after, .fa-duotone.fa-up-to-dotted-line:after {
  content: "";
}

.fad.fa-thought-bubble:after, .fa-duotone.fa-thought-bubble:after {
  content: "";
}

.fad.fa-skeleton-ribs:after, .fa-duotone.fa-skeleton-ribs:after {
  content: "";
}

.fad.fa-raygun:after, .fa-duotone.fa-raygun:after {
  content: "";
}

.fad.fa-flute:after, .fa-duotone.fa-flute:after {
  content: "";
}

.fad.fa-acorn:after, .fa-duotone.fa-acorn:after {
  content: "";
}

.fad.fa-video-arrow-up-right:after, .fa-duotone.fa-video-arrow-up-right:after {
  content: "";
}

.fad.fa-grate-droplet:after, .fa-duotone.fa-grate-droplet:after {
  content: "";
}

.fad.fa-seal-exclamation:after, .fa-duotone.fa-seal-exclamation:after {
  content: "";
}

.fad.fa-chess-bishop:after, .fa-duotone.fa-chess-bishop:after {
  content: "";
}

.fad.fa-message-sms:after, .fa-duotone.fa-message-sms:after {
  content: "";
}

.fad.fa-coffee-beans:after, .fa-duotone.fa-coffee-beans:after {
  content: "";
}

.fad.fa-hat-witch:after, .fa-duotone.fa-hat-witch:after {
  content: "";
}

.fad.fa-face-grin-wink:after, .fa-duotone.fa-face-grin-wink:after, .fad.fa-grin-wink:after, .fa-duotone.fa-grin-wink:after {
  content: "";
}

.fad.fa-clock-three-thirty:after, .fa-duotone.fa-clock-three-thirty:after {
  content: "";
}

.fad.fa-ear-deaf:after, .fa-duotone.fa-ear-deaf:after, .fad.fa-deaf:after, .fa-duotone.fa-deaf:after, .fad.fa-deafness:after, .fa-duotone.fa-deafness:after, .fad.fa-hard-of-hearing:after, .fa-duotone.fa-hard-of-hearing:after {
  content: "";
}

.fad.fa-alarm-clock:after, .fa-duotone.fa-alarm-clock:after {
  content: "";
}

.fad.fa-eclipse:after, .fa-duotone.fa-eclipse:after {
  content: "";
}

.fad.fa-face-relieved:after, .fa-duotone.fa-face-relieved:after {
  content: "";
}

.fad.fa-road-circle-check:after, .fa-duotone.fa-road-circle-check:after {
  content: "";
}

.fad.fa-dice-five:after, .fa-duotone.fa-dice-five:after {
  content: "";
}

.fad.fa-octagon-minus:after, .fa-duotone.fa-octagon-minus:after, .fad.fa-minus-octagon:after, .fa-duotone.fa-minus-octagon:after {
  content: "";
}

.fad.fa-square-rss:after, .fa-duotone.fa-square-rss:after, .fad.fa-rss-square:after, .fa-duotone.fa-rss-square:after {
  content: "";
}

.fad.fa-face-zany:after, .fa-duotone.fa-face-zany:after {
  content: "";
}

.fad.fa-tricycle:after, .fa-duotone.fa-tricycle:after {
  content: "";
}

.fad.fa-land-mine-on:after, .fa-duotone.fa-land-mine-on:after {
  content: "";
}

.fad.fa-square-arrow-up-left:after, .fa-duotone.fa-square-arrow-up-left:after {
  content: "";
}

.fad.fa-i-cursor:after, .fa-duotone.fa-i-cursor:after {
  content: "";
}

.fad.fa-chart-mixed-up-circle-dollar:after, .fa-duotone.fa-chart-mixed-up-circle-dollar:after {
  content: "";
}

.fad.fa-salt-shaker:after, .fa-duotone.fa-salt-shaker:after {
  content: "";
}

.fad.fa-stamp:after, .fa-duotone.fa-stamp:after {
  content: "";
}

.fad.fa-file-plus:after, .fa-duotone.fa-file-plus:after {
  content: "";
}

.fad.fa-draw-square:after, .fa-duotone.fa-draw-square:after {
  content: "";
}

.fad.fa-toilet-paper-under-slash:after, .fa-duotone.fa-toilet-paper-under-slash:after, .fad.fa-toilet-paper-reverse-slash:after, .fa-duotone.fa-toilet-paper-reverse-slash:after {
  content: "";
}

.fad.fa-stairs:after, .fa-duotone.fa-stairs:after {
  content: "";
}

.fad.fa-drone-front:after, .fa-duotone.fa-drone-front:after, .fad.fa-drone-alt:after, .fa-duotone.fa-drone-alt:after {
  content: "";
}

.fad.fa-glass-empty:after, .fa-duotone.fa-glass-empty:after {
  content: "";
}

.fad.fa-dial-high:after, .fa-duotone.fa-dial-high:after {
  content: "";
}

.fad.fa-user-helmet-safety:after, .fa-duotone.fa-user-helmet-safety:after, .fad.fa-user-construction:after, .fa-duotone.fa-user-construction:after, .fad.fa-user-hard-hat:after, .fa-duotone.fa-user-hard-hat:after {
  content: "";
}

.fad.fa-i:after, .fa-duotone.fa-i:after {
  content: "II";
}

.fad.fa-hryvnia-sign:after, .fa-duotone.fa-hryvnia-sign:after, .fad.fa-hryvnia:after, .fa-duotone.fa-hryvnia:after {
  content: "";
}

.fad.fa-arrow-down-left-and-arrow-up-right-to-center:after, .fa-duotone.fa-arrow-down-left-and-arrow-up-right-to-center:after {
  content: "";
}

.fad.fa-pills:after, .fa-duotone.fa-pills:after {
  content: "";
}

.fad.fa-face-grin-wide:after, .fa-duotone.fa-face-grin-wide:after, .fad.fa-grin-alt:after, .fa-duotone.fa-grin-alt:after {
  content: "";
}

.fad.fa-tooth:after, .fa-duotone.fa-tooth:after {
  content: "";
}

.fad.fa-basketball-hoop:after, .fa-duotone.fa-basketball-hoop:after {
  content: "";
}

.fad.fa-objects-align-bottom:after, .fa-duotone.fa-objects-align-bottom:after {
  content: "";
}

.fad.fa-v:after, .fa-duotone.fa-v:after {
  content: "VV";
}

.fad.fa-sparkles:after, .fa-duotone.fa-sparkles:after {
  content: "";
}

.fad.fa-squid:after, .fa-duotone.fa-squid:after {
  content: "";
}

.fad.fa-leafy-green:after, .fa-duotone.fa-leafy-green:after {
  content: "";
}

.fad.fa-circle-arrow-up-right:after, .fa-duotone.fa-circle-arrow-up-right:after {
  content: "";
}

.fad.fa-calendars:after, .fa-duotone.fa-calendars:after {
  content: "";
}

.fad.fa-bangladeshi-taka-sign:after, .fa-duotone.fa-bangladeshi-taka-sign:after {
  content: "";
}

.fad.fa-bicycle:after, .fa-duotone.fa-bicycle:after {
  content: "";
}

.fad.fa-hammer-war:after, .fa-duotone.fa-hammer-war:after {
  content: "";
}

.fad.fa-circle-d:after, .fa-duotone.fa-circle-d:after {
  content: "";
}

.fad.fa-spider-black-widow:after, .fa-duotone.fa-spider-black-widow:after {
  content: "";
}

.fad.fa-staff-snake:after, .fa-duotone.fa-staff-snake:after, .fad.fa-rod-asclepius:after, .fa-duotone.fa-rod-asclepius:after, .fad.fa-rod-snake:after, .fa-duotone.fa-rod-snake:after, .fad.fa-staff-aesculapius:after, .fa-duotone.fa-staff-aesculapius:after {
  content: "";
}

.fad.fa-pear:after, .fa-duotone.fa-pear:after {
  content: "";
}

.fad.fa-head-side-cough-slash:after, .fa-duotone.fa-head-side-cough-slash:after {
  content: "";
}

.fad.fa-file-mov:after, .fa-duotone.fa-file-mov:after {
  content: "";
}

.fad.fa-triangle:after, .fa-duotone.fa-triangle:after {
  content: "";
}

.fad.fa-apartment:after, .fa-duotone.fa-apartment:after {
  content: "";
}

.fad.fa-truck-medical:after, .fa-duotone.fa-truck-medical:after, .fad.fa-ambulance:after, .fa-duotone.fa-ambulance:after {
  content: "";
}

.fad.fa-pepper:after, .fa-duotone.fa-pepper:after {
  content: "";
}

.fad.fa-piano:after, .fa-duotone.fa-piano:after {
  content: "";
}

.fad.fa-gun-squirt:after, .fa-duotone.fa-gun-squirt:after {
  content: "";
}

.fad.fa-wheat-awn-circle-exclamation:after, .fa-duotone.fa-wheat-awn-circle-exclamation:after {
  content: "";
}

.fad.fa-snowman:after, .fa-duotone.fa-snowman:after {
  content: "";
}

.fad.fa-user-alien:after, .fa-duotone.fa-user-alien:after {
  content: "";
}

.fad.fa-shield-check:after, .fa-duotone.fa-shield-check:after {
  content: "";
}

.fad.fa-mortar-pestle:after, .fa-duotone.fa-mortar-pestle:after {
  content: "";
}

.fad.fa-road-barrier:after, .fa-duotone.fa-road-barrier:after {
  content: "";
}

.fad.fa-chart-candlestick:after, .fa-duotone.fa-chart-candlestick:after {
  content: "";
}

.fad.fa-briefcase-blank:after, .fa-duotone.fa-briefcase-blank:after {
  content: "";
}

.fad.fa-school:after, .fa-duotone.fa-school:after {
  content: "";
}

.fad.fa-igloo:after, .fa-duotone.fa-igloo:after {
  content: "";
}

.fad.fa-bracket-round:after, .fa-duotone.fa-bracket-round:after, .fad.fa-parenthesis:after, .fa-duotone.fa-parenthesis:after {
  content: "((";
}

.fad.fa-joint:after, .fa-duotone.fa-joint:after {
  content: "";
}

.fad.fa-horse-saddle:after, .fa-duotone.fa-horse-saddle:after {
  content: "";
}

.fad.fa-mug-marshmallows:after, .fa-duotone.fa-mug-marshmallows:after {
  content: "";
}

.fad.fa-filters:after, .fa-duotone.fa-filters:after {
  content: "";
}

.fad.fa-bell-on:after, .fa-duotone.fa-bell-on:after {
  content: "";
}

.fad.fa-angle-right:after, .fa-duotone.fa-angle-right:after {
  content: "";
}

.fad.fa-dial-med:after, .fa-duotone.fa-dial-med:after {
  content: "";
}

.fad.fa-horse:after, .fa-duotone.fa-horse:after {
  content: "";
}

.fad.fa-q:after, .fa-duotone.fa-q:after {
  content: "QQ";
}

.fad.fa-monitor-waveform:after, .fa-duotone.fa-monitor-waveform:after, .fad.fa-monitor-heart-rate:after, .fa-duotone.fa-monitor-heart-rate:after {
  content: "";
}

.fad.fa-link-simple:after, .fa-duotone.fa-link-simple:after {
  content: "";
}

.fad.fa-whistle:after, .fa-duotone.fa-whistle:after {
  content: "";
}

.fad.fa-g:after, .fa-duotone.fa-g:after {
  content: "GG";
}

.fad.fa-wine-glass-crack:after, .fa-duotone.fa-wine-glass-crack:after, .fad.fa-fragile:after, .fa-duotone.fa-fragile:after {
  content: "";
}

.fad.fa-slot-machine:after, .fa-duotone.fa-slot-machine:after {
  content: "";
}

.fad.fa-notes-medical:after, .fa-duotone.fa-notes-medical:after {
  content: "";
}

.fad.fa-car-wash:after, .fa-duotone.fa-car-wash:after {
  content: "";
}

.fad.fa-escalator:after, .fa-duotone.fa-escalator:after {
  content: "";
}

.fad.fa-comment-image:after, .fa-duotone.fa-comment-image:after {
  content: "";
}

.fad.fa-temperature-half:after, .fa-duotone.fa-temperature-half:after, .fad.fa-temperature-2:after, .fa-duotone.fa-temperature-2:after, .fad.fa-thermometer-2:after, .fa-duotone.fa-thermometer-2:after, .fad.fa-thermometer-half:after, .fa-duotone.fa-thermometer-half:after {
  content: "";
}

.fad.fa-dong-sign:after, .fa-duotone.fa-dong-sign:after {
  content: "";
}

.fad.fa-donut:after, .fa-duotone.fa-donut:after, .fad.fa-doughnut:after, .fa-duotone.fa-doughnut:after {
  content: "";
}

.fad.fa-capsules:after, .fa-duotone.fa-capsules:after {
  content: "";
}

.fad.fa-poo-storm:after, .fa-duotone.fa-poo-storm:after, .fad.fa-poo-bolt:after, .fa-duotone.fa-poo-bolt:after {
  content: "";
}

.fad.fa-tally-1:after, .fa-duotone.fa-tally-1:after {
  content: "";
}

.fad.fa-file-vector:after, .fa-duotone.fa-file-vector:after {
  content: "";
}

.fad.fa-face-frown-open:after, .fa-duotone.fa-face-frown-open:after, .fad.fa-frown-open:after, .fa-duotone.fa-frown-open:after {
  content: "";
}

.fad.fa-square-dashed:after, .fa-duotone.fa-square-dashed:after {
  content: "";
}

.fad.fa-bag-shopping-plus:after, .fa-duotone.fa-bag-shopping-plus:after {
  content: "";
}

.fad.fa-square-j:after, .fa-duotone.fa-square-j:after {
  content: "";
}

.fad.fa-hand-point-up:after, .fa-duotone.fa-hand-point-up:after {
  content: "";
}

.fad.fa-money-bill:after, .fa-duotone.fa-money-bill:after {
  content: "";
}

.fad.fa-arrow-up-big-small:after, .fa-duotone.fa-arrow-up-big-small:after, .fad.fa-sort-size-up:after, .fa-duotone.fa-sort-size-up:after {
  content: "";
}

.fad.fa-barcode-read:after, .fa-duotone.fa-barcode-read:after {
  content: "";
}

.fad.fa-baguette:after, .fa-duotone.fa-baguette:after {
  content: "";
}

.fad.fa-bowl-soft-serve:after, .fa-duotone.fa-bowl-soft-serve:after {
  content: "";
}

.fad.fa-face-holding-back-tears:after, .fa-duotone.fa-face-holding-back-tears:after {
  content: "";
}

.fad.fa-square-up:after, .fa-duotone.fa-square-up:after, .fad.fa-arrow-alt-square-up:after, .fa-duotone.fa-arrow-alt-square-up:after {
  content: "";
}

.fad.fa-train-subway-tunnel:after, .fa-duotone.fa-train-subway-tunnel:after, .fad.fa-subway-tunnel:after, .fa-duotone.fa-subway-tunnel:after {
  content: "";
}

.fad.fa-square-exclamation:after, .fa-duotone.fa-square-exclamation:after, .fad.fa-exclamation-square:after, .fa-duotone.fa-exclamation-square:after {
  content: "";
}

.fad.fa-semicolon:after, .fa-duotone.fa-semicolon:after {
  content: ";;";
}

.fad.fa-bookmark:after, .fa-duotone.fa-bookmark:after {
  content: "";
}

.fad.fa-fan-table:after, .fa-duotone.fa-fan-table:after {
  content: "";
}

.fad.fa-align-justify:after, .fa-duotone.fa-align-justify:after {
  content: "";
}

.fad.fa-battery-low:after, .fa-duotone.fa-battery-low:after, .fad.fa-battery-1:after, .fa-duotone.fa-battery-1:after {
  content: "";
}

.fad.fa-credit-card-front:after, .fa-duotone.fa-credit-card-front:after {
  content: "";
}

.fad.fa-brain-arrow-curved-right:after, .fa-duotone.fa-brain-arrow-curved-right:after, .fad.fa-mind-share:after, .fa-duotone.fa-mind-share:after {
  content: "";
}

.fad.fa-umbrella-beach:after, .fa-duotone.fa-umbrella-beach:after {
  content: "";
}

.fad.fa-helmet-un:after, .fa-duotone.fa-helmet-un:after {
  content: "";
}

.fad.fa-location-smile:after, .fa-duotone.fa-location-smile:after, .fad.fa-map-marker-smile:after, .fa-duotone.fa-map-marker-smile:after {
  content: "";
}

.fad.fa-arrow-left-to-line:after, .fa-duotone.fa-arrow-left-to-line:after, .fad.fa-arrow-to-left:after, .fa-duotone.fa-arrow-to-left:after {
  content: "";
}

.fad.fa-bullseye:after, .fa-duotone.fa-bullseye:after {
  content: "";
}

.fad.fa-sushi:after, .fa-duotone.fa-sushi:after, .fad.fa-nigiri:after, .fa-duotone.fa-nigiri:after {
  content: "";
}

.fad.fa-message-captions:after, .fa-duotone.fa-message-captions:after, .fad.fa-comment-alt-captions:after, .fa-duotone.fa-comment-alt-captions:after {
  content: "";
}

.fad.fa-trash-list:after, .fa-duotone.fa-trash-list:after {
  content: "";
}

.fad.fa-bacon:after, .fa-duotone.fa-bacon:after {
  content: "";
}

.fad.fa-option:after, .fa-duotone.fa-option:after {
  content: "";
}

.fad.fa-raccoon:after, .fa-duotone.fa-raccoon:after {
  content: "";
}

.fad.fa-hand-point-down:after, .fa-duotone.fa-hand-point-down:after {
  content: "";
}

.fad.fa-arrow-up-from-bracket:after, .fa-duotone.fa-arrow-up-from-bracket:after {
  content: "";
}

.fad.fa-head-side-gear:after, .fa-duotone.fa-head-side-gear:after {
  content: "";
}

.fad.fa-trash-plus:after, .fa-duotone.fa-trash-plus:after {
  content: "";
}

.fad.fa-file-cad:after, .fa-duotone.fa-file-cad:after {
  content: "";
}

.fad.fa-objects-align-top:after, .fa-duotone.fa-objects-align-top:after {
  content: "";
}

.fad.fa-folder:after, .fa-duotone.fa-folder:after, .fad.fa-folder-blank:after, .fa-duotone.fa-folder-blank:after {
  content: "";
}

.fad.fa-face-anxious-sweat:after, .fa-duotone.fa-face-anxious-sweat:after {
  content: "";
}

.fad.fa-credit-card-blank:after, .fa-duotone.fa-credit-card-blank:after {
  content: "";
}

.fad.fa-file-waveform:after, .fa-duotone.fa-file-waveform:after, .fad.fa-file-medical-alt:after, .fa-duotone.fa-file-medical-alt:after {
  content: "";
}

.fad.fa-microchip-ai:after, .fa-duotone.fa-microchip-ai:after {
  content: "";
}

.fad.fa-mug:after, .fa-duotone.fa-mug:after {
  content: "";
}

.fad.fa-plane-up-slash:after, .fa-duotone.fa-plane-up-slash:after {
  content: "";
}

.fad.fa-radiation:after, .fa-duotone.fa-radiation:after {
  content: "";
}

.fad.fa-pen-circle:after, .fa-duotone.fa-pen-circle:after {
  content: "";
}

.fad.fa-bag-seedling:after, .fa-duotone.fa-bag-seedling:after {
  content: "";
}

.fad.fa-chart-simple:after, .fa-duotone.fa-chart-simple:after {
  content: "";
}

.fad.fa-crutches:after, .fa-duotone.fa-crutches:after {
  content: "";
}

.fad.fa-circle-parking:after, .fa-duotone.fa-circle-parking:after, .fad.fa-parking-circle:after, .fa-duotone.fa-parking-circle:after {
  content: "";
}

.fad.fa-mars-stroke:after, .fa-duotone.fa-mars-stroke:after {
  content: "";
}

.fad.fa-leaf-oak:after, .fa-duotone.fa-leaf-oak:after {
  content: "";
}

.fad.fa-square-bolt:after, .fa-duotone.fa-square-bolt:after {
  content: "";
}

.fad.fa-vial:after, .fa-duotone.fa-vial:after {
  content: "";
}

.fad.fa-gauge:after, .fa-duotone.fa-gauge:after, .fad.fa-dashboard:after, .fa-duotone.fa-dashboard:after, .fad.fa-gauge-med:after, .fa-duotone.fa-gauge-med:after, .fad.fa-tachometer-alt-average:after, .fa-duotone.fa-tachometer-alt-average:after {
  content: "";
}

.fad.fa-wand-magic-sparkles:after, .fa-duotone.fa-wand-magic-sparkles:after, .fad.fa-magic-wand-sparkles:after, .fa-duotone.fa-magic-wand-sparkles:after {
  content: "";
}

.fad.fa-lambda:after, .fa-duotone.fa-lambda:after {
  content: "";
}

.fad.fa-e:after, .fa-duotone.fa-e:after {
  content: "EE";
}

.fad.fa-pizza:after, .fa-duotone.fa-pizza:after {
  content: "";
}

.fad.fa-bowl-chopsticks-noodles:after, .fa-duotone.fa-bowl-chopsticks-noodles:after {
  content: "";
}

.fad.fa-h3:after, .fa-duotone.fa-h3:after {
  content: "";
}

.fad.fa-pen-clip:after, .fa-duotone.fa-pen-clip:after, .fad.fa-pen-alt:after, .fa-duotone.fa-pen-alt:after {
  content: "";
}

.fad.fa-bridge-circle-exclamation:after, .fa-duotone.fa-bridge-circle-exclamation:after {
  content: "";
}

.fad.fa-badge-percent:after, .fa-duotone.fa-badge-percent:after {
  content: "";
}

.fad.fa-rotate-reverse:after, .fa-duotone.fa-rotate-reverse:after {
  content: "";
}

.fad.fa-user:after, .fa-duotone.fa-user:after {
  content: "";
}

.fad.fa-sensor:after, .fa-duotone.fa-sensor:after {
  content: "";
}

.fad.fa-comma:after, .fa-duotone.fa-comma:after {
  content: ",,";
}

.fad.fa-school-circle-check:after, .fa-duotone.fa-school-circle-check:after {
  content: "";
}

.fad.fa-toilet-paper-under:after, .fa-duotone.fa-toilet-paper-under:after, .fad.fa-toilet-paper-reverse:after, .fa-duotone.fa-toilet-paper-reverse:after {
  content: "";
}

.fad.fa-light-emergency:after, .fa-duotone.fa-light-emergency:after {
  content: "";
}

.fad.fa-arrow-down-to-arc:after, .fa-duotone.fa-arrow-down-to-arc:after {
  content: "";
}

.fad.fa-dumpster:after, .fa-duotone.fa-dumpster:after {
  content: "";
}

.fad.fa-van-shuttle:after, .fa-duotone.fa-van-shuttle:after, .fad.fa-shuttle-van:after, .fa-duotone.fa-shuttle-van:after {
  content: "";
}

.fad.fa-building-user:after, .fa-duotone.fa-building-user:after {
  content: "";
}

.fad.fa-light-switch:after, .fa-duotone.fa-light-switch:after {
  content: "";
}

.fad.fa-square-caret-left:after, .fa-duotone.fa-square-caret-left:after, .fad.fa-caret-square-left:after, .fa-duotone.fa-caret-square-left:after {
  content: "";
}

.fad.fa-highlighter:after, .fa-duotone.fa-highlighter:after {
  content: "";
}

.fad.fa-wave-pulse:after, .fa-duotone.fa-wave-pulse:after, .fad.fa-heart-rate:after, .fa-duotone.fa-heart-rate:after {
  content: "";
}

.fad.fa-key:after, .fa-duotone.fa-key:after {
  content: "";
}

.fad.fa-arrow-left-to-bracket:after, .fa-duotone.fa-arrow-left-to-bracket:after {
  content: "";
}

.fad.fa-hat-santa:after, .fa-duotone.fa-hat-santa:after {
  content: "";
}

.fad.fa-tamale:after, .fa-duotone.fa-tamale:after {
  content: "";
}

.fad.fa-box-check:after, .fa-duotone.fa-box-check:after {
  content: "";
}

.fad.fa-bullhorn:after, .fa-duotone.fa-bullhorn:after {
  content: "";
}

.fad.fa-steak:after, .fa-duotone.fa-steak:after {
  content: "";
}

.fad.fa-location-crosshairs-slash:after, .fa-duotone.fa-location-crosshairs-slash:after, .fad.fa-location-slash:after, .fa-duotone.fa-location-slash:after {
  content: "";
}

.fad.fa-person-dolly:after, .fa-duotone.fa-person-dolly:after {
  content: "";
}

.fad.fa-globe:after, .fa-duotone.fa-globe:after {
  content: "";
}

.fad.fa-synagogue:after, .fa-duotone.fa-synagogue:after {
  content: "";
}

.fad.fa-file-chart-column:after, .fa-duotone.fa-file-chart-column:after, .fad.fa-file-chart-line:after, .fa-duotone.fa-file-chart-line:after {
  content: "";
}

.fad.fa-person-half-dress:after, .fa-duotone.fa-person-half-dress:after {
  content: "";
}

.fad.fa-folder-image:after, .fa-duotone.fa-folder-image:after {
  content: "";
}

.fad.fa-calendar-pen:after, .fa-duotone.fa-calendar-pen:after, .fad.fa-calendar-edit:after, .fa-duotone.fa-calendar-edit:after {
  content: "";
}

.fad.fa-road-bridge:after, .fa-duotone.fa-road-bridge:after {
  content: "";
}

.fad.fa-face-smile-tear:after, .fa-duotone.fa-face-smile-tear:after {
  content: "";
}

.fad.fa-message-plus:after, .fa-duotone.fa-message-plus:after, .fad.fa-comment-alt-plus:after, .fa-duotone.fa-comment-alt-plus:after {
  content: "";
}

.fad.fa-location-arrow:after, .fa-duotone.fa-location-arrow:after {
  content: "";
}

.fad.fa-c:after, .fa-duotone.fa-c:after {
  content: "CC";
}

.fad.fa-tablet-button:after, .fa-duotone.fa-tablet-button:after {
  content: "";
}

.fad.fa-person-dress-fairy:after, .fa-duotone.fa-person-dress-fairy:after {
  content: "";
}

.fad.fa-rectangle-history-circle-user:after, .fa-duotone.fa-rectangle-history-circle-user:after {
  content: "";
}

.fad.fa-building-lock:after, .fa-duotone.fa-building-lock:after {
  content: "";
}

.fad.fa-chart-line-up:after, .fa-duotone.fa-chart-line-up:after {
  content: "";
}

.fad.fa-mailbox:after, .fa-duotone.fa-mailbox:after {
  content: "";
}

.fad.fa-sign-posts:after, .fa-duotone.fa-sign-posts:after {
  content: "";
}

.fad.fa-truck-bolt:after, .fa-duotone.fa-truck-bolt:after {
  content: "";
}

.fad.fa-pizza-slice:after, .fa-duotone.fa-pizza-slice:after {
  content: "";
}

.fad.fa-money-bill-wave:after, .fa-duotone.fa-money-bill-wave:after {
  content: "";
}

.fad.fa-chart-area:after, .fa-duotone.fa-chart-area:after, .fad.fa-area-chart:after, .fa-duotone.fa-area-chart:after {
  content: "";
}

.fad.fa-house-flag:after, .fa-duotone.fa-house-flag:after {
  content: "";
}

.fad.fa-circle-three-quarters-stroke:after, .fa-duotone.fa-circle-three-quarters-stroke:after {
  content: "";
}

.fad.fa-person-circle-minus:after, .fa-duotone.fa-person-circle-minus:after {
  content: "";
}

.fad.fa-scalpel:after, .fa-duotone.fa-scalpel:after {
  content: "";
}

.fad.fa-ban:after, .fa-duotone.fa-ban:after, .fad.fa-cancel:after, .fa-duotone.fa-cancel:after {
  content: "";
}

.fad.fa-bell-exclamation:after, .fa-duotone.fa-bell-exclamation:after {
  content: "";
}

.fad.fa-circle-bookmark:after, .fa-duotone.fa-circle-bookmark:after, .fad.fa-bookmark-circle:after, .fa-duotone.fa-bookmark-circle:after {
  content: "";
}

.fad.fa-egg-fried:after, .fa-duotone.fa-egg-fried:after {
  content: "";
}

.fad.fa-face-weary:after, .fa-duotone.fa-face-weary:after {
  content: "";
}

.fad.fa-uniform-martial-arts:after, .fa-duotone.fa-uniform-martial-arts:after {
  content: "";
}

.fad.fa-camera-rotate:after, .fa-duotone.fa-camera-rotate:after {
  content: "";
}

.fad.fa-sun-dust:after, .fa-duotone.fa-sun-dust:after {
  content: "";
}

.fad.fa-comment-text:after, .fa-duotone.fa-comment-text:after {
  content: "";
}

.fad.fa-spray-can-sparkles:after, .fa-duotone.fa-spray-can-sparkles:after, .fad.fa-air-freshener:after, .fa-duotone.fa-air-freshener:after {
  content: "";
}

.fad.fa-signal-bars:after, .fa-duotone.fa-signal-bars:after, .fad.fa-signal-alt:after, .fa-duotone.fa-signal-alt:after, .fad.fa-signal-alt-4:after, .fa-duotone.fa-signal-alt-4:after, .fad.fa-signal-bars-strong:after, .fa-duotone.fa-signal-bars-strong:after {
  content: "";
}

.fad.fa-diamond-exclamation:after, .fa-duotone.fa-diamond-exclamation:after {
  content: "";
}

.fad.fa-star:after, .fa-duotone.fa-star:after {
  content: "";
}

.fad.fa-dial-min:after, .fa-duotone.fa-dial-min:after {
  content: "";
}

.fad.fa-repeat:after, .fa-duotone.fa-repeat:after {
  content: "";
}

.fad.fa-cross:after, .fa-duotone.fa-cross:after {
  content: "";
}

.fad.fa-page-caret-down:after, .fa-duotone.fa-page-caret-down:after, .fad.fa-file-caret-down:after, .fa-duotone.fa-file-caret-down:after {
  content: "";
}

.fad.fa-box:after, .fa-duotone.fa-box:after {
  content: "";
}

.fad.fa-venus-mars:after, .fa-duotone.fa-venus-mars:after {
  content: "";
}

.fad.fa-clock-seven-thirty:after, .fa-duotone.fa-clock-seven-thirty:after {
  content: "";
}

.fad.fa-arrow-pointer:after, .fa-duotone.fa-arrow-pointer:after, .fad.fa-mouse-pointer:after, .fa-duotone.fa-mouse-pointer:after {
  content: "";
}

.fad.fa-clock-four-thirty:after, .fa-duotone.fa-clock-four-thirty:after {
  content: "";
}

.fad.fa-signal-bars-good:after, .fa-duotone.fa-signal-bars-good:after, .fad.fa-signal-alt-3:after, .fa-duotone.fa-signal-alt-3:after {
  content: "";
}

.fad.fa-cactus:after, .fa-duotone.fa-cactus:after {
  content: "";
}

.fad.fa-lightbulb-gear:after, .fa-duotone.fa-lightbulb-gear:after {
  content: "";
}

.fad.fa-maximize:after, .fa-duotone.fa-maximize:after, .fad.fa-expand-arrows-alt:after, .fa-duotone.fa-expand-arrows-alt:after {
  content: "";
}

.fad.fa-charging-station:after, .fa-duotone.fa-charging-station:after {
  content: "";
}

.fad.fa-shapes:after, .fa-duotone.fa-shapes:after, .fad.fa-triangle-circle-square:after, .fa-duotone.fa-triangle-circle-square:after {
  content: "";
}

.fad.fa-plane-tail:after, .fa-duotone.fa-plane-tail:after {
  content: "";
}

.fad.fa-gauge-simple-max:after, .fa-duotone.fa-gauge-simple-max:after, .fad.fa-tachometer-fastest:after, .fa-duotone.fa-tachometer-fastest:after {
  content: "";
}

.fad.fa-circle-u:after, .fa-duotone.fa-circle-u:after {
  content: "";
}

.fad.fa-shield-slash:after, .fa-duotone.fa-shield-slash:after {
  content: "";
}

.fad.fa-square-phone-hangup:after, .fa-duotone.fa-square-phone-hangup:after, .fad.fa-phone-square-down:after, .fa-duotone.fa-phone-square-down:after {
  content: "";
}

.fad.fa-arrow-up-left:after, .fa-duotone.fa-arrow-up-left:after {
  content: "";
}

.fad.fa-transporter-1:after, .fa-duotone.fa-transporter-1:after {
  content: "";
}

.fad.fa-peanuts:after, .fa-duotone.fa-peanuts:after {
  content: "";
}

.fad.fa-shuffle:after, .fa-duotone.fa-shuffle:after, .fad.fa-random:after, .fa-duotone.fa-random:after {
  content: "";
}

.fad.fa-person-running:after, .fa-duotone.fa-person-running:after, .fad.fa-running:after, .fa-duotone.fa-running:after {
  content: "";
}

.fad.fa-mobile-retro:after, .fa-duotone.fa-mobile-retro:after {
  content: "";
}

.fad.fa-grip-lines-vertical:after, .fa-duotone.fa-grip-lines-vertical:after {
  content: "";
}

.fad.fa-bin-bottles-recycle:after, .fa-duotone.fa-bin-bottles-recycle:after {
  content: "";
}

.fad.fa-arrow-up-from-square:after, .fa-duotone.fa-arrow-up-from-square:after {
  content: "";
}

.fad.fa-file-dashed-line:after, .fa-duotone.fa-file-dashed-line:after, .fad.fa-page-break:after, .fa-duotone.fa-page-break:after {
  content: "";
}

.fad.fa-bracket-curly-right:after, .fa-duotone.fa-bracket-curly-right:after {
  content: "}}";
}

.fad.fa-spider:after, .fa-duotone.fa-spider:after {
  content: "";
}

.fad.fa-clock-three:after, .fa-duotone.fa-clock-three:after {
  content: "";
}

.fad.fa-hands-bound:after, .fa-duotone.fa-hands-bound:after {
  content: "";
}

.fad.fa-scalpel-line-dashed:after, .fa-duotone.fa-scalpel-line-dashed:after, .fad.fa-scalpel-path:after, .fa-duotone.fa-scalpel-path:after {
  content: "";
}

.fad.fa-file-invoice-dollar:after, .fa-duotone.fa-file-invoice-dollar:after {
  content: "";
}

.fad.fa-pipe-smoking:after, .fa-duotone.fa-pipe-smoking:after {
  content: "";
}

.fad.fa-face-astonished:after, .fa-duotone.fa-face-astonished:after {
  content: "";
}

.fad.fa-window:after, .fa-duotone.fa-window:after {
  content: "";
}

.fad.fa-plane-circle-exclamation:after, .fa-duotone.fa-plane-circle-exclamation:after {
  content: "";
}

.fad.fa-ear:after, .fa-duotone.fa-ear:after {
  content: "";
}

.fad.fa-file-lock:after, .fa-duotone.fa-file-lock:after {
  content: "";
}

.fad.fa-diagram-venn:after, .fa-duotone.fa-diagram-venn:after {
  content: "";
}

.fad.fa-arrow-down-from-bracket:after, .fa-duotone.fa-arrow-down-from-bracket:after {
  content: "";
}

.fad.fa-x-ray:after, .fa-duotone.fa-x-ray:after {
  content: "";
}

.fad.fa-goal-net:after, .fa-duotone.fa-goal-net:after {
  content: "";
}

.fad.fa-coffin-cross:after, .fa-duotone.fa-coffin-cross:after {
  content: "";
}

.fad.fa-spell-check:after, .fa-duotone.fa-spell-check:after {
  content: "";
}

.fad.fa-location-xmark:after, .fa-duotone.fa-location-xmark:after, .fad.fa-map-marker-times:after, .fa-duotone.fa-map-marker-times:after, .fad.fa-map-marker-xmark:after, .fa-duotone.fa-map-marker-xmark:after {
  content: "";
}

.fad.fa-circle-quarter-stroke:after, .fa-duotone.fa-circle-quarter-stroke:after {
  content: "";
}

.fad.fa-lasso:after, .fa-duotone.fa-lasso:after {
  content: "";
}

.fad.fa-slash:after, .fa-duotone.fa-slash:after {
  content: "";
}

.fad.fa-person-to-portal:after, .fa-duotone.fa-person-to-portal:after, .fad.fa-portal-enter:after, .fa-duotone.fa-portal-enter:after {
  content: "";
}

.fad.fa-calendar-star:after, .fa-duotone.fa-calendar-star:after {
  content: "";
}

.fad.fa-computer-mouse:after, .fa-duotone.fa-computer-mouse:after, .fad.fa-mouse:after, .fa-duotone.fa-mouse:after {
  content: "";
}

.fad.fa-arrow-right-to-bracket:after, .fa-duotone.fa-arrow-right-to-bracket:after, .fad.fa-sign-in:after, .fa-duotone.fa-sign-in:after {
  content: "";
}

.fad.fa-pegasus:after, .fa-duotone.fa-pegasus:after {
  content: "";
}

.fad.fa-files-medical:after, .fa-duotone.fa-files-medical:after {
  content: "";
}

.fad.fa-cannon:after, .fa-duotone.fa-cannon:after {
  content: "";
}

.fad.fa-nfc-lock:after, .fa-duotone.fa-nfc-lock:after {
  content: "";
}

.fad.fa-person-ski-lift:after, .fa-duotone.fa-person-ski-lift:after, .fad.fa-ski-lift:after, .fa-duotone.fa-ski-lift:after {
  content: "";
}

.fad.fa-square-6:after, .fa-duotone.fa-square-6:after {
  content: "";
}

.fad.fa-shop-slash:after, .fa-duotone.fa-shop-slash:after, .fad.fa-store-alt-slash:after, .fa-duotone.fa-store-alt-slash:after {
  content: "";
}

.fad.fa-wind-turbine:after, .fa-duotone.fa-wind-turbine:after {
  content: "";
}

.fad.fa-sliders-simple:after, .fa-duotone.fa-sliders-simple:after {
  content: "";
}

.fad.fa-grid-round:after, .fa-duotone.fa-grid-round:after {
  content: "";
}

.fad.fa-badge-sheriff:after, .fa-duotone.fa-badge-sheriff:after {
  content: "";
}

.fad.fa-server:after, .fa-duotone.fa-server:after {
  content: "";
}

.fad.fa-virus-covid-slash:after, .fa-duotone.fa-virus-covid-slash:after {
  content: "";
}

.fad.fa-intersection:after, .fa-duotone.fa-intersection:after {
  content: "";
}

.fad.fa-shop-lock:after, .fa-duotone.fa-shop-lock:after {
  content: "";
}

.fad.fa-family:after, .fa-duotone.fa-family:after {
  content: "";
}

.fad.fa-hourglass-start:after, .fa-duotone.fa-hourglass-start:after, .fad.fa-hourglass-1:after, .fa-duotone.fa-hourglass-1:after {
  content: "";
}

.fad.fa-user-hair-buns:after, .fa-duotone.fa-user-hair-buns:after {
  content: "";
}

.fad.fa-blender-phone:after, .fa-duotone.fa-blender-phone:after {
  content: "";
}

.fad.fa-hourglass-clock:after, .fa-duotone.fa-hourglass-clock:after {
  content: "";
}

.fad.fa-person-seat-reclined:after, .fa-duotone.fa-person-seat-reclined:after {
  content: "";
}

.fad.fa-paper-plane-top:after, .fa-duotone.fa-paper-plane-top:after, .fad.fa-paper-plane-alt:after, .fa-duotone.fa-paper-plane-alt:after, .fad.fa-send:after, .fa-duotone.fa-send:after {
  content: "";
}

.fad.fa-message-arrow-up:after, .fa-duotone.fa-message-arrow-up:after, .fad.fa-comment-alt-arrow-up:after, .fa-duotone.fa-comment-alt-arrow-up:after {
  content: "";
}

.fad.fa-lightbulb-exclamation:after, .fa-duotone.fa-lightbulb-exclamation:after {
  content: "";
}

.fad.fa-layer-minus:after, .fa-duotone.fa-layer-minus:after, .fad.fa-layer-group-minus:after, .fa-duotone.fa-layer-group-minus:after {
  content: "";
}

.fad.fa-chart-pie-simple-circle-currency:after, .fa-duotone.fa-chart-pie-simple-circle-currency:after {
  content: "";
}

.fad.fa-circle-e:after, .fa-duotone.fa-circle-e:after {
  content: "";
}

.fad.fa-building-wheat:after, .fa-duotone.fa-building-wheat:after {
  content: "";
}

.fad.fa-gauge-max:after, .fa-duotone.fa-gauge-max:after, .fad.fa-tachometer-alt-fastest:after, .fa-duotone.fa-tachometer-alt-fastest:after {
  content: "";
}

.fad.fa-person-breastfeeding:after, .fa-duotone.fa-person-breastfeeding:after {
  content: "";
}

.fad.fa-apostrophe:after, .fa-duotone.fa-apostrophe:after {
  content: "''";
}

.fad.fa-file-png:after, .fa-duotone.fa-file-png:after {
  content: "";
}

.fad.fa-fire-hydrant:after, .fa-duotone.fa-fire-hydrant:after {
  content: "";
}

.fad.fa-right-to-bracket:after, .fa-duotone.fa-right-to-bracket:after, .fad.fa-sign-in-alt:after, .fa-duotone.fa-sign-in-alt:after {
  content: "";
}

.fad.fa-video-plus:after, .fa-duotone.fa-video-plus:after {
  content: "";
}

.fad.fa-square-right:after, .fa-duotone.fa-square-right:after, .fad.fa-arrow-alt-square-right:after, .fa-duotone.fa-arrow-alt-square-right:after {
  content: "";
}

.fad.fa-comment-smile:after, .fa-duotone.fa-comment-smile:after {
  content: "";
}

.fad.fa-venus:after, .fa-duotone.fa-venus:after {
  content: "";
}

.fad.fa-passport:after, .fa-duotone.fa-passport:after {
  content: "";
}

.fad.fa-inbox-in:after, .fa-duotone.fa-inbox-in:after, .fad.fa-inbox-arrow-down:after, .fa-duotone.fa-inbox-arrow-down:after {
  content: "";
}

.fad.fa-heart-pulse:after, .fa-duotone.fa-heart-pulse:after, .fad.fa-heartbeat:after, .fa-duotone.fa-heartbeat:after {
  content: "";
}

.fad.fa-circle-8:after, .fa-duotone.fa-circle-8:after {
  content: "";
}

.fad.fa-clouds-moon:after, .fa-duotone.fa-clouds-moon:after {
  content: "";
}

.fad.fa-clock-ten-thirty:after, .fa-duotone.fa-clock-ten-thirty:after {
  content: "";
}

.fad.fa-people-carry-box:after, .fa-duotone.fa-people-carry-box:after, .fad.fa-people-carry:after, .fa-duotone.fa-people-carry:after {
  content: "";
}

.fad.fa-folder-user:after, .fa-duotone.fa-folder-user:after {
  content: "";
}

.fad.fa-trash-can-xmark:after, .fa-duotone.fa-trash-can-xmark:after {
  content: "";
}

.fad.fa-temperature-high:after, .fa-duotone.fa-temperature-high:after {
  content: "";
}

.fad.fa-microchip:after, .fa-duotone.fa-microchip:after {
  content: "";
}

.fad.fa-left-long-to-line:after, .fa-duotone.fa-left-long-to-line:after {
  content: "";
}

.fad.fa-crown:after, .fa-duotone.fa-crown:after {
  content: "";
}

.fad.fa-weight-hanging:after, .fa-duotone.fa-weight-hanging:after {
  content: "";
}

.fad.fa-xmarks-lines:after, .fa-duotone.fa-xmarks-lines:after {
  content: "";
}

.fad.fa-file-prescription:after, .fa-duotone.fa-file-prescription:after {
  content: "";
}

.fad.fa-table-cells-lock:after, .fa-duotone.fa-table-cells-lock:after {
  content: "";
}

.fad.fa-calendar-range:after, .fa-duotone.fa-calendar-range:after {
  content: "";
}

.fad.fa-flower-daffodil:after, .fa-duotone.fa-flower-daffodil:after {
  content: "";
}

.fad.fa-hand-back-point-up:after, .fa-duotone.fa-hand-back-point-up:after {
  content: "";
}

.fad.fa-weight-scale:after, .fa-duotone.fa-weight-scale:after, .fad.fa-weight:after, .fa-duotone.fa-weight:after {
  content: "";
}

.fad.fa-arrow-up-to-arc:after, .fa-duotone.fa-arrow-up-to-arc:after {
  content: "";
}

.fad.fa-star-exclamation:after, .fa-duotone.fa-star-exclamation:after {
  content: "";
}

.fad.fa-books:after, .fa-duotone.fa-books:after {
  content: "";
}

.fad.fa-user-group:after, .fa-duotone.fa-user-group:after, .fad.fa-user-friends:after, .fa-duotone.fa-user-friends:after {
  content: "";
}

.fad.fa-arrow-up-a-z:after, .fa-duotone.fa-arrow-up-a-z:after, .fad.fa-sort-alpha-up:after, .fa-duotone.fa-sort-alpha-up:after {
  content: "";
}

.fad.fa-layer-plus:after, .fa-duotone.fa-layer-plus:after, .fad.fa-layer-group-plus:after, .fa-duotone.fa-layer-group-plus:after {
  content: "";
}

.fad.fa-play-pause:after, .fa-duotone.fa-play-pause:after {
  content: "";
}

.fad.fa-block-question:after, .fa-duotone.fa-block-question:after {
  content: "";
}

.fad.fa-snooze:after, .fa-duotone.fa-snooze:after, .fad.fa-zzz:after, .fa-duotone.fa-zzz:after {
  content: "";
}

.fad.fa-scanner-image:after, .fa-duotone.fa-scanner-image:after {
  content: "";
}

.fad.fa-tv-retro:after, .fa-duotone.fa-tv-retro:after {
  content: "";
}

.fad.fa-square-t:after, .fa-duotone.fa-square-t:after {
  content: "";
}

.fad.fa-farm:after, .fa-duotone.fa-farm:after, .fad.fa-barn-silo:after, .fa-duotone.fa-barn-silo:after {
  content: "";
}

.fad.fa-chess-knight:after, .fa-duotone.fa-chess-knight:after {
  content: "";
}

.fad.fa-bars-sort:after, .fa-duotone.fa-bars-sort:after {
  content: "";
}

.fad.fa-pallet-boxes:after, .fa-duotone.fa-pallet-boxes:after, .fad.fa-palette-boxes:after, .fa-duotone.fa-palette-boxes:after, .fad.fa-pallet-alt:after, .fa-duotone.fa-pallet-alt:after {
  content: "";
}

.fad.fa-face-laugh-squint:after, .fa-duotone.fa-face-laugh-squint:after, .fad.fa-laugh-squint:after, .fa-duotone.fa-laugh-squint:after {
  content: "";
}

.fad.fa-code-simple:after, .fa-duotone.fa-code-simple:after {
  content: "";
}

.fad.fa-bolt-slash:after, .fa-duotone.fa-bolt-slash:after {
  content: "";
}

.fad.fa-panel-fire:after, .fa-duotone.fa-panel-fire:after {
  content: "";
}

.fad.fa-binary-circle-check:after, .fa-duotone.fa-binary-circle-check:after {
  content: "";
}

.fad.fa-comment-minus:after, .fa-duotone.fa-comment-minus:after {
  content: "";
}

.fad.fa-burrito:after, .fa-duotone.fa-burrito:after {
  content: "";
}

.fad.fa-violin:after, .fa-duotone.fa-violin:after {
  content: "";
}

.fad.fa-objects-column:after, .fa-duotone.fa-objects-column:after {
  content: "";
}

.fad.fa-square-chevron-down:after, .fa-duotone.fa-square-chevron-down:after, .fad.fa-chevron-square-down:after, .fa-duotone.fa-chevron-square-down:after {
  content: "";
}

.fad.fa-comment-plus:after, .fa-duotone.fa-comment-plus:after {
  content: "";
}

.fad.fa-triangle-instrument:after, .fa-duotone.fa-triangle-instrument:after, .fad.fa-triangle-music:after, .fa-duotone.fa-triangle-music:after {
  content: "";
}

.fad.fa-wheelchair:after, .fa-duotone.fa-wheelchair:after {
  content: "";
}

.fad.fa-user-pilot-tie:after, .fa-duotone.fa-user-pilot-tie:after {
  content: "";
}

.fad.fa-piano-keyboard:after, .fa-duotone.fa-piano-keyboard:after {
  content: "";
}

.fad.fa-bed-empty:after, .fa-duotone.fa-bed-empty:after {
  content: "";
}

.fad.fa-circle-arrow-up:after, .fa-duotone.fa-circle-arrow-up:after, .fad.fa-arrow-circle-up:after, .fa-duotone.fa-arrow-circle-up:after {
  content: "";
}

.fad.fa-toggle-on:after, .fa-duotone.fa-toggle-on:after {
  content: "";
}

.fad.fa-rectangle-vertical:after, .fa-duotone.fa-rectangle-vertical:after, .fad.fa-rectangle-portrait:after, .fa-duotone.fa-rectangle-portrait:after {
  content: "";
}

.fad.fa-person-walking:after, .fa-duotone.fa-person-walking:after, .fad.fa-walking:after, .fa-duotone.fa-walking:after {
  content: "";
}

.fad.fa-l:after, .fa-duotone.fa-l:after {
  content: "LL";
}

.fad.fa-signal-stream:after, .fa-duotone.fa-signal-stream:after {
  content: "";
}

.fad.fa-down-to-bracket:after, .fa-duotone.fa-down-to-bracket:after {
  content: "";
}

.fad.fa-circle-z:after, .fa-duotone.fa-circle-z:after {
  content: "";
}

.fad.fa-stars:after, .fa-duotone.fa-stars:after {
  content: "";
}

.fad.fa-fire:after, .fa-duotone.fa-fire:after {
  content: "";
}

.fad.fa-bed-pulse:after, .fa-duotone.fa-bed-pulse:after, .fad.fa-procedures:after, .fa-duotone.fa-procedures:after {
  content: "";
}

.fad.fa-house-day:after, .fa-duotone.fa-house-day:after {
  content: "";
}

.fad.fa-shuttle-space:after, .fa-duotone.fa-shuttle-space:after, .fad.fa-space-shuttle:after, .fa-duotone.fa-space-shuttle:after {
  content: "";
}

.fad.fa-shirt-long-sleeve:after, .fa-duotone.fa-shirt-long-sleeve:after {
  content: "";
}

.fad.fa-chart-pie-simple:after, .fa-duotone.fa-chart-pie-simple:after, .fad.fa-chart-pie-alt:after, .fa-duotone.fa-chart-pie-alt:after {
  content: "";
}

.fad.fa-face-laugh:after, .fa-duotone.fa-face-laugh:after, .fad.fa-laugh:after, .fa-duotone.fa-laugh:after {
  content: "";
}

.fad.fa-folder-open:after, .fa-duotone.fa-folder-open:after {
  content: "";
}

.fad.fa-album-collection-circle-user:after, .fa-duotone.fa-album-collection-circle-user:after {
  content: "";
}

.fad.fa-candy:after, .fa-duotone.fa-candy:after {
  content: "";
}

.fad.fa-bowl-hot:after, .fa-duotone.fa-bowl-hot:after, .fad.fa-soup:after, .fa-duotone.fa-soup:after {
  content: "";
}

.fad.fa-flatbread:after, .fa-duotone.fa-flatbread:after {
  content: "";
}

.fad.fa-heart-circle-plus:after, .fa-duotone.fa-heart-circle-plus:after {
  content: "";
}

.fad.fa-code-fork:after, .fa-duotone.fa-code-fork:after {
  content: "";
}

.fad.fa-city:after, .fa-duotone.fa-city:after {
  content: "";
}

.fad.fa-signal-bars-weak:after, .fa-duotone.fa-signal-bars-weak:after, .fad.fa-signal-alt-1:after, .fa-duotone.fa-signal-alt-1:after {
  content: "";
}

.fad.fa-microphone-lines:after, .fa-duotone.fa-microphone-lines:after, .fad.fa-microphone-alt:after, .fa-duotone.fa-microphone-alt:after {
  content: "";
}

.fad.fa-clock-twelve:after, .fa-duotone.fa-clock-twelve:after {
  content: "";
}

.fad.fa-pepper-hot:after, .fa-duotone.fa-pepper-hot:after {
  content: "";
}

.fad.fa-citrus-slice:after, .fa-duotone.fa-citrus-slice:after {
  content: "";
}

.fad.fa-sheep:after, .fa-duotone.fa-sheep:after {
  content: "";
}

.fad.fa-unlock:after, .fa-duotone.fa-unlock:after {
  content: "";
}

.fad.fa-colon-sign:after, .fa-duotone.fa-colon-sign:after {
  content: "";
}

.fad.fa-headset:after, .fa-duotone.fa-headset:after {
  content: "";
}

.fad.fa-badger-honey:after, .fa-duotone.fa-badger-honey:after {
  content: "";
}

.fad.fa-h4:after, .fa-duotone.fa-h4:after {
  content: "";
}

.fad.fa-store-slash:after, .fa-duotone.fa-store-slash:after {
  content: "";
}

.fad.fa-road-circle-xmark:after, .fa-duotone.fa-road-circle-xmark:after {
  content: "";
}

.fad.fa-signal-slash:after, .fa-duotone.fa-signal-slash:after {
  content: "";
}

.fad.fa-user-minus:after, .fa-duotone.fa-user-minus:after {
  content: "";
}

.fad.fa-mars-stroke-up:after, .fa-duotone.fa-mars-stroke-up:after, .fad.fa-mars-stroke-v:after, .fa-duotone.fa-mars-stroke-v:after {
  content: "";
}

.fad.fa-champagne-glasses:after, .fa-duotone.fa-champagne-glasses:after, .fad.fa-glass-cheers:after, .fa-duotone.fa-glass-cheers:after {
  content: "";
}

.fad.fa-taco:after, .fa-duotone.fa-taco:after {
  content: "";
}

.fad.fa-hexagon-plus:after, .fa-duotone.fa-hexagon-plus:after, .fad.fa-plus-hexagon:after, .fa-duotone.fa-plus-hexagon:after {
  content: "";
}

.fad.fa-clipboard:after, .fa-duotone.fa-clipboard:after {
  content: "";
}

.fad.fa-house-circle-exclamation:after, .fa-duotone.fa-house-circle-exclamation:after {
  content: "";
}

.fad.fa-file-arrow-up:after, .fa-duotone.fa-file-arrow-up:after, .fad.fa-file-upload:after, .fa-duotone.fa-file-upload:after {
  content: "";
}

.fad.fa-wifi:after, .fa-duotone.fa-wifi:after, .fad.fa-wifi-3:after, .fa-duotone.fa-wifi-3:after, .fad.fa-wifi-strong:after, .fa-duotone.fa-wifi-strong:after {
  content: "";
}

.fad.fa-messages:after, .fa-duotone.fa-messages:after, .fad.fa-comments-alt:after, .fa-duotone.fa-comments-alt:after {
  content: "";
}

.fad.fa-bath:after, .fa-duotone.fa-bath:after, .fad.fa-bathtub:after, .fa-duotone.fa-bathtub:after {
  content: "";
}

.fad.fa-umbrella-simple:after, .fa-duotone.fa-umbrella-simple:after, .fad.fa-umbrella-alt:after, .fa-duotone.fa-umbrella-alt:after {
  content: "";
}

.fad.fa-rectangle-history-circle-plus:after, .fa-duotone.fa-rectangle-history-circle-plus:after {
  content: "";
}

.fad.fa-underline:after, .fa-duotone.fa-underline:after {
  content: "";
}

.fad.fa-prescription-bottle-pill:after, .fa-duotone.fa-prescription-bottle-pill:after {
  content: "";
}

.fad.fa-user-pen:after, .fa-duotone.fa-user-pen:after, .fad.fa-user-edit:after, .fa-duotone.fa-user-edit:after {
  content: "";
}

.fad.fa-binary-slash:after, .fa-duotone.fa-binary-slash:after {
  content: "";
}

.fad.fa-square-o:after, .fa-duotone.fa-square-o:after {
  content: "";
}

.fad.fa-caduceus:after, .fa-duotone.fa-caduceus:after {
  content: "";
}

.fad.fa-signature:after, .fa-duotone.fa-signature:after {
  content: "";
}

.fad.fa-stroopwafel:after, .fa-duotone.fa-stroopwafel:after {
  content: "";
}

.fad.fa-bold:after, .fa-duotone.fa-bold:after {
  content: "";
}

.fad.fa-anchor-lock:after, .fa-duotone.fa-anchor-lock:after {
  content: "";
}

.fad.fa-building-ngo:after, .fa-duotone.fa-building-ngo:after {
  content: "";
}

.fad.fa-transporter-3:after, .fa-duotone.fa-transporter-3:after {
  content: "";
}

.fad.fa-engine-warning:after, .fa-duotone.fa-engine-warning:after, .fad.fa-engine-exclamation:after, .fa-duotone.fa-engine-exclamation:after {
  content: "";
}

.fad.fa-circle-down-right:after, .fa-duotone.fa-circle-down-right:after {
  content: "";
}

.fad.fa-square-k:after, .fa-duotone.fa-square-k:after {
  content: "";
}

.fad.fa-manat-sign:after, .fa-duotone.fa-manat-sign:after {
  content: "";
}

.fad.fa-money-check-pen:after, .fa-duotone.fa-money-check-pen:after, .fad.fa-money-check-edit:after, .fa-duotone.fa-money-check-edit:after {
  content: "";
}

.fad.fa-not-equal:after, .fa-duotone.fa-not-equal:after {
  content: "";
}

.fad.fa-border-top-left:after, .fa-duotone.fa-border-top-left:after, .fad.fa-border-style:after, .fa-duotone.fa-border-style:after {
  content: "";
}

.fad.fa-map-location-dot:after, .fa-duotone.fa-map-location-dot:after, .fad.fa-map-marked-alt:after, .fa-duotone.fa-map-marked-alt:after {
  content: "";
}

.fad.fa-tilde:after, .fa-duotone.fa-tilde:after {
  content: "~~";
}

.fad.fa-jedi:after, .fa-duotone.fa-jedi:after {
  content: "";
}

.fad.fa-square-poll-vertical:after, .fa-duotone.fa-square-poll-vertical:after, .fad.fa-poll:after, .fa-duotone.fa-poll:after {
  content: "";
}

.fad.fa-arrow-down-square-triangle:after, .fa-duotone.fa-arrow-down-square-triangle:after, .fad.fa-sort-shapes-down-alt:after, .fa-duotone.fa-sort-shapes-down-alt:after {
  content: "";
}

.fad.fa-mug-hot:after, .fa-duotone.fa-mug-hot:after {
  content: "";
}

.fad.fa-dog-leashed:after, .fa-duotone.fa-dog-leashed:after {
  content: "";
}

.fad.fa-car-battery:after, .fa-duotone.fa-car-battery:after, .fad.fa-battery-car:after, .fa-duotone.fa-battery-car:after {
  content: "";
}

.fad.fa-face-downcast-sweat:after, .fa-duotone.fa-face-downcast-sweat:after {
  content: "";
}

.fad.fa-mailbox-flag-up:after, .fa-duotone.fa-mailbox-flag-up:after {
  content: "";
}

.fad.fa-memo-circle-info:after, .fa-duotone.fa-memo-circle-info:after {
  content: "";
}

.fad.fa-gift:after, .fa-duotone.fa-gift:after {
  content: "";
}

.fad.fa-dice-two:after, .fa-duotone.fa-dice-two:after {
  content: "";
}

.fad.fa-volume:after, .fa-duotone.fa-volume:after, .fad.fa-volume-medium:after, .fa-duotone.fa-volume-medium:after {
  content: "";
}

.fad.fa-transporter-5:after, .fa-duotone.fa-transporter-5:after {
  content: "";
}

.fad.fa-gauge-circle-bolt:after, .fa-duotone.fa-gauge-circle-bolt:after {
  content: "";
}

.fad.fa-coin-front:after, .fa-duotone.fa-coin-front:after {
  content: "";
}

.fad.fa-file-slash:after, .fa-duotone.fa-file-slash:after {
  content: "";
}

.fad.fa-message-arrow-up-right:after, .fa-duotone.fa-message-arrow-up-right:after {
  content: "";
}

.fad.fa-treasure-chest:after, .fa-duotone.fa-treasure-chest:after {
  content: "";
}

.fad.fa-chess-queen:after, .fa-duotone.fa-chess-queen:after {
  content: "";
}

.fad.fa-paintbrush-fine:after, .fa-duotone.fa-paintbrush-fine:after, .fad.fa-paint-brush-alt:after, .fa-duotone.fa-paint-brush-alt:after, .fad.fa-paint-brush-fine:after, .fa-duotone.fa-paint-brush-fine:after, .fad.fa-paintbrush-alt:after, .fa-duotone.fa-paintbrush-alt:after {
  content: "";
}

.fad.fa-glasses:after, .fa-duotone.fa-glasses:after {
  content: "";
}

.fad.fa-hood-cloak:after, .fa-duotone.fa-hood-cloak:after {
  content: "";
}

.fad.fa-square-quote:after, .fa-duotone.fa-square-quote:after {
  content: "";
}

.fad.fa-up-left:after, .fa-duotone.fa-up-left:after {
  content: "";
}

.fad.fa-bring-front:after, .fa-duotone.fa-bring-front:after {
  content: "";
}

.fad.fa-chess-board:after, .fa-duotone.fa-chess-board:after {
  content: "";
}

.fad.fa-burger-cheese:after, .fa-duotone.fa-burger-cheese:after, .fad.fa-cheeseburger:after, .fa-duotone.fa-cheeseburger:after {
  content: "";
}

.fad.fa-building-circle-check:after, .fa-duotone.fa-building-circle-check:after {
  content: "";
}

.fad.fa-repeat-1:after, .fa-duotone.fa-repeat-1:after {
  content: "";
}

.fad.fa-arrow-down-to-line:after, .fa-duotone.fa-arrow-down-to-line:after, .fad.fa-arrow-to-bottom:after, .fa-duotone.fa-arrow-to-bottom:after {
  content: "";
}

.fad.fa-grid-5:after, .fa-duotone.fa-grid-5:after {
  content: "";
}

.fad.fa-swap-arrows:after, .fa-duotone.fa-swap-arrows:after {
  content: "";
}

.fad.fa-right-long-to-line:after, .fa-duotone.fa-right-long-to-line:after {
  content: "";
}

.fad.fa-person-chalkboard:after, .fa-duotone.fa-person-chalkboard:after {
  content: "";
}

.fad.fa-mars-stroke-right:after, .fa-duotone.fa-mars-stroke-right:after, .fad.fa-mars-stroke-h:after, .fa-duotone.fa-mars-stroke-h:after {
  content: "";
}

.fad.fa-hand-back-fist:after, .fa-duotone.fa-hand-back-fist:after, .fad.fa-hand-rock:after, .fa-duotone.fa-hand-rock:after {
  content: "";
}

.fad.fa-grid-round-5:after, .fa-duotone.fa-grid-round-5:after {
  content: "";
}

.fad.fa-tally:after, .fa-duotone.fa-tally:after, .fad.fa-tally-5:after, .fa-duotone.fa-tally-5:after {
  content: "";
}

.fad.fa-square-caret-up:after, .fa-duotone.fa-square-caret-up:after, .fad.fa-caret-square-up:after, .fa-duotone.fa-caret-square-up:after {
  content: "";
}

.fad.fa-cloud-showers-water:after, .fa-duotone.fa-cloud-showers-water:after {
  content: "";
}

.fad.fa-chart-bar:after, .fa-duotone.fa-chart-bar:after, .fad.fa-bar-chart:after, .fa-duotone.fa-bar-chart:after {
  content: "";
}

.fad.fa-hands-bubbles:after, .fa-duotone.fa-hands-bubbles:after, .fad.fa-hands-wash:after, .fa-duotone.fa-hands-wash:after {
  content: "";
}

.fad.fa-less-than-equal:after, .fa-duotone.fa-less-than-equal:after {
  content: "";
}

.fad.fa-train:after, .fa-duotone.fa-train:after {
  content: "";
}

.fad.fa-up-from-dotted-line:after, .fa-duotone.fa-up-from-dotted-line:after {
  content: "";
}

.fad.fa-eye-low-vision:after, .fa-duotone.fa-eye-low-vision:after, .fad.fa-low-vision:after, .fa-duotone.fa-low-vision:after {
  content: "";
}

.fad.fa-traffic-light-go:after, .fa-duotone.fa-traffic-light-go:after {
  content: "";
}

.fad.fa-face-exhaling:after, .fa-duotone.fa-face-exhaling:after {
  content: "";
}

.fad.fa-sensor-fire:after, .fa-duotone.fa-sensor-fire:after {
  content: "";
}

.fad.fa-user-unlock:after, .fa-duotone.fa-user-unlock:after {
  content: "";
}

.fad.fa-hexagon-divide:after, .fa-duotone.fa-hexagon-divide:after {
  content: "";
}

.fad.fa-00:after, .fa-duotone.fa-00:after {
  content: "";
}

.fad.fa-crow:after, .fa-duotone.fa-crow:after {
  content: "";
}

.fad.fa-cassette-betamax:after, .fa-duotone.fa-cassette-betamax:after, .fad.fa-betamax:after, .fa-duotone.fa-betamax:after {
  content: "";
}

.fad.fa-sailboat:after, .fa-duotone.fa-sailboat:after {
  content: "";
}

.fad.fa-window-restore:after, .fa-duotone.fa-window-restore:after {
  content: "";
}

.fad.fa-nfc-magnifying-glass:after, .fa-duotone.fa-nfc-magnifying-glass:after {
  content: "";
}

.fad.fa-file-binary:after, .fa-duotone.fa-file-binary:after {
  content: "";
}

.fad.fa-circle-v:after, .fa-duotone.fa-circle-v:after {
  content: "";
}

.fad.fa-square-plus:after, .fa-duotone.fa-square-plus:after, .fad.fa-plus-square:after, .fa-duotone.fa-plus-square:after {
  content: "";
}

.fad.fa-bowl-scoops:after, .fa-duotone.fa-bowl-scoops:after {
  content: "";
}

.fad.fa-mistletoe:after, .fa-duotone.fa-mistletoe:after {
  content: "";
}

.fad.fa-custard:after, .fa-duotone.fa-custard:after {
  content: "";
}

.fad.fa-lacrosse-stick:after, .fa-duotone.fa-lacrosse-stick:after {
  content: "";
}

.fad.fa-hockey-mask:after, .fa-duotone.fa-hockey-mask:after {
  content: "";
}

.fad.fa-sunrise:after, .fa-duotone.fa-sunrise:after {
  content: "";
}

.fad.fa-subtitles:after, .fa-duotone.fa-subtitles:after {
  content: "";
}

.fad.fa-panel-ews:after, .fa-duotone.fa-panel-ews:after {
  content: "";
}

.fad.fa-torii-gate:after, .fa-duotone.fa-torii-gate:after {
  content: "";
}

.fad.fa-cloud-exclamation:after, .fa-duotone.fa-cloud-exclamation:after {
  content: "";
}

.fad.fa-message-lines:after, .fa-duotone.fa-message-lines:after, .fad.fa-comment-alt-lines:after, .fa-duotone.fa-comment-alt-lines:after {
  content: "";
}

.fad.fa-frog:after, .fa-duotone.fa-frog:after {
  content: "";
}

.fad.fa-bucket:after, .fa-duotone.fa-bucket:after {
  content: "";
}

.fad.fa-floppy-disk-pen:after, .fa-duotone.fa-floppy-disk-pen:after {
  content: "";
}

.fad.fa-image:after, .fa-duotone.fa-image:after {
  content: "";
}

.fad.fa-window-frame:after, .fa-duotone.fa-window-frame:after {
  content: "";
}

.fad.fa-microphone:after, .fa-duotone.fa-microphone:after {
  content: "";
}

.fad.fa-cow:after, .fa-duotone.fa-cow:after {
  content: "";
}

.fad.fa-file-zip:after, .fa-duotone.fa-file-zip:after {
  content: "";
}

.fad.fa-square-ring:after, .fa-duotone.fa-square-ring:after {
  content: "";
}

.fad.fa-down-from-line:after, .fa-duotone.fa-down-from-line:after, .fad.fa-arrow-alt-from-top:after, .fa-duotone.fa-arrow-alt-from-top:after {
  content: "";
}

.fad.fa-caret-up:after, .fa-duotone.fa-caret-up:after {
  content: "";
}

.fad.fa-shield-xmark:after, .fa-duotone.fa-shield-xmark:after, .fad.fa-shield-times:after, .fa-duotone.fa-shield-times:after {
  content: "";
}

.fad.fa-screwdriver:after, .fa-duotone.fa-screwdriver:after {
  content: "";
}

.fad.fa-circle-sort-down:after, .fa-duotone.fa-circle-sort-down:after, .fad.fa-sort-circle-down:after, .fa-duotone.fa-sort-circle-down:after {
  content: "";
}

.fad.fa-folder-closed:after, .fa-duotone.fa-folder-closed:after {
  content: "";
}

.fad.fa-house-tsunami:after, .fa-duotone.fa-house-tsunami:after {
  content: "";
}

.fad.fa-square-nfi:after, .fa-duotone.fa-square-nfi:after {
  content: "";
}

.fad.fa-forklift:after, .fa-duotone.fa-forklift:after {
  content: "";
}

.fad.fa-arrow-up-from-ground-water:after, .fa-duotone.fa-arrow-up-from-ground-water:after {
  content: "";
}

.fad.fa-bracket-square-right:after, .fa-duotone.fa-bracket-square-right:after {
  content: "]]";
}

.fad.fa-martini-glass:after, .fa-duotone.fa-martini-glass:after, .fad.fa-glass-martini-alt:after, .fa-duotone.fa-glass-martini-alt:after {
  content: "";
}

.fad.fa-rotate-left:after, .fa-duotone.fa-rotate-left:after, .fad.fa-rotate-back:after, .fa-duotone.fa-rotate-back:after, .fad.fa-rotate-backward:after, .fa-duotone.fa-rotate-backward:after, .fad.fa-undo-alt:after, .fa-duotone.fa-undo-alt:after {
  content: "";
}

.fad.fa-table-columns:after, .fa-duotone.fa-table-columns:after, .fad.fa-columns:after, .fa-duotone.fa-columns:after {
  content: "";
}

.fad.fa-square-a:after, .fa-duotone.fa-square-a:after {
  content: "";
}

.fad.fa-tick:after, .fa-duotone.fa-tick:after {
  content: "";
}

.fad.fa-lemon:after, .fa-duotone.fa-lemon:after {
  content: "";
}

.fad.fa-head-side-mask:after, .fa-duotone.fa-head-side-mask:after {
  content: "";
}

.fad.fa-handshake:after, .fa-duotone.fa-handshake:after {
  content: "";
}

.fad.fa-gem:after, .fa-duotone.fa-gem:after {
  content: "";
}

.fad.fa-dolly:after, .fa-duotone.fa-dolly:after, .fad.fa-dolly-box:after, .fa-duotone.fa-dolly-box:after {
  content: "";
}

.fad.fa-smoking:after, .fa-duotone.fa-smoking:after {
  content: "";
}

.fad.fa-minimize:after, .fa-duotone.fa-minimize:after, .fad.fa-compress-arrows-alt:after, .fa-duotone.fa-compress-arrows-alt:after {
  content: "";
}

.fad.fa-refrigerator:after, .fa-duotone.fa-refrigerator:after {
  content: "";
}

.fad.fa-monument:after, .fa-duotone.fa-monument:after {
  content: "";
}

.fad.fa-octagon-xmark:after, .fa-duotone.fa-octagon-xmark:after, .fad.fa-times-octagon:after, .fa-duotone.fa-times-octagon:after, .fad.fa-xmark-octagon:after, .fa-duotone.fa-xmark-octagon:after {
  content: "";
}

.fad.fa-align-slash:after, .fa-duotone.fa-align-slash:after {
  content: "";
}

.fad.fa-snowplow:after, .fa-duotone.fa-snowplow:after {
  content: "";
}

.fad.fa-angles-right:after, .fa-duotone.fa-angles-right:after, .fad.fa-angle-double-right:after, .fa-duotone.fa-angle-double-right:after {
  content: "";
}

.fad.fa-truck-ramp-couch:after, .fa-duotone.fa-truck-ramp-couch:after, .fad.fa-truck-couch:after, .fa-duotone.fa-truck-couch:after {
  content: "";
}

.fad.fa-cannabis:after, .fa-duotone.fa-cannabis:after {
  content: "";
}

.fad.fa-circle-play:after, .fa-duotone.fa-circle-play:after, .fad.fa-play-circle:after, .fa-duotone.fa-play-circle:after {
  content: "";
}

.fad.fa-arrow-up-right-and-arrow-down-left-from-center:after, .fa-duotone.fa-arrow-up-right-and-arrow-down-left-from-center:after {
  content: "";
}

.fad.fa-location-arrow-up:after, .fa-duotone.fa-location-arrow-up:after {
  content: "";
}

.fad.fa-tablets:after, .fa-duotone.fa-tablets:after {
  content: "";
}

.fad.fa-360-degrees:after, .fa-duotone.fa-360-degrees:after {
  content: "";
}

.fad.fa-ethernet:after, .fa-duotone.fa-ethernet:after {
  content: "";
}

.fad.fa-euro-sign:after, .fa-duotone.fa-euro-sign:after, .fad.fa-eur:after, .fa-duotone.fa-eur:after, .fad.fa-euro:after, .fa-duotone.fa-euro:after {
  content: "";
}

.fad.fa-chair:after, .fa-duotone.fa-chair:after {
  content: "";
}

.fad.fa-circle-check:after, .fa-duotone.fa-circle-check:after, .fad.fa-check-circle:after, .fa-duotone.fa-check-circle:after {
  content: "";
}

.fad.fa-square-dashed-circle-plus:after, .fa-duotone.fa-square-dashed-circle-plus:after {
  content: "";
}

.fad.fa-hand-holding-circle-dollar:after, .fa-duotone.fa-hand-holding-circle-dollar:after {
  content: "";
}

.fad.fa-money-simple-from-bracket:after, .fa-duotone.fa-money-simple-from-bracket:after {
  content: "";
}

.fad.fa-bat:after, .fa-duotone.fa-bat:after {
  content: "";
}

.fad.fa-circle-stop:after, .fa-duotone.fa-circle-stop:after, .fad.fa-stop-circle:after, .fa-duotone.fa-stop-circle:after {
  content: "";
}

.fad.fa-head-side-headphones:after, .fa-duotone.fa-head-side-headphones:after {
  content: "";
}

.fad.fa-phone-rotary:after, .fa-duotone.fa-phone-rotary:after {
  content: "";
}

.fad.fa-arrow-up-to-bracket:after, .fa-duotone.fa-arrow-up-to-bracket:after {
  content: "";
}

.fad.fa-compass-drafting:after, .fa-duotone.fa-compass-drafting:after, .fad.fa-drafting-compass:after, .fa-duotone.fa-drafting-compass:after {
  content: "";
}

.fad.fa-plate-wheat:after, .fa-duotone.fa-plate-wheat:after {
  content: "";
}

.fad.fa-calendar-circle-minus:after, .fa-duotone.fa-calendar-circle-minus:after {
  content: "";
}

.fad.fa-chopsticks:after, .fa-duotone.fa-chopsticks:after {
  content: "";
}

.fad.fa-car-wrench:after, .fa-duotone.fa-car-wrench:after, .fad.fa-car-mechanic:after, .fa-duotone.fa-car-mechanic:after {
  content: "";
}

.fad.fa-icicles:after, .fa-duotone.fa-icicles:after {
  content: "";
}

.fad.fa-person-shelter:after, .fa-duotone.fa-person-shelter:after {
  content: "";
}

.fad.fa-neuter:after, .fa-duotone.fa-neuter:after {
  content: "";
}

.fad.fa-id-badge:after, .fa-duotone.fa-id-badge:after {
  content: "";
}

.fad.fa-kazoo:after, .fa-duotone.fa-kazoo:after {
  content: "";
}

.fad.fa-marker:after, .fa-duotone.fa-marker:after {
  content: "";
}

.fad.fa-bin-bottles:after, .fa-duotone.fa-bin-bottles:after {
  content: "";
}

.fad.fa-face-laugh-beam:after, .fa-duotone.fa-face-laugh-beam:after, .fad.fa-laugh-beam:after, .fa-duotone.fa-laugh-beam:after {
  content: "";
}

.fad.fa-square-arrow-down-left:after, .fa-duotone.fa-square-arrow-down-left:after {
  content: "";
}

.fad.fa-battery-bolt:after, .fa-duotone.fa-battery-bolt:after {
  content: "";
}

.fad.fa-tree-large:after, .fa-duotone.fa-tree-large:after {
  content: "";
}

.fad.fa-helicopter-symbol:after, .fa-duotone.fa-helicopter-symbol:after {
  content: "";
}

.fad.fa-aperture:after, .fa-duotone.fa-aperture:after {
  content: "";
}

.fad.fa-universal-access:after, .fa-duotone.fa-universal-access:after {
  content: "";
}

.fad.fa-gear-complex:after, .fa-duotone.fa-gear-complex:after {
  content: "";
}

.fad.fa-file-magnifying-glass:after, .fa-duotone.fa-file-magnifying-glass:after, .fad.fa-file-search:after, .fa-duotone.fa-file-search:after {
  content: "";
}

.fad.fa-up-right:after, .fa-duotone.fa-up-right:after {
  content: "";
}

.fad.fa-circle-chevron-up:after, .fa-duotone.fa-circle-chevron-up:after, .fad.fa-chevron-circle-up:after, .fa-duotone.fa-chevron-circle-up:after {
  content: "";
}

.fad.fa-user-police:after, .fa-duotone.fa-user-police:after {
  content: "";
}

.fad.fa-lari-sign:after, .fa-duotone.fa-lari-sign:after {
  content: "";
}

.fad.fa-volcano:after, .fa-duotone.fa-volcano:after {
  content: "";
}

.fad.fa-teddy-bear:after, .fa-duotone.fa-teddy-bear:after {
  content: "";
}

.fad.fa-stocking:after, .fa-duotone.fa-stocking:after {
  content: "";
}

.fad.fa-person-walking-dashed-line-arrow-right:after, .fa-duotone.fa-person-walking-dashed-line-arrow-right:after {
  content: "";
}

.fad.fa-image-slash:after, .fa-duotone.fa-image-slash:after {
  content: "";
}

.fad.fa-mask-snorkel:after, .fa-duotone.fa-mask-snorkel:after {
  content: "";
}

.fad.fa-smoke:after, .fa-duotone.fa-smoke:after {
  content: "";
}

.fad.fa-sterling-sign:after, .fa-duotone.fa-sterling-sign:after, .fad.fa-gbp:after, .fa-duotone.fa-gbp:after, .fad.fa-pound-sign:after, .fa-duotone.fa-pound-sign:after {
  content: "";
}

.fad.fa-battery-exclamation:after, .fa-duotone.fa-battery-exclamation:after {
  content: "";
}

.fad.fa-viruses:after, .fa-duotone.fa-viruses:after {
  content: "";
}

.fad.fa-square-person-confined:after, .fa-duotone.fa-square-person-confined:after {
  content: "";
}

.fad.fa-user-tie:after, .fa-duotone.fa-user-tie:after {
  content: "";
}

.fad.fa-up-to-bracket:after, .fa-duotone.fa-up-to-bracket:after {
  content: "";
}

.fad.fa-arrow-down-long:after, .fa-duotone.fa-arrow-down-long:after, .fad.fa-long-arrow-down:after, .fa-duotone.fa-long-arrow-down:after {
  content: "";
}

.fad.fa-tent-arrow-down-to-line:after, .fa-duotone.fa-tent-arrow-down-to-line:after {
  content: "";
}

.fad.fa-certificate:after, .fa-duotone.fa-certificate:after {
  content: "";
}

.fad.fa-crystal-ball:after, .fa-duotone.fa-crystal-ball:after {
  content: "";
}

.fad.fa-reply-all:after, .fa-duotone.fa-reply-all:after, .fad.fa-mail-reply-all:after, .fa-duotone.fa-mail-reply-all:after {
  content: "";
}

.fad.fa-suitcase:after, .fa-duotone.fa-suitcase:after {
  content: "";
}

.fad.fa-person-skating:after, .fa-duotone.fa-person-skating:after, .fad.fa-skating:after, .fa-duotone.fa-skating:after {
  content: "";
}

.fad.fa-star-shooting:after, .fa-duotone.fa-star-shooting:after {
  content: "";
}

.fad.fa-binary-lock:after, .fa-duotone.fa-binary-lock:after {
  content: "";
}

.fad.fa-filter-circle-dollar:after, .fa-duotone.fa-filter-circle-dollar:after, .fad.fa-funnel-dollar:after, .fa-duotone.fa-funnel-dollar:after {
  content: "";
}

.fad.fa-camera-retro:after, .fa-duotone.fa-camera-retro:after {
  content: "";
}

.fad.fa-circle-arrow-down:after, .fa-duotone.fa-circle-arrow-down:after, .fad.fa-arrow-circle-down:after, .fa-duotone.fa-arrow-circle-down:after {
  content: "";
}

.fad.fa-comment-pen:after, .fa-duotone.fa-comment-pen:after, .fad.fa-comment-edit:after, .fa-duotone.fa-comment-edit:after {
  content: "";
}

.fad.fa-file-import:after, .fa-duotone.fa-file-import:after, .fad.fa-arrow-right-to-file:after, .fa-duotone.fa-arrow-right-to-file:after {
  content: "";
}

.fad.fa-banjo:after, .fa-duotone.fa-banjo:after {
  content: "";
}

.fad.fa-square-arrow-up-right:after, .fa-duotone.fa-square-arrow-up-right:after, .fad.fa-external-link-square:after, .fa-duotone.fa-external-link-square:after {
  content: "";
}

.fad.fa-light-emergency-on:after, .fa-duotone.fa-light-emergency-on:after {
  content: "";
}

.fad.fa-kerning:after, .fa-duotone.fa-kerning:after {
  content: "";
}

.fad.fa-box-open:after, .fa-duotone.fa-box-open:after {
  content: "";
}

.fad.fa-square-f:after, .fa-duotone.fa-square-f:after {
  content: "";
}

.fad.fa-scroll:after, .fa-duotone.fa-scroll:after {
  content: "";
}

.fad.fa-spa:after, .fa-duotone.fa-spa:after {
  content: "";
}

.fad.fa-arrow-left-from-line:after, .fa-duotone.fa-arrow-left-from-line:after, .fad.fa-arrow-from-right:after, .fa-duotone.fa-arrow-from-right:after {
  content: "";
}

.fad.fa-strawberry:after, .fa-duotone.fa-strawberry:after {
  content: "";
}

.fad.fa-location-pin-lock:after, .fa-duotone.fa-location-pin-lock:after {
  content: "";
}

.fad.fa-pause:after, .fa-duotone.fa-pause:after {
  content: "";
}

.fad.fa-clock-eight-thirty:after, .fa-duotone.fa-clock-eight-thirty:after {
  content: "";
}

.fad.fa-plane-engines:after, .fa-duotone.fa-plane-engines:after, .fad.fa-plane-alt:after, .fa-duotone.fa-plane-alt:after {
  content: "";
}

.fad.fa-hill-avalanche:after, .fa-duotone.fa-hill-avalanche:after {
  content: "";
}

.fad.fa-temperature-empty:after, .fa-duotone.fa-temperature-empty:after, .fad.fa-temperature-0:after, .fa-duotone.fa-temperature-0:after, .fad.fa-thermometer-0:after, .fa-duotone.fa-thermometer-0:after, .fad.fa-thermometer-empty:after, .fa-duotone.fa-thermometer-empty:after {
  content: "";
}

.fad.fa-bomb:after, .fa-duotone.fa-bomb:after {
  content: "";
}

.fad.fa-gauge-low:after, .fa-duotone.fa-gauge-low:after, .fad.fa-tachometer-alt-slow:after, .fa-duotone.fa-tachometer-alt-slow:after {
  content: "";
}

.fad.fa-registered:after, .fa-duotone.fa-registered:after {
  content: "";
}

.fad.fa-trash-can-plus:after, .fa-duotone.fa-trash-can-plus:after {
  content: "";
}

.fad.fa-address-card:after, .fa-duotone.fa-address-card:after, .fad.fa-contact-card:after, .fa-duotone.fa-contact-card:after, .fad.fa-vcard:after, .fa-duotone.fa-vcard:after {
  content: "";
}

.fad.fa-scale-unbalanced-flip:after, .fa-duotone.fa-scale-unbalanced-flip:after, .fad.fa-balance-scale-right:after, .fa-duotone.fa-balance-scale-right:after {
  content: "";
}

.fad.fa-globe-snow:after, .fa-duotone.fa-globe-snow:after {
  content: "";
}

.fad.fa-subscript:after, .fa-duotone.fa-subscript:after {
  content: "";
}

.fad.fa-diamond-turn-right:after, .fa-duotone.fa-diamond-turn-right:after, .fad.fa-directions:after, .fa-duotone.fa-directions:after {
  content: "";
}

.fad.fa-integral:after, .fa-duotone.fa-integral:after {
  content: "";
}

.fad.fa-burst:after, .fa-duotone.fa-burst:after {
  content: "";
}

.fad.fa-house-laptop:after, .fa-duotone.fa-house-laptop:after, .fad.fa-laptop-house:after, .fa-duotone.fa-laptop-house:after {
  content: "";
}

.fad.fa-face-tired:after, .fa-duotone.fa-face-tired:after, .fad.fa-tired:after, .fa-duotone.fa-tired:after {
  content: "";
}

.fad.fa-money-bills:after, .fa-duotone.fa-money-bills:after {
  content: "";
}

.fad.fa-blinds-raised:after, .fa-duotone.fa-blinds-raised:after {
  content: "";
}

.fad.fa-smog:after, .fa-duotone.fa-smog:after {
  content: "";
}

.fad.fa-ufo-beam:after, .fa-duotone.fa-ufo-beam:after {
  content: "";
}

.fad.fa-circle-caret-up:after, .fa-duotone.fa-circle-caret-up:after, .fad.fa-caret-circle-up:after, .fa-duotone.fa-caret-circle-up:after {
  content: "";
}

.fad.fa-user-vneck-hair-long:after, .fa-duotone.fa-user-vneck-hair-long:after {
  content: "";
}

.fad.fa-square-a-lock:after, .fa-duotone.fa-square-a-lock:after {
  content: "";
}

.fad.fa-crutch:after, .fa-duotone.fa-crutch:after {
  content: "";
}

.fad.fa-gas-pump-slash:after, .fa-duotone.fa-gas-pump-slash:after {
  content: "";
}

.fad.fa-cloud-arrow-up:after, .fa-duotone.fa-cloud-arrow-up:after, .fad.fa-cloud-upload:after, .fa-duotone.fa-cloud-upload:after, .fad.fa-cloud-upload-alt:after, .fa-duotone.fa-cloud-upload-alt:after {
  content: "";
}

.fad.fa-palette:after, .fa-duotone.fa-palette:after {
  content: "";
}

.fad.fa-transporter-4:after, .fa-duotone.fa-transporter-4:after {
  content: "";
}

.fad.fa-chart-mixed-up-circle-currency:after, .fa-duotone.fa-chart-mixed-up-circle-currency:after {
  content: "";
}

.fad.fa-objects-align-right:after, .fa-duotone.fa-objects-align-right:after {
  content: "";
}

.fad.fa-arrows-turn-right:after, .fa-duotone.fa-arrows-turn-right:after {
  content: "";
}

.fad.fa-vest:after, .fa-duotone.fa-vest:after {
  content: "";
}

.fad.fa-pig:after, .fa-duotone.fa-pig:after {
  content: "";
}

.fad.fa-inbox-full:after, .fa-duotone.fa-inbox-full:after {
  content: "";
}

.fad.fa-circle-envelope:after, .fa-duotone.fa-circle-envelope:after, .fad.fa-envelope-circle:after, .fa-duotone.fa-envelope-circle:after {
  content: "";
}

.fad.fa-triangle-person-digging:after, .fa-duotone.fa-triangle-person-digging:after, .fad.fa-construction:after, .fa-duotone.fa-construction:after {
  content: "";
}

.fad.fa-ferry:after, .fa-duotone.fa-ferry:after {
  content: "";
}

.fad.fa-bullseye-arrow:after, .fa-duotone.fa-bullseye-arrow:after {
  content: "";
}

.fad.fa-arrows-down-to-people:after, .fa-duotone.fa-arrows-down-to-people:after {
  content: "";
}

.fad.fa-seedling:after, .fa-duotone.fa-seedling:after, .fad.fa-sprout:after, .fa-duotone.fa-sprout:after {
  content: "";
}

.fad.fa-clock-seven:after, .fa-duotone.fa-clock-seven:after {
  content: "";
}

.fad.fa-left-right:after, .fa-duotone.fa-left-right:after, .fad.fa-arrows-alt-h:after, .fa-duotone.fa-arrows-alt-h:after {
  content: "";
}

.fad.fa-boxes-packing:after, .fa-duotone.fa-boxes-packing:after {
  content: "";
}

.fad.fa-circle-arrow-left:after, .fa-duotone.fa-circle-arrow-left:after, .fad.fa-arrow-circle-left:after, .fa-duotone.fa-arrow-circle-left:after {
  content: "";
}

.fad.fa-flashlight:after, .fa-duotone.fa-flashlight:after {
  content: "";
}

.fad.fa-file-jpg:after, .fa-duotone.fa-file-jpg:after {
  content: "";
}

.fad.fa-group-arrows-rotate:after, .fa-duotone.fa-group-arrows-rotate:after {
  content: "";
}

.fad.fa-bowl-food:after, .fa-duotone.fa-bowl-food:after {
  content: "";
}

.fad.fa-square-9:after, .fa-duotone.fa-square-9:after {
  content: "";
}

.fad.fa-candy-cane:after, .fa-duotone.fa-candy-cane:after {
  content: "";
}

.fad.fa-arrow-down-wide-short:after, .fa-duotone.fa-arrow-down-wide-short:after, .fad.fa-sort-amount-asc:after, .fa-duotone.fa-sort-amount-asc:after, .fad.fa-sort-amount-down:after, .fa-duotone.fa-sort-amount-down:after {
  content: "";
}

.fad.fa-square-dollar:after, .fa-duotone.fa-square-dollar:after, .fad.fa-dollar-square:after, .fa-duotone.fa-dollar-square:after, .fad.fa-usd-square:after, .fa-duotone.fa-usd-square:after {
  content: "";
}

.fad.fa-phone-arrow-right:after, .fa-duotone.fa-phone-arrow-right:after {
  content: "";
}

.fad.fa-hand-holding-seedling:after, .fa-duotone.fa-hand-holding-seedling:after {
  content: "";
}

.fad.fa-message-check:after, .fa-duotone.fa-message-check:after, .fad.fa-comment-alt-check:after, .fa-duotone.fa-comment-alt-check:after {
  content: "";
}

.fad.fa-cloud-bolt:after, .fa-duotone.fa-cloud-bolt:after, .fad.fa-thunderstorm:after, .fa-duotone.fa-thunderstorm:after {
  content: "";
}

.fad.fa-chart-line-up-down:after, .fa-duotone.fa-chart-line-up-down:after {
  content: "";
}

.fad.fa-text-slash:after, .fa-duotone.fa-text-slash:after, .fad.fa-remove-format:after, .fa-duotone.fa-remove-format:after {
  content: "";
}

.fad.fa-watch:after, .fa-duotone.fa-watch:after {
  content: "";
}

.fad.fa-circle-down-left:after, .fa-duotone.fa-circle-down-left:after {
  content: "";
}

.fad.fa-text:after, .fa-duotone.fa-text:after {
  content: "";
}

.fad.fa-projector:after, .fa-duotone.fa-projector:after {
  content: "";
}

.fad.fa-face-smile-wink:after, .fa-duotone.fa-face-smile-wink:after, .fad.fa-smile-wink:after, .fa-duotone.fa-smile-wink:after {
  content: "";
}

.fad.fa-tombstone-blank:after, .fa-duotone.fa-tombstone-blank:after, .fad.fa-tombstone-alt:after, .fa-duotone.fa-tombstone-alt:after {
  content: "";
}

.fad.fa-chess-king-piece:after, .fa-duotone.fa-chess-king-piece:after, .fad.fa-chess-king-alt:after, .fa-duotone.fa-chess-king-alt:after {
  content: "";
}

.fad.fa-circle-6:after, .fa-duotone.fa-circle-6:after {
  content: "";
}

.fad.fa-waves-sine:after, .fa-duotone.fa-waves-sine:after {
  content: "";
}

.fad.fa-left:after, .fa-duotone.fa-left:after, .fad.fa-arrow-alt-left:after, .fa-duotone.fa-arrow-alt-left:after {
  content: "";
}

.fad.fa-file-word:after, .fa-duotone.fa-file-word:after {
  content: "";
}

.fad.fa-file-powerpoint:after, .fa-duotone.fa-file-powerpoint:after {
  content: "";
}

.fad.fa-square-down:after, .fa-duotone.fa-square-down:after, .fad.fa-arrow-alt-square-down:after, .fa-duotone.fa-arrow-alt-square-down:after {
  content: "";
}

.fad.fa-objects-align-center-vertical:after, .fa-duotone.fa-objects-align-center-vertical:after {
  content: "";
}

.fad.fa-arrows-left-right:after, .fa-duotone.fa-arrows-left-right:after, .fad.fa-arrows-h:after, .fa-duotone.fa-arrows-h:after {
  content: "";
}

.fad.fa-house-lock:after, .fa-duotone.fa-house-lock:after {
  content: "";
}

.fad.fa-cloud-arrow-down:after, .fa-duotone.fa-cloud-arrow-down:after, .fad.fa-cloud-download:after, .fa-duotone.fa-cloud-download:after, .fad.fa-cloud-download-alt:after, .fa-duotone.fa-cloud-download-alt:after {
  content: "";
}

.fad.fa-wreath:after, .fa-duotone.fa-wreath:after {
  content: "";
}

.fad.fa-children:after, .fa-duotone.fa-children:after {
  content: "";
}

.fad.fa-meter-droplet:after, .fa-duotone.fa-meter-droplet:after {
  content: "";
}

.fad.fa-chalkboard:after, .fa-duotone.fa-chalkboard:after, .fad.fa-blackboard:after, .fa-duotone.fa-blackboard:after {
  content: "";
}

.fad.fa-user-large-slash:after, .fa-duotone.fa-user-large-slash:after, .fad.fa-user-alt-slash:after, .fa-duotone.fa-user-alt-slash:after {
  content: "";
}

.fad.fa-signal-strong:after, .fa-duotone.fa-signal-strong:after, .fad.fa-signal-4:after, .fa-duotone.fa-signal-4:after {
  content: "";
}

.fad.fa-lollipop:after, .fa-duotone.fa-lollipop:after, .fad.fa-lollypop:after, .fa-duotone.fa-lollypop:after {
  content: "";
}

.fad.fa-list-tree:after, .fa-duotone.fa-list-tree:after {
  content: "";
}

.fad.fa-envelope-open:after, .fa-duotone.fa-envelope-open:after {
  content: "";
}

.fad.fa-draw-circle:after, .fa-duotone.fa-draw-circle:after {
  content: "";
}

.fad.fa-cat-space:after, .fa-duotone.fa-cat-space:after {
  content: "";
}

.fad.fa-handshake-simple-slash:after, .fa-duotone.fa-handshake-simple-slash:after, .fad.fa-handshake-alt-slash:after, .fa-duotone.fa-handshake-alt-slash:after {
  content: "";
}

.fad.fa-rabbit-running:after, .fa-duotone.fa-rabbit-running:after, .fad.fa-rabbit-fast:after, .fa-duotone.fa-rabbit-fast:after {
  content: "";
}

.fad.fa-memo-pad:after, .fa-duotone.fa-memo-pad:after {
  content: "";
}

.fad.fa-mattress-pillow:after, .fa-duotone.fa-mattress-pillow:after {
  content: "";
}

.fad.fa-alarm-plus:after, .fa-duotone.fa-alarm-plus:after {
  content: "";
}

.fad.fa-alicorn:after, .fa-duotone.fa-alicorn:after {
  content: "";
}

.fad.fa-comment-question:after, .fa-duotone.fa-comment-question:after {
  content: "";
}

.fad.fa-gingerbread-man:after, .fa-duotone.fa-gingerbread-man:after {
  content: "";
}

.fad.fa-guarani-sign:after, .fa-duotone.fa-guarani-sign:after {
  content: "";
}

.fad.fa-burger-fries:after, .fa-duotone.fa-burger-fries:after {
  content: "";
}

.fad.fa-mug-tea:after, .fa-duotone.fa-mug-tea:after {
  content: "";
}

.fad.fa-border-top:after, .fa-duotone.fa-border-top:after {
  content: "";
}

.fad.fa-arrows-rotate:after, .fa-duotone.fa-arrows-rotate:after, .fad.fa-refresh:after, .fa-duotone.fa-refresh:after, .fad.fa-sync:after, .fa-duotone.fa-sync:after {
  content: "";
}

.fad.fa-circle-book-open:after, .fa-duotone.fa-circle-book-open:after, .fad.fa-book-circle:after, .fa-duotone.fa-book-circle:after {
  content: "";
}

.fad.fa-arrows-to-dotted-line:after, .fa-duotone.fa-arrows-to-dotted-line:after {
  content: "";
}

.fad.fa-fire-extinguisher:after, .fa-duotone.fa-fire-extinguisher:after {
  content: "";
}

.fad.fa-magnifying-glass-arrows-rotate:after, .fa-duotone.fa-magnifying-glass-arrows-rotate:after {
  content: "";
}

.fad.fa-garage-open:after, .fa-duotone.fa-garage-open:after {
  content: "";
}

.fad.fa-shelves-empty:after, .fa-duotone.fa-shelves-empty:after {
  content: "";
}

.fad.fa-cruzeiro-sign:after, .fa-duotone.fa-cruzeiro-sign:after {
  content: "";
}

.fad.fa-watch-apple:after, .fa-duotone.fa-watch-apple:after {
  content: "";
}

.fad.fa-watch-calculator:after, .fa-duotone.fa-watch-calculator:after {
  content: "";
}

.fad.fa-list-dropdown:after, .fa-duotone.fa-list-dropdown:after {
  content: "";
}

.fad.fa-cabinet-filing:after, .fa-duotone.fa-cabinet-filing:after {
  content: "";
}

.fad.fa-burger-soda:after, .fa-duotone.fa-burger-soda:after {
  content: "";
}

.fad.fa-square-arrow-up:after, .fa-duotone.fa-square-arrow-up:after, .fad.fa-arrow-square-up:after, .fa-duotone.fa-arrow-square-up:after {
  content: "";
}

.fad.fa-greater-than-equal:after, .fa-duotone.fa-greater-than-equal:after {
  content: "";
}

.fad.fa-pallet-box:after, .fa-duotone.fa-pallet-box:after {
  content: "";
}

.fad.fa-face-confounded:after, .fa-duotone.fa-face-confounded:after {
  content: "";
}

.fad.fa-shield-halved:after, .fa-duotone.fa-shield-halved:after, .fad.fa-shield-alt:after, .fa-duotone.fa-shield-alt:after {
  content: "";
}

.fad.fa-truck-plow:after, .fa-duotone.fa-truck-plow:after {
  content: "";
}

.fad.fa-book-atlas:after, .fa-duotone.fa-book-atlas:after, .fad.fa-atlas:after, .fa-duotone.fa-atlas:after {
  content: "";
}

.fad.fa-virus:after, .fa-duotone.fa-virus:after {
  content: "";
}

.fad.fa-grid-round-2:after, .fa-duotone.fa-grid-round-2:after {
  content: "";
}

.fad.fa-comment-middle-top:after, .fa-duotone.fa-comment-middle-top:after {
  content: "";
}

.fad.fa-wave:after, .fa-duotone.fa-wave:after {
  content: "";
}

.fad.fa-envelope-circle-check:after, .fa-duotone.fa-envelope-circle-check:after {
  content: "";
}

.fad.fa-layer-group:after, .fa-duotone.fa-layer-group:after {
  content: "";
}

.fad.fa-restroom-simple:after, .fa-duotone.fa-restroom-simple:after {
  content: "";
}

.fad.fa-arrows-to-dot:after, .fa-duotone.fa-arrows-to-dot:after {
  content: "";
}

.fad.fa-border-outer:after, .fa-duotone.fa-border-outer:after {
  content: "";
}

.fad.fa-hashtag-lock:after, .fa-duotone.fa-hashtag-lock:after {
  content: "";
}

.fad.fa-clock-two-thirty:after, .fa-duotone.fa-clock-two-thirty:after {
  content: "";
}

.fad.fa-archway:after, .fa-duotone.fa-archway:after {
  content: "";
}

.fad.fa-heart-circle-check:after, .fa-duotone.fa-heart-circle-check:after {
  content: "";
}

.fad.fa-house-chimney-crack:after, .fa-duotone.fa-house-chimney-crack:after, .fad.fa-house-damage:after, .fa-duotone.fa-house-damage:after {
  content: "";
}

.fad.fa-file-zipper:after, .fa-duotone.fa-file-zipper:after, .fad.fa-file-archive:after, .fa-duotone.fa-file-archive:after {
  content: "";
}

.fad.fa-ticket-perforated:after, .fa-duotone.fa-ticket-perforated:after {
  content: "";
}

.fad.fa-heart-half:after, .fa-duotone.fa-heart-half:after {
  content: "";
}

.fad.fa-comment-check:after, .fa-duotone.fa-comment-check:after {
  content: "";
}

.fad.fa-square:after, .fa-duotone.fa-square:after {
  content: "";
}

.fad.fa-memo:after, .fa-duotone.fa-memo:after {
  content: "";
}

.fad.fa-martini-glass-empty:after, .fa-duotone.fa-martini-glass-empty:after, .fad.fa-glass-martini:after, .fa-duotone.fa-glass-martini:after {
  content: "";
}

.fad.fa-couch:after, .fa-duotone.fa-couch:after {
  content: "";
}

.fad.fa-cedi-sign:after, .fa-duotone.fa-cedi-sign:after {
  content: "";
}

.fad.fa-italic:after, .fa-duotone.fa-italic:after {
  content: "";
}

.fad.fa-glass-citrus:after, .fa-duotone.fa-glass-citrus:after {
  content: "";
}

.fad.fa-calendar-lines-pen:after, .fa-duotone.fa-calendar-lines-pen:after {
  content: "";
}

.fad.fa-table-cells-column-lock:after, .fa-duotone.fa-table-cells-column-lock:after {
  content: "";
}

.fad.fa-church:after, .fa-duotone.fa-church:after {
  content: "";
}

.fad.fa-person-snowmobiling:after, .fa-duotone.fa-person-snowmobiling:after, .fad.fa-snowmobile:after, .fa-duotone.fa-snowmobile:after {
  content: "";
}

.fad.fa-face-hushed:after, .fa-duotone.fa-face-hushed:after {
  content: "";
}

.fad.fa-comments-dollar:after, .fa-duotone.fa-comments-dollar:after {
  content: "";
}

.fad.fa-tickets-simple:after, .fa-duotone.fa-tickets-simple:after {
  content: "";
}

.fad.fa-pickaxe:after, .fa-duotone.fa-pickaxe:after {
  content: "";
}

.fad.fa-link-simple-slash:after, .fa-duotone.fa-link-simple-slash:after {
  content: "";
}

.fad.fa-democrat:after, .fa-duotone.fa-democrat:after {
  content: "";
}

.fad.fa-face-confused:after, .fa-duotone.fa-face-confused:after {
  content: "";
}

.fad.fa-pinball:after, .fa-duotone.fa-pinball:after {
  content: "";
}

.fad.fa-z:after, .fa-duotone.fa-z:after {
  content: "ZZ";
}

.fad.fa-person-skiing:after, .fa-duotone.fa-person-skiing:after, .fad.fa-skiing:after, .fa-duotone.fa-skiing:after {
  content: "";
}

.fad.fa-deer:after, .fa-duotone.fa-deer:after {
  content: "";
}

.fad.fa-input-pipe:after, .fa-duotone.fa-input-pipe:after {
  content: "";
}

.fad.fa-road-lock:after, .fa-duotone.fa-road-lock:after {
  content: "";
}

.fad.fa-a:after, .fa-duotone.fa-a:after {
  content: "AA";
}

.fad.fa-bookmark-slash:after, .fa-duotone.fa-bookmark-slash:after {
  content: "";
}

.fad.fa-temperature-arrow-down:after, .fa-duotone.fa-temperature-arrow-down:after, .fad.fa-temperature-down:after, .fa-duotone.fa-temperature-down:after {
  content: "";
}

.fad.fa-mace:after, .fa-duotone.fa-mace:after {
  content: "";
}

.fad.fa-feather-pointed:after, .fa-duotone.fa-feather-pointed:after, .fad.fa-feather-alt:after, .fa-duotone.fa-feather-alt:after {
  content: "";
}

.fad.fa-sausage:after, .fa-duotone.fa-sausage:after {
  content: "";
}

.fad.fa-trash-can-clock:after, .fa-duotone.fa-trash-can-clock:after {
  content: "";
}

.fad.fa-p:after, .fa-duotone.fa-p:after {
  content: "PP";
}

.fad.fa-broom-wide:after, .fa-duotone.fa-broom-wide:after {
  content: "";
}

.fad.fa-snowflake:after, .fa-duotone.fa-snowflake:after {
  content: "";
}

.fad.fa-stomach:after, .fa-duotone.fa-stomach:after {
  content: "";
}

.fad.fa-newspaper:after, .fa-duotone.fa-newspaper:after {
  content: "";
}

.fad.fa-rectangle-ad:after, .fa-duotone.fa-rectangle-ad:after, .fad.fa-ad:after, .fa-duotone.fa-ad:after {
  content: "";
}

.fad.fa-guitar-electric:after, .fa-duotone.fa-guitar-electric:after {
  content: "";
}

.fad.fa-arrow-turn-down-right:after, .fa-duotone.fa-arrow-turn-down-right:after {
  content: "";
}

.fad.fa-moon-cloud:after, .fa-duotone.fa-moon-cloud:after {
  content: "";
}

.fad.fa-bread-slice-butter:after, .fa-duotone.fa-bread-slice-butter:after {
  content: "";
}

.fad.fa-circle-arrow-right:after, .fa-duotone.fa-circle-arrow-right:after, .fad.fa-arrow-circle-right:after, .fa-duotone.fa-arrow-circle-right:after {
  content: "";
}

.fad.fa-user-group-crown:after, .fa-duotone.fa-user-group-crown:after, .fad.fa-users-crown:after, .fa-duotone.fa-users-crown:after {
  content: "";
}

.fad.fa-circle-i:after, .fa-duotone.fa-circle-i:after {
  content: "";
}

.fad.fa-toilet-paper-check:after, .fa-duotone.fa-toilet-paper-check:after {
  content: "";
}

.fad.fa-filter-circle-xmark:after, .fa-duotone.fa-filter-circle-xmark:after {
  content: "";
}

.fad.fa-locust:after, .fa-duotone.fa-locust:after {
  content: "";
}

.fad.fa-sort:after, .fa-duotone.fa-sort:after, .fad.fa-unsorted:after, .fa-duotone.fa-unsorted:after {
  content: "";
}

.fad.fa-list-ol:after, .fa-duotone.fa-list-ol:after, .fad.fa-list-1-2:after, .fa-duotone.fa-list-1-2:after, .fad.fa-list-numeric:after, .fa-duotone.fa-list-numeric:after {
  content: "";
}

.fad.fa-chart-waterfall:after, .fa-duotone.fa-chart-waterfall:after {
  content: "";
}

.fad.fa-sparkle:after, .fa-duotone.fa-sparkle:after {
  content: "";
}

.fad.fa-face-party:after, .fa-duotone.fa-face-party:after {
  content: "";
}

.fad.fa-kidneys:after, .fa-duotone.fa-kidneys:after {
  content: "";
}

.fad.fa-wifi-exclamation:after, .fa-duotone.fa-wifi-exclamation:after {
  content: "";
}

.fad.fa-chart-network:after, .fa-duotone.fa-chart-network:after {
  content: "";
}

.fad.fa-person-dress-burst:after, .fa-duotone.fa-person-dress-burst:after {
  content: "";
}

.fad.fa-dice-d4:after, .fa-duotone.fa-dice-d4:after {
  content: "";
}

.fad.fa-money-check-dollar:after, .fa-duotone.fa-money-check-dollar:after, .fad.fa-money-check-alt:after, .fa-duotone.fa-money-check-alt:after {
  content: "";
}

.fad.fa-vector-square:after, .fa-duotone.fa-vector-square:after {
  content: "";
}

.fad.fa-bread-slice:after, .fa-duotone.fa-bread-slice:after {
  content: "";
}

.fad.fa-language:after, .fa-duotone.fa-language:after {
  content: "";
}

.fad.fa-wheat-awn-slash:after, .fa-duotone.fa-wheat-awn-slash:after {
  content: "";
}

.fad.fa-face-kiss-wink-heart:after, .fa-duotone.fa-face-kiss-wink-heart:after, .fad.fa-kiss-wink-heart:after, .fa-duotone.fa-kiss-wink-heart:after {
  content: "";
}

.fad.fa-dagger:after, .fa-duotone.fa-dagger:after {
  content: "";
}

.fad.fa-podium:after, .fa-duotone.fa-podium:after {
  content: "";
}

.fad.fa-memo-circle-check:after, .fa-duotone.fa-memo-circle-check:after {
  content: "";
}

.fad.fa-route-highway:after, .fa-duotone.fa-route-highway:after {
  content: "";
}

.fad.fa-down-to-line:after, .fa-duotone.fa-down-to-line:after, .fad.fa-arrow-alt-to-bottom:after, .fa-duotone.fa-arrow-alt-to-bottom:after {
  content: "";
}

.fad.fa-filter:after, .fa-duotone.fa-filter:after {
  content: "";
}

.fad.fa-square-g:after, .fa-duotone.fa-square-g:after {
  content: "";
}

.fad.fa-circle-phone:after, .fa-duotone.fa-circle-phone:after, .fad.fa-phone-circle:after, .fa-duotone.fa-phone-circle:after {
  content: "";
}

.fad.fa-clipboard-prescription:after, .fa-duotone.fa-clipboard-prescription:after {
  content: "";
}

.fad.fa-user-nurse-hair:after, .fa-duotone.fa-user-nurse-hair:after {
  content: "";
}

.fad.fa-question:after, .fa-duotone.fa-question:after {
  content: "??";
}

.fad.fa-file-signature:after, .fa-duotone.fa-file-signature:after {
  content: "";
}

.fad.fa-toggle-large-on:after, .fa-duotone.fa-toggle-large-on:after {
  content: "";
}

.fad.fa-up-down-left-right:after, .fa-duotone.fa-up-down-left-right:after, .fad.fa-arrows-alt:after, .fa-duotone.fa-arrows-alt:after {
  content: "";
}

.fad.fa-dryer-heat:after, .fa-duotone.fa-dryer-heat:after, .fad.fa-dryer-alt:after, .fa-duotone.fa-dryer-alt:after {
  content: "";
}

.fad.fa-house-chimney-user:after, .fa-duotone.fa-house-chimney-user:after {
  content: "";
}

.fad.fa-hand-holding-heart:after, .fa-duotone.fa-hand-holding-heart:after {
  content: "";
}

.fad.fa-arrow-up-small-big:after, .fa-duotone.fa-arrow-up-small-big:after, .fad.fa-sort-size-up-alt:after, .fa-duotone.fa-sort-size-up-alt:after {
  content: "";
}

.fad.fa-train-track:after, .fa-duotone.fa-train-track:after {
  content: "";
}

.fad.fa-puzzle-piece:after, .fa-duotone.fa-puzzle-piece:after {
  content: "";
}

.fad.fa-money-check:after, .fa-duotone.fa-money-check:after {
  content: "";
}

.fad.fa-star-half-stroke:after, .fa-duotone.fa-star-half-stroke:after, .fad.fa-star-half-alt:after, .fa-duotone.fa-star-half-alt:after {
  content: "";
}

.fad.fa-file-exclamation:after, .fa-duotone.fa-file-exclamation:after {
  content: "";
}

.fad.fa-code:after, .fa-duotone.fa-code:after {
  content: "";
}

.fad.fa-whiskey-glass:after, .fa-duotone.fa-whiskey-glass:after, .fad.fa-glass-whiskey:after, .fa-duotone.fa-glass-whiskey:after {
  content: "";
}

.fad.fa-moon-stars:after, .fa-duotone.fa-moon-stars:after {
  content: "";
}

.fad.fa-building-circle-exclamation:after, .fa-duotone.fa-building-circle-exclamation:after {
  content: "";
}

.fad.fa-clothes-hanger:after, .fa-duotone.fa-clothes-hanger:after {
  content: "";
}

.fad.fa-mobile-notch:after, .fa-duotone.fa-mobile-notch:after, .fad.fa-mobile-iphone:after, .fa-duotone.fa-mobile-iphone:after {
  content: "";
}

.fad.fa-magnifying-glass-chart:after, .fa-duotone.fa-magnifying-glass-chart:after {
  content: "";
}

.fad.fa-arrow-up-right-from-square:after, .fa-duotone.fa-arrow-up-right-from-square:after, .fad.fa-external-link:after, .fa-duotone.fa-external-link:after {
  content: "";
}

.fad.fa-cubes-stacked:after, .fa-duotone.fa-cubes-stacked:after {
  content: "";
}

.fad.fa-images-user:after, .fa-duotone.fa-images-user:after {
  content: "";
}

.fad.fa-won-sign:after, .fa-duotone.fa-won-sign:after, .fad.fa-krw:after, .fa-duotone.fa-krw:after, .fad.fa-won:after, .fa-duotone.fa-won:after {
  content: "";
}

.fad.fa-image-polaroid-user:after, .fa-duotone.fa-image-polaroid-user:after {
  content: "";
}

.fad.fa-virus-covid:after, .fa-duotone.fa-virus-covid:after {
  content: "";
}

.fad.fa-square-ellipsis:after, .fa-duotone.fa-square-ellipsis:after {
  content: "";
}

.fad.fa-pie:after, .fa-duotone.fa-pie:after {
  content: "";
}

.fad.fa-chess-knight-piece:after, .fa-duotone.fa-chess-knight-piece:after, .fad.fa-chess-knight-alt:after, .fa-duotone.fa-chess-knight-alt:after {
  content: "";
}

.fad.fa-austral-sign:after, .fa-duotone.fa-austral-sign:after {
  content: "";
}

.fad.fa-cloud-plus:after, .fa-duotone.fa-cloud-plus:after {
  content: "";
}

.fad.fa-f:after, .fa-duotone.fa-f:after {
  content: "FF";
}

.fad.fa-leaf:after, .fa-duotone.fa-leaf:after {
  content: "";
}

.fad.fa-bed-bunk:after, .fa-duotone.fa-bed-bunk:after {
  content: "";
}

.fad.fa-road:after, .fa-duotone.fa-road:after {
  content: "";
}

.fad.fa-taxi:after, .fa-duotone.fa-taxi:after, .fad.fa-cab:after, .fa-duotone.fa-cab:after {
  content: "";
}

.fad.fa-person-circle-plus:after, .fa-duotone.fa-person-circle-plus:after {
  content: "";
}

.fad.fa-chart-pie:after, .fa-duotone.fa-chart-pie:after, .fad.fa-pie-chart:after, .fa-duotone.fa-pie-chart:after {
  content: "";
}

.fad.fa-bolt-lightning:after, .fa-duotone.fa-bolt-lightning:after {
  content: "";
}

.fad.fa-clock-eight:after, .fa-duotone.fa-clock-eight:after {
  content: "";
}

.fad.fa-sack-xmark:after, .fa-duotone.fa-sack-xmark:after {
  content: "";
}

.fad.fa-file-xls:after, .fa-duotone.fa-file-xls:after {
  content: "";
}

.fad.fa-file-excel:after, .fa-duotone.fa-file-excel:after {
  content: "";
}

.fad.fa-file-contract:after, .fa-duotone.fa-file-contract:after {
  content: "";
}

.fad.fa-fish-fins:after, .fa-duotone.fa-fish-fins:after {
  content: "";
}

.fad.fa-circle-q:after, .fa-duotone.fa-circle-q:after {
  content: "";
}

.fad.fa-building-flag:after, .fa-duotone.fa-building-flag:after {
  content: "";
}

.fad.fa-face-grin-beam:after, .fa-duotone.fa-face-grin-beam:after, .fad.fa-grin-beam:after, .fa-duotone.fa-grin-beam:after {
  content: "";
}

.fad.fa-object-ungroup:after, .fa-duotone.fa-object-ungroup:after {
  content: "";
}

.fad.fa-face-disguise:after, .fa-duotone.fa-face-disguise:after {
  content: "";
}

.fad.fa-circle-arrow-down-right:after, .fa-duotone.fa-circle-arrow-down-right:after {
  content: "";
}

.fad.fa-alien-8bit:after, .fa-duotone.fa-alien-8bit:after, .fad.fa-alien-monster:after, .fa-duotone.fa-alien-monster:after {
  content: "";
}

.fad.fa-hand-point-ribbon:after, .fa-duotone.fa-hand-point-ribbon:after {
  content: "";
}

.fad.fa-poop:after, .fa-duotone.fa-poop:after {
  content: "";
}

.fad.fa-object-exclude:after, .fa-duotone.fa-object-exclude:after {
  content: "";
}

.fad.fa-telescope:after, .fa-duotone.fa-telescope:after {
  content: "";
}

.fad.fa-location-pin:after, .fa-duotone.fa-location-pin:after, .fad.fa-map-marker:after, .fa-duotone.fa-map-marker:after {
  content: "";
}

.fad.fa-square-list:after, .fa-duotone.fa-square-list:after {
  content: "";
}

.fad.fa-kaaba:after, .fa-duotone.fa-kaaba:after {
  content: "";
}

.fad.fa-toilet-paper:after, .fa-duotone.fa-toilet-paper:after {
  content: "";
}

.fad.fa-helmet-safety:after, .fa-duotone.fa-helmet-safety:after, .fad.fa-hard-hat:after, .fa-duotone.fa-hard-hat:after, .fad.fa-hat-hard:after, .fa-duotone.fa-hat-hard:after {
  content: "";
}

.fad.fa-comment-code:after, .fa-duotone.fa-comment-code:after {
  content: "";
}

.fad.fa-sim-cards:after, .fa-duotone.fa-sim-cards:after {
  content: "";
}

.fad.fa-starship:after, .fa-duotone.fa-starship:after {
  content: "";
}

.fad.fa-eject:after, .fa-duotone.fa-eject:after {
  content: "";
}

.fad.fa-circle-right:after, .fa-duotone.fa-circle-right:after, .fad.fa-arrow-alt-circle-right:after, .fa-duotone.fa-arrow-alt-circle-right:after {
  content: "";
}

.fad.fa-plane-circle-check:after, .fa-duotone.fa-plane-circle-check:after {
  content: "";
}

.fad.fa-seal:after, .fa-duotone.fa-seal:after {
  content: "";
}

.fad.fa-user-cowboy:after, .fa-duotone.fa-user-cowboy:after {
  content: "";
}

.fad.fa-hexagon-vertical-nft:after, .fa-duotone.fa-hexagon-vertical-nft:after {
  content: "";
}

.fad.fa-face-rolling-eyes:after, .fa-duotone.fa-face-rolling-eyes:after, .fad.fa-meh-rolling-eyes:after, .fa-duotone.fa-meh-rolling-eyes:after {
  content: "";
}

.fad.fa-bread-loaf:after, .fa-duotone.fa-bread-loaf:after {
  content: "";
}

.fad.fa-rings-wedding:after, .fa-duotone.fa-rings-wedding:after {
  content: "";
}

.fad.fa-object-group:after, .fa-duotone.fa-object-group:after {
  content: "";
}

.fad.fa-french-fries:after, .fa-duotone.fa-french-fries:after {
  content: "";
}

.fad.fa-chart-line:after, .fa-duotone.fa-chart-line:after, .fad.fa-line-chart:after, .fa-duotone.fa-line-chart:after {
  content: "";
}

.fad.fa-calendar-arrow-down:after, .fa-duotone.fa-calendar-arrow-down:after, .fad.fa-calendar-download:after, .fa-duotone.fa-calendar-download:after {
  content: "";
}

.fad.fa-send-back:after, .fa-duotone.fa-send-back:after {
  content: "";
}

.fad.fa-mask-ventilator:after, .fa-duotone.fa-mask-ventilator:after {
  content: "";
}

.fad.fa-tickets:after, .fa-duotone.fa-tickets:after {
  content: "";
}

.fad.fa-signature-lock:after, .fa-duotone.fa-signature-lock:after {
  content: "";
}

.fad.fa-arrow-right:after, .fa-duotone.fa-arrow-right:after {
  content: "";
}

.fad.fa-signs-post:after, .fa-duotone.fa-signs-post:after, .fad.fa-map-signs:after, .fa-duotone.fa-map-signs:after {
  content: "";
}

.fad.fa-octagon-plus:after, .fa-duotone.fa-octagon-plus:after, .fad.fa-plus-octagon:after, .fa-duotone.fa-plus-octagon:after {
  content: "";
}

.fad.fa-cash-register:after, .fa-duotone.fa-cash-register:after {
  content: "";
}

.fad.fa-person-circle-question:after, .fa-duotone.fa-person-circle-question:after {
  content: "";
}

.fad.fa-melon-slice:after, .fa-duotone.fa-melon-slice:after {
  content: "";
}

.fad.fa-space-station-moon:after, .fa-duotone.fa-space-station-moon:after {
  content: "";
}

.fad.fa-message-smile:after, .fa-duotone.fa-message-smile:after, .fad.fa-comment-alt-smile:after, .fa-duotone.fa-comment-alt-smile:after {
  content: "";
}

.fad.fa-cup-straw:after, .fa-duotone.fa-cup-straw:after {
  content: "";
}

.fad.fa-left-from-line:after, .fa-duotone.fa-left-from-line:after, .fad.fa-arrow-alt-from-right:after, .fa-duotone.fa-arrow-alt-from-right:after {
  content: "";
}

.fad.fa-h:after, .fa-duotone.fa-h:after {
  content: "HH";
}

.fad.fa-basket-shopping-simple:after, .fa-duotone.fa-basket-shopping-simple:after, .fad.fa-shopping-basket-alt:after, .fa-duotone.fa-shopping-basket-alt:after {
  content: "";
}

.fad.fa-hands-holding-heart:after, .fa-duotone.fa-hands-holding-heart:after, .fad.fa-hands-heart:after, .fa-duotone.fa-hands-heart:after {
  content: "";
}

.fad.fa-clock-nine:after, .fa-duotone.fa-clock-nine:after {
  content: "";
}

.fad.fa-hammer-brush:after, .fa-duotone.fa-hammer-brush:after {
  content: "";
}

.fad.fa-tarp:after, .fa-duotone.fa-tarp:after {
  content: "";
}

.fad.fa-face-sleepy:after, .fa-duotone.fa-face-sleepy:after {
  content: "";
}

.fad.fa-hand-horns:after, .fa-duotone.fa-hand-horns:after {
  content: "";
}

.fad.fa-screwdriver-wrench:after, .fa-duotone.fa-screwdriver-wrench:after, .fad.fa-tools:after, .fa-duotone.fa-tools:after {
  content: "";
}

.fad.fa-arrows-to-eye:after, .fa-duotone.fa-arrows-to-eye:after {
  content: "";
}

.fad.fa-circle-three-quarters:after, .fa-duotone.fa-circle-three-quarters:after {
  content: "";
}

.fad.fa-trophy-star:after, .fa-duotone.fa-trophy-star:after, .fad.fa-trophy-alt:after, .fa-duotone.fa-trophy-alt:after {
  content: "";
}

.fad.fa-plug-circle-bolt:after, .fa-duotone.fa-plug-circle-bolt:after {
  content: "";
}

.fad.fa-face-thermometer:after, .fa-duotone.fa-face-thermometer:after {
  content: "";
}

.fad.fa-grid-round-4:after, .fa-duotone.fa-grid-round-4:after {
  content: "";
}

.fad.fa-sign-posts-wrench:after, .fa-duotone.fa-sign-posts-wrench:after {
  content: "";
}

.fad.fa-shirt-running:after, .fa-duotone.fa-shirt-running:after {
  content: "";
}

.fad.fa-book-circle-arrow-up:after, .fa-duotone.fa-book-circle-arrow-up:after {
  content: "";
}

.fad.fa-face-nauseated:after, .fa-duotone.fa-face-nauseated:after {
  content: "";
}

.fad.fa-heart:after, .fa-duotone.fa-heart:after {
  content: "";
}

.fad.fa-file-chart-pie:after, .fa-duotone.fa-file-chart-pie:after {
  content: "";
}

.fad.fa-mars-and-venus:after, .fa-duotone.fa-mars-and-venus:after {
  content: "";
}

.fad.fa-house-user:after, .fa-duotone.fa-house-user:after, .fad.fa-home-user:after, .fa-duotone.fa-home-user:after {
  content: "";
}

.fad.fa-circle-arrow-down-left:after, .fa-duotone.fa-circle-arrow-down-left:after {
  content: "";
}

.fad.fa-dumpster-fire:after, .fa-duotone.fa-dumpster-fire:after {
  content: "";
}

.fad.fa-hexagon-minus:after, .fa-duotone.fa-hexagon-minus:after, .fad.fa-minus-hexagon:after, .fa-duotone.fa-minus-hexagon:after {
  content: "";
}

.fad.fa-left-to-line:after, .fa-duotone.fa-left-to-line:after, .fad.fa-arrow-alt-to-left:after, .fa-duotone.fa-arrow-alt-to-left:after {
  content: "";
}

.fad.fa-house-crack:after, .fa-duotone.fa-house-crack:after {
  content: "";
}

.fad.fa-paw-simple:after, .fa-duotone.fa-paw-simple:after, .fad.fa-paw-alt:after, .fa-duotone.fa-paw-alt:after {
  content: "";
}

.fad.fa-arrow-left-long-to-line:after, .fa-duotone.fa-arrow-left-long-to-line:after {
  content: "";
}

.fad.fa-brackets-round:after, .fa-duotone.fa-brackets-round:after, .fad.fa-parentheses:after, .fa-duotone.fa-parentheses:after {
  content: "";
}

.fad.fa-martini-glass-citrus:after, .fa-duotone.fa-martini-glass-citrus:after, .fad.fa-cocktail:after, .fa-duotone.fa-cocktail:after {
  content: "";
}

.fad.fa-user-shakespeare:after, .fa-duotone.fa-user-shakespeare:after {
  content: "";
}

.fad.fa-arrow-right-to-arc:after, .fa-duotone.fa-arrow-right-to-arc:after {
  content: "";
}

.fad.fa-face-surprise:after, .fa-duotone.fa-face-surprise:after, .fad.fa-surprise:after, .fa-duotone.fa-surprise:after {
  content: "";
}

.fad.fa-bottle-water:after, .fa-duotone.fa-bottle-water:after {
  content: "";
}

.fad.fa-circle-pause:after, .fa-duotone.fa-circle-pause:after, .fad.fa-pause-circle:after, .fa-duotone.fa-pause-circle:after {
  content: "";
}

.fad.fa-gauge-circle-plus:after, .fa-duotone.fa-gauge-circle-plus:after {
  content: "";
}

.fad.fa-folders:after, .fa-duotone.fa-folders:after {
  content: "";
}

.fad.fa-angel:after, .fa-duotone.fa-angel:after {
  content: "";
}

.fad.fa-value-absolute:after, .fa-duotone.fa-value-absolute:after {
  content: "";
}

.fad.fa-rabbit:after, .fa-duotone.fa-rabbit:after {
  content: "";
}

.fad.fa-toilet-paper-slash:after, .fa-duotone.fa-toilet-paper-slash:after {
  content: "";
}

.fad.fa-circle-euro:after, .fa-duotone.fa-circle-euro:after {
  content: "";
}

.fad.fa-apple-whole:after, .fa-duotone.fa-apple-whole:after, .fad.fa-apple-alt:after, .fa-duotone.fa-apple-alt:after {
  content: "";
}

.fad.fa-kitchen-set:after, .fa-duotone.fa-kitchen-set:after {
  content: "";
}

.fad.fa-diamond-half:after, .fa-duotone.fa-diamond-half:after {
  content: "";
}

.fad.fa-lock-keyhole:after, .fa-duotone.fa-lock-keyhole:after, .fad.fa-lock-alt:after, .fa-duotone.fa-lock-alt:after {
  content: "";
}

.fad.fa-r:after, .fa-duotone.fa-r:after {
  content: "RR";
}

.fad.fa-temperature-quarter:after, .fa-duotone.fa-temperature-quarter:after, .fad.fa-temperature-1:after, .fa-duotone.fa-temperature-1:after, .fad.fa-thermometer-1:after, .fa-duotone.fa-thermometer-1:after, .fad.fa-thermometer-quarter:after, .fa-duotone.fa-thermometer-quarter:after {
  content: "";
}

.fad.fa-square-info:after, .fa-duotone.fa-square-info:after, .fad.fa-info-square:after, .fa-duotone.fa-info-square:after {
  content: "";
}

.fad.fa-wifi-slash:after, .fa-duotone.fa-wifi-slash:after {
  content: "";
}

.fad.fa-toilet-paper-xmark:after, .fa-duotone.fa-toilet-paper-xmark:after {
  content: "";
}

.fad.fa-hands-holding-dollar:after, .fa-duotone.fa-hands-holding-dollar:after, .fad.fa-hands-usd:after, .fa-duotone.fa-hands-usd:after {
  content: "";
}

.fad.fa-cube:after, .fa-duotone.fa-cube:after {
  content: "";
}

.fad.fa-arrow-down-triangle-square:after, .fa-duotone.fa-arrow-down-triangle-square:after, .fad.fa-sort-shapes-down:after, .fa-duotone.fa-sort-shapes-down:after {
  content: "";
}

.fad.fa-bitcoin-sign:after, .fa-duotone.fa-bitcoin-sign:after {
  content: "";
}

.fad.fa-shutters:after, .fa-duotone.fa-shutters:after {
  content: "";
}

.fad.fa-shield-dog:after, .fa-duotone.fa-shield-dog:after {
  content: "";
}

.fad.fa-solar-panel:after, .fa-duotone.fa-solar-panel:after {
  content: "";
}

.fad.fa-lock-open:after, .fa-duotone.fa-lock-open:after {
  content: "";
}

.fad.fa-table-tree:after, .fa-duotone.fa-table-tree:after {
  content: "";
}

.fad.fa-house-chimney-heart:after, .fa-duotone.fa-house-chimney-heart:after {
  content: "";
}

.fad.fa-tally-3:after, .fa-duotone.fa-tally-3:after {
  content: "";
}

.fad.fa-elevator:after, .fa-duotone.fa-elevator:after {
  content: "";
}

.fad.fa-money-bill-transfer:after, .fa-duotone.fa-money-bill-transfer:after {
  content: "";
}

.fad.fa-money-bill-trend-up:after, .fa-duotone.fa-money-bill-trend-up:after {
  content: "";
}

.fad.fa-house-flood-water-circle-arrow-right:after, .fa-duotone.fa-house-flood-water-circle-arrow-right:after {
  content: "";
}

.fad.fa-square-poll-horizontal:after, .fa-duotone.fa-square-poll-horizontal:after, .fad.fa-poll-h:after, .fa-duotone.fa-poll-h:after {
  content: "";
}

.fad.fa-circle:after, .fa-duotone.fa-circle:after {
  content: "";
}

.fad.fa-left-to-bracket:after, .fa-duotone.fa-left-to-bracket:after {
  content: "";
}

.fad.fa-cart-circle-exclamation:after, .fa-duotone.fa-cart-circle-exclamation:after {
  content: "";
}

.fad.fa-sword:after, .fa-duotone.fa-sword:after {
  content: "";
}

.fad.fa-backward-fast:after, .fa-duotone.fa-backward-fast:after, .fad.fa-fast-backward:after, .fa-duotone.fa-fast-backward:after {
  content: "";
}

.fad.fa-recycle:after, .fa-duotone.fa-recycle:after {
  content: "";
}

.fad.fa-user-astronaut:after, .fa-duotone.fa-user-astronaut:after {
  content: "";
}

.fad.fa-interrobang:after, .fa-duotone.fa-interrobang:after {
  content: "";
}

.fad.fa-plane-slash:after, .fa-duotone.fa-plane-slash:after {
  content: "";
}

.fad.fa-circle-dashed:after, .fa-duotone.fa-circle-dashed:after {
  content: "";
}

.fad.fa-trademark:after, .fa-duotone.fa-trademark:after {
  content: "";
}

.fad.fa-basketball:after, .fa-duotone.fa-basketball:after, .fad.fa-basketball-ball:after, .fa-duotone.fa-basketball-ball:after {
  content: "";
}

.fad.fa-fork-knife:after, .fa-duotone.fa-fork-knife:after, .fad.fa-utensils-alt:after, .fa-duotone.fa-utensils-alt:after {
  content: "";
}

.fad.fa-satellite-dish:after, .fa-duotone.fa-satellite-dish:after {
  content: "";
}

.fad.fa-badge-check:after, .fa-duotone.fa-badge-check:after {
  content: "";
}

.fad.fa-circle-up:after, .fa-duotone.fa-circle-up:after, .fad.fa-arrow-alt-circle-up:after, .fa-duotone.fa-arrow-alt-circle-up:after {
  content: "";
}

.fad.fa-slider:after, .fa-duotone.fa-slider:after {
  content: "";
}

.fad.fa-mobile-screen-button:after, .fa-duotone.fa-mobile-screen-button:after, .fad.fa-mobile-alt:after, .fa-duotone.fa-mobile-alt:after {
  content: "";
}

.fad.fa-clock-one-thirty:after, .fa-duotone.fa-clock-one-thirty:after {
  content: "";
}

.fad.fa-inbox-out:after, .fa-duotone.fa-inbox-out:after, .fad.fa-inbox-arrow-up:after, .fa-duotone.fa-inbox-arrow-up:after {
  content: "";
}

.fad.fa-cloud-slash:after, .fa-duotone.fa-cloud-slash:after {
  content: "";
}

.fad.fa-volume-high:after, .fa-duotone.fa-volume-high:after, .fad.fa-volume-up:after, .fa-duotone.fa-volume-up:after {
  content: "";
}

.fad.fa-users-rays:after, .fa-duotone.fa-users-rays:after {
  content: "";
}

.fad.fa-wallet:after, .fa-duotone.fa-wallet:after {
  content: "";
}

.fad.fa-octagon-check:after, .fa-duotone.fa-octagon-check:after {
  content: "";
}

.fad.fa-flatbread-stuffed:after, .fa-duotone.fa-flatbread-stuffed:after {
  content: "";
}

.fad.fa-clipboard-check:after, .fa-duotone.fa-clipboard-check:after {
  content: "";
}

.fad.fa-cart-circle-plus:after, .fa-duotone.fa-cart-circle-plus:after {
  content: "";
}

.fad.fa-truck-clock:after, .fa-duotone.fa-truck-clock:after, .fad.fa-shipping-timed:after, .fa-duotone.fa-shipping-timed:after {
  content: "";
}

.fad.fa-pool-8-ball:after, .fa-duotone.fa-pool-8-ball:after {
  content: "";
}

.fad.fa-file-audio:after, .fa-duotone.fa-file-audio:after {
  content: "";
}

.fad.fa-turn-down-left:after, .fa-duotone.fa-turn-down-left:after {
  content: "";
}

.fad.fa-lock-hashtag:after, .fa-duotone.fa-lock-hashtag:after {
  content: "";
}

.fad.fa-chart-radar:after, .fa-duotone.fa-chart-radar:after {
  content: "";
}

.fad.fa-staff:after, .fa-duotone.fa-staff:after {
  content: "";
}

.fad.fa-burger:after, .fa-duotone.fa-burger:after, .fad.fa-hamburger:after, .fa-duotone.fa-hamburger:after {
  content: "";
}

.fad.fa-utility-pole:after, .fa-duotone.fa-utility-pole:after {
  content: "";
}

.fad.fa-transporter-6:after, .fa-duotone.fa-transporter-6:after {
  content: "";
}

.fad.fa-arrow-turn-left:after, .fa-duotone.fa-arrow-turn-left:after {
  content: "";
}

.fad.fa-wrench:after, .fa-duotone.fa-wrench:after {
  content: "";
}

.fad.fa-bugs:after, .fa-duotone.fa-bugs:after {
  content: "";
}

.fad.fa-vector-polygon:after, .fa-duotone.fa-vector-polygon:after {
  content: "";
}

.fad.fa-diagram-nested:after, .fa-duotone.fa-diagram-nested:after {
  content: "";
}

.fad.fa-rupee-sign:after, .fa-duotone.fa-rupee-sign:after, .fad.fa-rupee:after, .fa-duotone.fa-rupee:after {
  content: "";
}

.fad.fa-file-image:after, .fa-duotone.fa-file-image:after {
  content: "";
}

.fad.fa-circle-question:after, .fa-duotone.fa-circle-question:after, .fad.fa-question-circle:after, .fa-duotone.fa-question-circle:after {
  content: "";
}

.fad.fa-tickets-perforated:after, .fa-duotone.fa-tickets-perforated:after {
  content: "";
}

.fad.fa-image-user:after, .fa-duotone.fa-image-user:after {
  content: "";
}

.fad.fa-buoy:after, .fa-duotone.fa-buoy:after {
  content: "";
}

.fad.fa-plane-departure:after, .fa-duotone.fa-plane-departure:after {
  content: "";
}

.fad.fa-handshake-slash:after, .fa-duotone.fa-handshake-slash:after {
  content: "";
}

.fad.fa-book-bookmark:after, .fa-duotone.fa-book-bookmark:after {
  content: "";
}

.fad.fa-border-center-h:after, .fa-duotone.fa-border-center-h:after {
  content: "";
}

.fad.fa-can-food:after, .fa-duotone.fa-can-food:after {
  content: "";
}

.fad.fa-typewriter:after, .fa-duotone.fa-typewriter:after {
  content: "";
}

.fad.fa-arrow-right-from-arc:after, .fa-duotone.fa-arrow-right-from-arc:after {
  content: "";
}

.fad.fa-circle-k:after, .fa-duotone.fa-circle-k:after {
  content: "";
}

.fad.fa-face-hand-over-mouth:after, .fa-duotone.fa-face-hand-over-mouth:after {
  content: "";
}

.fad.fa-popcorn:after, .fa-duotone.fa-popcorn:after {
  content: "";
}

.fad.fa-house-water:after, .fa-duotone.fa-house-water:after, .fad.fa-house-flood:after, .fa-duotone.fa-house-flood:after {
  content: "";
}

.fad.fa-object-subtract:after, .fa-duotone.fa-object-subtract:after {
  content: "";
}

.fad.fa-code-branch:after, .fa-duotone.fa-code-branch:after {
  content: "";
}

.fad.fa-warehouse-full:after, .fa-duotone.fa-warehouse-full:after, .fad.fa-warehouse-alt:after, .fa-duotone.fa-warehouse-alt:after {
  content: "";
}

.fad.fa-hat-cowboy:after, .fa-duotone.fa-hat-cowboy:after {
  content: "";
}

.fad.fa-bridge:after, .fa-duotone.fa-bridge:after {
  content: "";
}

.fad.fa-phone-flip:after, .fa-duotone.fa-phone-flip:after, .fad.fa-phone-alt:after, .fa-duotone.fa-phone-alt:after {
  content: "";
}

.fad.fa-arrow-down-from-dotted-line:after, .fa-duotone.fa-arrow-down-from-dotted-line:after {
  content: "";
}

.fad.fa-file-doc:after, .fa-duotone.fa-file-doc:after {
  content: "";
}

.fad.fa-square-quarters:after, .fa-duotone.fa-square-quarters:after {
  content: "";
}

.fad.fa-truck-front:after, .fa-duotone.fa-truck-front:after {
  content: "";
}

.fad.fa-cat:after, .fa-duotone.fa-cat:after {
  content: "";
}

.fad.fa-trash-xmark:after, .fa-duotone.fa-trash-xmark:after {
  content: "";
}

.fad.fa-circle-caret-left:after, .fa-duotone.fa-circle-caret-left:after, .fad.fa-caret-circle-left:after, .fa-duotone.fa-caret-circle-left:after {
  content: "";
}

.fad.fa-files:after, .fa-duotone.fa-files:after {
  content: "";
}

.fad.fa-anchor-circle-exclamation:after, .fa-duotone.fa-anchor-circle-exclamation:after {
  content: "";
}

.fad.fa-face-clouds:after, .fa-duotone.fa-face-clouds:after {
  content: "";
}

.fad.fa-user-crown:after, .fa-duotone.fa-user-crown:after {
  content: "";
}

.fad.fa-basket-shopping-plus:after, .fa-duotone.fa-basket-shopping-plus:after {
  content: "";
}

.fad.fa-truck-field:after, .fa-duotone.fa-truck-field:after {
  content: "";
}

.fad.fa-route:after, .fa-duotone.fa-route:after {
  content: "";
}

.fad.fa-cart-circle-check:after, .fa-duotone.fa-cart-circle-check:after {
  content: "";
}

.fad.fa-clipboard-question:after, .fa-duotone.fa-clipboard-question:after {
  content: "";
}

.fad.fa-panorama:after, .fa-duotone.fa-panorama:after {
  content: "";
}

.fad.fa-comment-medical:after, .fa-duotone.fa-comment-medical:after {
  content: "";
}

.fad.fa-teeth-open:after, .fa-duotone.fa-teeth-open:after {
  content: "";
}

.fad.fa-user-tie-hair-long:after, .fa-duotone.fa-user-tie-hair-long:after {
  content: "";
}

.fad.fa-file-circle-minus:after, .fa-duotone.fa-file-circle-minus:after {
  content: "";
}

.fad.fa-head-side-medical:after, .fa-duotone.fa-head-side-medical:after {
  content: "";
}

.fad.fa-arrow-turn-right:after, .fa-duotone.fa-arrow-turn-right:after {
  content: "";
}

.fad.fa-tags:after, .fa-duotone.fa-tags:after {
  content: "";
}

.fad.fa-wine-glass:after, .fa-duotone.fa-wine-glass:after {
  content: "";
}

.fad.fa-forward-fast:after, .fa-duotone.fa-forward-fast:after, .fad.fa-fast-forward:after, .fa-duotone.fa-fast-forward:after {
  content: "";
}

.fad.fa-face-meh-blank:after, .fa-duotone.fa-face-meh-blank:after, .fad.fa-meh-blank:after, .fa-duotone.fa-meh-blank:after {
  content: "";
}

.fad.fa-user-robot:after, .fa-duotone.fa-user-robot:after {
  content: "";
}

.fad.fa-square-parking:after, .fa-duotone.fa-square-parking:after, .fad.fa-parking:after, .fa-duotone.fa-parking:after {
  content: "";
}

.fad.fa-card-diamond:after, .fa-duotone.fa-card-diamond:after {
  content: "";
}

.fad.fa-face-zipper:after, .fa-duotone.fa-face-zipper:after {
  content: "";
}

.fad.fa-face-raised-eyebrow:after, .fa-duotone.fa-face-raised-eyebrow:after {
  content: "";
}

.fad.fa-house-signal:after, .fa-duotone.fa-house-signal:after {
  content: "";
}

.fad.fa-square-chevron-up:after, .fa-duotone.fa-square-chevron-up:after, .fad.fa-chevron-square-up:after, .fa-duotone.fa-chevron-square-up:after {
  content: "";
}

.fad.fa-bars-progress:after, .fa-duotone.fa-bars-progress:after, .fad.fa-tasks-alt:after, .fa-duotone.fa-tasks-alt:after {
  content: "";
}

.fad.fa-faucet-drip:after, .fa-duotone.fa-faucet-drip:after {
  content: "";
}

.fad.fa-arrows-to-line:after, .fa-duotone.fa-arrows-to-line:after {
  content: "";
}

.fad.fa-dolphin:after, .fa-duotone.fa-dolphin:after {
  content: "";
}

.fad.fa-arrow-up-right:after, .fa-duotone.fa-arrow-up-right:after {
  content: "";
}

.fad.fa-circle-r:after, .fa-duotone.fa-circle-r:after {
  content: "";
}

.fad.fa-cart-flatbed:after, .fa-duotone.fa-cart-flatbed:after, .fad.fa-dolly-flatbed:after, .fa-duotone.fa-dolly-flatbed:after {
  content: "";
}

.fad.fa-ban-smoking:after, .fa-duotone.fa-ban-smoking:after, .fad.fa-smoking-ban:after, .fa-duotone.fa-smoking-ban:after {
  content: "";
}

.fad.fa-circle-sort-up:after, .fa-duotone.fa-circle-sort-up:after, .fad.fa-sort-circle-up:after, .fa-duotone.fa-sort-circle-up:after {
  content: "";
}

.fad.fa-terminal:after, .fa-duotone.fa-terminal:after {
  content: "";
}

.fad.fa-mobile-button:after, .fa-duotone.fa-mobile-button:after {
  content: "";
}

.fad.fa-house-medical-flag:after, .fa-duotone.fa-house-medical-flag:after {
  content: "";
}

.fad.fa-basket-shopping:after, .fa-duotone.fa-basket-shopping:after, .fad.fa-shopping-basket:after, .fa-duotone.fa-shopping-basket:after {
  content: "";
}

.fad.fa-tape:after, .fa-duotone.fa-tape:after {
  content: "";
}

.fad.fa-chestnut:after, .fa-duotone.fa-chestnut:after {
  content: "";
}

.fad.fa-bus-simple:after, .fa-duotone.fa-bus-simple:after, .fad.fa-bus-alt:after, .fa-duotone.fa-bus-alt:after {
  content: "";
}

.fad.fa-eye:after, .fa-duotone.fa-eye:after {
  content: "";
}

.fad.fa-face-sad-cry:after, .fa-duotone.fa-face-sad-cry:after, .fad.fa-sad-cry:after, .fa-duotone.fa-sad-cry:after {
  content: "";
}

.fad.fa-heat:after, .fa-duotone.fa-heat:after {
  content: "";
}

.fad.fa-ticket-airline:after, .fa-duotone.fa-ticket-airline:after, .fad.fa-ticket-perforated-plane:after, .fa-duotone.fa-ticket-perforated-plane:after, .fad.fa-ticket-plane:after, .fa-duotone.fa-ticket-plane:after {
  content: "";
}

.fad.fa-boot-heeled:after, .fa-duotone.fa-boot-heeled:after {
  content: "";
}

.fad.fa-arrows-minimize:after, .fa-duotone.fa-arrows-minimize:after, .fad.fa-compress-arrows:after, .fa-duotone.fa-compress-arrows:after {
  content: "";
}

.fad.fa-audio-description:after, .fa-duotone.fa-audio-description:after {
  content: "";
}

.fad.fa-person-military-to-person:after, .fa-duotone.fa-person-military-to-person:after {
  content: "";
}

.fad.fa-file-shield:after, .fa-duotone.fa-file-shield:after {
  content: "";
}

.fad.fa-hexagon:after, .fa-duotone.fa-hexagon:after {
  content: "";
}

.fad.fa-manhole:after, .fa-duotone.fa-manhole:after {
  content: "";
}

.fad.fa-user-slash:after, .fa-duotone.fa-user-slash:after {
  content: "";
}

.fad.fa-pen:after, .fa-duotone.fa-pen:after {
  content: "";
}

.fad.fa-tower-observation:after, .fa-duotone.fa-tower-observation:after {
  content: "";
}

.fad.fa-floppy-disks:after, .fa-duotone.fa-floppy-disks:after {
  content: "";
}

.fad.fa-toilet-paper-blank-under:after, .fa-duotone.fa-toilet-paper-blank-under:after, .fad.fa-toilet-paper-reverse-alt:after, .fa-duotone.fa-toilet-paper-reverse-alt:after {
  content: "";
}

.fad.fa-file-code:after, .fa-duotone.fa-file-code:after {
  content: "";
}

.fad.fa-signal:after, .fa-duotone.fa-signal:after, .fad.fa-signal-5:after, .fa-duotone.fa-signal-5:after, .fad.fa-signal-perfect:after, .fa-duotone.fa-signal-perfect:after {
  content: "";
}

.fad.fa-pump:after, .fa-duotone.fa-pump:after {
  content: "";
}

.fad.fa-bus:after, .fa-duotone.fa-bus:after {
  content: "";
}

.fad.fa-heart-circle-xmark:after, .fa-duotone.fa-heart-circle-xmark:after {
  content: "";
}

.fad.fa-arrow-up-left-from-circle:after, .fa-duotone.fa-arrow-up-left-from-circle:after {
  content: "";
}

.fad.fa-house-chimney:after, .fa-duotone.fa-house-chimney:after, .fad.fa-home-lg:after, .fa-duotone.fa-home-lg:after {
  content: "";
}

.fad.fa-window-maximize:after, .fa-duotone.fa-window-maximize:after {
  content: "";
}

.fad.fa-dryer:after, .fa-duotone.fa-dryer:after {
  content: "";
}

.fad.fa-face-frown:after, .fa-duotone.fa-face-frown:after, .fad.fa-frown:after, .fa-duotone.fa-frown:after {
  content: "";
}

.fad.fa-chess-bishop-piece:after, .fa-duotone.fa-chess-bishop-piece:after, .fad.fa-chess-bishop-alt:after, .fa-duotone.fa-chess-bishop-alt:after {
  content: "";
}

.fad.fa-shirt-tank-top:after, .fa-duotone.fa-shirt-tank-top:after {
  content: "";
}

.fad.fa-diploma:after, .fa-duotone.fa-diploma:after, .fad.fa-scroll-ribbon:after, .fa-duotone.fa-scroll-ribbon:after {
  content: "";
}

.fad.fa-screencast:after, .fa-duotone.fa-screencast:after {
  content: "";
}

.fad.fa-walker:after, .fa-duotone.fa-walker:after {
  content: "";
}

.fad.fa-prescription:after, .fa-duotone.fa-prescription:after {
  content: "";
}

.fad.fa-shop:after, .fa-duotone.fa-shop:after, .fad.fa-store-alt:after, .fa-duotone.fa-store-alt:after {
  content: "";
}

.fad.fa-floppy-disk:after, .fa-duotone.fa-floppy-disk:after, .fad.fa-save:after, .fa-duotone.fa-save:after {
  content: "";
}

.fad.fa-vihara:after, .fa-duotone.fa-vihara:after {
  content: "";
}

.fad.fa-face-kiss-closed-eyes:after, .fa-duotone.fa-face-kiss-closed-eyes:after {
  content: "";
}

.fad.fa-scale-unbalanced:after, .fa-duotone.fa-scale-unbalanced:after, .fad.fa-balance-scale-left:after, .fa-duotone.fa-balance-scale-left:after {
  content: "";
}

.fad.fa-file-user:after, .fa-duotone.fa-file-user:after {
  content: "";
}

.fad.fa-user-police-tie:after, .fa-duotone.fa-user-police-tie:after {
  content: "";
}

.fad.fa-face-tongue-money:after, .fa-duotone.fa-face-tongue-money:after {
  content: "";
}

.fad.fa-tennis-ball:after, .fa-duotone.fa-tennis-ball:after {
  content: "";
}

.fad.fa-square-l:after, .fa-duotone.fa-square-l:after {
  content: "";
}

.fad.fa-sort-up:after, .fa-duotone.fa-sort-up:after, .fad.fa-sort-asc:after, .fa-duotone.fa-sort-asc:after {
  content: "";
}

.fad.fa-calendar-arrow-up:after, .fa-duotone.fa-calendar-arrow-up:after, .fad.fa-calendar-upload:after, .fa-duotone.fa-calendar-upload:after {
  content: "";
}

.fad.fa-comment-dots:after, .fa-duotone.fa-comment-dots:after, .fad.fa-commenting:after, .fa-duotone.fa-commenting:after {
  content: "";
}

.fad.fa-plant-wilt:after, .fa-duotone.fa-plant-wilt:after {
  content: "";
}

.fad.fa-scarf:after, .fa-duotone.fa-scarf:after {
  content: "";
}

.fad.fa-album-circle-plus:after, .fa-duotone.fa-album-circle-plus:after {
  content: "";
}

.fad.fa-user-nurse-hair-long:after, .fa-duotone.fa-user-nurse-hair-long:after {
  content: "";
}

.fad.fa-diamond:after, .fa-duotone.fa-diamond:after {
  content: "";
}

.fad.fa-square-left:after, .fa-duotone.fa-square-left:after, .fad.fa-arrow-alt-square-left:after, .fa-duotone.fa-arrow-alt-square-left:after {
  content: "";
}

.fad.fa-face-grin-squint:after, .fa-duotone.fa-face-grin-squint:after, .fad.fa-grin-squint:after, .fa-duotone.fa-grin-squint:after {
  content: "";
}

.fad.fa-circle-ellipsis-vertical:after, .fa-duotone.fa-circle-ellipsis-vertical:after {
  content: "";
}

.fad.fa-hand-holding-dollar:after, .fa-duotone.fa-hand-holding-dollar:after, .fad.fa-hand-holding-usd:after, .fa-duotone.fa-hand-holding-usd:after {
  content: "";
}

.fad.fa-grid-dividers:after, .fa-duotone.fa-grid-dividers:after {
  content: "";
}

.fad.fa-bacterium:after, .fa-duotone.fa-bacterium:after {
  content: "";
}

.fad.fa-hand-pointer:after, .fa-duotone.fa-hand-pointer:after {
  content: "";
}

.fad.fa-drum-steelpan:after, .fa-duotone.fa-drum-steelpan:after {
  content: "";
}

.fad.fa-hand-scissors:after, .fa-duotone.fa-hand-scissors:after {
  content: "";
}

.fad.fa-hands-praying:after, .fa-duotone.fa-hands-praying:after, .fad.fa-praying-hands:after, .fa-duotone.fa-praying-hands:after {
  content: "";
}

.fad.fa-face-pensive:after, .fa-duotone.fa-face-pensive:after {
  content: "";
}

.fad.fa-user-music:after, .fa-duotone.fa-user-music:after {
  content: "";
}

.fad.fa-arrow-rotate-right:after, .fa-duotone.fa-arrow-rotate-right:after, .fad.fa-arrow-right-rotate:after, .fa-duotone.fa-arrow-right-rotate:after, .fad.fa-arrow-rotate-forward:after, .fa-duotone.fa-arrow-rotate-forward:after, .fad.fa-redo:after, .fa-duotone.fa-redo:after {
  content: "";
}

.fad.fa-messages-dollar:after, .fa-duotone.fa-messages-dollar:after, .fad.fa-comments-alt-dollar:after, .fa-duotone.fa-comments-alt-dollar:after {
  content: "";
}

.fad.fa-sensor-on:after, .fa-duotone.fa-sensor-on:after {
  content: "";
}

.fad.fa-balloon:after, .fa-duotone.fa-balloon:after {
  content: "";
}

.fad.fa-biohazard:after, .fa-duotone.fa-biohazard:after {
  content: "";
}

.fad.fa-chess-queen-piece:after, .fa-duotone.fa-chess-queen-piece:after, .fad.fa-chess-queen-alt:after, .fa-duotone.fa-chess-queen-alt:after {
  content: "";
}

.fad.fa-location-crosshairs:after, .fa-duotone.fa-location-crosshairs:after, .fad.fa-location:after, .fa-duotone.fa-location:after {
  content: "";
}

.fad.fa-mars-double:after, .fa-duotone.fa-mars-double:after {
  content: "";
}

.fad.fa-left-from-bracket:after, .fa-duotone.fa-left-from-bracket:after {
  content: "";
}

.fad.fa-house-person-leave:after, .fa-duotone.fa-house-person-leave:after, .fad.fa-house-leave:after, .fa-duotone.fa-house-leave:after, .fad.fa-house-person-depart:after, .fa-duotone.fa-house-person-depart:after {
  content: "";
}

.fad.fa-ruler-triangle:after, .fa-duotone.fa-ruler-triangle:after {
  content: "";
}

.fad.fa-card-club:after, .fa-duotone.fa-card-club:after {
  content: "";
}

.fad.fa-child-dress:after, .fa-duotone.fa-child-dress:after {
  content: "";
}

.fad.fa-users-between-lines:after, .fa-duotone.fa-users-between-lines:after {
  content: "";
}

.fad.fa-lungs-virus:after, .fa-duotone.fa-lungs-virus:after {
  content: "";
}

.fad.fa-spinner-third:after, .fa-duotone.fa-spinner-third:after {
  content: "";
}

.fad.fa-face-grin-tears:after, .fa-duotone.fa-face-grin-tears:after, .fad.fa-grin-tears:after, .fa-duotone.fa-grin-tears:after {
  content: "";
}

.fad.fa-phone:after, .fa-duotone.fa-phone:after {
  content: "";
}

.fad.fa-computer-mouse-scrollwheel:after, .fa-duotone.fa-computer-mouse-scrollwheel:after, .fad.fa-mouse-alt:after, .fa-duotone.fa-mouse-alt:after {
  content: "";
}

.fad.fa-calendar-xmark:after, .fa-duotone.fa-calendar-xmark:after, .fad.fa-calendar-times:after, .fa-duotone.fa-calendar-times:after {
  content: "";
}

.fad.fa-child-reaching:after, .fa-duotone.fa-child-reaching:after {
  content: "";
}

.fad.fa-table-layout:after, .fa-duotone.fa-table-layout:after {
  content: "";
}

.fad.fa-narwhal:after, .fa-duotone.fa-narwhal:after {
  content: "";
}

.fad.fa-ramp-loading:after, .fa-duotone.fa-ramp-loading:after {
  content: "";
}

.fad.fa-calendar-circle-plus:after, .fa-duotone.fa-calendar-circle-plus:after {
  content: "";
}

.fad.fa-toothbrush:after, .fa-duotone.fa-toothbrush:after {
  content: "";
}

.fad.fa-border-inner:after, .fa-duotone.fa-border-inner:after {
  content: "";
}

.fad.fa-paw-claws:after, .fa-duotone.fa-paw-claws:after {
  content: "";
}

.fad.fa-kiwi-fruit:after, .fa-duotone.fa-kiwi-fruit:after {
  content: "";
}

.fad.fa-traffic-light-slow:after, .fa-duotone.fa-traffic-light-slow:after {
  content: "";
}

.fad.fa-rectangle-code:after, .fa-duotone.fa-rectangle-code:after {
  content: "";
}

.fad.fa-head-side-virus:after, .fa-duotone.fa-head-side-virus:after {
  content: "";
}

.fad.fa-keyboard-brightness:after, .fa-duotone.fa-keyboard-brightness:after {
  content: "";
}

.fad.fa-books-medical:after, .fa-duotone.fa-books-medical:after {
  content: "";
}

.fad.fa-lightbulb-slash:after, .fa-duotone.fa-lightbulb-slash:after {
  content: "";
}

.fad.fa-house-blank:after, .fa-duotone.fa-house-blank:after, .fad.fa-home-blank:after, .fa-duotone.fa-home-blank:after {
  content: "";
}

.fad.fa-square-5:after, .fa-duotone.fa-square-5:after {
  content: "";
}

.fad.fa-square-heart:after, .fa-duotone.fa-square-heart:after, .fad.fa-heart-square:after, .fa-duotone.fa-heart-square:after {
  content: "";
}

.fad.fa-puzzle:after, .fa-duotone.fa-puzzle:after {
  content: "";
}

.fad.fa-user-gear:after, .fa-duotone.fa-user-gear:after, .fad.fa-user-cog:after, .fa-duotone.fa-user-cog:after {
  content: "";
}

.fad.fa-pipe-circle-check:after, .fa-duotone.fa-pipe-circle-check:after {
  content: "";
}

.fad.fa-arrow-up-1-9:after, .fa-duotone.fa-arrow-up-1-9:after, .fad.fa-sort-numeric-up:after, .fa-duotone.fa-sort-numeric-up:after {
  content: "";
}

.fad.fa-octagon-exclamation:after, .fa-duotone.fa-octagon-exclamation:after {
  content: "";
}

.fad.fa-dial-low:after, .fa-duotone.fa-dial-low:after {
  content: "";
}

.fad.fa-door-closed:after, .fa-duotone.fa-door-closed:after {
  content: "";
}

.fad.fa-laptop-mobile:after, .fa-duotone.fa-laptop-mobile:after, .fad.fa-phone-laptop:after, .fa-duotone.fa-phone-laptop:after {
  content: "";
}

.fad.fa-conveyor-belt-boxes:after, .fa-duotone.fa-conveyor-belt-boxes:after, .fad.fa-conveyor-belt-alt:after, .fa-duotone.fa-conveyor-belt-alt:after {
  content: "";
}

.fad.fa-shield-virus:after, .fa-duotone.fa-shield-virus:after {
  content: "";
}

.fad.fa-starfighter-twin-ion-engine-advanced:after, .fa-duotone.fa-starfighter-twin-ion-engine-advanced:after, .fad.fa-starfighter-alt-advanced:after, .fa-duotone.fa-starfighter-alt-advanced:after {
  content: "";
}

.fad.fa-dice-six:after, .fa-duotone.fa-dice-six:after {
  content: "";
}

.fad.fa-starfighter-twin-ion-engine:after, .fa-duotone.fa-starfighter-twin-ion-engine:after, .fad.fa-starfighter-alt:after, .fa-duotone.fa-starfighter-alt:after {
  content: "";
}

.fad.fa-rocket-launch:after, .fa-duotone.fa-rocket-launch:after {
  content: "";
}

.fad.fa-mosquito-net:after, .fa-duotone.fa-mosquito-net:after {
  content: "";
}

.fad.fa-vent-damper:after, .fa-duotone.fa-vent-damper:after {
  content: "";
}

.fad.fa-bridge-water:after, .fa-duotone.fa-bridge-water:after {
  content: "";
}

.fad.fa-ban-bug:after, .fa-duotone.fa-ban-bug:after, .fad.fa-debug:after, .fa-duotone.fa-debug:after {
  content: "";
}

.fad.fa-person-booth:after, .fa-duotone.fa-person-booth:after {
  content: "";
}

.fad.fa-text-width:after, .fa-duotone.fa-text-width:after {
  content: "";
}

.fad.fa-garage-car:after, .fa-duotone.fa-garage-car:after {
  content: "";
}

.fad.fa-square-kanban:after, .fa-duotone.fa-square-kanban:after {
  content: "";
}

.fad.fa-hat-wizard:after, .fa-duotone.fa-hat-wizard:after {
  content: "";
}

.fad.fa-chart-kanban:after, .fa-duotone.fa-chart-kanban:after {
  content: "";
}

.fad.fa-pen-fancy:after, .fa-duotone.fa-pen-fancy:after {
  content: "";
}

.fad.fa-coffee-pot:after, .fa-duotone.fa-coffee-pot:after {
  content: "";
}

.fad.fa-mouse-field:after, .fa-duotone.fa-mouse-field:after {
  content: "";
}

.fad.fa-person-digging:after, .fa-duotone.fa-person-digging:after, .fad.fa-digging:after, .fa-duotone.fa-digging:after {
  content: "";
}

.fad.fa-shower-down:after, .fa-duotone.fa-shower-down:after, .fad.fa-shower-alt:after, .fa-duotone.fa-shower-alt:after {
  content: "";
}

.fad.fa-box-circle-check:after, .fa-duotone.fa-box-circle-check:after {
  content: "";
}

.fad.fa-brightness:after, .fa-duotone.fa-brightness:after {
  content: "";
}

.fad.fa-car-side-bolt:after, .fa-duotone.fa-car-side-bolt:after {
  content: "";
}

.fad.fa-file-xml:after, .fa-duotone.fa-file-xml:after {
  content: "";
}

.fad.fa-ornament:after, .fa-duotone.fa-ornament:after {
  content: "";
}

.fad.fa-phone-arrow-down-left:after, .fa-duotone.fa-phone-arrow-down-left:after, .fad.fa-phone-arrow-down:after, .fa-duotone.fa-phone-arrow-down:after, .fad.fa-phone-incoming:after, .fa-duotone.fa-phone-incoming:after {
  content: "";
}

.fad.fa-cloud-word:after, .fa-duotone.fa-cloud-word:after {
  content: "";
}

.fad.fa-hand-fingers-crossed:after, .fa-duotone.fa-hand-fingers-crossed:after {
  content: "";
}

.fad.fa-trash:after, .fa-duotone.fa-trash:after {
  content: "";
}

.fad.fa-gauge-simple:after, .fa-duotone.fa-gauge-simple:after, .fad.fa-gauge-simple-med:after, .fa-duotone.fa-gauge-simple-med:after, .fad.fa-tachometer-average:after, .fa-duotone.fa-tachometer-average:after {
  content: "";
}

.fad.fa-arrow-down-small-big:after, .fa-duotone.fa-arrow-down-small-big:after, .fad.fa-sort-size-down-alt:after, .fa-duotone.fa-sort-size-down-alt:after {
  content: "";
}

.fad.fa-book-medical:after, .fa-duotone.fa-book-medical:after {
  content: "";
}

.fad.fa-face-melting:after, .fa-duotone.fa-face-melting:after {
  content: "";
}

.fad.fa-poo:after, .fa-duotone.fa-poo:after {
  content: "";
}

.fad.fa-pen-clip-slash:after, .fa-duotone.fa-pen-clip-slash:after, .fad.fa-pen-alt-slash:after, .fa-duotone.fa-pen-alt-slash:after {
  content: "";
}

.fad.fa-quote-right:after, .fa-duotone.fa-quote-right:after, .fad.fa-quote-right-alt:after, .fa-duotone.fa-quote-right-alt:after {
  content: "";
}

.fad.fa-scroll-old:after, .fa-duotone.fa-scroll-old:after {
  content: "";
}

.fad.fa-guitars:after, .fa-duotone.fa-guitars:after {
  content: "";
}

.fad.fa-phone-xmark:after, .fa-duotone.fa-phone-xmark:after {
  content: "";
}

.fad.fa-hose:after, .fa-duotone.fa-hose:after {
  content: "";
}

.fad.fa-clock-six:after, .fa-duotone.fa-clock-six:after {
  content: "";
}

.fad.fa-shirt:after, .fa-duotone.fa-shirt:after, .fad.fa-t-shirt:after, .fa-duotone.fa-t-shirt:after, .fad.fa-tshirt:after, .fa-duotone.fa-tshirt:after {
  content: "";
}

.fad.fa-billboard:after, .fa-duotone.fa-billboard:after {
  content: "";
}

.fad.fa-square-r:after, .fa-duotone.fa-square-r:after {
  content: "";
}

.fad.fa-cubes:after, .fa-duotone.fa-cubes:after {
  content: "";
}

.fad.fa-envelope-open-dollar:after, .fa-duotone.fa-envelope-open-dollar:after {
  content: "";
}

.fad.fa-divide:after, .fa-duotone.fa-divide:after {
  content: "";
}

.fad.fa-sun-cloud:after, .fa-duotone.fa-sun-cloud:after {
  content: "";
}

.fad.fa-lamp-floor:after, .fa-duotone.fa-lamp-floor:after {
  content: "";
}

.fad.fa-square-7:after, .fa-duotone.fa-square-7:after {
  content: "";
}

.fad.fa-tenge-sign:after, .fa-duotone.fa-tenge-sign:after, .fad.fa-tenge:after, .fa-duotone.fa-tenge:after {
  content: "";
}

.fad.fa-headphones:after, .fa-duotone.fa-headphones:after {
  content: "";
}

.fad.fa-hands-holding:after, .fa-duotone.fa-hands-holding:after {
  content: "";
}

.fad.fa-campfire:after, .fa-duotone.fa-campfire:after {
  content: "";
}

.fad.fa-circle-ampersand:after, .fa-duotone.fa-circle-ampersand:after {
  content: "";
}

.fad.fa-snowflakes:after, .fa-duotone.fa-snowflakes:after {
  content: "";
}

.fad.fa-hands-clapping:after, .fa-duotone.fa-hands-clapping:after {
  content: "";
}

.fad.fa-republican:after, .fa-duotone.fa-republican:after {
  content: "";
}

.fad.fa-leaf-maple:after, .fa-duotone.fa-leaf-maple:after {
  content: "";
}

.fad.fa-arrow-left:after, .fa-duotone.fa-arrow-left:after {
  content: "";
}

.fad.fa-person-circle-xmark:after, .fa-duotone.fa-person-circle-xmark:after {
  content: "";
}

.fad.fa-ruler:after, .fa-duotone.fa-ruler:after {
  content: "";
}

.fad.fa-arrow-left-from-bracket:after, .fa-duotone.fa-arrow-left-from-bracket:after {
  content: "";
}

.fad.fa-cup-straw-swoosh:after, .fa-duotone.fa-cup-straw-swoosh:after {
  content: "";
}

.fad.fa-temperature-sun:after, .fa-duotone.fa-temperature-sun:after, .fad.fa-temperature-hot:after, .fa-duotone.fa-temperature-hot:after {
  content: "";
}

.fad.fa-align-left:after, .fa-duotone.fa-align-left:after {
  content: "";
}

.fad.fa-dice-d6:after, .fa-duotone.fa-dice-d6:after {
  content: "";
}

.fad.fa-restroom:after, .fa-duotone.fa-restroom:after {
  content: "";
}

.fad.fa-high-definition:after, .fa-duotone.fa-high-definition:after, .fad.fa-rectangle-hd:after, .fa-duotone.fa-rectangle-hd:after {
  content: "";
}

.fad.fa-j:after, .fa-duotone.fa-j:after {
  content: "JJ";
}

.fad.fa-galaxy:after, .fa-duotone.fa-galaxy:after {
  content: "";
}

.fad.fa-users-viewfinder:after, .fa-duotone.fa-users-viewfinder:after {
  content: "";
}

.fad.fa-file-video:after, .fa-duotone.fa-file-video:after {
  content: "";
}

.fad.fa-cherries:after, .fa-duotone.fa-cherries:after {
  content: "";
}

.fad.fa-up-right-from-square:after, .fa-duotone.fa-up-right-from-square:after, .fad.fa-external-link-alt:after, .fa-duotone.fa-external-link-alt:after {
  content: "";
}

.fad.fa-circle-sort:after, .fa-duotone.fa-circle-sort:after, .fad.fa-sort-circle:after, .fa-duotone.fa-sort-circle:after {
  content: "";
}

.fad.fa-table-cells:after, .fa-duotone.fa-table-cells:after, .fad.fa-th:after, .fa-duotone.fa-th:after {
  content: "";
}

.fad.fa-bag-shopping-minus:after, .fa-duotone.fa-bag-shopping-minus:after {
  content: "";
}

.fad.fa-file-pdf:after, .fa-duotone.fa-file-pdf:after {
  content: "";
}

.fad.fa-siren:after, .fa-duotone.fa-siren:after {
  content: "";
}

.fad.fa-arrow-up-to-dotted-line:after, .fa-duotone.fa-arrow-up-to-dotted-line:after {
  content: "";
}

.fad.fa-image-landscape:after, .fa-duotone.fa-image-landscape:after, .fad.fa-landscape:after, .fa-duotone.fa-landscape:after {
  content: "";
}

.fad.fa-tank-water:after, .fa-duotone.fa-tank-water:after {
  content: "";
}

.fad.fa-curling-stone:after, .fa-duotone.fa-curling-stone:after, .fad.fa-curling:after, .fa-duotone.fa-curling:after {
  content: "";
}

.fad.fa-gamepad-modern:after, .fa-duotone.fa-gamepad-modern:after, .fad.fa-gamepad-alt:after, .fa-duotone.fa-gamepad-alt:after {
  content: "";
}

.fad.fa-messages-question:after, .fa-duotone.fa-messages-question:after {
  content: "";
}

.fad.fa-book-bible:after, .fa-duotone.fa-book-bible:after, .fad.fa-bible:after, .fa-duotone.fa-bible:after {
  content: "";
}

.fad.fa-o:after, .fa-duotone.fa-o:after {
  content: "OO";
}

.fad.fa-suitcase-medical:after, .fa-duotone.fa-suitcase-medical:after, .fad.fa-medkit:after, .fa-duotone.fa-medkit:after {
  content: "";
}

.fad.fa-briefcase-arrow-right:after, .fa-duotone.fa-briefcase-arrow-right:after {
  content: "";
}

.fad.fa-expand-wide:after, .fa-duotone.fa-expand-wide:after {
  content: "";
}

.fad.fa-clock-eleven-thirty:after, .fa-duotone.fa-clock-eleven-thirty:after {
  content: "";
}

.fad.fa-rv:after, .fa-duotone.fa-rv:after {
  content: "";
}

.fad.fa-user-secret:after, .fa-duotone.fa-user-secret:after {
  content: "";
}

.fad.fa-otter:after, .fa-duotone.fa-otter:after {
  content: "";
}

.fad.fa-dreidel:after, .fa-duotone.fa-dreidel:after {
  content: "";
}

.fad.fa-person-dress:after, .fa-duotone.fa-person-dress:after, .fad.fa-female:after, .fa-duotone.fa-female:after {
  content: "";
}

.fad.fa-comment-dollar:after, .fa-duotone.fa-comment-dollar:after {
  content: "";
}

.fad.fa-business-time:after, .fa-duotone.fa-business-time:after, .fad.fa-briefcase-clock:after, .fa-duotone.fa-briefcase-clock:after {
  content: "";
}

.fad.fa-flower-tulip:after, .fa-duotone.fa-flower-tulip:after {
  content: "";
}

.fad.fa-people-pants-simple:after, .fa-duotone.fa-people-pants-simple:after {
  content: "";
}

.fad.fa-cloud-drizzle:after, .fa-duotone.fa-cloud-drizzle:after {
  content: "";
}

.fad.fa-table-cells-large:after, .fa-duotone.fa-table-cells-large:after, .fad.fa-th-large:after, .fa-duotone.fa-th-large:after {
  content: "";
}

.fad.fa-book-tanakh:after, .fa-duotone.fa-book-tanakh:after, .fad.fa-tanakh:after, .fa-duotone.fa-tanakh:after {
  content: "";
}

.fad.fa-solar-system:after, .fa-duotone.fa-solar-system:after {
  content: "";
}

.fad.fa-seal-question:after, .fa-duotone.fa-seal-question:after {
  content: "";
}

.fad.fa-phone-volume:after, .fa-duotone.fa-phone-volume:after, .fad.fa-volume-control-phone:after, .fa-duotone.fa-volume-control-phone:after {
  content: "";
}

.fad.fa-disc-drive:after, .fa-duotone.fa-disc-drive:after {
  content: "";
}

.fad.fa-hat-cowboy-side:after, .fa-duotone.fa-hat-cowboy-side:after {
  content: "";
}

.fad.fa-table-rows:after, .fa-duotone.fa-table-rows:after, .fad.fa-rows:after, .fa-duotone.fa-rows:after {
  content: "";
}

.fad.fa-location-exclamation:after, .fa-duotone.fa-location-exclamation:after, .fad.fa-map-marker-exclamation:after, .fa-duotone.fa-map-marker-exclamation:after {
  content: "";
}

.fad.fa-face-fearful:after, .fa-duotone.fa-face-fearful:after {
  content: "";
}

.fad.fa-clipboard-user:after, .fa-duotone.fa-clipboard-user:after {
  content: "";
}

.fad.fa-bus-school:after, .fa-duotone.fa-bus-school:after {
  content: "";
}

.fad.fa-film-slash:after, .fa-duotone.fa-film-slash:after {
  content: "";
}

.fad.fa-square-arrow-down-right:after, .fa-duotone.fa-square-arrow-down-right:after {
  content: "";
}

.fad.fa-book-sparkles:after, .fa-duotone.fa-book-sparkles:after, .fad.fa-book-spells:after, .fa-duotone.fa-book-spells:after {
  content: "";
}

.fad.fa-washing-machine:after, .fa-duotone.fa-washing-machine:after, .fad.fa-washer:after, .fa-duotone.fa-washer:after {
  content: "";
}

.fad.fa-child:after, .fa-duotone.fa-child:after {
  content: "";
}

.fad.fa-lira-sign:after, .fa-duotone.fa-lira-sign:after {
  content: "";
}

.fad.fa-user-visor:after, .fa-duotone.fa-user-visor:after {
  content: "";
}

.fad.fa-file-plus-minus:after, .fa-duotone.fa-file-plus-minus:after {
  content: "";
}

.fad.fa-chess-clock-flip:after, .fa-duotone.fa-chess-clock-flip:after, .fad.fa-chess-clock-alt:after, .fa-duotone.fa-chess-clock-alt:after {
  content: "";
}

.fad.fa-satellite:after, .fa-duotone.fa-satellite:after {
  content: "";
}

.fad.fa-truck-fire:after, .fa-duotone.fa-truck-fire:after {
  content: "";
}

.fad.fa-plane-lock:after, .fa-duotone.fa-plane-lock:after {
  content: "";
}

.fad.fa-steering-wheel:after, .fa-duotone.fa-steering-wheel:after {
  content: "";
}

.fad.fa-tag:after, .fa-duotone.fa-tag:after {
  content: "";
}

.fad.fa-stretcher:after, .fa-duotone.fa-stretcher:after {
  content: "";
}

.fad.fa-book-section:after, .fa-duotone.fa-book-section:after, .fad.fa-book-law:after, .fa-duotone.fa-book-law:after {
  content: "";
}

.fad.fa-inboxes:after, .fa-duotone.fa-inboxes:after {
  content: "";
}

.fad.fa-coffee-bean:after, .fa-duotone.fa-coffee-bean:after {
  content: "";
}

.fad.fa-circle-yen:after, .fa-duotone.fa-circle-yen:after {
  content: "";
}

.fad.fa-brackets-curly:after, .fa-duotone.fa-brackets-curly:after {
  content: "";
}

.fad.fa-ellipsis-stroke-vertical:after, .fa-duotone.fa-ellipsis-stroke-vertical:after, .fad.fa-ellipsis-v-alt:after, .fa-duotone.fa-ellipsis-v-alt:after {
  content: "";
}

.fad.fa-comment:after, .fa-duotone.fa-comment:after {
  content: "";
}

.fad.fa-square-1:after, .fa-duotone.fa-square-1:after {
  content: "";
}

.fad.fa-cake-candles:after, .fa-duotone.fa-cake-candles:after, .fad.fa-birthday-cake:after, .fa-duotone.fa-birthday-cake:after, .fad.fa-cake:after, .fa-duotone.fa-cake:after {
  content: "";
}

.fad.fa-head-side:after, .fa-duotone.fa-head-side:after {
  content: "";
}

.fad.fa-truck-ladder:after, .fa-duotone.fa-truck-ladder:after {
  content: "";
}

.fad.fa-envelope:after, .fa-duotone.fa-envelope:after {
  content: "";
}

.fad.fa-dolly-empty:after, .fa-duotone.fa-dolly-empty:after {
  content: "";
}

.fad.fa-face-tissue:after, .fa-duotone.fa-face-tissue:after {
  content: "";
}

.fad.fa-angles-up:after, .fa-duotone.fa-angles-up:after, .fad.fa-angle-double-up:after, .fa-duotone.fa-angle-double-up:after {
  content: "";
}

.fad.fa-bin-recycle:after, .fa-duotone.fa-bin-recycle:after {
  content: "";
}

.fad.fa-paperclip:after, .fa-duotone.fa-paperclip:after {
  content: "";
}

.fad.fa-chart-line-down:after, .fa-duotone.fa-chart-line-down:after {
  content: "";
}

.fad.fa-arrow-right-to-city:after, .fa-duotone.fa-arrow-right-to-city:after {
  content: "";
}

.fad.fa-lock-a:after, .fa-duotone.fa-lock-a:after {
  content: "";
}

.fad.fa-ribbon:after, .fa-duotone.fa-ribbon:after {
  content: "";
}

.fad.fa-lungs:after, .fa-duotone.fa-lungs:after {
  content: "";
}

.fad.fa-person-pinball:after, .fa-duotone.fa-person-pinball:after {
  content: "";
}

.fad.fa-arrow-up-9-1:after, .fa-duotone.fa-arrow-up-9-1:after, .fad.fa-sort-numeric-up-alt:after, .fa-duotone.fa-sort-numeric-up-alt:after {
  content: "";
}

.fad.fa-apple-core:after, .fa-duotone.fa-apple-core:after {
  content: "";
}

.fad.fa-circle-y:after, .fa-duotone.fa-circle-y:after {
  content: "";
}

.fad.fa-h6:after, .fa-duotone.fa-h6:after {
  content: "";
}

.fad.fa-litecoin-sign:after, .fa-duotone.fa-litecoin-sign:after {
  content: "";
}

.fad.fa-bottle-baby:after, .fa-duotone.fa-bottle-baby:after {
  content: "";
}

.fad.fa-circle-small:after, .fa-duotone.fa-circle-small:after {
  content: "";
}

.fad.fa-border-none:after, .fa-duotone.fa-border-none:after {
  content: "";
}

.fad.fa-arrow-turn-down-left:after, .fa-duotone.fa-arrow-turn-down-left:after {
  content: "";
}

.fad.fa-circle-wifi-circle-wifi:after, .fa-duotone.fa-circle-wifi-circle-wifi:after, .fad.fa-circle-wifi-group:after, .fa-duotone.fa-circle-wifi-group:after {
  content: "";
}

.fad.fa-circle-nodes:after, .fa-duotone.fa-circle-nodes:after {
  content: "";
}

.fad.fa-parachute-box:after, .fa-duotone.fa-parachute-box:after {
  content: "";
}

.fad.fa-reflect-horizontal:after, .fa-duotone.fa-reflect-horizontal:after {
  content: "";
}

.fad.fa-message-medical:after, .fa-duotone.fa-message-medical:after, .fad.fa-comment-alt-medical:after, .fa-duotone.fa-comment-alt-medical:after {
  content: "";
}

.fad.fa-rugby-ball:after, .fa-duotone.fa-rugby-ball:after {
  content: "";
}

.fad.fa-comment-music:after, .fa-duotone.fa-comment-music:after {
  content: "";
}

.fad.fa-indent:after, .fa-duotone.fa-indent:after {
  content: "";
}

.fad.fa-tree-deciduous:after, .fa-duotone.fa-tree-deciduous:after, .fad.fa-tree-alt:after, .fa-duotone.fa-tree-alt:after {
  content: "";
}

.fad.fa-puzzle-piece-simple:after, .fa-duotone.fa-puzzle-piece-simple:after, .fad.fa-puzzle-piece-alt:after, .fa-duotone.fa-puzzle-piece-alt:after {
  content: "";
}

.fad.fa-truck-field-un:after, .fa-duotone.fa-truck-field-un:after {
  content: "";
}

.fad.fa-nfc-trash:after, .fa-duotone.fa-nfc-trash:after {
  content: "";
}

.fad.fa-hourglass:after, .fa-duotone.fa-hourglass:after, .fad.fa-hourglass-empty:after, .fa-duotone.fa-hourglass-empty:after {
  content: "";
}

.fad.fa-mountain:after, .fa-duotone.fa-mountain:after {
  content: "";
}

.fad.fa-file-xmark:after, .fa-duotone.fa-file-xmark:after, .fad.fa-file-times:after, .fa-duotone.fa-file-times:after {
  content: "";
}

.fad.fa-house-heart:after, .fa-duotone.fa-house-heart:after, .fad.fa-home-heart:after, .fa-duotone.fa-home-heart:after {
  content: "";
}

.fad.fa-house-chimney-blank:after, .fa-duotone.fa-house-chimney-blank:after {
  content: "";
}

.fad.fa-meter-bolt:after, .fa-duotone.fa-meter-bolt:after {
  content: "";
}

.fad.fa-user-doctor:after, .fa-duotone.fa-user-doctor:after, .fad.fa-user-md:after, .fa-duotone.fa-user-md:after {
  content: "";
}

.fad.fa-slash-back:after, .fa-duotone.fa-slash-back:after {
  content: "\\\\";
}

.fad.fa-circle-info:after, .fa-duotone.fa-circle-info:after, .fad.fa-info-circle:after, .fa-duotone.fa-info-circle:after {
  content: "";
}

.fad.fa-fishing-rod:after, .fa-duotone.fa-fishing-rod:after {
  content: "";
}

.fad.fa-hammer-crash:after, .fa-duotone.fa-hammer-crash:after {
  content: "";
}

.fad.fa-message-heart:after, .fa-duotone.fa-message-heart:after {
  content: "";
}

.fad.fa-cloud-meatball:after, .fa-duotone.fa-cloud-meatball:after {
  content: "";
}

.fad.fa-camera-polaroid:after, .fa-duotone.fa-camera-polaroid:after {
  content: "";
}

.fad.fa-camera:after, .fa-duotone.fa-camera:after, .fad.fa-camera-alt:after, .fa-duotone.fa-camera-alt:after {
  content: "";
}

.fad.fa-square-virus:after, .fa-duotone.fa-square-virus:after {
  content: "";
}

.fad.fa-cart-arrow-up:after, .fa-duotone.fa-cart-arrow-up:after {
  content: "";
}

.fad.fa-meteor:after, .fa-duotone.fa-meteor:after {
  content: "";
}

.fad.fa-car-on:after, .fa-duotone.fa-car-on:after {
  content: "";
}

.fad.fa-sleigh:after, .fa-duotone.fa-sleigh:after {
  content: "";
}

.fad.fa-arrow-down-1-9:after, .fa-duotone.fa-arrow-down-1-9:after, .fad.fa-sort-numeric-asc:after, .fa-duotone.fa-sort-numeric-asc:after, .fad.fa-sort-numeric-down:after, .fa-duotone.fa-sort-numeric-down:after {
  content: "";
}

.fad.fa-buoy-mooring:after, .fa-duotone.fa-buoy-mooring:after {
  content: "";
}

.fad.fa-square-4:after, .fa-duotone.fa-square-4:after {
  content: "";
}

.fad.fa-hand-holding-droplet:after, .fa-duotone.fa-hand-holding-droplet:after, .fad.fa-hand-holding-water:after, .fa-duotone.fa-hand-holding-water:after {
  content: "";
}

.fad.fa-file-eps:after, .fa-duotone.fa-file-eps:after {
  content: "";
}

.fad.fa-tricycle-adult:after, .fa-duotone.fa-tricycle-adult:after {
  content: "";
}

.fad.fa-waveform:after, .fa-duotone.fa-waveform:after {
  content: "";
}

.fad.fa-water:after, .fa-duotone.fa-water:after {
  content: "";
}

.fad.fa-star-sharp-half-stroke:after, .fa-duotone.fa-star-sharp-half-stroke:after, .fad.fa-star-sharp-half-alt:after, .fa-duotone.fa-star-sharp-half-alt:after {
  content: "";
}

.fad.fa-nfc-signal:after, .fa-duotone.fa-nfc-signal:after {
  content: "";
}

.fad.fa-plane-prop:after, .fa-duotone.fa-plane-prop:after {
  content: "";
}

.fad.fa-calendar-check:after, .fa-duotone.fa-calendar-check:after {
  content: "";
}

.fad.fa-clock-desk:after, .fa-duotone.fa-clock-desk:after {
  content: "";
}

.fad.fa-calendar-clock:after, .fa-duotone.fa-calendar-clock:after, .fad.fa-calendar-time:after, .fa-duotone.fa-calendar-time:after {
  content: "";
}

.fad.fa-braille:after, .fa-duotone.fa-braille:after {
  content: "";
}

.fad.fa-prescription-bottle-medical:after, .fa-duotone.fa-prescription-bottle-medical:after, .fad.fa-prescription-bottle-alt:after, .fa-duotone.fa-prescription-bottle-alt:after {
  content: "";
}

.fad.fa-plate-utensils:after, .fa-duotone.fa-plate-utensils:after {
  content: "";
}

.fad.fa-family-pants:after, .fa-duotone.fa-family-pants:after {
  content: "";
}

.fad.fa-hose-reel:after, .fa-duotone.fa-hose-reel:after {
  content: "";
}

.fad.fa-house-window:after, .fa-duotone.fa-house-window:after {
  content: "";
}

.fad.fa-landmark:after, .fa-duotone.fa-landmark:after {
  content: "";
}

.fad.fa-truck:after, .fa-duotone.fa-truck:after {
  content: "";
}

.fad.fa-music-magnifying-glass:after, .fa-duotone.fa-music-magnifying-glass:after {
  content: "";
}

.fad.fa-crosshairs:after, .fa-duotone.fa-crosshairs:after {
  content: "";
}

.fad.fa-cloud-rainbow:after, .fa-duotone.fa-cloud-rainbow:after {
  content: "";
}

.fad.fa-person-cane:after, .fa-duotone.fa-person-cane:after {
  content: "";
}

.fad.fa-alien:after, .fa-duotone.fa-alien:after {
  content: "";
}

.fad.fa-tent:after, .fa-duotone.fa-tent:after {
  content: "";
}

.fad.fa-laptop-binary:after, .fa-duotone.fa-laptop-binary:after {
  content: "";
}

.fad.fa-vest-patches:after, .fa-duotone.fa-vest-patches:after {
  content: "";
}

.fad.fa-people-dress-simple:after, .fa-duotone.fa-people-dress-simple:after {
  content: "";
}

.fad.fa-check-double:after, .fa-duotone.fa-check-double:after {
  content: "";
}

.fad.fa-arrow-down-a-z:after, .fa-duotone.fa-arrow-down-a-z:after, .fad.fa-sort-alpha-asc:after, .fa-duotone.fa-sort-alpha-asc:after, .fad.fa-sort-alpha-down:after, .fa-duotone.fa-sort-alpha-down:after {
  content: "";
}

.fad.fa-bowling-ball-pin:after, .fa-duotone.fa-bowling-ball-pin:after {
  content: "";
}

.fad.fa-bell-school-slash:after, .fa-duotone.fa-bell-school-slash:after {
  content: "";
}

.fad.fa-plus-large:after, .fa-duotone.fa-plus-large:after {
  content: "";
}

.fad.fa-money-bill-wheat:after, .fa-duotone.fa-money-bill-wheat:after {
  content: "";
}

.fad.fa-camera-viewfinder:after, .fa-duotone.fa-camera-viewfinder:after, .fad.fa-screenshot:after, .fa-duotone.fa-screenshot:after {
  content: "";
}

.fad.fa-message-music:after, .fa-duotone.fa-message-music:after, .fad.fa-comment-alt-music:after, .fa-duotone.fa-comment-alt-music:after {
  content: "";
}

.fad.fa-car-building:after, .fa-duotone.fa-car-building:after {
  content: "";
}

.fad.fa-border-bottom-right:after, .fa-duotone.fa-border-bottom-right:after, .fad.fa-border-style-alt:after, .fa-duotone.fa-border-style-alt:after {
  content: "";
}

.fad.fa-octagon:after, .fa-duotone.fa-octagon:after {
  content: "";
}

.fad.fa-comment-arrow-up-right:after, .fa-duotone.fa-comment-arrow-up-right:after {
  content: "";
}

.fad.fa-octagon-divide:after, .fa-duotone.fa-octagon-divide:after {
  content: "";
}

.fad.fa-cookie:after, .fa-duotone.fa-cookie:after {
  content: "";
}

.fad.fa-arrow-rotate-left:after, .fa-duotone.fa-arrow-rotate-left:after, .fad.fa-arrow-left-rotate:after, .fa-duotone.fa-arrow-left-rotate:after, .fad.fa-arrow-rotate-back:after, .fa-duotone.fa-arrow-rotate-back:after, .fad.fa-arrow-rotate-backward:after, .fa-duotone.fa-arrow-rotate-backward:after, .fad.fa-undo:after, .fa-duotone.fa-undo:after {
  content: "";
}

.fad.fa-tv-music:after, .fa-duotone.fa-tv-music:after {
  content: "";
}

.fad.fa-hard-drive:after, .fa-duotone.fa-hard-drive:after, .fad.fa-hdd:after, .fa-duotone.fa-hdd:after {
  content: "";
}

.fad.fa-reel:after, .fa-duotone.fa-reel:after {
  content: "";
}

.fad.fa-face-grin-squint-tears:after, .fa-duotone.fa-face-grin-squint-tears:after, .fad.fa-grin-squint-tears:after, .fa-duotone.fa-grin-squint-tears:after {
  content: "";
}

.fad.fa-dumbbell:after, .fa-duotone.fa-dumbbell:after {
  content: "";
}

.fad.fa-rectangle-list:after, .fa-duotone.fa-rectangle-list:after, .fad.fa-list-alt:after, .fa-duotone.fa-list-alt:after {
  content: "";
}

.fad.fa-tarp-droplet:after, .fa-duotone.fa-tarp-droplet:after {
  content: "";
}

.fad.fa-alarm-exclamation:after, .fa-duotone.fa-alarm-exclamation:after {
  content: "";
}

.fad.fa-house-medical-circle-check:after, .fa-duotone.fa-house-medical-circle-check:after {
  content: "";
}

.fad.fa-traffic-cone:after, .fa-duotone.fa-traffic-cone:after {
  content: "";
}

.fad.fa-grate:after, .fa-duotone.fa-grate:after {
  content: "";
}

.fad.fa-arrow-down-right:after, .fa-duotone.fa-arrow-down-right:after {
  content: "";
}

.fad.fa-person-skiing-nordic:after, .fa-duotone.fa-person-skiing-nordic:after, .fad.fa-skiing-nordic:after, .fa-duotone.fa-skiing-nordic:after {
  content: "";
}

.fad.fa-calendar-plus:after, .fa-duotone.fa-calendar-plus:after {
  content: "";
}

.fad.fa-person-from-portal:after, .fa-duotone.fa-person-from-portal:after, .fad.fa-portal-exit:after, .fa-duotone.fa-portal-exit:after {
  content: "";
}

.fad.fa-plane-arrival:after, .fa-duotone.fa-plane-arrival:after {
  content: "";
}

.fad.fa-cowbell-circle-plus:after, .fa-duotone.fa-cowbell-circle-plus:after, .fad.fa-cowbell-more:after, .fa-duotone.fa-cowbell-more:after {
  content: "";
}

.fad.fa-circle-left:after, .fa-duotone.fa-circle-left:after, .fad.fa-arrow-alt-circle-left:after, .fa-duotone.fa-arrow-alt-circle-left:after {
  content: "";
}

.fad.fa-distribute-spacing-vertical:after, .fa-duotone.fa-distribute-spacing-vertical:after {
  content: "";
}

.fad.fa-signal-bars-fair:after, .fa-duotone.fa-signal-bars-fair:after, .fad.fa-signal-alt-2:after, .fa-duotone.fa-signal-alt-2:after {
  content: "";
}

.fad.fa-sportsball:after, .fa-duotone.fa-sportsball:after {
  content: "";
}

.fad.fa-game-console-handheld-crank:after, .fa-duotone.fa-game-console-handheld-crank:after {
  content: "";
}

.fad.fa-train-subway:after, .fa-duotone.fa-train-subway:after, .fad.fa-subway:after, .fa-duotone.fa-subway:after {
  content: "";
}

.fad.fa-chart-gantt:after, .fa-duotone.fa-chart-gantt:after {
  content: "";
}

.fad.fa-face-smile-upside-down:after, .fa-duotone.fa-face-smile-upside-down:after {
  content: "";
}

.fad.fa-ball-pile:after, .fa-duotone.fa-ball-pile:after {
  content: "";
}

.fad.fa-badge-dollar:after, .fa-duotone.fa-badge-dollar:after {
  content: "";
}

.fad.fa-money-bills-simple:after, .fa-duotone.fa-money-bills-simple:after, .fad.fa-money-bills-alt:after, .fa-duotone.fa-money-bills-alt:after {
  content: "";
}

.fad.fa-list-timeline:after, .fa-duotone.fa-list-timeline:after {
  content: "";
}

.fad.fa-indian-rupee-sign:after, .fa-duotone.fa-indian-rupee-sign:after, .fad.fa-indian-rupee:after, .fa-duotone.fa-indian-rupee:after, .fad.fa-inr:after, .fa-duotone.fa-inr:after {
  content: "";
}

.fad.fa-crop-simple:after, .fa-duotone.fa-crop-simple:after, .fad.fa-crop-alt:after, .fa-duotone.fa-crop-alt:after {
  content: "";
}

.fad.fa-money-bill-1:after, .fa-duotone.fa-money-bill-1:after, .fad.fa-money-bill-alt:after, .fa-duotone.fa-money-bill-alt:after {
  content: "";
}

.fad.fa-left-long:after, .fa-duotone.fa-left-long:after, .fad.fa-long-arrow-alt-left:after, .fa-duotone.fa-long-arrow-alt-left:after {
  content: "";
}

.fad.fa-keyboard-down:after, .fa-duotone.fa-keyboard-down:after {
  content: "";
}

.fad.fa-circle-up-right:after, .fa-duotone.fa-circle-up-right:after {
  content: "";
}

.fad.fa-cloud-bolt-moon:after, .fa-duotone.fa-cloud-bolt-moon:after, .fad.fa-thunderstorm-moon:after, .fa-duotone.fa-thunderstorm-moon:after {
  content: "";
}

.fad.fa-turn-left-up:after, .fa-duotone.fa-turn-left-up:after {
  content: "";
}

.fad.fa-dna:after, .fa-duotone.fa-dna:after {
  content: "";
}

.fad.fa-virus-slash:after, .fa-duotone.fa-virus-slash:after {
  content: "";
}

.fad.fa-bracket-round-right:after, .fa-duotone.fa-bracket-round-right:after {
  content: "))";
}

.fad.fa-circle-sterling:after, .fa-duotone.fa-circle-sterling:after {
  content: "";
}

.fad.fa-circle-5:after, .fa-duotone.fa-circle-5:after {
  content: "";
}

.fad.fa-minus:after, .fa-duotone.fa-minus:after, .fad.fa-subtract:after, .fa-duotone.fa-subtract:after {
  content: "";
}

.fad.fa-fire-flame:after, .fa-duotone.fa-fire-flame:after, .fad.fa-flame:after, .fa-duotone.fa-flame:after {
  content: "";
}

.fad.fa-right-to-line:after, .fa-duotone.fa-right-to-line:after, .fad.fa-arrow-alt-to-right:after, .fa-duotone.fa-arrow-alt-to-right:after {
  content: "";
}

.fad.fa-gif:after, .fa-duotone.fa-gif:after {
  content: "";
}

.fad.fa-chess:after, .fa-duotone.fa-chess:after {
  content: "";
}

.fad.fa-trash-slash:after, .fa-duotone.fa-trash-slash:after {
  content: "";
}

.fad.fa-arrow-left-long:after, .fa-duotone.fa-arrow-left-long:after, .fad.fa-long-arrow-left:after, .fa-duotone.fa-long-arrow-left:after {
  content: "";
}

.fad.fa-plug-circle-check:after, .fa-duotone.fa-plug-circle-check:after {
  content: "";
}

.fad.fa-font-case:after, .fa-duotone.fa-font-case:after {
  content: "";
}

.fad.fa-street-view:after, .fa-duotone.fa-street-view:after {
  content: "";
}

.fad.fa-arrow-down-left:after, .fa-duotone.fa-arrow-down-left:after {
  content: "";
}

.fad.fa-franc-sign:after, .fa-duotone.fa-franc-sign:after {
  content: "";
}

.fad.fa-flask-round-poison:after, .fa-duotone.fa-flask-round-poison:after, .fad.fa-flask-poison:after, .fa-duotone.fa-flask-poison:after {
  content: "";
}

.fad.fa-volume-off:after, .fa-duotone.fa-volume-off:after {
  content: "";
}

.fad.fa-book-circle-arrow-right:after, .fa-duotone.fa-book-circle-arrow-right:after {
  content: "";
}

.fad.fa-chart-user:after, .fa-duotone.fa-chart-user:after, .fad.fa-user-chart:after, .fa-duotone.fa-user-chart:after {
  content: "";
}

.fad.fa-hands-asl-interpreting:after, .fa-duotone.fa-hands-asl-interpreting:after, .fad.fa-american-sign-language-interpreting:after, .fa-duotone.fa-american-sign-language-interpreting:after, .fad.fa-asl-interpreting:after, .fa-duotone.fa-asl-interpreting:after, .fad.fa-hands-american-sign-language-interpreting:after, .fa-duotone.fa-hands-american-sign-language-interpreting:after {
  content: "";
}

.fad.fa-presentation-screen:after, .fa-duotone.fa-presentation-screen:after, .fad.fa-presentation:after, .fa-duotone.fa-presentation:after {
  content: "";
}

.fad.fa-circle-bolt:after, .fa-duotone.fa-circle-bolt:after {
  content: "";
}

.fad.fa-face-smile-halo:after, .fa-duotone.fa-face-smile-halo:after {
  content: "";
}

.fad.fa-cart-circle-arrow-down:after, .fa-duotone.fa-cart-circle-arrow-down:after {
  content: "";
}

.fad.fa-house-person-return:after, .fa-duotone.fa-house-person-return:after, .fad.fa-house-person-arrive:after, .fa-duotone.fa-house-person-arrive:after, .fad.fa-house-return:after, .fa-duotone.fa-house-return:after {
  content: "";
}

.fad.fa-message-xmark:after, .fa-duotone.fa-message-xmark:after, .fad.fa-comment-alt-times:after, .fa-duotone.fa-comment-alt-times:after, .fad.fa-message-times:after, .fa-duotone.fa-message-times:after {
  content: "";
}

.fad.fa-file-certificate:after, .fa-duotone.fa-file-certificate:after, .fad.fa-file-award:after, .fa-duotone.fa-file-award:after {
  content: "";
}

.fad.fa-user-doctor-hair-long:after, .fa-duotone.fa-user-doctor-hair-long:after {
  content: "";
}

.fad.fa-camera-security:after, .fa-duotone.fa-camera-security:after, .fad.fa-camera-home:after, .fa-duotone.fa-camera-home:after {
  content: "";
}

.fad.fa-gear:after, .fa-duotone.fa-gear:after, .fad.fa-cog:after, .fa-duotone.fa-cog:after {
  content: "";
}

.fad.fa-droplet-slash:after, .fa-duotone.fa-droplet-slash:after, .fad.fa-tint-slash:after, .fa-duotone.fa-tint-slash:after {
  content: "";
}

.fad.fa-book-heart:after, .fa-duotone.fa-book-heart:after {
  content: "";
}

.fad.fa-mosque:after, .fa-duotone.fa-mosque:after {
  content: "";
}

.fad.fa-duck:after, .fa-duotone.fa-duck:after {
  content: "";
}

.fad.fa-mosquito:after, .fa-duotone.fa-mosquito:after {
  content: "";
}

.fad.fa-star-of-david:after, .fa-duotone.fa-star-of-david:after {
  content: "";
}

.fad.fa-flag-swallowtail:after, .fa-duotone.fa-flag-swallowtail:after, .fad.fa-flag-alt:after, .fa-duotone.fa-flag-alt:after {
  content: "";
}

.fad.fa-person-military-rifle:after, .fa-duotone.fa-person-military-rifle:after {
  content: "";
}

.fad.fa-car-garage:after, .fa-duotone.fa-car-garage:after {
  content: "";
}

.fad.fa-cart-shopping:after, .fa-duotone.fa-cart-shopping:after, .fad.fa-shopping-cart:after, .fa-duotone.fa-shopping-cart:after {
  content: "";
}

.fad.fa-book-font:after, .fa-duotone.fa-book-font:after {
  content: "";
}

.fad.fa-shield-plus:after, .fa-duotone.fa-shield-plus:after {
  content: "";
}

.fad.fa-vials:after, .fa-duotone.fa-vials:after {
  content: "";
}

.fad.fa-eye-dropper-full:after, .fa-duotone.fa-eye-dropper-full:after {
  content: "";
}

.fad.fa-distribute-spacing-horizontal:after, .fa-duotone.fa-distribute-spacing-horizontal:after {
  content: "";
}

.fad.fa-tablet-rugged:after, .fa-duotone.fa-tablet-rugged:after {
  content: "";
}

.fad.fa-temperature-snow:after, .fa-duotone.fa-temperature-snow:after, .fad.fa-temperature-frigid:after, .fa-duotone.fa-temperature-frigid:after {
  content: "";
}

.fad.fa-moped:after, .fa-duotone.fa-moped:after {
  content: "";
}

.fad.fa-face-smile-plus:after, .fa-duotone.fa-face-smile-plus:after, .fad.fa-smile-plus:after, .fa-duotone.fa-smile-plus:after {
  content: "";
}

.fad.fa-radio-tuner:after, .fa-duotone.fa-radio-tuner:after, .fad.fa-radio-alt:after, .fa-duotone.fa-radio-alt:after {
  content: "";
}

.fad.fa-face-swear:after, .fa-duotone.fa-face-swear:after {
  content: "";
}

.fad.fa-water-arrow-down:after, .fa-duotone.fa-water-arrow-down:after, .fad.fa-water-lower:after, .fa-duotone.fa-water-lower:after {
  content: "";
}

.fad.fa-scanner-touchscreen:after, .fa-duotone.fa-scanner-touchscreen:after {
  content: "";
}

.fad.fa-circle-7:after, .fa-duotone.fa-circle-7:after {
  content: "";
}

.fad.fa-plug-circle-plus:after, .fa-duotone.fa-plug-circle-plus:after {
  content: "";
}

.fad.fa-person-ski-jumping:after, .fa-duotone.fa-person-ski-jumping:after, .fad.fa-ski-jump:after, .fa-duotone.fa-ski-jump:after {
  content: "";
}

.fad.fa-place-of-worship:after, .fa-duotone.fa-place-of-worship:after {
  content: "";
}

.fad.fa-water-arrow-up:after, .fa-duotone.fa-water-arrow-up:after, .fad.fa-water-rise:after, .fa-duotone.fa-water-rise:after {
  content: "";
}

.fad.fa-waveform-lines:after, .fa-duotone.fa-waveform-lines:after, .fad.fa-waveform-path:after, .fa-duotone.fa-waveform-path:after {
  content: "";
}

.fad.fa-split:after, .fa-duotone.fa-split:after {
  content: "";
}

.fad.fa-film-canister:after, .fa-duotone.fa-film-canister:after, .fad.fa-film-cannister:after, .fa-duotone.fa-film-cannister:after {
  content: "";
}

.fad.fa-folder-xmark:after, .fa-duotone.fa-folder-xmark:after, .fad.fa-folder-times:after, .fa-duotone.fa-folder-times:after {
  content: "";
}

.fad.fa-toilet-paper-blank:after, .fa-duotone.fa-toilet-paper-blank:after, .fad.fa-toilet-paper-alt:after, .fa-duotone.fa-toilet-paper-alt:after {
  content: "";
}

.fad.fa-tablet-screen:after, .fa-duotone.fa-tablet-screen:after, .fad.fa-tablet-android-alt:after, .fa-duotone.fa-tablet-android-alt:after {
  content: "";
}

.fad.fa-hexagon-vertical-nft-slanted:after, .fa-duotone.fa-hexagon-vertical-nft-slanted:after {
  content: "";
}

.fad.fa-folder-music:after, .fa-duotone.fa-folder-music:after {
  content: "";
}

.fad.fa-display-medical:after, .fa-duotone.fa-display-medical:after, .fad.fa-desktop-medical:after, .fa-duotone.fa-desktop-medical:after {
  content: "";
}

.fad.fa-share-all:after, .fa-duotone.fa-share-all:after {
  content: "";
}

.fad.fa-peapod:after, .fa-duotone.fa-peapod:after {
  content: "";
}

.fad.fa-chess-clock:after, .fa-duotone.fa-chess-clock:after {
  content: "";
}

.fad.fa-axe:after, .fa-duotone.fa-axe:after {
  content: "";
}

.fad.fa-square-d:after, .fa-duotone.fa-square-d:after {
  content: "";
}

.fad.fa-grip-vertical:after, .fa-duotone.fa-grip-vertical:after {
  content: "";
}

.fad.fa-mobile-signal-out:after, .fa-duotone.fa-mobile-signal-out:after {
  content: "";
}

.fad.fa-arrow-turn-up:after, .fa-duotone.fa-arrow-turn-up:after, .fad.fa-level-up:after, .fa-duotone.fa-level-up:after {
  content: "";
}

.fad.fa-u:after, .fa-duotone.fa-u:after {
  content: "UU";
}

.fad.fa-arrow-up-from-dotted-line:after, .fa-duotone.fa-arrow-up-from-dotted-line:after {
  content: "";
}

.fad.fa-square-root-variable:after, .fa-duotone.fa-square-root-variable:after, .fad.fa-square-root-alt:after, .fa-duotone.fa-square-root-alt:after {
  content: "";
}

.fad.fa-light-switch-on:after, .fa-duotone.fa-light-switch-on:after {
  content: "";
}

.fad.fa-arrow-down-arrow-up:after, .fa-duotone.fa-arrow-down-arrow-up:after, .fad.fa-sort-alt:after, .fa-duotone.fa-sort-alt:after {
  content: "";
}

.fad.fa-raindrops:after, .fa-duotone.fa-raindrops:after {
  content: "";
}

.fad.fa-dash:after, .fa-duotone.fa-dash:after, .fad.fa-minus-large:after, .fa-duotone.fa-minus-large:after {
  content: "";
}

.fad.fa-clock:after, .fa-duotone.fa-clock:after, .fad.fa-clock-four:after, .fa-duotone.fa-clock-four:after {
  content: "";
}

.fad.fa-input-numeric:after, .fa-duotone.fa-input-numeric:after {
  content: "";
}

.fad.fa-truck-tow:after, .fa-duotone.fa-truck-tow:after {
  content: "";
}

.fad.fa-backward-step:after, .fa-duotone.fa-backward-step:after, .fad.fa-step-backward:after, .fa-duotone.fa-step-backward:after {
  content: "";
}

.fad.fa-pallet:after, .fa-duotone.fa-pallet:after {
  content: "";
}

.fad.fa-car-bolt:after, .fa-duotone.fa-car-bolt:after {
  content: "";
}

.fad.fa-arrows-maximize:after, .fa-duotone.fa-arrows-maximize:after, .fad.fa-expand-arrows:after, .fa-duotone.fa-expand-arrows:after {
  content: "";
}

.fad.fa-faucet:after, .fa-duotone.fa-faucet:after {
  content: "";
}

.fad.fa-cloud-sleet:after, .fa-duotone.fa-cloud-sleet:after {
  content: "";
}

.fad.fa-lamp-street:after, .fa-duotone.fa-lamp-street:after {
  content: "";
}

.fad.fa-list-radio:after, .fa-duotone.fa-list-radio:after {
  content: "";
}

.fad.fa-pen-nib-slash:after, .fa-duotone.fa-pen-nib-slash:after {
  content: "";
}

.fad.fa-baseball-bat-ball:after, .fa-duotone.fa-baseball-bat-ball:after {
  content: "";
}

.fad.fa-square-up-left:after, .fa-duotone.fa-square-up-left:after {
  content: "";
}

.fad.fa-overline:after, .fa-duotone.fa-overline:after {
  content: "";
}

.fad.fa-s:after, .fa-duotone.fa-s:after {
  content: "SS";
}

.fad.fa-timeline:after, .fa-duotone.fa-timeline:after {
  content: "";
}

.fad.fa-keyboard:after, .fa-duotone.fa-keyboard:after {
  content: "";
}

.fad.fa-arrows-from-dotted-line:after, .fa-duotone.fa-arrows-from-dotted-line:after {
  content: "";
}

.fad.fa-usb-drive:after, .fa-duotone.fa-usb-drive:after {
  content: "";
}

.fad.fa-ballot:after, .fa-duotone.fa-ballot:after {
  content: "";
}

.fad.fa-caret-down:after, .fa-duotone.fa-caret-down:after {
  content: "";
}

.fad.fa-location-dot-slash:after, .fa-duotone.fa-location-dot-slash:after, .fad.fa-map-marker-alt-slash:after, .fa-duotone.fa-map-marker-alt-slash:after {
  content: "";
}

.fad.fa-cards:after, .fa-duotone.fa-cards:after {
  content: "";
}

.fad.fa-house-chimney-medical:after, .fa-duotone.fa-house-chimney-medical:after, .fad.fa-clinic-medical:after, .fa-duotone.fa-clinic-medical:after {
  content: "";
}

.fad.fa-boxing-glove:after, .fa-duotone.fa-boxing-glove:after, .fad.fa-glove-boxing:after, .fa-duotone.fa-glove-boxing:after {
  content: "";
}

.fad.fa-temperature-three-quarters:after, .fa-duotone.fa-temperature-three-quarters:after, .fad.fa-temperature-3:after, .fa-duotone.fa-temperature-3:after, .fad.fa-thermometer-3:after, .fa-duotone.fa-thermometer-3:after, .fad.fa-thermometer-three-quarters:after, .fa-duotone.fa-thermometer-three-quarters:after {
  content: "";
}

.fad.fa-bell-school:after, .fa-duotone.fa-bell-school:after {
  content: "";
}

.fad.fa-mobile-screen:after, .fa-duotone.fa-mobile-screen:after, .fad.fa-mobile-android-alt:after, .fa-duotone.fa-mobile-android-alt:after {
  content: "";
}

.fad.fa-plane-up:after, .fa-duotone.fa-plane-up:after {
  content: "";
}

.fad.fa-folder-heart:after, .fa-duotone.fa-folder-heart:after {
  content: "";
}

.fad.fa-circle-location-arrow:after, .fa-duotone.fa-circle-location-arrow:after, .fad.fa-location-circle:after, .fa-duotone.fa-location-circle:after {
  content: "";
}

.fad.fa-face-head-bandage:after, .fa-duotone.fa-face-head-bandage:after {
  content: "";
}

.fad.fa-sushi-roll:after, .fa-duotone.fa-sushi-roll:after, .fad.fa-maki-roll:after, .fa-duotone.fa-maki-roll:after, .fad.fa-makizushi:after, .fa-duotone.fa-makizushi:after {
  content: "";
}

.fad.fa-car-bump:after, .fa-duotone.fa-car-bump:after {
  content: "";
}

.fad.fa-piggy-bank:after, .fa-duotone.fa-piggy-bank:after {
  content: "";
}

.fad.fa-racquet:after, .fa-duotone.fa-racquet:after {
  content: "";
}

.fad.fa-car-mirrors:after, .fa-duotone.fa-car-mirrors:after {
  content: "";
}

.fad.fa-industry-windows:after, .fa-duotone.fa-industry-windows:after, .fad.fa-industry-alt:after, .fa-duotone.fa-industry-alt:after {
  content: "";
}

.fad.fa-bolt-auto:after, .fa-duotone.fa-bolt-auto:after {
  content: "";
}

.fad.fa-battery-half:after, .fa-duotone.fa-battery-half:after, .fad.fa-battery-3:after, .fa-duotone.fa-battery-3:after {
  content: "";
}

.fad.fa-flux-capacitor:after, .fa-duotone.fa-flux-capacitor:after {
  content: "";
}

.fad.fa-mountain-city:after, .fa-duotone.fa-mountain-city:after {
  content: "";
}

.fad.fa-coins:after, .fa-duotone.fa-coins:after {
  content: "";
}

.fad.fa-honey-pot:after, .fa-duotone.fa-honey-pot:after {
  content: "";
}

.fad.fa-olive:after, .fa-duotone.fa-olive:after {
  content: "";
}

.fad.fa-khanda:after, .fa-duotone.fa-khanda:after {
  content: "";
}

.fad.fa-filter-list:after, .fa-duotone.fa-filter-list:after {
  content: "";
}

.fad.fa-outlet:after, .fa-duotone.fa-outlet:after {
  content: "";
}

.fad.fa-sliders:after, .fa-duotone.fa-sliders:after, .fad.fa-sliders-h:after, .fa-duotone.fa-sliders-h:after {
  content: "";
}

.fad.fa-cauldron:after, .fa-duotone.fa-cauldron:after {
  content: "";
}

.fad.fa-people:after, .fa-duotone.fa-people:after {
  content: "";
}

.fad.fa-folder-tree:after, .fa-duotone.fa-folder-tree:after {
  content: "";
}

.fad.fa-network-wired:after, .fa-duotone.fa-network-wired:after {
  content: "";
}

.fad.fa-croissant:after, .fa-duotone.fa-croissant:after {
  content: "";
}

.fad.fa-map-pin:after, .fa-duotone.fa-map-pin:after {
  content: "";
}

.fad.fa-hamsa:after, .fa-duotone.fa-hamsa:after {
  content: "";
}

.fad.fa-cent-sign:after, .fa-duotone.fa-cent-sign:after {
  content: "";
}

.fad.fa-swords-laser:after, .fa-duotone.fa-swords-laser:after {
  content: "";
}

.fad.fa-flask:after, .fa-duotone.fa-flask:after {
  content: "";
}

.fad.fa-person-pregnant:after, .fa-duotone.fa-person-pregnant:after {
  content: "";
}

.fad.fa-square-u:after, .fa-duotone.fa-square-u:after {
  content: "";
}

.fad.fa-wand-sparkles:after, .fa-duotone.fa-wand-sparkles:after {
  content: "";
}

.fad.fa-router:after, .fa-duotone.fa-router:after {
  content: "";
}

.fad.fa-ellipsis-vertical:after, .fa-duotone.fa-ellipsis-vertical:after, .fad.fa-ellipsis-v:after, .fa-duotone.fa-ellipsis-v:after {
  content: "";
}

.fad.fa-sword-laser-alt:after, .fa-duotone.fa-sword-laser-alt:after {
  content: "";
}

.fad.fa-ticket:after, .fa-duotone.fa-ticket:after {
  content: "";
}

.fad.fa-power-off:after, .fa-duotone.fa-power-off:after {
  content: "";
}

.fad.fa-coin:after, .fa-duotone.fa-coin:after {
  content: "";
}

.fad.fa-laptop-slash:after, .fa-duotone.fa-laptop-slash:after {
  content: "";
}

.fad.fa-right-long:after, .fa-duotone.fa-right-long:after, .fad.fa-long-arrow-alt-right:after, .fa-duotone.fa-long-arrow-alt-right:after {
  content: "";
}

.fad.fa-circle-b:after, .fa-duotone.fa-circle-b:after {
  content: "";
}

.fad.fa-person-dress-simple:after, .fa-duotone.fa-person-dress-simple:after {
  content: "";
}

.fad.fa-pipe-collar:after, .fa-duotone.fa-pipe-collar:after {
  content: "";
}

.fad.fa-lights-holiday:after, .fa-duotone.fa-lights-holiday:after {
  content: "";
}

.fad.fa-citrus:after, .fa-duotone.fa-citrus:after {
  content: "";
}

.fad.fa-flag-usa:after, .fa-duotone.fa-flag-usa:after {
  content: "";
}

.fad.fa-laptop-file:after, .fa-duotone.fa-laptop-file:after {
  content: "";
}

.fad.fa-tty:after, .fa-duotone.fa-tty:after, .fad.fa-teletype:after, .fa-duotone.fa-teletype:after {
  content: "";
}

.fad.fa-chart-tree-map:after, .fa-duotone.fa-chart-tree-map:after {
  content: "";
}

.fad.fa-diagram-next:after, .fa-duotone.fa-diagram-next:after {
  content: "";
}

.fad.fa-person-rifle:after, .fa-duotone.fa-person-rifle:after {
  content: "";
}

.fad.fa-clock-five-thirty:after, .fa-duotone.fa-clock-five-thirty:after {
  content: "";
}

.fad.fa-pipe-valve:after, .fa-duotone.fa-pipe-valve:after {
  content: "";
}

.fad.fa-arrow-up-from-arc:after, .fa-duotone.fa-arrow-up-from-arc:after {
  content: "";
}

.fad.fa-face-spiral-eyes:after, .fa-duotone.fa-face-spiral-eyes:after {
  content: "";
}

.fad.fa-compress-wide:after, .fa-duotone.fa-compress-wide:after {
  content: "";
}

.fad.fa-circle-phone-hangup:after, .fa-duotone.fa-circle-phone-hangup:after, .fad.fa-phone-circle-down:after, .fa-duotone.fa-phone-circle-down:after {
  content: "";
}

.fad.fa-gear-complex-code:after, .fa-duotone.fa-gear-complex-code:after {
  content: "";
}

.fad.fa-house-medical-circle-exclamation:after, .fa-duotone.fa-house-medical-circle-exclamation:after {
  content: "";
}

.fad.fa-badminton:after, .fa-duotone.fa-badminton:after {
  content: "";
}

.fad.fa-closed-captioning:after, .fa-duotone.fa-closed-captioning:after {
  content: "";
}

.fad.fa-person-hiking:after, .fa-duotone.fa-person-hiking:after, .fad.fa-hiking:after, .fa-duotone.fa-hiking:after {
  content: "";
}

.fad.fa-right-from-line:after, .fa-duotone.fa-right-from-line:after, .fad.fa-arrow-alt-from-left:after, .fa-duotone.fa-arrow-alt-from-left:after {
  content: "";
}

.fad.fa-venus-double:after, .fa-duotone.fa-venus-double:after {
  content: "";
}

.fad.fa-images:after, .fa-duotone.fa-images:after {
  content: "";
}

.fad.fa-calculator:after, .fa-duotone.fa-calculator:after {
  content: "";
}

.fad.fa-shuttlecock:after, .fa-duotone.fa-shuttlecock:after {
  content: "";
}

.fad.fa-user-hair:after, .fa-duotone.fa-user-hair:after {
  content: "";
}

.fad.fa-eye-evil:after, .fa-duotone.fa-eye-evil:after {
  content: "";
}

.fad.fa-people-pulling:after, .fa-duotone.fa-people-pulling:after {
  content: "";
}

.fad.fa-n:after, .fa-duotone.fa-n:after {
  content: "NN";
}

.fad.fa-swap:after, .fa-duotone.fa-swap:after {
  content: "";
}

.fad.fa-garage:after, .fa-duotone.fa-garage:after {
  content: "";
}

.fad.fa-cable-car:after, .fa-duotone.fa-cable-car:after, .fad.fa-tram:after, .fa-duotone.fa-tram:after {
  content: "";
}

.fad.fa-shovel-snow:after, .fa-duotone.fa-shovel-snow:after {
  content: "";
}

.fad.fa-cloud-rain:after, .fa-duotone.fa-cloud-rain:after {
  content: "";
}

.fad.fa-face-lying:after, .fa-duotone.fa-face-lying:after {
  content: "";
}

.fad.fa-sprinkler:after, .fa-duotone.fa-sprinkler:after {
  content: "";
}

.fad.fa-building-circle-xmark:after, .fa-duotone.fa-building-circle-xmark:after {
  content: "";
}

.fad.fa-person-sledding:after, .fa-duotone.fa-person-sledding:after, .fad.fa-sledding:after, .fa-duotone.fa-sledding:after {
  content: "";
}

.fad.fa-game-console-handheld:after, .fa-duotone.fa-game-console-handheld:after {
  content: "";
}

.fad.fa-ship:after, .fa-duotone.fa-ship:after {
  content: "";
}

.fad.fa-clock-six-thirty:after, .fa-duotone.fa-clock-six-thirty:after {
  content: "";
}

.fad.fa-battery-slash:after, .fa-duotone.fa-battery-slash:after {
  content: "";
}

.fad.fa-tugrik-sign:after, .fa-duotone.fa-tugrik-sign:after {
  content: "";
}

.fad.fa-arrows-down-to-line:after, .fa-duotone.fa-arrows-down-to-line:after {
  content: "";
}

.fad.fa-download:after, .fa-duotone.fa-download:after {
  content: "";
}

.fad.fa-angles-up-down:after, .fa-duotone.fa-angles-up-down:after {
  content: "";
}

.fad.fa-shelves:after, .fa-duotone.fa-shelves:after, .fad.fa-inventory:after, .fa-duotone.fa-inventory:after {
  content: "";
}

.fad.fa-cloud-snow:after, .fa-duotone.fa-cloud-snow:after {
  content: "";
}

.fad.fa-face-grin:after, .fa-duotone.fa-face-grin:after, .fad.fa-grin:after, .fa-duotone.fa-grin:after {
  content: "";
}

.fad.fa-delete-left:after, .fa-duotone.fa-delete-left:after, .fad.fa-backspace:after, .fa-duotone.fa-backspace:after {
  content: "";
}

.fad.fa-oven:after, .fa-duotone.fa-oven:after {
  content: "";
}

.fad.fa-cloud-binary:after, .fa-duotone.fa-cloud-binary:after {
  content: "";
}

.fad.fa-eye-dropper:after, .fa-duotone.fa-eye-dropper:after, .fad.fa-eye-dropper-empty:after, .fa-duotone.fa-eye-dropper-empty:after, .fad.fa-eyedropper:after, .fa-duotone.fa-eyedropper:after {
  content: "";
}

.fad.fa-comment-captions:after, .fa-duotone.fa-comment-captions:after {
  content: "";
}

.fad.fa-comments-question:after, .fa-duotone.fa-comments-question:after {
  content: "";
}

.fad.fa-scribble:after, .fa-duotone.fa-scribble:after {
  content: "";
}

.fad.fa-rotate-exclamation:after, .fa-duotone.fa-rotate-exclamation:after {
  content: "";
}

.fad.fa-file-circle-check:after, .fa-duotone.fa-file-circle-check:after {
  content: "";
}

.fad.fa-glass:after, .fa-duotone.fa-glass:after {
  content: "";
}

.fad.fa-loader:after, .fa-duotone.fa-loader:after {
  content: "";
}

.fad.fa-forward:after, .fa-duotone.fa-forward:after {
  content: "";
}

.fad.fa-user-pilot:after, .fa-duotone.fa-user-pilot:after {
  content: "";
}

.fad.fa-mobile:after, .fa-duotone.fa-mobile:after, .fad.fa-mobile-android:after, .fa-duotone.fa-mobile-android:after, .fad.fa-mobile-phone:after, .fa-duotone.fa-mobile-phone:after {
  content: "";
}

.fad.fa-code-pull-request-closed:after, .fa-duotone.fa-code-pull-request-closed:after {
  content: "";
}

.fad.fa-face-meh:after, .fa-duotone.fa-face-meh:after, .fad.fa-meh:after, .fa-duotone.fa-meh:after {
  content: "";
}

.fad.fa-align-center:after, .fa-duotone.fa-align-center:after {
  content: "";
}

.fad.fa-book-skull:after, .fa-duotone.fa-book-skull:after, .fad.fa-book-dead:after, .fa-duotone.fa-book-dead:after {
  content: "";
}

.fad.fa-id-card:after, .fa-duotone.fa-id-card:after, .fad.fa-drivers-license:after, .fa-duotone.fa-drivers-license:after {
  content: "";
}

.fad.fa-face-dotted:after, .fa-duotone.fa-face-dotted:after {
  content: "";
}

.fad.fa-face-worried:after, .fa-duotone.fa-face-worried:after {
  content: "";
}

.fad.fa-outdent:after, .fa-duotone.fa-outdent:after, .fad.fa-dedent:after, .fa-duotone.fa-dedent:after {
  content: "";
}

.fad.fa-court-sport:after, .fa-duotone.fa-court-sport:after {
  content: "";
}

.fad.fa-heart-circle-exclamation:after, .fa-duotone.fa-heart-circle-exclamation:after {
  content: "";
}

.fad.fa-house:after, .fa-duotone.fa-house:after, .fad.fa-home:after, .fa-duotone.fa-home:after, .fad.fa-home-alt:after, .fa-duotone.fa-home-alt:after, .fad.fa-home-lg-alt:after, .fa-duotone.fa-home-lg-alt:after {
  content: "";
}

.fad.fa-vector-circle:after, .fa-duotone.fa-vector-circle:after {
  content: "";
}

.fad.fa-car-circle-bolt:after, .fa-duotone.fa-car-circle-bolt:after {
  content: "";
}

.fad.fa-calendar-week:after, .fa-duotone.fa-calendar-week:after {
  content: "";
}

.fad.fa-flying-disc:after, .fa-duotone.fa-flying-disc:after {
  content: "";
}

.fad.fa-laptop-medical:after, .fa-duotone.fa-laptop-medical:after {
  content: "";
}

.fad.fa-square-down-right:after, .fa-duotone.fa-square-down-right:after {
  content: "";
}

.fad.fa-b:after, .fa-duotone.fa-b:after {
  content: "BB";
}

.fad.fa-seat-airline:after, .fa-duotone.fa-seat-airline:after {
  content: "";
}

.fad.fa-moon-over-sun:after, .fa-duotone.fa-moon-over-sun:after, .fad.fa-eclipse-alt:after, .fa-duotone.fa-eclipse-alt:after {
  content: "";
}

.fad.fa-pipe:after, .fa-duotone.fa-pipe:after {
  content: "||";
}

.fad.fa-file-medical:after, .fa-duotone.fa-file-medical:after {
  content: "";
}

.fad.fa-potato:after, .fa-duotone.fa-potato:after {
  content: "";
}

.fad.fa-dice-one:after, .fa-duotone.fa-dice-one:after {
  content: "";
}

.fad.fa-circle-a:after, .fa-duotone.fa-circle-a:after {
  content: "";
}

.fad.fa-helmet-battle:after, .fa-duotone.fa-helmet-battle:after {
  content: "";
}

.fad.fa-butter:after, .fa-duotone.fa-butter:after {
  content: "";
}

.fad.fa-blanket-fire:after, .fa-duotone.fa-blanket-fire:after {
  content: "";
}

.fad.fa-kiwi-bird:after, .fa-duotone.fa-kiwi-bird:after {
  content: "";
}

.fad.fa-castle:after, .fa-duotone.fa-castle:after {
  content: "";
}

.fad.fa-golf-club:after, .fa-duotone.fa-golf-club:after {
  content: "";
}

.fad.fa-arrow-right-arrow-left:after, .fa-duotone.fa-arrow-right-arrow-left:after, .fad.fa-exchange:after, .fa-duotone.fa-exchange:after {
  content: "";
}

.fad.fa-rotate-right:after, .fa-duotone.fa-rotate-right:after, .fad.fa-redo-alt:after, .fa-duotone.fa-redo-alt:after, .fad.fa-rotate-forward:after, .fa-duotone.fa-rotate-forward:after {
  content: "";
}

.fad.fa-utensils:after, .fa-duotone.fa-utensils:after, .fad.fa-cutlery:after, .fa-duotone.fa-cutlery:after {
  content: "";
}

.fad.fa-arrow-up-wide-short:after, .fa-duotone.fa-arrow-up-wide-short:after, .fad.fa-sort-amount-up:after, .fa-duotone.fa-sort-amount-up:after {
  content: "";
}

.fad.fa-chart-pie-simple-circle-dollar:after, .fa-duotone.fa-chart-pie-simple-circle-dollar:after {
  content: "";
}

.fad.fa-balloons:after, .fa-duotone.fa-balloons:after {
  content: "";
}

.fad.fa-mill-sign:after, .fa-duotone.fa-mill-sign:after {
  content: "";
}

.fad.fa-bowl-rice:after, .fa-duotone.fa-bowl-rice:after {
  content: "";
}

.fad.fa-timeline-arrow:after, .fa-duotone.fa-timeline-arrow:after {
  content: "";
}

.fad.fa-skull:after, .fa-duotone.fa-skull:after {
  content: "";
}

.fad.fa-game-board-simple:after, .fa-duotone.fa-game-board-simple:after, .fad.fa-game-board-alt:after, .fa-duotone.fa-game-board-alt:after {
  content: "";
}

.fad.fa-circle-video:after, .fa-duotone.fa-circle-video:after, .fad.fa-video-circle:after, .fa-duotone.fa-video-circle:after {
  content: "";
}

.fad.fa-chart-scatter-bubble:after, .fa-duotone.fa-chart-scatter-bubble:after {
  content: "";
}

.fad.fa-house-turret:after, .fa-duotone.fa-house-turret:after {
  content: "";
}

.fad.fa-banana:after, .fa-duotone.fa-banana:after {
  content: "";
}

.fad.fa-hand-holding-skull:after, .fa-duotone.fa-hand-holding-skull:after {
  content: "";
}

.fad.fa-people-dress:after, .fa-duotone.fa-people-dress:after {
  content: "";
}

.fad.fa-loveseat:after, .fa-duotone.fa-loveseat:after, .fad.fa-couch-small:after, .fa-duotone.fa-couch-small:after {
  content: "";
}

.fad.fa-tower-broadcast:after, .fa-duotone.fa-tower-broadcast:after, .fad.fa-broadcast-tower:after, .fa-duotone.fa-broadcast-tower:after {
  content: "";
}

.fad.fa-truck-pickup:after, .fa-duotone.fa-truck-pickup:after {
  content: "";
}

.fad.fa-block-quote:after, .fa-duotone.fa-block-quote:after {
  content: "";
}

.fad.fa-up-long:after, .fa-duotone.fa-up-long:after, .fad.fa-long-arrow-alt-up:after, .fa-duotone.fa-long-arrow-alt-up:after {
  content: "";
}

.fad.fa-stop:after, .fa-duotone.fa-stop:after {
  content: "";
}

.fad.fa-code-merge:after, .fa-duotone.fa-code-merge:after {
  content: "";
}

.fad.fa-money-check-dollar-pen:after, .fa-duotone.fa-money-check-dollar-pen:after, .fad.fa-money-check-edit-alt:after, .fa-duotone.fa-money-check-edit-alt:after {
  content: "";
}

.fad.fa-up-from-line:after, .fa-duotone.fa-up-from-line:after, .fad.fa-arrow-alt-from-bottom:after, .fa-duotone.fa-arrow-alt-from-bottom:after {
  content: "";
}

.fad.fa-upload:after, .fa-duotone.fa-upload:after {
  content: "";
}

.fad.fa-hurricane:after, .fa-duotone.fa-hurricane:after {
  content: "";
}

.fad.fa-grid-round-2-plus:after, .fa-duotone.fa-grid-round-2-plus:after {
  content: "";
}

.fad.fa-people-pants:after, .fa-duotone.fa-people-pants:after {
  content: "";
}

.fad.fa-mound:after, .fa-duotone.fa-mound:after {
  content: "";
}

.fad.fa-windsock:after, .fa-duotone.fa-windsock:after {
  content: "";
}

.fad.fa-circle-half:after, .fa-duotone.fa-circle-half:after {
  content: "";
}

.fad.fa-brake-warning:after, .fa-duotone.fa-brake-warning:after {
  content: "";
}

.fad.fa-toilet-portable:after, .fa-duotone.fa-toilet-portable:after {
  content: "";
}

.fad.fa-compact-disc:after, .fa-duotone.fa-compact-disc:after {
  content: "";
}

.fad.fa-file-arrow-down:after, .fa-duotone.fa-file-arrow-down:after, .fad.fa-file-download:after, .fa-duotone.fa-file-download:after {
  content: "";
}

.fad.fa-saxophone-fire:after, .fa-duotone.fa-saxophone-fire:after, .fad.fa-sax-hot:after, .fa-duotone.fa-sax-hot:after {
  content: "";
}

.fad.fa-camera-web-slash:after, .fa-duotone.fa-camera-web-slash:after, .fad.fa-webcam-slash:after, .fa-duotone.fa-webcam-slash:after {
  content: "";
}

.fad.fa-folder-medical:after, .fa-duotone.fa-folder-medical:after {
  content: "";
}

.fad.fa-folder-gear:after, .fa-duotone.fa-folder-gear:after, .fad.fa-folder-cog:after, .fa-duotone.fa-folder-cog:after {
  content: "";
}

.fad.fa-hand-wave:after, .fa-duotone.fa-hand-wave:after {
  content: "";
}

.fad.fa-arrow-up-arrow-down:after, .fa-duotone.fa-arrow-up-arrow-down:after, .fad.fa-sort-up-down:after, .fa-duotone.fa-sort-up-down:after {
  content: "";
}

.fad.fa-caravan:after, .fa-duotone.fa-caravan:after {
  content: "";
}

.fad.fa-shield-cat:after, .fa-duotone.fa-shield-cat:after {
  content: "";
}

.fad.fa-message-slash:after, .fa-duotone.fa-message-slash:after, .fad.fa-comment-alt-slash:after, .fa-duotone.fa-comment-alt-slash:after {
  content: "";
}

.fad.fa-bolt:after, .fa-duotone.fa-bolt:after, .fad.fa-zap:after, .fa-duotone.fa-zap:after {
  content: "";
}

.fad.fa-trash-can-check:after, .fa-duotone.fa-trash-can-check:after {
  content: "";
}

.fad.fa-glass-water:after, .fa-duotone.fa-glass-water:after {
  content: "";
}

.fad.fa-oil-well:after, .fa-duotone.fa-oil-well:after {
  content: "";
}

.fad.fa-person-simple:after, .fa-duotone.fa-person-simple:after {
  content: "";
}

.fad.fa-arrow-turn-left-up:after, .fa-duotone.fa-arrow-turn-left-up:after {
  content: "";
}

.fad.fa-vault:after, .fa-duotone.fa-vault:after {
  content: "";
}

.fad.fa-mars:after, .fa-duotone.fa-mars:after {
  content: "";
}

.fad.fa-toilet:after, .fa-duotone.fa-toilet:after {
  content: "";
}

.fad.fa-plane-circle-xmark:after, .fa-duotone.fa-plane-circle-xmark:after {
  content: "";
}

.fad.fa-yen-sign:after, .fa-duotone.fa-yen-sign:after, .fad.fa-cny:after, .fa-duotone.fa-cny:after, .fad.fa-jpy:after, .fa-duotone.fa-jpy:after, .fad.fa-rmb:after, .fa-duotone.fa-rmb:after, .fad.fa-yen:after, .fa-duotone.fa-yen:after {
  content: "";
}

.fad.fa-gear-code:after, .fa-duotone.fa-gear-code:after {
  content: "";
}

.fad.fa-notes:after, .fa-duotone.fa-notes:after {
  content: "";
}

.fad.fa-ruble-sign:after, .fa-duotone.fa-ruble-sign:after, .fad.fa-rouble:after, .fa-duotone.fa-rouble:after, .fad.fa-rub:after, .fa-duotone.fa-rub:after, .fad.fa-ruble:after, .fa-duotone.fa-ruble:after {
  content: "";
}

.fad.fa-trash-undo:after, .fa-duotone.fa-trash-undo:after, .fad.fa-trash-arrow-turn-left:after, .fa-duotone.fa-trash-arrow-turn-left:after {
  content: "";
}

.fad.fa-champagne-glass:after, .fa-duotone.fa-champagne-glass:after, .fad.fa-glass-champagne:after, .fa-duotone.fa-glass-champagne:after {
  content: "";
}

.fad.fa-objects-align-center-horizontal:after, .fa-duotone.fa-objects-align-center-horizontal:after {
  content: "";
}

.fad.fa-sun:after, .fa-duotone.fa-sun:after {
  content: "";
}

.fad.fa-trash-can-slash:after, .fa-duotone.fa-trash-can-slash:after, .fad.fa-trash-alt-slash:after, .fa-duotone.fa-trash-alt-slash:after {
  content: "";
}

.fad.fa-screen-users:after, .fa-duotone.fa-screen-users:after, .fad.fa-users-class:after, .fa-duotone.fa-users-class:after {
  content: "";
}

.fad.fa-guitar:after, .fa-duotone.fa-guitar:after {
  content: "";
}

.fad.fa-square-arrow-left:after, .fa-duotone.fa-square-arrow-left:after, .fad.fa-arrow-square-left:after, .fa-duotone.fa-arrow-square-left:after {
  content: "";
}

.fad.fa-square-8:after, .fa-duotone.fa-square-8:after {
  content: "";
}

.fad.fa-face-smile-hearts:after, .fa-duotone.fa-face-smile-hearts:after {
  content: "";
}

.fad.fa-brackets-square:after, .fa-duotone.fa-brackets-square:after, .fad.fa-brackets:after, .fa-duotone.fa-brackets:after {
  content: "";
}

.fad.fa-laptop-arrow-down:after, .fa-duotone.fa-laptop-arrow-down:after {
  content: "";
}

.fad.fa-hockey-stick-puck:after, .fa-duotone.fa-hockey-stick-puck:after {
  content: "";
}

.fad.fa-house-tree:after, .fa-duotone.fa-house-tree:after {
  content: "";
}

.fad.fa-signal-fair:after, .fa-duotone.fa-signal-fair:after, .fad.fa-signal-2:after, .fa-duotone.fa-signal-2:after {
  content: "";
}

.fad.fa-face-laugh-wink:after, .fa-duotone.fa-face-laugh-wink:after, .fad.fa-laugh-wink:after, .fa-duotone.fa-laugh-wink:after {
  content: "";
}

.fad.fa-circle-dollar:after, .fa-duotone.fa-circle-dollar:after, .fad.fa-dollar-circle:after, .fa-duotone.fa-dollar-circle:after, .fad.fa-usd-circle:after, .fa-duotone.fa-usd-circle:after {
  content: "";
}

.fad.fa-horse-head:after, .fa-duotone.fa-horse-head:after {
  content: "";
}

.fad.fa-arrows-repeat:after, .fa-duotone.fa-arrows-repeat:after, .fad.fa-repeat-alt:after, .fa-duotone.fa-repeat-alt:after {
  content: "";
}

.fad.fa-bore-hole:after, .fa-duotone.fa-bore-hole:after {
  content: "";
}

.fad.fa-industry:after, .fa-duotone.fa-industry:after {
  content: "";
}

.fad.fa-image-polaroid:after, .fa-duotone.fa-image-polaroid:after {
  content: "";
}

.fad.fa-wave-triangle:after, .fa-duotone.fa-wave-triangle:after {
  content: "";
}

.fad.fa-turn-left-down:after, .fa-duotone.fa-turn-left-down:after {
  content: "";
}

.fad.fa-person-running-fast:after, .fa-duotone.fa-person-running-fast:after {
  content: "";
}

.fad.fa-circle-down:after, .fa-duotone.fa-circle-down:after, .fad.fa-arrow-alt-circle-down:after, .fa-duotone.fa-arrow-alt-circle-down:after {
  content: "";
}

.fad.fa-grill:after, .fa-duotone.fa-grill:after {
  content: "";
}

.fad.fa-arrows-turn-to-dots:after, .fa-duotone.fa-arrows-turn-to-dots:after {
  content: "";
}

.fad.fa-chart-mixed:after, .fa-duotone.fa-chart-mixed:after, .fad.fa-analytics:after, .fa-duotone.fa-analytics:after {
  content: "";
}

.fad.fa-florin-sign:after, .fa-duotone.fa-florin-sign:after {
  content: "";
}

.fad.fa-arrow-down-short-wide:after, .fa-duotone.fa-arrow-down-short-wide:after, .fad.fa-sort-amount-desc:after, .fa-duotone.fa-sort-amount-desc:after, .fad.fa-sort-amount-down-alt:after, .fa-duotone.fa-sort-amount-down-alt:after {
  content: "";
}

.fad.fa-less-than:after, .fa-duotone.fa-less-than:after {
  content: "<<";
}

.fad.fa-display-code:after, .fa-duotone.fa-display-code:after, .fad.fa-desktop-code:after, .fa-duotone.fa-desktop-code:after {
  content: "";
}

.fad.fa-face-drooling:after, .fa-duotone.fa-face-drooling:after {
  content: "";
}

.fad.fa-oil-temperature:after, .fa-duotone.fa-oil-temperature:after, .fad.fa-oil-temp:after, .fa-duotone.fa-oil-temp:after {
  content: "";
}

.fad.fa-square-question:after, .fa-duotone.fa-square-question:after, .fad.fa-question-square:after, .fa-duotone.fa-question-square:after {
  content: "";
}

.fad.fa-air-conditioner:after, .fa-duotone.fa-air-conditioner:after {
  content: "";
}

.fad.fa-angle-down:after, .fa-duotone.fa-angle-down:after {
  content: "";
}

.fad.fa-mountains:after, .fa-duotone.fa-mountains:after {
  content: "";
}

.fad.fa-omega:after, .fa-duotone.fa-omega:after {
  content: "";
}

.fad.fa-car-tunnel:after, .fa-duotone.fa-car-tunnel:after {
  content: "";
}

.fad.fa-person-dolly-empty:after, .fa-duotone.fa-person-dolly-empty:after {
  content: "";
}

.fad.fa-pan-food:after, .fa-duotone.fa-pan-food:after {
  content: "";
}

.fad.fa-head-side-cough:after, .fa-duotone.fa-head-side-cough:after {
  content: "";
}

.fad.fa-grip-lines:after, .fa-duotone.fa-grip-lines:after {
  content: "";
}

.fad.fa-thumbs-down:after, .fa-duotone.fa-thumbs-down:after {
  content: "";
}

.fad.fa-user-lock:after, .fa-duotone.fa-user-lock:after {
  content: "";
}

.fad.fa-arrow-right-long:after, .fa-duotone.fa-arrow-right-long:after, .fad.fa-long-arrow-right:after, .fa-duotone.fa-long-arrow-right:after {
  content: "";
}

.fad.fa-tickets-airline:after, .fa-duotone.fa-tickets-airline:after, .fad.fa-tickets-perforated-plane:after, .fa-duotone.fa-tickets-perforated-plane:after, .fad.fa-tickets-plane:after, .fa-duotone.fa-tickets-plane:after {
  content: "";
}

.fad.fa-tent-double-peak:after, .fa-duotone.fa-tent-double-peak:after {
  content: "";
}

.fad.fa-anchor-circle-xmark:after, .fa-duotone.fa-anchor-circle-xmark:after {
  content: "";
}

.fad.fa-ellipsis:after, .fa-duotone.fa-ellipsis:after, .fad.fa-ellipsis-h:after, .fa-duotone.fa-ellipsis-h:after {
  content: "";
}

.fad.fa-nfc-slash:after, .fa-duotone.fa-nfc-slash:after {
  content: "";
}

.fad.fa-chess-pawn:after, .fa-duotone.fa-chess-pawn:after {
  content: "";
}

.fad.fa-kit-medical:after, .fa-duotone.fa-kit-medical:after, .fad.fa-first-aid:after, .fa-duotone.fa-first-aid:after {
  content: "";
}

.fad.fa-grid-2-plus:after, .fa-duotone.fa-grid-2-plus:after {
  content: "";
}

.fad.fa-bells:after, .fa-duotone.fa-bells:after {
  content: "";
}

.fad.fa-person-through-window:after, .fa-duotone.fa-person-through-window:after {
  content: "";
}

.fad.fa-toolbox:after, .fa-duotone.fa-toolbox:after {
  content: "";
}

.fad.fa-envelope-dot:after, .fa-duotone.fa-envelope-dot:after, .fad.fa-envelope-badge:after, .fa-duotone.fa-envelope-badge:after {
  content: "";
}

.fad.fa-magnifying-glass-waveform:after, .fa-duotone.fa-magnifying-glass-waveform:after {
  content: "";
}

.fad.fa-hands-holding-circle:after, .fa-duotone.fa-hands-holding-circle:after {
  content: "";
}

.fad.fa-bug:after, .fa-duotone.fa-bug:after {
  content: "";
}

.fad.fa-bowl-chopsticks:after, .fa-duotone.fa-bowl-chopsticks:after {
  content: "";
}

.fad.fa-credit-card:after, .fa-duotone.fa-credit-card:after, .fad.fa-credit-card-alt:after, .fa-duotone.fa-credit-card-alt:after {
  content: "";
}

.fad.fa-circle-s:after, .fa-duotone.fa-circle-s:after {
  content: "";
}

.fad.fa-box-ballot:after, .fa-duotone.fa-box-ballot:after {
  content: "";
}

.fad.fa-car:after, .fa-duotone.fa-car:after, .fad.fa-automobile:after, .fa-duotone.fa-automobile:after {
  content: "";
}

.fad.fa-hand-holding-hand:after, .fa-duotone.fa-hand-holding-hand:after {
  content: "";
}

.fad.fa-user-tie-hair:after, .fa-duotone.fa-user-tie-hair:after {
  content: "";
}

.fad.fa-podium-star:after, .fa-duotone.fa-podium-star:after {
  content: "";
}

.fad.fa-user-hair-mullet:after, .fa-duotone.fa-user-hair-mullet:after, .fad.fa-business-front:after, .fa-duotone.fa-business-front:after, .fad.fa-party-back:after, .fa-duotone.fa-party-back:after, .fad.fa-trian-balbot:after, .fa-duotone.fa-trian-balbot:after {
  content: "";
}

.fad.fa-microphone-stand:after, .fa-duotone.fa-microphone-stand:after {
  content: "";
}

.fad.fa-book-open-reader:after, .fa-duotone.fa-book-open-reader:after, .fad.fa-book-reader:after, .fa-duotone.fa-book-reader:after {
  content: "";
}

.fad.fa-family-dress:after, .fa-duotone.fa-family-dress:after {
  content: "";
}

.fad.fa-circle-x:after, .fa-duotone.fa-circle-x:after {
  content: "";
}

.fad.fa-cabin:after, .fa-duotone.fa-cabin:after {
  content: "";
}

.fad.fa-mountain-sun:after, .fa-duotone.fa-mountain-sun:after {
  content: "";
}

.fad.fa-chart-simple-horizontal:after, .fa-duotone.fa-chart-simple-horizontal:after {
  content: "";
}

.fad.fa-arrows-left-right-to-line:after, .fa-duotone.fa-arrows-left-right-to-line:after {
  content: "";
}

.fad.fa-hand-back-point-left:after, .fa-duotone.fa-hand-back-point-left:after {
  content: "";
}

.fad.fa-message-dots:after, .fa-duotone.fa-message-dots:after, .fad.fa-comment-alt-dots:after, .fa-duotone.fa-comment-alt-dots:after, .fad.fa-messaging:after, .fa-duotone.fa-messaging:after {
  content: "";
}

.fad.fa-file-heart:after, .fa-duotone.fa-file-heart:after {
  content: "";
}

.fad.fa-beer-mug:after, .fa-duotone.fa-beer-mug:after, .fad.fa-beer-foam:after, .fa-duotone.fa-beer-foam:after {
  content: "";
}

.fad.fa-dice-d20:after, .fa-duotone.fa-dice-d20:after {
  content: "";
}

.fad.fa-drone:after, .fa-duotone.fa-drone:after {
  content: "";
}

.fad.fa-truck-droplet:after, .fa-duotone.fa-truck-droplet:after {
  content: "";
}

.fad.fa-file-circle-xmark:after, .fa-duotone.fa-file-circle-xmark:after {
  content: "";
}

.fad.fa-temperature-arrow-up:after, .fa-duotone.fa-temperature-arrow-up:after, .fad.fa-temperature-up:after, .fa-duotone.fa-temperature-up:after {
  content: "";
}

.fad.fa-medal:after, .fa-duotone.fa-medal:after {
  content: "";
}

.fad.fa-person-fairy:after, .fa-duotone.fa-person-fairy:after {
  content: "";
}

.fad.fa-bed:after, .fa-duotone.fa-bed:after {
  content: "";
}

.fad.fa-book-copy:after, .fa-duotone.fa-book-copy:after {
  content: "";
}

.fad.fa-square-h:after, .fa-duotone.fa-square-h:after, .fad.fa-h-square:after, .fa-duotone.fa-h-square:after {
  content: "";
}

.fad.fa-square-c:after, .fa-duotone.fa-square-c:after {
  content: "";
}

.fad.fa-clock-two:after, .fa-duotone.fa-clock-two:after {
  content: "";
}

.fad.fa-square-ellipsis-vertical:after, .fa-duotone.fa-square-ellipsis-vertical:after {
  content: "";
}

.fad.fa-calendar-users:after, .fa-duotone.fa-calendar-users:after {
  content: "";
}

.fad.fa-podcast:after, .fa-duotone.fa-podcast:after {
  content: "";
}

.fad.fa-bee:after, .fa-duotone.fa-bee:after {
  content: "";
}

.fad.fa-temperature-full:after, .fa-duotone.fa-temperature-full:after, .fad.fa-temperature-4:after, .fa-duotone.fa-temperature-4:after, .fad.fa-thermometer-4:after, .fa-duotone.fa-thermometer-4:after, .fad.fa-thermometer-full:after, .fa-duotone.fa-thermometer-full:after {
  content: "";
}

.fad.fa-bell:after, .fa-duotone.fa-bell:after {
  content: "";
}

.fad.fa-candy-bar:after, .fa-duotone.fa-candy-bar:after, .fad.fa-chocolate-bar:after, .fa-duotone.fa-chocolate-bar:after {
  content: "";
}

.fad.fa-xmark-large:after, .fa-duotone.fa-xmark-large:after {
  content: "";
}

.fad.fa-pinata:after, .fa-duotone.fa-pinata:after {
  content: "";
}

.fad.fa-file-ppt:after, .fa-duotone.fa-file-ppt:after {
  content: "";
}

.fad.fa-arrows-from-line:after, .fa-duotone.fa-arrows-from-line:after {
  content: "";
}

.fad.fa-superscript:after, .fa-duotone.fa-superscript:after {
  content: "";
}

.fad.fa-bowl-spoon:after, .fa-duotone.fa-bowl-spoon:after {
  content: "";
}

.fad.fa-hexagon-check:after, .fa-duotone.fa-hexagon-check:after {
  content: "";
}

.fad.fa-plug-circle-xmark:after, .fa-duotone.fa-plug-circle-xmark:after {
  content: "";
}

.fad.fa-star-of-life:after, .fa-duotone.fa-star-of-life:after {
  content: "";
}

.fad.fa-phone-slash:after, .fa-duotone.fa-phone-slash:after {
  content: "";
}

.fad.fa-traffic-light-stop:after, .fa-duotone.fa-traffic-light-stop:after {
  content: "";
}

.fad.fa-paint-roller:after, .fa-duotone.fa-paint-roller:after {
  content: "";
}

.fad.fa-accent-grave:after, .fa-duotone.fa-accent-grave:after {
  content: "``";
}

.fad.fa-handshake-angle:after, .fa-duotone.fa-handshake-angle:after, .fad.fa-hands-helping:after, .fa-duotone.fa-hands-helping:after {
  content: "";
}

.fad.fa-circle-0:after, .fa-duotone.fa-circle-0:after {
  content: "";
}

.fad.fa-dial-med-low:after, .fa-duotone.fa-dial-med-low:after {
  content: "";
}

.fad.fa-location-dot:after, .fa-duotone.fa-location-dot:after, .fad.fa-map-marker-alt:after, .fa-duotone.fa-map-marker-alt:after {
  content: "";
}

.fad.fa-crab:after, .fa-duotone.fa-crab:after {
  content: "";
}

.fad.fa-box-open-full:after, .fa-duotone.fa-box-open-full:after, .fad.fa-box-full:after, .fa-duotone.fa-box-full:after {
  content: "";
}

.fad.fa-file:after, .fa-duotone.fa-file:after {
  content: "";
}

.fad.fa-greater-than:after, .fa-duotone.fa-greater-than:after {
  content: ">>";
}

.fad.fa-quotes:after, .fa-duotone.fa-quotes:after {
  content: "";
}

.fad.fa-pretzel:after, .fa-duotone.fa-pretzel:after {
  content: "";
}

.fad.fa-t-rex:after, .fa-duotone.fa-t-rex:after {
  content: "";
}

.fad.fa-person-swimming:after, .fa-duotone.fa-person-swimming:after, .fad.fa-swimmer:after, .fa-duotone.fa-swimmer:after {
  content: "";
}

.fad.fa-arrow-down:after, .fa-duotone.fa-arrow-down:after {
  content: "";
}

.fad.fa-user-robot-xmarks:after, .fa-duotone.fa-user-robot-xmarks:after {
  content: "";
}

.fad.fa-message-quote:after, .fa-duotone.fa-message-quote:after, .fad.fa-comment-alt-quote:after, .fa-duotone.fa-comment-alt-quote:after {
  content: "";
}

.fad.fa-candy-corn:after, .fa-duotone.fa-candy-corn:after {
  content: "";
}

.fad.fa-folder-magnifying-glass:after, .fa-duotone.fa-folder-magnifying-glass:after, .fad.fa-folder-search:after, .fa-duotone.fa-folder-search:after {
  content: "";
}

.fad.fa-notebook:after, .fa-duotone.fa-notebook:after {
  content: "";
}

.fad.fa-circle-wifi:after, .fa-duotone.fa-circle-wifi:after {
  content: "";
}

.fad.fa-droplet:after, .fa-duotone.fa-droplet:after, .fad.fa-tint:after, .fa-duotone.fa-tint:after {
  content: "";
}

.fad.fa-bullseye-pointer:after, .fa-duotone.fa-bullseye-pointer:after {
  content: "";
}

.fad.fa-eraser:after, .fa-duotone.fa-eraser:after {
  content: "";
}

.fad.fa-hexagon-image:after, .fa-duotone.fa-hexagon-image:after {
  content: "";
}

.fad.fa-earth-americas:after, .fa-duotone.fa-earth-americas:after, .fad.fa-earth:after, .fa-duotone.fa-earth:after, .fad.fa-earth-america:after, .fa-duotone.fa-earth-america:after, .fad.fa-globe-americas:after, .fa-duotone.fa-globe-americas:after {
  content: "";
}

.fad.fa-file-svg:after, .fa-duotone.fa-file-svg:after {
  content: "";
}

.fad.fa-crate-apple:after, .fa-duotone.fa-crate-apple:after, .fad.fa-apple-crate:after, .fa-duotone.fa-apple-crate:after {
  content: "";
}

.fad.fa-person-burst:after, .fa-duotone.fa-person-burst:after {
  content: "";
}

.fad.fa-game-board:after, .fa-duotone.fa-game-board:after {
  content: "";
}

.fad.fa-hat-chef:after, .fa-duotone.fa-hat-chef:after {
  content: "";
}

.fad.fa-hand-back-point-right:after, .fa-duotone.fa-hand-back-point-right:after {
  content: "";
}

.fad.fa-dove:after, .fa-duotone.fa-dove:after {
  content: "";
}

.fad.fa-snowflake-droplets:after, .fa-duotone.fa-snowflake-droplets:after {
  content: "";
}

.fad.fa-battery-empty:after, .fa-duotone.fa-battery-empty:after, .fad.fa-battery-0:after, .fa-duotone.fa-battery-0:after {
  content: "";
}

.fad.fa-grid-4:after, .fa-duotone.fa-grid-4:after {
  content: "";
}

.fad.fa-socks:after, .fa-duotone.fa-socks:after {
  content: "";
}

.fad.fa-face-sunglasses:after, .fa-duotone.fa-face-sunglasses:after {
  content: "";
}

.fad.fa-inbox:after, .fa-duotone.fa-inbox:after {
  content: "";
}

.fad.fa-square-0:after, .fa-duotone.fa-square-0:after {
  content: "";
}

.fad.fa-section:after, .fa-duotone.fa-section:after {
  content: "";
}

.fad.fa-square-this-way-up:after, .fa-duotone.fa-square-this-way-up:after, .fad.fa-box-up:after, .fa-duotone.fa-box-up:after {
  content: "";
}

.fad.fa-gauge-high:after, .fa-duotone.fa-gauge-high:after, .fad.fa-tachometer-alt:after, .fa-duotone.fa-tachometer-alt:after, .fad.fa-tachometer-alt-fast:after, .fa-duotone.fa-tachometer-alt-fast:after {
  content: "";
}

.fad.fa-square-ampersand:after, .fa-duotone.fa-square-ampersand:after {
  content: "";
}

.fad.fa-envelope-open-text:after, .fa-duotone.fa-envelope-open-text:after {
  content: "";
}

.fad.fa-lamp-desk:after, .fa-duotone.fa-lamp-desk:after {
  content: "";
}

.fad.fa-hospital:after, .fa-duotone.fa-hospital:after, .fad.fa-hospital-alt:after, .fa-duotone.fa-hospital-alt:after, .fad.fa-hospital-wide:after, .fa-duotone.fa-hospital-wide:after {
  content: "";
}

.fad.fa-poll-people:after, .fa-duotone.fa-poll-people:after {
  content: "";
}

.fad.fa-whiskey-glass-ice:after, .fa-duotone.fa-whiskey-glass-ice:after, .fad.fa-glass-whiskey-rocks:after, .fa-duotone.fa-glass-whiskey-rocks:after {
  content: "";
}

.fad.fa-wine-bottle:after, .fa-duotone.fa-wine-bottle:after {
  content: "";
}

.fad.fa-chess-rook:after, .fa-duotone.fa-chess-rook:after {
  content: "";
}

.fad.fa-user-bounty-hunter:after, .fa-duotone.fa-user-bounty-hunter:after {
  content: "";
}

.fad.fa-bars-staggered:after, .fa-duotone.fa-bars-staggered:after, .fad.fa-reorder:after, .fa-duotone.fa-reorder:after, .fad.fa-stream:after, .fa-duotone.fa-stream:after {
  content: "";
}

.fad.fa-diagram-sankey:after, .fa-duotone.fa-diagram-sankey:after {
  content: "";
}

.fad.fa-cloud-hail-mixed:after, .fa-duotone.fa-cloud-hail-mixed:after {
  content: "";
}

.fad.fa-circle-up-left:after, .fa-duotone.fa-circle-up-left:after {
  content: "";
}

.fad.fa-dharmachakra:after, .fa-duotone.fa-dharmachakra:after {
  content: "";
}

.fad.fa-objects-align-left:after, .fa-duotone.fa-objects-align-left:after {
  content: "";
}

.fad.fa-oil-can-drip:after, .fa-duotone.fa-oil-can-drip:after {
  content: "";
}

.fad.fa-face-smiling-hands:after, .fa-duotone.fa-face-smiling-hands:after {
  content: "";
}

.fad.fa-broccoli:after, .fa-duotone.fa-broccoli:after {
  content: "";
}

.fad.fa-route-interstate:after, .fa-duotone.fa-route-interstate:after {
  content: "";
}

.fad.fa-ear-muffs:after, .fa-duotone.fa-ear-muffs:after {
  content: "";
}

.fad.fa-hotdog:after, .fa-duotone.fa-hotdog:after {
  content: "";
}

.fad.fa-transporter-empty:after, .fa-duotone.fa-transporter-empty:after {
  content: "";
}

.fad.fa-person-walking-with-cane:after, .fa-duotone.fa-person-walking-with-cane:after, .fad.fa-blind:after, .fa-duotone.fa-blind:after {
  content: "";
}

.fad.fa-angle-90:after, .fa-duotone.fa-angle-90:after {
  content: "";
}

.fad.fa-rectangle-terminal:after, .fa-duotone.fa-rectangle-terminal:after {
  content: "";
}

.fad.fa-kite:after, .fa-duotone.fa-kite:after {
  content: "";
}

.fad.fa-drum:after, .fa-duotone.fa-drum:after {
  content: "";
}

.fad.fa-scrubber:after, .fa-duotone.fa-scrubber:after {
  content: "";
}

.fad.fa-ice-cream:after, .fa-duotone.fa-ice-cream:after {
  content: "";
}

.fad.fa-heart-circle-bolt:after, .fa-duotone.fa-heart-circle-bolt:after {
  content: "";
}

.fad.fa-fish-bones:after, .fa-duotone.fa-fish-bones:after {
  content: "";
}

.fad.fa-deer-rudolph:after, .fa-duotone.fa-deer-rudolph:after {
  content: "";
}

.fad.fa-fax:after, .fa-duotone.fa-fax:after {
  content: "";
}

.fad.fa-paragraph:after, .fa-duotone.fa-paragraph:after {
  content: "";
}

.fad.fa-head-side-heart:after, .fa-duotone.fa-head-side-heart:after {
  content: "";
}

.fad.fa-square-e:after, .fa-duotone.fa-square-e:after {
  content: "";
}

.fad.fa-meter-fire:after, .fa-duotone.fa-meter-fire:after {
  content: "";
}

.fad.fa-cloud-hail:after, .fa-duotone.fa-cloud-hail:after {
  content: "";
}

.fad.fa-check-to-slot:after, .fa-duotone.fa-check-to-slot:after, .fad.fa-vote-yea:after, .fa-duotone.fa-vote-yea:after {
  content: "";
}

.fad.fa-money-from-bracket:after, .fa-duotone.fa-money-from-bracket:after {
  content: "";
}

.fad.fa-star-half:after, .fa-duotone.fa-star-half:after {
  content: "";
}

.fad.fa-car-bus:after, .fa-duotone.fa-car-bus:after {
  content: "";
}

.fad.fa-speaker:after, .fa-duotone.fa-speaker:after {
  content: "";
}

.fad.fa-timer:after, .fa-duotone.fa-timer:after {
  content: "";
}

.fad.fa-boxes-stacked:after, .fa-duotone.fa-boxes-stacked:after, .fad.fa-boxes:after, .fa-duotone.fa-boxes:after, .fad.fa-boxes-alt:after, .fa-duotone.fa-boxes-alt:after {
  content: "";
}

.fad.fa-landmark-magnifying-glass:after, .fa-duotone.fa-landmark-magnifying-glass:after {
  content: "";
}

.fad.fa-grill-hot:after, .fa-duotone.fa-grill-hot:after {
  content: "";
}

.fad.fa-ballot-check:after, .fa-duotone.fa-ballot-check:after {
  content: "";
}

.fad.fa-link:after, .fa-duotone.fa-link:after, .fad.fa-chain:after, .fa-duotone.fa-chain:after {
  content: "";
}

.fad.fa-ear-listen:after, .fa-duotone.fa-ear-listen:after, .fad.fa-assistive-listening-systems:after, .fa-duotone.fa-assistive-listening-systems:after {
  content: "";
}

.fad.fa-file-minus:after, .fa-duotone.fa-file-minus:after {
  content: "";
}

.fad.fa-tree-city:after, .fa-duotone.fa-tree-city:after {
  content: "";
}

.fad.fa-play:after, .fa-duotone.fa-play:after {
  content: "";
}

.fad.fa-font:after, .fa-duotone.fa-font:after {
  content: "";
}

.fad.fa-cup-togo:after, .fa-duotone.fa-cup-togo:after, .fad.fa-coffee-togo:after, .fa-duotone.fa-coffee-togo:after {
  content: "";
}

.fad.fa-square-down-left:after, .fa-duotone.fa-square-down-left:after {
  content: "";
}

.fad.fa-burger-lettuce:after, .fa-duotone.fa-burger-lettuce:after {
  content: "";
}

.fad.fa-table-cells-row-lock:after, .fa-duotone.fa-table-cells-row-lock:after {
  content: "";
}

.fad.fa-rupiah-sign:after, .fa-duotone.fa-rupiah-sign:after {
  content: "";
}

.fad.fa-magnifying-glass:after, .fa-duotone.fa-magnifying-glass:after, .fad.fa-search:after, .fa-duotone.fa-search:after {
  content: "";
}

.fad.fa-table-tennis-paddle-ball:after, .fa-duotone.fa-table-tennis-paddle-ball:after, .fad.fa-ping-pong-paddle-ball:after, .fa-duotone.fa-ping-pong-paddle-ball:after, .fad.fa-table-tennis:after, .fa-duotone.fa-table-tennis:after {
  content: "";
}

.fad.fa-person-dots-from-line:after, .fa-duotone.fa-person-dots-from-line:after, .fad.fa-diagnoses:after, .fa-duotone.fa-diagnoses:after {
  content: "";
}

.fad.fa-chevrons-down:after, .fa-duotone.fa-chevrons-down:after, .fad.fa-chevron-double-down:after, .fa-duotone.fa-chevron-double-down:after {
  content: "";
}

.fad.fa-trash-can-arrow-up:after, .fa-duotone.fa-trash-can-arrow-up:after, .fad.fa-trash-restore-alt:after, .fa-duotone.fa-trash-restore-alt:after {
  content: "";
}

.fad.fa-signal-good:after, .fa-duotone.fa-signal-good:after, .fad.fa-signal-3:after, .fa-duotone.fa-signal-3:after {
  content: "";
}

.fad.fa-location-question:after, .fa-duotone.fa-location-question:after, .fad.fa-map-marker-question:after, .fa-duotone.fa-map-marker-question:after {
  content: "";
}

.fad.fa-floppy-disk-circle-xmark:after, .fa-duotone.fa-floppy-disk-circle-xmark:after, .fad.fa-floppy-disk-times:after, .fa-duotone.fa-floppy-disk-times:after, .fad.fa-save-circle-xmark:after, .fa-duotone.fa-save-circle-xmark:after, .fad.fa-save-times:after, .fa-duotone.fa-save-times:after {
  content: "";
}

.fad.fa-naira-sign:after, .fa-duotone.fa-naira-sign:after {
  content: "";
}

.fad.fa-peach:after, .fa-duotone.fa-peach:after {
  content: "";
}

.fad.fa-taxi-bus:after, .fa-duotone.fa-taxi-bus:after {
  content: "";
}

.fad.fa-bracket-curly:after, .fa-duotone.fa-bracket-curly:after, .fad.fa-bracket-curly-left:after, .fa-duotone.fa-bracket-curly-left:after {
  content: "{{";
}

.fad.fa-lobster:after, .fa-duotone.fa-lobster:after {
  content: "";
}

.fad.fa-cart-flatbed-empty:after, .fa-duotone.fa-cart-flatbed-empty:after, .fad.fa-dolly-flatbed-empty:after, .fa-duotone.fa-dolly-flatbed-empty:after {
  content: "";
}

.fad.fa-colon:after, .fa-duotone.fa-colon:after {
  content: "::";
}

.fad.fa-cart-arrow-down:after, .fa-duotone.fa-cart-arrow-down:after {
  content: "";
}

.fad.fa-wand:after, .fa-duotone.fa-wand:after {
  content: "";
}

.fad.fa-walkie-talkie:after, .fa-duotone.fa-walkie-talkie:after {
  content: "";
}

.fad.fa-file-pen:after, .fa-duotone.fa-file-pen:after, .fad.fa-file-edit:after, .fa-duotone.fa-file-edit:after {
  content: "";
}

.fad.fa-receipt:after, .fa-duotone.fa-receipt:after {
  content: "";
}

.fad.fa-table-picnic:after, .fa-duotone.fa-table-picnic:after {
  content: "";
}

.fad.fa-square-pen:after, .fa-duotone.fa-square-pen:after, .fad.fa-pen-square:after, .fa-duotone.fa-pen-square:after, .fad.fa-pencil-square:after, .fa-duotone.fa-pencil-square:after {
  content: "";
}

.fad.fa-circle-microphone-lines:after, .fa-duotone.fa-circle-microphone-lines:after, .fad.fa-microphone-circle-alt:after, .fa-duotone.fa-microphone-circle-alt:after {
  content: "";
}

.fad.fa-display-slash:after, .fa-duotone.fa-display-slash:after, .fad.fa-desktop-slash:after, .fa-duotone.fa-desktop-slash:after {
  content: "";
}

.fad.fa-suitcase-rolling:after, .fa-duotone.fa-suitcase-rolling:after {
  content: "";
}

.fad.fa-person-circle-exclamation:after, .fa-duotone.fa-person-circle-exclamation:after {
  content: "";
}

.fad.fa-transporter-2:after, .fa-duotone.fa-transporter-2:after {
  content: "";
}

.fad.fa-hands-holding-diamond:after, .fa-duotone.fa-hands-holding-diamond:after, .fad.fa-hand-receiving:after, .fa-duotone.fa-hand-receiving:after {
  content: "";
}

.fad.fa-money-bill-simple-wave:after, .fa-duotone.fa-money-bill-simple-wave:after {
  content: "";
}

.fad.fa-chevron-down:after, .fa-duotone.fa-chevron-down:after {
  content: "";
}

.fad.fa-battery-full:after, .fa-duotone.fa-battery-full:after, .fad.fa-battery:after, .fa-duotone.fa-battery:after, .fad.fa-battery-5:after, .fa-duotone.fa-battery-5:after {
  content: "";
}

.fad.fa-bell-plus:after, .fa-duotone.fa-bell-plus:after {
  content: "";
}

.fad.fa-book-arrow-right:after, .fa-duotone.fa-book-arrow-right:after {
  content: "";
}

.fad.fa-hospitals:after, .fa-duotone.fa-hospitals:after {
  content: "";
}

.fad.fa-club:after, .fa-duotone.fa-club:after {
  content: "";
}

.fad.fa-skull-crossbones:after, .fa-duotone.fa-skull-crossbones:after {
  content: "";
}

.fad.fa-droplet-degree:after, .fa-duotone.fa-droplet-degree:after, .fad.fa-dewpoint:after, .fa-duotone.fa-dewpoint:after {
  content: "";
}

.fad.fa-code-compare:after, .fa-duotone.fa-code-compare:after {
  content: "";
}

.fad.fa-list-ul:after, .fa-duotone.fa-list-ul:after, .fad.fa-list-dots:after, .fa-duotone.fa-list-dots:after {
  content: "";
}

.fad.fa-hand-holding-magic:after, .fa-duotone.fa-hand-holding-magic:after {
  content: "";
}

.fad.fa-watermelon-slice:after, .fa-duotone.fa-watermelon-slice:after {
  content: "";
}

.fad.fa-circle-ellipsis:after, .fa-duotone.fa-circle-ellipsis:after {
  content: "";
}

.fad.fa-school-lock:after, .fa-duotone.fa-school-lock:after {
  content: "";
}

.fad.fa-tower-cell:after, .fa-duotone.fa-tower-cell:after {
  content: "";
}

.fad.fa-sd-cards:after, .fa-duotone.fa-sd-cards:after {
  content: "";
}

.fad.fa-jug-bottle:after, .fa-duotone.fa-jug-bottle:after {
  content: "";
}

.fad.fa-down-long:after, .fa-duotone.fa-down-long:after, .fad.fa-long-arrow-alt-down:after, .fa-duotone.fa-long-arrow-alt-down:after {
  content: "";
}

.fad.fa-envelopes:after, .fa-duotone.fa-envelopes:after {
  content: "";
}

.fad.fa-phone-office:after, .fa-duotone.fa-phone-office:after {
  content: "";
}

.fad.fa-ranking-star:after, .fa-duotone.fa-ranking-star:after {
  content: "";
}

.fad.fa-chess-king:after, .fa-duotone.fa-chess-king:after {
  content: "";
}

.fad.fa-nfc-pen:after, .fa-duotone.fa-nfc-pen:after {
  content: "";
}

.fad.fa-person-harassing:after, .fa-duotone.fa-person-harassing:after {
  content: "";
}

.fad.fa-magnifying-glass-play:after, .fa-duotone.fa-magnifying-glass-play:after {
  content: "";
}

.fad.fa-hat-winter:after, .fa-duotone.fa-hat-winter:after {
  content: "";
}

.fad.fa-brazilian-real-sign:after, .fa-duotone.fa-brazilian-real-sign:after {
  content: "";
}

.fad.fa-landmark-dome:after, .fa-duotone.fa-landmark-dome:after, .fad.fa-landmark-alt:after, .fa-duotone.fa-landmark-alt:after {
  content: "";
}

.fad.fa-bone-break:after, .fa-duotone.fa-bone-break:after {
  content: "";
}

.fad.fa-arrow-up:after, .fa-duotone.fa-arrow-up:after {
  content: "";
}

.fad.fa-down-from-dotted-line:after, .fa-duotone.fa-down-from-dotted-line:after {
  content: "";
}

.fad.fa-tv:after, .fa-duotone.fa-tv:after, .fad.fa-television:after, .fa-duotone.fa-television:after, .fad.fa-tv-alt:after, .fa-duotone.fa-tv-alt:after {
  content: "";
}

.fad.fa-border-left:after, .fa-duotone.fa-border-left:after {
  content: "";
}

.fad.fa-circle-divide:after, .fa-duotone.fa-circle-divide:after {
  content: "";
}

.fad.fa-shrimp:after, .fa-duotone.fa-shrimp:after {
  content: "";
}

.fad.fa-list-check:after, .fa-duotone.fa-list-check:after, .fad.fa-tasks:after, .fa-duotone.fa-tasks:after {
  content: "";
}

.fad.fa-diagram-subtask:after, .fa-duotone.fa-diagram-subtask:after {
  content: "";
}

.fad.fa-jug-detergent:after, .fa-duotone.fa-jug-detergent:after {
  content: "";
}

.fad.fa-circle-user:after, .fa-duotone.fa-circle-user:after, .fad.fa-user-circle:after, .fa-duotone.fa-user-circle:after {
  content: "";
}

.fad.fa-square-y:after, .fa-duotone.fa-square-y:after {
  content: "";
}

.fad.fa-user-doctor-hair:after, .fa-duotone.fa-user-doctor-hair:after {
  content: "";
}

.fad.fa-planet-ringed:after, .fa-duotone.fa-planet-ringed:after {
  content: "";
}

.fad.fa-mushroom:after, .fa-duotone.fa-mushroom:after {
  content: "";
}

.fad.fa-user-shield:after, .fa-duotone.fa-user-shield:after {
  content: "";
}

.fad.fa-megaphone:after, .fa-duotone.fa-megaphone:after {
  content: "";
}

.fad.fa-wreath-laurel:after, .fa-duotone.fa-wreath-laurel:after {
  content: "";
}

.fad.fa-circle-exclamation-check:after, .fa-duotone.fa-circle-exclamation-check:after {
  content: "";
}

.fad.fa-wind:after, .fa-duotone.fa-wind:after {
  content: "";
}

.fad.fa-box-dollar:after, .fa-duotone.fa-box-dollar:after, .fad.fa-box-usd:after, .fa-duotone.fa-box-usd:after {
  content: "";
}

.fad.fa-car-burst:after, .fa-duotone.fa-car-burst:after, .fad.fa-car-crash:after, .fa-duotone.fa-car-crash:after {
  content: "";
}

.fad.fa-y:after, .fa-duotone.fa-y:after {
  content: "YY";
}

.fad.fa-user-headset:after, .fa-duotone.fa-user-headset:after {
  content: "";
}

.fad.fa-arrows-retweet:after, .fa-duotone.fa-arrows-retweet:after, .fad.fa-retweet-alt:after, .fa-duotone.fa-retweet-alt:after {
  content: "";
}

.fad.fa-person-snowboarding:after, .fa-duotone.fa-person-snowboarding:after, .fad.fa-snowboarding:after, .fa-duotone.fa-snowboarding:after {
  content: "";
}

.fad.fa-square-chevron-right:after, .fa-duotone.fa-square-chevron-right:after, .fad.fa-chevron-square-right:after, .fa-duotone.fa-chevron-square-right:after {
  content: "";
}

.fad.fa-lacrosse-stick-ball:after, .fa-duotone.fa-lacrosse-stick-ball:after {
  content: "";
}

.fad.fa-truck-fast:after, .fa-duotone.fa-truck-fast:after, .fad.fa-shipping-fast:after, .fa-duotone.fa-shipping-fast:after {
  content: "";
}

.fad.fa-user-magnifying-glass:after, .fa-duotone.fa-user-magnifying-glass:after {
  content: "";
}

.fad.fa-star-sharp:after, .fa-duotone.fa-star-sharp:after {
  content: "";
}

.fad.fa-comment-heart:after, .fa-duotone.fa-comment-heart:after {
  content: "";
}

.fad.fa-circle-1:after, .fa-duotone.fa-circle-1:after {
  content: "";
}

.fad.fa-circle-star:after, .fa-duotone.fa-circle-star:after, .fad.fa-star-circle:after, .fa-duotone.fa-star-circle:after {
  content: "";
}

.fad.fa-fish:after, .fa-duotone.fa-fish:after {
  content: "";
}

.fad.fa-cloud-fog:after, .fa-duotone.fa-cloud-fog:after, .fad.fa-fog:after, .fa-duotone.fa-fog:after {
  content: "";
}

.fad.fa-waffle:after, .fa-duotone.fa-waffle:after {
  content: "";
}

.fad.fa-music-note:after, .fa-duotone.fa-music-note:after, .fad.fa-music-alt:after, .fa-duotone.fa-music-alt:after {
  content: "";
}

.fad.fa-hexagon-exclamation:after, .fa-duotone.fa-hexagon-exclamation:after {
  content: "";
}

.fad.fa-cart-shopping-fast:after, .fa-duotone.fa-cart-shopping-fast:after {
  content: "";
}

.fad.fa-object-union:after, .fa-duotone.fa-object-union:after {
  content: "";
}

.fad.fa-user-graduate:after, .fa-duotone.fa-user-graduate:after {
  content: "";
}

.fad.fa-starfighter:after, .fa-duotone.fa-starfighter:after {
  content: "";
}

.fad.fa-circle-half-stroke:after, .fa-duotone.fa-circle-half-stroke:after, .fad.fa-adjust:after, .fa-duotone.fa-adjust:after {
  content: "";
}

.fad.fa-arrow-right-long-to-line:after, .fa-duotone.fa-arrow-right-long-to-line:after {
  content: "";
}

.fad.fa-square-arrow-down:after, .fa-duotone.fa-square-arrow-down:after, .fad.fa-arrow-square-down:after, .fa-duotone.fa-arrow-square-down:after {
  content: "";
}

.fad.fa-diamond-half-stroke:after, .fa-duotone.fa-diamond-half-stroke:after {
  content: "";
}

.fad.fa-clapperboard:after, .fa-duotone.fa-clapperboard:after {
  content: "";
}

.fad.fa-square-chevron-left:after, .fa-duotone.fa-square-chevron-left:after, .fad.fa-chevron-square-left:after, .fa-duotone.fa-chevron-square-left:after {
  content: "";
}

.fad.fa-phone-intercom:after, .fa-duotone.fa-phone-intercom:after {
  content: "";
}

.fad.fa-link-horizontal:after, .fa-duotone.fa-link-horizontal:after, .fad.fa-chain-horizontal:after, .fa-duotone.fa-chain-horizontal:after {
  content: "";
}

.fad.fa-mango:after, .fa-duotone.fa-mango:after {
  content: "";
}

.fad.fa-music-note-slash:after, .fa-duotone.fa-music-note-slash:after, .fad.fa-music-alt-slash:after, .fa-duotone.fa-music-alt-slash:after {
  content: "";
}

.fad.fa-circle-radiation:after, .fa-duotone.fa-circle-radiation:after, .fad.fa-radiation-alt:after, .fa-duotone.fa-radiation-alt:after {
  content: "";
}

.fad.fa-face-tongue-sweat:after, .fa-duotone.fa-face-tongue-sweat:after {
  content: "";
}

.fad.fa-globe-stand:after, .fa-duotone.fa-globe-stand:after {
  content: "";
}

.fad.fa-baseball:after, .fa-duotone.fa-baseball:after, .fad.fa-baseball-ball:after, .fa-duotone.fa-baseball-ball:after {
  content: "";
}

.fad.fa-circle-p:after, .fa-duotone.fa-circle-p:after {
  content: "";
}

.fad.fa-award-simple:after, .fa-duotone.fa-award-simple:after {
  content: "";
}

.fad.fa-jet-fighter-up:after, .fa-duotone.fa-jet-fighter-up:after {
  content: "";
}

.fad.fa-diagram-project:after, .fa-duotone.fa-diagram-project:after, .fad.fa-project-diagram:after, .fa-duotone.fa-project-diagram:after {
  content: "";
}

.fad.fa-pedestal:after, .fa-duotone.fa-pedestal:after {
  content: "";
}

.fad.fa-chart-pyramid:after, .fa-duotone.fa-chart-pyramid:after {
  content: "";
}

.fad.fa-sidebar:after, .fa-duotone.fa-sidebar:after {
  content: "";
}

.fad.fa-snowman-head:after, .fa-duotone.fa-snowman-head:after, .fad.fa-frosty-head:after, .fa-duotone.fa-frosty-head:after {
  content: "";
}

.fad.fa-copy:after, .fa-duotone.fa-copy:after {
  content: "";
}

.fad.fa-burger-glass:after, .fa-duotone.fa-burger-glass:after {
  content: "";
}

.fad.fa-volume-xmark:after, .fa-duotone.fa-volume-xmark:after, .fad.fa-volume-mute:after, .fa-duotone.fa-volume-mute:after, .fad.fa-volume-times:after, .fa-duotone.fa-volume-times:after {
  content: "";
}

.fad.fa-hand-sparkles:after, .fa-duotone.fa-hand-sparkles:after {
  content: "";
}

.fad.fa-bars-filter:after, .fa-duotone.fa-bars-filter:after {
  content: "";
}

.fad.fa-paintbrush-pencil:after, .fa-duotone.fa-paintbrush-pencil:after {
  content: "";
}

.fad.fa-party-bell:after, .fa-duotone.fa-party-bell:after {
  content: "";
}

.fad.fa-user-vneck-hair:after, .fa-duotone.fa-user-vneck-hair:after {
  content: "";
}

.fad.fa-jack-o-lantern:after, .fa-duotone.fa-jack-o-lantern:after {
  content: "";
}

.fad.fa-grip:after, .fa-duotone.fa-grip:after, .fad.fa-grip-horizontal:after, .fa-duotone.fa-grip-horizontal:after {
  content: "";
}

.fad.fa-share-from-square:after, .fa-duotone.fa-share-from-square:after, .fad.fa-share-square:after, .fa-duotone.fa-share-square:after {
  content: "";
}

.fad.fa-keynote:after, .fa-duotone.fa-keynote:after {
  content: "";
}

.fad.fa-child-combatant:after, .fa-duotone.fa-child-combatant:after, .fad.fa-child-rifle:after, .fa-duotone.fa-child-rifle:after {
  content: "";
}

.fad.fa-gun:after, .fa-duotone.fa-gun:after {
  content: "";
}

.fad.fa-square-phone:after, .fa-duotone.fa-square-phone:after, .fad.fa-phone-square:after, .fa-duotone.fa-phone-square:after {
  content: "";
}

.fad.fa-hat-beach:after, .fa-duotone.fa-hat-beach:after {
  content: "";
}

.fad.fa-plus:after, .fa-duotone.fa-plus:after, .fad.fa-add:after, .fa-duotone.fa-add:after {
  content: "++";
}

.fad.fa-expand:after, .fa-duotone.fa-expand:after {
  content: "";
}

.fad.fa-computer:after, .fa-duotone.fa-computer:after {
  content: "";
}

.fad.fa-fort:after, .fa-duotone.fa-fort:after {
  content: "";
}

.fad.fa-cloud-check:after, .fa-duotone.fa-cloud-check:after {
  content: "";
}

.fad.fa-xmark:after, .fa-duotone.fa-xmark:after, .fad.fa-close:after, .fa-duotone.fa-close:after, .fad.fa-multiply:after, .fa-duotone.fa-multiply:after, .fad.fa-remove:after, .fa-duotone.fa-remove:after, .fad.fa-times:after, .fa-duotone.fa-times:after {
  content: "";
}

.fad.fa-face-smirking:after, .fa-duotone.fa-face-smirking:after {
  content: "";
}

.fad.fa-arrows-up-down-left-right:after, .fa-duotone.fa-arrows-up-down-left-right:after, .fad.fa-arrows:after, .fa-duotone.fa-arrows:after {
  content: "";
}

.fad.fa-chalkboard-user:after, .fa-duotone.fa-chalkboard-user:after, .fad.fa-chalkboard-teacher:after, .fa-duotone.fa-chalkboard-teacher:after {
  content: "";
}

.fad.fa-rhombus:after, .fa-duotone.fa-rhombus:after {
  content: "";
}

.fad.fa-claw-marks:after, .fa-duotone.fa-claw-marks:after {
  content: "";
}

.fad.fa-peso-sign:after, .fa-duotone.fa-peso-sign:after {
  content: "";
}

.fad.fa-face-smile-tongue:after, .fa-duotone.fa-face-smile-tongue:after {
  content: "";
}

.fad.fa-cart-circle-xmark:after, .fa-duotone.fa-cart-circle-xmark:after {
  content: "";
}

.fad.fa-building-shield:after, .fa-duotone.fa-building-shield:after {
  content: "";
}

.fad.fa-circle-phone-flip:after, .fa-duotone.fa-circle-phone-flip:after, .fad.fa-phone-circle-alt:after, .fa-duotone.fa-phone-circle-alt:after {
  content: "";
}

.fad.fa-baby:after, .fa-duotone.fa-baby:after {
  content: "";
}

.fad.fa-users-line:after, .fa-duotone.fa-users-line:after {
  content: "";
}

.fad.fa-quote-left:after, .fa-duotone.fa-quote-left:after, .fad.fa-quote-left-alt:after, .fa-duotone.fa-quote-left-alt:after {
  content: "";
}

.fad.fa-tractor:after, .fa-duotone.fa-tractor:after {
  content: "";
}

.fad.fa-down-from-bracket:after, .fa-duotone.fa-down-from-bracket:after {
  content: "";
}

.fad.fa-key-skeleton:after, .fa-duotone.fa-key-skeleton:after {
  content: "";
}

.fad.fa-trash-arrow-up:after, .fa-duotone.fa-trash-arrow-up:after, .fad.fa-trash-restore:after, .fa-duotone.fa-trash-restore:after {
  content: "";
}

.fad.fa-arrow-down-up-lock:after, .fa-duotone.fa-arrow-down-up-lock:after {
  content: "";
}

.fad.fa-arrow-down-to-bracket:after, .fa-duotone.fa-arrow-down-to-bracket:after {
  content: "";
}

.fad.fa-lines-leaning:after, .fa-duotone.fa-lines-leaning:after {
  content: "";
}

.fad.fa-square-q:after, .fa-duotone.fa-square-q:after {
  content: "";
}

.fad.fa-ruler-combined:after, .fa-duotone.fa-ruler-combined:after {
  content: "";
}

.fad.fa-symbols:after, .fa-duotone.fa-symbols:after, .fad.fa-icons-alt:after, .fa-duotone.fa-icons-alt:after {
  content: "";
}

.fad.fa-copyright:after, .fa-duotone.fa-copyright:after {
  content: "";
}

.fad.fa-flask-gear:after, .fa-duotone.fa-flask-gear:after {
  content: "";
}

.fad.fa-highlighter-line:after, .fa-duotone.fa-highlighter-line:after {
  content: "";
}

.fad.fa-bracket-square:after, .fa-duotone.fa-bracket-square:after, .fad.fa-bracket:after, .fa-duotone.fa-bracket:after, .fad.fa-bracket-left:after, .fa-duotone.fa-bracket-left:after {
  content: "[[";
}

.fad.fa-island-tropical:after, .fa-duotone.fa-island-tropical:after, .fad.fa-island-tree-palm:after, .fa-duotone.fa-island-tree-palm:after {
  content: "";
}

.fad.fa-arrow-right-from-line:after, .fa-duotone.fa-arrow-right-from-line:after, .fad.fa-arrow-from-left:after, .fa-duotone.fa-arrow-from-left:after {
  content: "";
}

.fad.fa-h2:after, .fa-duotone.fa-h2:after {
  content: "";
}

.fad.fa-equals:after, .fa-duotone.fa-equals:after {
  content: "==";
}

.fad.fa-cake-slice:after, .fa-duotone.fa-cake-slice:after, .fad.fa-shortcake:after, .fa-duotone.fa-shortcake:after {
  content: "";
}

.fad.fa-building-magnifying-glass:after, .fa-duotone.fa-building-magnifying-glass:after {
  content: "";
}

.fad.fa-peanut:after, .fa-duotone.fa-peanut:after {
  content: "";
}

.fad.fa-wrench-simple:after, .fa-duotone.fa-wrench-simple:after {
  content: "";
}

.fad.fa-blender:after, .fa-duotone.fa-blender:after {
  content: "";
}

.fad.fa-teeth:after, .fa-duotone.fa-teeth:after {
  content: "";
}

.fad.fa-tally-2:after, .fa-duotone.fa-tally-2:after {
  content: "";
}

.fad.fa-shekel-sign:after, .fa-duotone.fa-shekel-sign:after, .fad.fa-ils:after, .fa-duotone.fa-ils:after, .fad.fa-shekel:after, .fa-duotone.fa-shekel:after, .fad.fa-sheqel:after, .fa-duotone.fa-sheqel:after, .fad.fa-sheqel-sign:after, .fa-duotone.fa-sheqel-sign:after {
  content: "";
}

.fad.fa-cars:after, .fa-duotone.fa-cars:after {
  content: "";
}

.fad.fa-axe-battle:after, .fa-duotone.fa-axe-battle:after {
  content: "";
}

.fad.fa-user-hair-long:after, .fa-duotone.fa-user-hair-long:after {
  content: "";
}

.fad.fa-map:after, .fa-duotone.fa-map:after {
  content: "";
}

.fad.fa-arrow-left-from-arc:after, .fa-duotone.fa-arrow-left-from-arc:after {
  content: "";
}

.fad.fa-file-circle-info:after, .fa-duotone.fa-file-circle-info:after {
  content: "";
}

.fad.fa-face-disappointed:after, .fa-duotone.fa-face-disappointed:after {
  content: "";
}

.fad.fa-lasso-sparkles:after, .fa-duotone.fa-lasso-sparkles:after {
  content: "";
}

.fad.fa-clock-eleven:after, .fa-duotone.fa-clock-eleven:after {
  content: "";
}

.fad.fa-rocket:after, .fa-duotone.fa-rocket:after {
  content: "";
}

.fad.fa-siren-on:after, .fa-duotone.fa-siren-on:after {
  content: "";
}

.fad.fa-clock-ten:after, .fa-duotone.fa-clock-ten:after {
  content: "";
}

.fad.fa-candle-holder:after, .fa-duotone.fa-candle-holder:after {
  content: "";
}

.fad.fa-video-arrow-down-left:after, .fa-duotone.fa-video-arrow-down-left:after {
  content: "";
}

.fad.fa-photo-film:after, .fa-duotone.fa-photo-film:after, .fad.fa-photo-video:after, .fa-duotone.fa-photo-video:after {
  content: "";
}

.fad.fa-floppy-disk-circle-arrow-right:after, .fa-duotone.fa-floppy-disk-circle-arrow-right:after, .fad.fa-save-circle-arrow-right:after, .fa-duotone.fa-save-circle-arrow-right:after {
  content: "";
}

.fad.fa-folder-minus:after, .fa-duotone.fa-folder-minus:after {
  content: "";
}

.fad.fa-planet-moon:after, .fa-duotone.fa-planet-moon:after {
  content: "";
}

.fad.fa-face-eyes-xmarks:after, .fa-duotone.fa-face-eyes-xmarks:after {
  content: "";
}

.fad.fa-chart-scatter:after, .fa-duotone.fa-chart-scatter:after {
  content: "";
}

.fad.fa-circle-gf:after, .fa-duotone.fa-circle-gf:after {
  content: "";
}

.fad.fa-display-arrow-down:after, .fa-duotone.fa-display-arrow-down:after {
  content: "";
}

.fad.fa-store:after, .fa-duotone.fa-store:after {
  content: "";
}

.fad.fa-arrow-trend-up:after, .fa-duotone.fa-arrow-trend-up:after {
  content: "";
}

.fad.fa-plug-circle-minus:after, .fa-duotone.fa-plug-circle-minus:after {
  content: "";
}

.fad.fa-olive-branch:after, .fa-duotone.fa-olive-branch:after {
  content: "";
}

.fad.fa-angle:after, .fa-duotone.fa-angle:after {
  content: "";
}

.fad.fa-vacuum-robot:after, .fa-duotone.fa-vacuum-robot:after {
  content: "";
}

.fad.fa-sign-hanging:after, .fa-duotone.fa-sign-hanging:after, .fad.fa-sign:after, .fa-duotone.fa-sign:after {
  content: "";
}

.fad.fa-square-divide:after, .fa-duotone.fa-square-divide:after {
  content: "";
}

.fad.fa-folder-check:after, .fa-duotone.fa-folder-check:after {
  content: "";
}

.fad.fa-signal-stream-slash:after, .fa-duotone.fa-signal-stream-slash:after {
  content: "";
}

.fad.fa-bezier-curve:after, .fa-duotone.fa-bezier-curve:after {
  content: "";
}

.fad.fa-eye-dropper-half:after, .fa-duotone.fa-eye-dropper-half:after {
  content: "";
}

.fad.fa-store-lock:after, .fa-duotone.fa-store-lock:after {
  content: "";
}

.fad.fa-bell-slash:after, .fa-duotone.fa-bell-slash:after {
  content: "";
}

.fad.fa-cloud-bolt-sun:after, .fa-duotone.fa-cloud-bolt-sun:after, .fad.fa-thunderstorm-sun:after, .fa-duotone.fa-thunderstorm-sun:after {
  content: "";
}

.fad.fa-camera-slash:after, .fa-duotone.fa-camera-slash:after {
  content: "";
}

.fad.fa-comment-quote:after, .fa-duotone.fa-comment-quote:after {
  content: "";
}

.fad.fa-tablet:after, .fa-duotone.fa-tablet:after, .fad.fa-tablet-android:after, .fa-duotone.fa-tablet-android:after {
  content: "";
}

.fad.fa-school-flag:after, .fa-duotone.fa-school-flag:after {
  content: "";
}

.fad.fa-message-code:after, .fa-duotone.fa-message-code:after {
  content: "";
}

.fad.fa-glass-half:after, .fa-duotone.fa-glass-half:after, .fad.fa-glass-half-empty:after, .fa-duotone.fa-glass-half-empty:after, .fad.fa-glass-half-full:after, .fa-duotone.fa-glass-half-full:after {
  content: "";
}

.fad.fa-fill:after, .fa-duotone.fa-fill:after {
  content: "";
}

.fad.fa-message-minus:after, .fa-duotone.fa-message-minus:after, .fad.fa-comment-alt-minus:after, .fa-duotone.fa-comment-alt-minus:after {
  content: "";
}

.fad.fa-angle-up:after, .fa-duotone.fa-angle-up:after {
  content: "";
}

.fad.fa-dinosaur:after, .fa-duotone.fa-dinosaur:after {
  content: "";
}

.fad.fa-drumstick-bite:after, .fa-duotone.fa-drumstick-bite:after {
  content: "";
}

.fad.fa-link-horizontal-slash:after, .fa-duotone.fa-link-horizontal-slash:after, .fad.fa-chain-horizontal-slash:after, .fa-duotone.fa-chain-horizontal-slash:after {
  content: "";
}

.fad.fa-holly-berry:after, .fa-duotone.fa-holly-berry:after {
  content: "";
}

.fad.fa-nose:after, .fa-duotone.fa-nose:after {
  content: "";
}

.fad.fa-arrow-left-to-arc:after, .fa-duotone.fa-arrow-left-to-arc:after {
  content: "";
}

.fad.fa-chevron-left:after, .fa-duotone.fa-chevron-left:after {
  content: "";
}

.fad.fa-bacteria:after, .fa-duotone.fa-bacteria:after {
  content: "";
}

.fad.fa-clouds:after, .fa-duotone.fa-clouds:after {
  content: "";
}

.fad.fa-money-bill-simple:after, .fa-duotone.fa-money-bill-simple:after {
  content: "";
}

.fad.fa-hand-lizard:after, .fa-duotone.fa-hand-lizard:after {
  content: "";
}

.fad.fa-table-pivot:after, .fa-duotone.fa-table-pivot:after {
  content: "";
}

.fad.fa-filter-slash:after, .fa-duotone.fa-filter-slash:after {
  content: "";
}

.fad.fa-trash-can-undo:after, .fa-duotone.fa-trash-can-undo:after, .fad.fa-trash-can-arrow-turn-left:after, .fa-duotone.fa-trash-can-arrow-turn-left:after, .fad.fa-trash-undo-alt:after, .fa-duotone.fa-trash-undo-alt:after {
  content: "";
}

.fad.fa-notdef:after, .fa-duotone.fa-notdef:after {
  content: "";
}

.fad.fa-disease:after, .fa-duotone.fa-disease:after {
  content: "";
}

.fad.fa-person-to-door:after, .fa-duotone.fa-person-to-door:after {
  content: "";
}

.fad.fa-turntable:after, .fa-duotone.fa-turntable:after {
  content: "";
}

.fad.fa-briefcase-medical:after, .fa-duotone.fa-briefcase-medical:after {
  content: "";
}

.fad.fa-genderless:after, .fa-duotone.fa-genderless:after {
  content: "";
}

.fad.fa-chevron-right:after, .fa-duotone.fa-chevron-right:after {
  content: "";
}

.fad.fa-signal-weak:after, .fa-duotone.fa-signal-weak:after, .fad.fa-signal-1:after, .fa-duotone.fa-signal-1:after {
  content: "";
}

.fad.fa-clock-five:after, .fa-duotone.fa-clock-five:after {
  content: "";
}

.fad.fa-retweet:after, .fa-duotone.fa-retweet:after {
  content: "";
}

.fad.fa-car-rear:after, .fa-duotone.fa-car-rear:after, .fad.fa-car-alt:after, .fa-duotone.fa-car-alt:after {
  content: "";
}

.fad.fa-pump-soap:after, .fa-duotone.fa-pump-soap:after {
  content: "";
}

.fad.fa-computer-classic:after, .fa-duotone.fa-computer-classic:after {
  content: "";
}

.fad.fa-frame:after, .fa-duotone.fa-frame:after {
  content: "";
}

.fad.fa-video-slash:after, .fa-duotone.fa-video-slash:after {
  content: "";
}

.fad.fa-battery-quarter:after, .fa-duotone.fa-battery-quarter:after, .fad.fa-battery-2:after, .fa-duotone.fa-battery-2:after {
  content: "";
}

.fad.fa-ellipsis-stroke:after, .fa-duotone.fa-ellipsis-stroke:after, .fad.fa-ellipsis-h-alt:after, .fa-duotone.fa-ellipsis-h-alt:after {
  content: "";
}

.fad.fa-radio:after, .fa-duotone.fa-radio:after {
  content: "";
}

.fad.fa-baby-carriage:after, .fa-duotone.fa-baby-carriage:after, .fad.fa-carriage-baby:after, .fa-duotone.fa-carriage-baby:after {
  content: "";
}

.fad.fa-face-expressionless:after, .fa-duotone.fa-face-expressionless:after {
  content: "";
}

.fad.fa-down-to-dotted-line:after, .fa-duotone.fa-down-to-dotted-line:after {
  content: "";
}

.fad.fa-cloud-music:after, .fa-duotone.fa-cloud-music:after {
  content: "";
}

.fad.fa-traffic-light:after, .fa-duotone.fa-traffic-light:after {
  content: "";
}

.fad.fa-cloud-minus:after, .fa-duotone.fa-cloud-minus:after {
  content: "";
}

.fad.fa-thermometer:after, .fa-duotone.fa-thermometer:after {
  content: "";
}

.fad.fa-shield-minus:after, .fa-duotone.fa-shield-minus:after {
  content: "";
}

.fad.fa-vr-cardboard:after, .fa-duotone.fa-vr-cardboard:after {
  content: "";
}

.fad.fa-car-tilt:after, .fa-duotone.fa-car-tilt:after {
  content: "";
}

.fad.fa-gauge-circle-minus:after, .fa-duotone.fa-gauge-circle-minus:after {
  content: "";
}

.fad.fa-brightness-low:after, .fa-duotone.fa-brightness-low:after {
  content: "";
}

.fad.fa-hand-middle-finger:after, .fa-duotone.fa-hand-middle-finger:after {
  content: "";
}

.fad.fa-percent:after, .fa-duotone.fa-percent:after, .fad.fa-percentage:after, .fa-duotone.fa-percentage:after {
  content: "%%";
}

.fad.fa-truck-moving:after, .fa-duotone.fa-truck-moving:after {
  content: "";
}

.fad.fa-glass-water-droplet:after, .fa-duotone.fa-glass-water-droplet:after {
  content: "";
}

.fad.fa-conveyor-belt:after, .fa-duotone.fa-conveyor-belt:after {
  content: "";
}

.fad.fa-location-check:after, .fa-duotone.fa-location-check:after, .fad.fa-map-marker-check:after, .fa-duotone.fa-map-marker-check:after {
  content: "";
}

.fad.fa-coin-vertical:after, .fa-duotone.fa-coin-vertical:after {
  content: "";
}

.fad.fa-display:after, .fa-duotone.fa-display:after {
  content: "";
}

.fad.fa-person-sign:after, .fa-duotone.fa-person-sign:after {
  content: "";
}

.fad.fa-face-smile:after, .fa-duotone.fa-face-smile:after, .fad.fa-smile:after, .fa-duotone.fa-smile:after {
  content: "";
}

.fad.fa-phone-hangup:after, .fa-duotone.fa-phone-hangup:after {
  content: "";
}

.fad.fa-signature-slash:after, .fa-duotone.fa-signature-slash:after {
  content: "";
}

.fad.fa-thumbtack:after, .fa-duotone.fa-thumbtack:after, .fad.fa-thumb-tack:after, .fa-duotone.fa-thumb-tack:after {
  content: "";
}

.fad.fa-wheat-slash:after, .fa-duotone.fa-wheat-slash:after {
  content: "";
}

.fad.fa-trophy:after, .fa-duotone.fa-trophy:after {
  content: "";
}

.fad.fa-clouds-sun:after, .fa-duotone.fa-clouds-sun:after {
  content: "";
}

.fad.fa-person-praying:after, .fa-duotone.fa-person-praying:after, .fad.fa-pray:after, .fa-duotone.fa-pray:after {
  content: "";
}

.fad.fa-hammer:after, .fa-duotone.fa-hammer:after {
  content: "";
}

.fad.fa-face-vomit:after, .fa-duotone.fa-face-vomit:after {
  content: "";
}

.fad.fa-speakers:after, .fa-duotone.fa-speakers:after {
  content: "";
}

.fad.fa-tty-answer:after, .fa-duotone.fa-tty-answer:after, .fad.fa-teletype-answer:after, .fa-duotone.fa-teletype-answer:after {
  content: "";
}

.fad.fa-mug-tea-saucer:after, .fa-duotone.fa-mug-tea-saucer:after {
  content: "";
}

.fad.fa-diagram-lean-canvas:after, .fa-duotone.fa-diagram-lean-canvas:after {
  content: "";
}

.fad.fa-alt:after, .fa-duotone.fa-alt:after {
  content: "";
}

.fad.fa-dial:after, .fa-duotone.fa-dial:after, .fad.fa-dial-med-high:after, .fa-duotone.fa-dial-med-high:after {
  content: "";
}

.fad.fa-hand-peace:after, .fa-duotone.fa-hand-peace:after {
  content: "";
}

.fad.fa-circle-trash:after, .fa-duotone.fa-circle-trash:after, .fad.fa-trash-circle:after, .fa-duotone.fa-trash-circle:after {
  content: "";
}

.fad.fa-rotate:after, .fa-duotone.fa-rotate:after, .fad.fa-sync-alt:after, .fa-duotone.fa-sync-alt:after {
  content: "";
}

.fad.fa-circle-quarters:after, .fa-duotone.fa-circle-quarters:after {
  content: "";
}

.fad.fa-spinner:after, .fa-duotone.fa-spinner:after {
  content: "";
}

.fad.fa-tower-control:after, .fa-duotone.fa-tower-control:after {
  content: "";
}

.fad.fa-arrow-up-triangle-square:after, .fa-duotone.fa-arrow-up-triangle-square:after, .fad.fa-sort-shapes-up:after, .fa-duotone.fa-sort-shapes-up:after {
  content: "";
}

.fad.fa-whale:after, .fa-duotone.fa-whale:after {
  content: "";
}

.fad.fa-robot:after, .fa-duotone.fa-robot:after {
  content: "";
}

.fad.fa-peace:after, .fa-duotone.fa-peace:after {
  content: "";
}

.fad.fa-party-horn:after, .fa-duotone.fa-party-horn:after {
  content: "";
}

.fad.fa-gears:after, .fa-duotone.fa-gears:after, .fad.fa-cogs:after, .fa-duotone.fa-cogs:after {
  content: "";
}

.fad.fa-sun-bright:after, .fa-duotone.fa-sun-bright:after, .fad.fa-sun-alt:after, .fa-duotone.fa-sun-alt:after {
  content: "";
}

.fad.fa-warehouse:after, .fa-duotone.fa-warehouse:after {
  content: "";
}

.fad.fa-conveyor-belt-arm:after, .fa-duotone.fa-conveyor-belt-arm:after {
  content: "";
}

.fad.fa-lock-keyhole-open:after, .fa-duotone.fa-lock-keyhole-open:after, .fad.fa-lock-open-alt:after, .fa-duotone.fa-lock-open-alt:after {
  content: "";
}

.fad.fa-square-fragile:after, .fa-duotone.fa-square-fragile:after, .fad.fa-box-fragile:after, .fa-duotone.fa-box-fragile:after, .fad.fa-square-wine-glass-crack:after, .fa-duotone.fa-square-wine-glass-crack:after {
  content: "";
}

.fad.fa-arrow-up-right-dots:after, .fa-duotone.fa-arrow-up-right-dots:after {
  content: "";
}

.fad.fa-square-n:after, .fa-duotone.fa-square-n:after {
  content: "";
}

.fad.fa-splotch:after, .fa-duotone.fa-splotch:after {
  content: "";
}

.fad.fa-face-grin-hearts:after, .fa-duotone.fa-face-grin-hearts:after, .fad.fa-grin-hearts:after, .fa-duotone.fa-grin-hearts:after {
  content: "";
}

.fad.fa-meter:after, .fa-duotone.fa-meter:after {
  content: "";
}

.fad.fa-mandolin:after, .fa-duotone.fa-mandolin:after {
  content: "";
}

.fad.fa-dice-four:after, .fa-duotone.fa-dice-four:after {
  content: "";
}

.fad.fa-sim-card:after, .fa-duotone.fa-sim-card:after {
  content: "";
}

.fad.fa-transgender:after, .fa-duotone.fa-transgender:after, .fad.fa-transgender-alt:after, .fa-duotone.fa-transgender-alt:after {
  content: "";
}

.fad.fa-mercury:after, .fa-duotone.fa-mercury:after {
  content: "";
}

.fad.fa-up-from-bracket:after, .fa-duotone.fa-up-from-bracket:after {
  content: "";
}

.fad.fa-knife-kitchen:after, .fa-duotone.fa-knife-kitchen:after {
  content: "";
}

.fad.fa-border-right:after, .fa-duotone.fa-border-right:after {
  content: "";
}

.fad.fa-arrow-turn-down:after, .fa-duotone.fa-arrow-turn-down:after, .fad.fa-level-down:after, .fa-duotone.fa-level-down:after {
  content: "";
}

.fad.fa-spade:after, .fa-duotone.fa-spade:after {
  content: "";
}

.fad.fa-card-spade:after, .fa-duotone.fa-card-spade:after {
  content: "";
}

.fad.fa-line-columns:after, .fa-duotone.fa-line-columns:after {
  content: "";
}

.fad.fa-ant:after, .fa-duotone.fa-ant:after {
  content: "";
}

.fad.fa-arrow-right-to-line:after, .fa-duotone.fa-arrow-right-to-line:after, .fad.fa-arrow-to-right:after, .fa-duotone.fa-arrow-to-right:after {
  content: "";
}

.fad.fa-person-falling-burst:after, .fa-duotone.fa-person-falling-burst:after {
  content: "";
}

.fad.fa-flag-pennant:after, .fa-duotone.fa-flag-pennant:after, .fad.fa-pennant:after, .fa-duotone.fa-pennant:after {
  content: "";
}

.fad.fa-conveyor-belt-empty:after, .fa-duotone.fa-conveyor-belt-empty:after {
  content: "";
}

.fad.fa-user-group-simple:after, .fa-duotone.fa-user-group-simple:after {
  content: "";
}

.fad.fa-award:after, .fa-duotone.fa-award:after {
  content: "";
}

.fad.fa-ticket-simple:after, .fa-duotone.fa-ticket-simple:after, .fad.fa-ticket-alt:after, .fa-duotone.fa-ticket-alt:after {
  content: "";
}

.fad.fa-building:after, .fa-duotone.fa-building:after {
  content: "";
}

.fad.fa-angles-left:after, .fa-duotone.fa-angles-left:after, .fad.fa-angle-double-left:after, .fa-duotone.fa-angle-double-left:after {
  content: "";
}

.fad.fa-camcorder:after, .fa-duotone.fa-camcorder:after, .fad.fa-video-handheld:after, .fa-duotone.fa-video-handheld:after {
  content: "";
}

.fad.fa-pancakes:after, .fa-duotone.fa-pancakes:after {
  content: "";
}

.fad.fa-album-circle-user:after, .fa-duotone.fa-album-circle-user:after {
  content: "";
}

.fad.fa-subtitles-slash:after, .fa-duotone.fa-subtitles-slash:after {
  content: "";
}

.fad.fa-qrcode:after, .fa-duotone.fa-qrcode:after {
  content: "";
}

.fad.fa-dice-d10:after, .fa-duotone.fa-dice-d10:after {
  content: "";
}

.fad.fa-fireplace:after, .fa-duotone.fa-fireplace:after {
  content: "";
}

.fad.fa-browser:after, .fa-duotone.fa-browser:after {
  content: "";
}

.fad.fa-pen-paintbrush:after, .fa-duotone.fa-pen-paintbrush:after, .fad.fa-pencil-paintbrush:after, .fa-duotone.fa-pencil-paintbrush:after {
  content: "";
}

.fad.fa-fish-cooked:after, .fa-duotone.fa-fish-cooked:after {
  content: "";
}

.fad.fa-chair-office:after, .fa-duotone.fa-chair-office:after {
  content: "";
}

.fad.fa-magnifying-glass-music:after, .fa-duotone.fa-magnifying-glass-music:after {
  content: "";
}

.fad.fa-nesting-dolls:after, .fa-duotone.fa-nesting-dolls:after {
  content: "";
}

.fad.fa-clock-rotate-left:after, .fa-duotone.fa-clock-rotate-left:after, .fad.fa-history:after, .fa-duotone.fa-history:after {
  content: "";
}

.fad.fa-trumpet:after, .fa-duotone.fa-trumpet:after {
  content: "";
}

.fad.fa-face-grin-beam-sweat:after, .fa-duotone.fa-face-grin-beam-sweat:after, .fad.fa-grin-beam-sweat:after, .fa-duotone.fa-grin-beam-sweat:after {
  content: "";
}

.fad.fa-fire-smoke:after, .fa-duotone.fa-fire-smoke:after {
  content: "";
}

.fad.fa-phone-missed:after, .fa-duotone.fa-phone-missed:after {
  content: "";
}

.fad.fa-file-export:after, .fa-duotone.fa-file-export:after, .fad.fa-arrow-right-from-file:after, .fa-duotone.fa-arrow-right-from-file:after {
  content: "";
}

.fad.fa-shield:after, .fa-duotone.fa-shield:after, .fad.fa-shield-blank:after, .fa-duotone.fa-shield-blank:after {
  content: "";
}

.fad.fa-arrow-up-short-wide:after, .fa-duotone.fa-arrow-up-short-wide:after, .fad.fa-sort-amount-up-alt:after, .fa-duotone.fa-sort-amount-up-alt:after {
  content: "";
}

.fad.fa-arrows-repeat-1:after, .fa-duotone.fa-arrows-repeat-1:after, .fad.fa-repeat-1-alt:after, .fa-duotone.fa-repeat-1-alt:after {
  content: "";
}

.fad.fa-gun-slash:after, .fa-duotone.fa-gun-slash:after {
  content: "";
}

.fad.fa-avocado:after, .fa-duotone.fa-avocado:after {
  content: "";
}

.fad.fa-binary:after, .fa-duotone.fa-binary:after {
  content: "";
}

.fad.fa-glasses-round:after, .fa-duotone.fa-glasses-round:after, .fad.fa-glasses-alt:after, .fa-duotone.fa-glasses-alt:after {
  content: "";
}

.fad.fa-phone-plus:after, .fa-duotone.fa-phone-plus:after {
  content: "";
}

.fad.fa-ditto:after, .fa-duotone.fa-ditto:after {
  content: "\"\"";
}

.fad.fa-person-seat:after, .fa-duotone.fa-person-seat:after {
  content: "";
}

.fad.fa-house-medical:after, .fa-duotone.fa-house-medical:after {
  content: "";
}

.fad.fa-golf-ball-tee:after, .fa-duotone.fa-golf-ball-tee:after, .fad.fa-golf-ball:after, .fa-duotone.fa-golf-ball:after {
  content: "";
}

.fad.fa-circle-chevron-left:after, .fa-duotone.fa-circle-chevron-left:after, .fad.fa-chevron-circle-left:after, .fa-duotone.fa-chevron-circle-left:after {
  content: "";
}

.fad.fa-house-chimney-window:after, .fa-duotone.fa-house-chimney-window:after {
  content: "";
}

.fad.fa-scythe:after, .fa-duotone.fa-scythe:after {
  content: "";
}

.fad.fa-pen-nib:after, .fa-duotone.fa-pen-nib:after {
  content: "";
}

.fad.fa-ban-parking:after, .fa-duotone.fa-ban-parking:after, .fad.fa-parking-circle-slash:after, .fa-duotone.fa-parking-circle-slash:after {
  content: "";
}

.fad.fa-tent-arrow-turn-left:after, .fa-duotone.fa-tent-arrow-turn-left:after {
  content: "";
}

.fad.fa-face-diagonal-mouth:after, .fa-duotone.fa-face-diagonal-mouth:after {
  content: "";
}

.fad.fa-diagram-cells:after, .fa-duotone.fa-diagram-cells:after {
  content: "";
}

.fad.fa-cricket-bat-ball:after, .fa-duotone.fa-cricket-bat-ball:after, .fad.fa-cricket:after, .fa-duotone.fa-cricket:after {
  content: "";
}

.fad.fa-tents:after, .fa-duotone.fa-tents:after {
  content: "";
}

.fad.fa-wand-magic:after, .fa-duotone.fa-wand-magic:after, .fad.fa-magic:after, .fa-duotone.fa-magic:after {
  content: "";
}

.fad.fa-dog:after, .fa-duotone.fa-dog:after {
  content: "";
}

.fad.fa-pen-line:after, .fa-duotone.fa-pen-line:after {
  content: "";
}

.fad.fa-atom-simple:after, .fa-duotone.fa-atom-simple:after, .fad.fa-atom-alt:after, .fa-duotone.fa-atom-alt:after {
  content: "";
}

.fad.fa-ampersand:after, .fa-duotone.fa-ampersand:after {
  content: "&&";
}

.fad.fa-carrot:after, .fa-duotone.fa-carrot:after {
  content: "";
}

.fad.fa-arrow-up-from-line:after, .fa-duotone.fa-arrow-up-from-line:after, .fad.fa-arrow-from-bottom:after, .fa-duotone.fa-arrow-from-bottom:after {
  content: "";
}

.fad.fa-moon:after, .fa-duotone.fa-moon:after {
  content: "";
}

.fad.fa-pen-slash:after, .fa-duotone.fa-pen-slash:after {
  content: "";
}

.fad.fa-wine-glass-empty:after, .fa-duotone.fa-wine-glass-empty:after, .fad.fa-wine-glass-alt:after, .fa-duotone.fa-wine-glass-alt:after {
  content: "";
}

.fad.fa-square-star:after, .fa-duotone.fa-square-star:after {
  content: "";
}

.fad.fa-cheese:after, .fa-duotone.fa-cheese:after {
  content: "";
}

.fad.fa-send-backward:after, .fa-duotone.fa-send-backward:after {
  content: "";
}

.fad.fa-yin-yang:after, .fa-duotone.fa-yin-yang:after {
  content: "";
}

.fad.fa-music:after, .fa-duotone.fa-music:after {
  content: "";
}

.fad.fa-compass-slash:after, .fa-duotone.fa-compass-slash:after {
  content: "";
}

.fad.fa-clock-one:after, .fa-duotone.fa-clock-one:after {
  content: "";
}

.fad.fa-file-music:after, .fa-duotone.fa-file-music:after {
  content: "";
}

.fad.fa-code-commit:after, .fa-duotone.fa-code-commit:after {
  content: "";
}

.fad.fa-temperature-low:after, .fa-duotone.fa-temperature-low:after {
  content: "";
}

.fad.fa-person-biking:after, .fa-duotone.fa-person-biking:after, .fad.fa-biking:after, .fa-duotone.fa-biking:after {
  content: "";
}

.fad.fa-display-chart-up-circle-currency:after, .fa-duotone.fa-display-chart-up-circle-currency:after {
  content: "";
}

.fad.fa-skeleton:after, .fa-duotone.fa-skeleton:after {
  content: "";
}

.fad.fa-circle-g:after, .fa-duotone.fa-circle-g:after {
  content: "";
}

.fad.fa-circle-arrow-up-left:after, .fa-duotone.fa-circle-arrow-up-left:after {
  content: "";
}

.fad.fa-coin-blank:after, .fa-duotone.fa-coin-blank:after {
  content: "";
}

.fad.fa-broom:after, .fa-duotone.fa-broom:after {
  content: "";
}

.fad.fa-vacuum:after, .fa-duotone.fa-vacuum:after {
  content: "";
}

.fad.fa-shield-heart:after, .fa-duotone.fa-shield-heart:after {
  content: "";
}

.fad.fa-card-heart:after, .fa-duotone.fa-card-heart:after {
  content: "";
}

.fad.fa-lightbulb-cfl-on:after, .fa-duotone.fa-lightbulb-cfl-on:after {
  content: "";
}

.fad.fa-melon:after, .fa-duotone.fa-melon:after {
  content: "";
}

.fad.fa-gopuram:after, .fa-duotone.fa-gopuram:after {
  content: "";
}

.fad.fa-earth-oceania:after, .fa-duotone.fa-earth-oceania:after, .fad.fa-globe-oceania:after, .fa-duotone.fa-globe-oceania:after {
  content: "";
}

.fad.fa-container-storage:after, .fa-duotone.fa-container-storage:after {
  content: "";
}

.fad.fa-face-pouting:after, .fa-duotone.fa-face-pouting:after {
  content: "";
}

.fad.fa-square-xmark:after, .fa-duotone.fa-square-xmark:after, .fad.fa-times-square:after, .fa-duotone.fa-times-square:after, .fad.fa-xmark-square:after, .fa-duotone.fa-xmark-square:after {
  content: "";
}

.fad.fa-face-explode:after, .fa-duotone.fa-face-explode:after, .fad.fa-exploding-head:after, .fa-duotone.fa-exploding-head:after {
  content: "";
}

.fad.fa-hashtag:after, .fa-duotone.fa-hashtag:after {
  content: "##";
}

.fad.fa-up-right-and-down-left-from-center:after, .fa-duotone.fa-up-right-and-down-left-from-center:after, .fad.fa-expand-alt:after, .fa-duotone.fa-expand-alt:after {
  content: "";
}

.fad.fa-oil-can:after, .fa-duotone.fa-oil-can:after {
  content: "";
}

.fad.fa-t:after, .fa-duotone.fa-t:after {
  content: "TT";
}

.fad.fa-transformer-bolt:after, .fa-duotone.fa-transformer-bolt:after {
  content: "";
}

.fad.fa-hippo:after, .fa-duotone.fa-hippo:after {
  content: "";
}

.fad.fa-chart-column:after, .fa-duotone.fa-chart-column:after {
  content: "";
}

.fad.fa-cassette-vhs:after, .fa-duotone.fa-cassette-vhs:after, .fad.fa-vhs:after, .fa-duotone.fa-vhs:after {
  content: "";
}

.fad.fa-infinity:after, .fa-duotone.fa-infinity:after {
  content: "";
}

.fad.fa-vial-circle-check:after, .fa-duotone.fa-vial-circle-check:after {
  content: "";
}

.fad.fa-chimney:after, .fa-duotone.fa-chimney:after {
  content: "";
}

.fad.fa-object-intersect:after, .fa-duotone.fa-object-intersect:after {
  content: "";
}

.fad.fa-person-arrow-down-to-line:after, .fa-duotone.fa-person-arrow-down-to-line:after {
  content: "";
}

.fad.fa-voicemail:after, .fa-duotone.fa-voicemail:after {
  content: "";
}

.fad.fa-block-brick:after, .fa-duotone.fa-block-brick:after, .fad.fa-wall-brick:after, .fa-duotone.fa-wall-brick:after {
  content: "";
}

.fad.fa-fan:after, .fa-duotone.fa-fan:after {
  content: "";
}

.fad.fa-bags-shopping:after, .fa-duotone.fa-bags-shopping:after {
  content: "";
}

.fad.fa-paragraph-left:after, .fa-duotone.fa-paragraph-left:after, .fad.fa-paragraph-rtl:after, .fa-duotone.fa-paragraph-rtl:after {
  content: "";
}

.fad.fa-person-walking-luggage:after, .fa-duotone.fa-person-walking-luggage:after {
  content: "";
}

.fad.fa-caravan-simple:after, .fa-duotone.fa-caravan-simple:after, .fad.fa-caravan-alt:after, .fa-duotone.fa-caravan-alt:after {
  content: "";
}

.fad.fa-turtle:after, .fa-duotone.fa-turtle:after {
  content: "";
}

.fad.fa-pencil-mechanical:after, .fa-duotone.fa-pencil-mechanical:after {
  content: "";
}

.fad.fa-up-down:after, .fa-duotone.fa-up-down:after, .fad.fa-arrows-alt-v:after, .fa-duotone.fa-arrows-alt-v:after {
  content: "";
}

.fad.fa-cloud-moon-rain:after, .fa-duotone.fa-cloud-moon-rain:after {
  content: "";
}

.fad.fa-booth-curtain:after, .fa-duotone.fa-booth-curtain:after {
  content: "";
}

.fad.fa-calendar:after, .fa-duotone.fa-calendar:after {
  content: "";
}

.fad.fa-box-heart:after, .fa-duotone.fa-box-heart:after {
  content: "";
}

.fad.fa-trailer:after, .fa-duotone.fa-trailer:after {
  content: "";
}

.fad.fa-user-doctor-message:after, .fa-duotone.fa-user-doctor-message:after, .fad.fa-user-md-chat:after, .fa-duotone.fa-user-md-chat:after {
  content: "";
}

.fad.fa-bahai:after, .fa-duotone.fa-bahai:after, .fad.fa-haykal:after, .fa-duotone.fa-haykal:after {
  content: "";
}

.fad.fa-lighthouse:after, .fa-duotone.fa-lighthouse:after {
  content: "";
}

.fad.fa-amp-guitar:after, .fa-duotone.fa-amp-guitar:after {
  content: "";
}

.fad.fa-sd-card:after, .fa-duotone.fa-sd-card:after {
  content: "";
}

.fad.fa-volume-slash:after, .fa-duotone.fa-volume-slash:after {
  content: "";
}

.fad.fa-border-bottom:after, .fa-duotone.fa-border-bottom:after {
  content: "";
}

.fad.fa-wifi-weak:after, .fa-duotone.fa-wifi-weak:after, .fad.fa-wifi-1:after, .fa-duotone.fa-wifi-1:after {
  content: "";
}

.fad.fa-dragon:after, .fa-duotone.fa-dragon:after {
  content: "";
}

.fad.fa-shoe-prints:after, .fa-duotone.fa-shoe-prints:after {
  content: "";
}

.fad.fa-circle-plus:after, .fa-duotone.fa-circle-plus:after, .fad.fa-plus-circle:after, .fa-duotone.fa-plus-circle:after {
  content: "";
}

.fad.fa-face-grin-tongue-wink:after, .fa-duotone.fa-face-grin-tongue-wink:after, .fad.fa-grin-tongue-wink:after, .fa-duotone.fa-grin-tongue-wink:after {
  content: "";
}

.fad.fa-hand-holding:after, .fa-duotone.fa-hand-holding:after {
  content: "";
}

.fad.fa-plug-circle-exclamation:after, .fa-duotone.fa-plug-circle-exclamation:after {
  content: "";
}

.fad.fa-link-slash:after, .fa-duotone.fa-link-slash:after, .fad.fa-chain-broken:after, .fa-duotone.fa-chain-broken:after, .fad.fa-chain-slash:after, .fa-duotone.fa-chain-slash:after, .fad.fa-unlink:after, .fa-duotone.fa-unlink:after {
  content: "";
}

.fad.fa-clone:after, .fa-duotone.fa-clone:after {
  content: "";
}

.fad.fa-person-walking-arrow-loop-left:after, .fa-duotone.fa-person-walking-arrow-loop-left:after {
  content: "";
}

.fad.fa-arrow-up-z-a:after, .fa-duotone.fa-arrow-up-z-a:after, .fad.fa-sort-alpha-up-alt:after, .fa-duotone.fa-sort-alpha-up-alt:after {
  content: "";
}

.fad.fa-fire-flame-curved:after, .fa-duotone.fa-fire-flame-curved:after, .fad.fa-fire-alt:after, .fa-duotone.fa-fire-alt:after {
  content: "";
}

.fad.fa-tornado:after, .fa-duotone.fa-tornado:after {
  content: "";
}

.fad.fa-file-circle-plus:after, .fa-duotone.fa-file-circle-plus:after {
  content: "";
}

.fad.fa-delete-right:after, .fa-duotone.fa-delete-right:after {
  content: "";
}

.fad.fa-book-quran:after, .fa-duotone.fa-book-quran:after, .fad.fa-quran:after, .fa-duotone.fa-quran:after {
  content: "";
}

.fad.fa-circle-quarter:after, .fa-duotone.fa-circle-quarter:after {
  content: "";
}

.fad.fa-anchor:after, .fa-duotone.fa-anchor:after {
  content: "";
}

.fad.fa-border-all:after, .fa-duotone.fa-border-all:after {
  content: "";
}

.fad.fa-function:after, .fa-duotone.fa-function:after {
  content: "";
}

.fad.fa-face-angry:after, .fa-duotone.fa-face-angry:after, .fad.fa-angry:after, .fa-duotone.fa-angry:after {
  content: "";
}

.fad.fa-people-simple:after, .fa-duotone.fa-people-simple:after {
  content: "";
}

.fad.fa-cookie-bite:after, .fa-duotone.fa-cookie-bite:after {
  content: "";
}

.fad.fa-arrow-trend-down:after, .fa-duotone.fa-arrow-trend-down:after {
  content: "";
}

.fad.fa-rss:after, .fa-duotone.fa-rss:after, .fad.fa-feed:after, .fa-duotone.fa-feed:after {
  content: "";
}

.fad.fa-face-monocle:after, .fa-duotone.fa-face-monocle:after {
  content: "";
}

.fad.fa-draw-polygon:after, .fa-duotone.fa-draw-polygon:after {
  content: "";
}

.fad.fa-scale-balanced:after, .fa-duotone.fa-scale-balanced:after, .fad.fa-balance-scale:after, .fa-duotone.fa-balance-scale:after {
  content: "";
}

.fad.fa-calendar-lines:after, .fa-duotone.fa-calendar-lines:after, .fad.fa-calendar-note:after, .fa-duotone.fa-calendar-note:after {
  content: "";
}

.fad.fa-arrow-down-big-small:after, .fa-duotone.fa-arrow-down-big-small:after, .fad.fa-sort-size-down:after, .fa-duotone.fa-sort-size-down:after {
  content: "";
}

.fad.fa-gauge-simple-high:after, .fa-duotone.fa-gauge-simple-high:after, .fad.fa-tachometer:after, .fa-duotone.fa-tachometer:after, .fad.fa-tachometer-fast:after, .fa-duotone.fa-tachometer-fast:after {
  content: "";
}

.fad.fa-do-not-enter:after, .fa-duotone.fa-do-not-enter:after {
  content: "";
}

.fad.fa-shower:after, .fa-duotone.fa-shower:after {
  content: "";
}

.fad.fa-dice-d8:after, .fa-duotone.fa-dice-d8:after {
  content: "";
}

.fad.fa-desktop:after, .fa-duotone.fa-desktop:after, .fad.fa-desktop-alt:after, .fa-duotone.fa-desktop-alt:after {
  content: "";
}

.fad.fa-m:after, .fa-duotone.fa-m:after {
  content: "MM";
}

.fad.fa-spinner-scale:after, .fa-duotone.fa-spinner-scale:after {
  content: "";
}

.fad.fa-grip-dots-vertical:after, .fa-duotone.fa-grip-dots-vertical:after {
  content: "";
}

.fad.fa-face-viewfinder:after, .fa-duotone.fa-face-viewfinder:after {
  content: "";
}

.fad.fa-soft-serve:after, .fa-duotone.fa-soft-serve:after, .fad.fa-creemee:after, .fa-duotone.fa-creemee:after {
  content: "";
}

.fad.fa-h5:after, .fa-duotone.fa-h5:after {
  content: "";
}

.fad.fa-hand-back-point-down:after, .fa-duotone.fa-hand-back-point-down:after {
  content: "";
}

.fad.fa-table-list:after, .fa-duotone.fa-table-list:after, .fad.fa-th-list:after, .fa-duotone.fa-th-list:after {
  content: "";
}

.fad.fa-basket-shopping-minus:after, .fa-duotone.fa-basket-shopping-minus:after {
  content: "";
}

.fad.fa-comment-sms:after, .fa-duotone.fa-comment-sms:after, .fad.fa-sms:after, .fa-duotone.fa-sms:after {
  content: "";
}

.fad.fa-rectangle:after, .fa-duotone.fa-rectangle:after, .fad.fa-rectangle-landscape:after, .fa-duotone.fa-rectangle-landscape:after {
  content: "";
}

.fad.fa-clipboard-list-check:after, .fa-duotone.fa-clipboard-list-check:after {
  content: "";
}

.fad.fa-turkey:after, .fa-duotone.fa-turkey:after {
  content: "";
}

.fad.fa-book:after, .fa-duotone.fa-book:after {
  content: "";
}

.fad.fa-user-plus:after, .fa-duotone.fa-user-plus:after {
  content: "";
}

.fad.fa-ice-skate:after, .fa-duotone.fa-ice-skate:after {
  content: "";
}

.fad.fa-check:after, .fa-duotone.fa-check:after {
  content: "";
}

.fad.fa-battery-three-quarters:after, .fa-duotone.fa-battery-three-quarters:after, .fad.fa-battery-4:after, .fa-duotone.fa-battery-4:after {
  content: "";
}

.fad.fa-tomato:after, .fa-duotone.fa-tomato:after {
  content: "";
}

.fad.fa-sword-laser:after, .fa-duotone.fa-sword-laser:after {
  content: "";
}

.fad.fa-house-circle-check:after, .fa-duotone.fa-house-circle-check:after {
  content: "";
}

.fad.fa-buildings:after, .fa-duotone.fa-buildings:after {
  content: "";
}

.fad.fa-angle-left:after, .fa-duotone.fa-angle-left:after {
  content: "";
}

.fad.fa-cart-flatbed-boxes:after, .fa-duotone.fa-cart-flatbed-boxes:after, .fad.fa-dolly-flatbed-alt:after, .fa-duotone.fa-dolly-flatbed-alt:after {
  content: "";
}

.fad.fa-diagram-successor:after, .fa-duotone.fa-diagram-successor:after {
  content: "";
}

.fad.fa-truck-arrow-right:after, .fa-duotone.fa-truck-arrow-right:after {
  content: "";
}

.fad.fa-square-w:after, .fa-duotone.fa-square-w:after {
  content: "";
}

.fad.fa-arrows-split-up-and-left:after, .fa-duotone.fa-arrows-split-up-and-left:after {
  content: "";
}

.fad.fa-lamp:after, .fa-duotone.fa-lamp:after {
  content: "";
}

.fad.fa-airplay:after, .fa-duotone.fa-airplay:after {
  content: "";
}

.fad.fa-hand-fist:after, .fa-duotone.fa-hand-fist:after, .fad.fa-fist-raised:after, .fa-duotone.fa-fist-raised:after {
  content: "";
}

.fad.fa-shield-quartered:after, .fa-duotone.fa-shield-quartered:after {
  content: "";
}

.fad.fa-slash-forward:after, .fa-duotone.fa-slash-forward:after {
  content: "//";
}

.fad.fa-location-pen:after, .fa-duotone.fa-location-pen:after, .fad.fa-map-marker-edit:after, .fa-duotone.fa-map-marker-edit:after {
  content: "";
}

.fad.fa-cloud-moon:after, .fa-duotone.fa-cloud-moon:after {
  content: "";
}

.fad.fa-pot-food:after, .fa-duotone.fa-pot-food:after {
  content: "";
}

.fad.fa-briefcase:after, .fa-duotone.fa-briefcase:after {
  content: "";
}

.fad.fa-person-falling:after, .fa-duotone.fa-person-falling:after {
  content: "";
}

.fad.fa-image-portrait:after, .fa-duotone.fa-image-portrait:after, .fad.fa-portrait:after, .fa-duotone.fa-portrait:after {
  content: "";
}

.fad.fa-user-tag:after, .fa-duotone.fa-user-tag:after {
  content: "";
}

.fad.fa-rug:after, .fa-duotone.fa-rug:after {
  content: "";
}

.fad.fa-print-slash:after, .fa-duotone.fa-print-slash:after {
  content: "";
}

.fad.fa-earth-europe:after, .fa-duotone.fa-earth-europe:after, .fad.fa-globe-europe:after, .fa-duotone.fa-globe-europe:after {
  content: "";
}

.fad.fa-cart-flatbed-suitcase:after, .fa-duotone.fa-cart-flatbed-suitcase:after, .fad.fa-luggage-cart:after, .fa-duotone.fa-luggage-cart:after {
  content: "";
}

.fad.fa-hand-back-point-ribbon:after, .fa-duotone.fa-hand-back-point-ribbon:after {
  content: "";
}

.fad.fa-rectangle-xmark:after, .fa-duotone.fa-rectangle-xmark:after, .fad.fa-rectangle-times:after, .fa-duotone.fa-rectangle-times:after, .fad.fa-times-rectangle:after, .fa-duotone.fa-times-rectangle:after, .fad.fa-window-close:after, .fa-duotone.fa-window-close:after {
  content: "";
}

.fad.fa-tire-rugged:after, .fa-duotone.fa-tire-rugged:after {
  content: "";
}

.fad.fa-lightbulb-dollar:after, .fa-duotone.fa-lightbulb-dollar:after {
  content: "";
}

.fad.fa-cowbell:after, .fa-duotone.fa-cowbell:after {
  content: "";
}

.fad.fa-baht-sign:after, .fa-duotone.fa-baht-sign:after {
  content: "";
}

.fad.fa-corner:after, .fa-duotone.fa-corner:after {
  content: "";
}

.fad.fa-chevrons-right:after, .fa-duotone.fa-chevrons-right:after, .fad.fa-chevron-double-right:after, .fa-duotone.fa-chevron-double-right:after {
  content: "";
}

.fad.fa-book-open:after, .fa-duotone.fa-book-open:after {
  content: "";
}

.fad.fa-book-journal-whills:after, .fa-duotone.fa-book-journal-whills:after, .fad.fa-journal-whills:after, .fa-duotone.fa-journal-whills:after {
  content: "";
}

.fad.fa-inhaler:after, .fa-duotone.fa-inhaler:after {
  content: "";
}

.fad.fa-handcuffs:after, .fa-duotone.fa-handcuffs:after {
  content: "";
}

.fad.fa-snake:after, .fa-duotone.fa-snake:after {
  content: "";
}

.fad.fa-triangle-exclamation:after, .fa-duotone.fa-triangle-exclamation:after, .fad.fa-exclamation-triangle:after, .fa-duotone.fa-exclamation-triangle:after, .fad.fa-warning:after, .fa-duotone.fa-warning:after {
  content: "";
}

.fad.fa-note-medical:after, .fa-duotone.fa-note-medical:after {
  content: "";
}

.fad.fa-database:after, .fa-duotone.fa-database:after {
  content: "";
}

.fad.fa-down-left:after, .fa-duotone.fa-down-left:after {
  content: "";
}

.fad.fa-share:after, .fa-duotone.fa-share:after, .fad.fa-mail-forward:after, .fa-duotone.fa-mail-forward:after {
  content: "";
}

.fad.fa-face-thinking:after, .fa-duotone.fa-face-thinking:after {
  content: "";
}

.fad.fa-turn-down-right:after, .fa-duotone.fa-turn-down-right:after {
  content: "";
}

.fad.fa-bottle-droplet:after, .fa-duotone.fa-bottle-droplet:after {
  content: "";
}

.fad.fa-mask-face:after, .fa-duotone.fa-mask-face:after {
  content: "";
}

.fad.fa-hill-rockslide:after, .fa-duotone.fa-hill-rockslide:after {
  content: "";
}

.fad.fa-scanner-keyboard:after, .fa-duotone.fa-scanner-keyboard:after {
  content: "";
}

.fad.fa-circle-o:after, .fa-duotone.fa-circle-o:after {
  content: "";
}

.fad.fa-grid-horizontal:after, .fa-duotone.fa-grid-horizontal:after {
  content: "";
}

.fad.fa-message-dollar:after, .fa-duotone.fa-message-dollar:after, .fad.fa-comment-alt-dollar:after, .fa-duotone.fa-comment-alt-dollar:after {
  content: "";
}

.fad.fa-right-left:after, .fa-duotone.fa-right-left:after, .fad.fa-exchange-alt:after, .fa-duotone.fa-exchange-alt:after {
  content: "";
}

.fad.fa-columns-3:after, .fa-duotone.fa-columns-3:after {
  content: "";
}

.fad.fa-paper-plane:after, .fa-duotone.fa-paper-plane:after {
  content: "";
}

.fad.fa-road-circle-exclamation:after, .fa-duotone.fa-road-circle-exclamation:after {
  content: "";
}

.fad.fa-dungeon:after, .fa-duotone.fa-dungeon:after {
  content: "";
}

.fad.fa-hand-holding-box:after, .fa-duotone.fa-hand-holding-box:after {
  content: "";
}

.fad.fa-input-text:after, .fa-duotone.fa-input-text:after {
  content: "";
}

.fad.fa-window-flip:after, .fa-duotone.fa-window-flip:after, .fad.fa-window-alt:after, .fa-duotone.fa-window-alt:after {
  content: "";
}

.fad.fa-align-right:after, .fa-duotone.fa-align-right:after {
  content: "";
}

.fad.fa-scanner-gun:after, .fa-duotone.fa-scanner-gun:after, .fad.fa-scanner:after, .fa-duotone.fa-scanner:after {
  content: "";
}

.fad.fa-tire:after, .fa-duotone.fa-tire:after {
  content: "";
}

.fad.fa-engine:after, .fa-duotone.fa-engine:after {
  content: "";
}

.fad.fa-money-bill-1-wave:after, .fa-duotone.fa-money-bill-1-wave:after, .fad.fa-money-bill-wave-alt:after, .fa-duotone.fa-money-bill-wave-alt:after {
  content: "";
}

.fad.fa-life-ring:after, .fa-duotone.fa-life-ring:after {
  content: "";
}

.fad.fa-hands:after, .fa-duotone.fa-hands:after, .fad.fa-sign-language:after, .fa-duotone.fa-sign-language:after, .fad.fa-signing:after, .fa-duotone.fa-signing:after {
  content: "";
}

.fad.fa-circle-caret-right:after, .fa-duotone.fa-circle-caret-right:after, .fad.fa-caret-circle-right:after, .fa-duotone.fa-caret-circle-right:after {
  content: "";
}

.fad.fa-turn-left:after, .fa-duotone.fa-turn-left:after {
  content: "";
}

.fad.fa-wheat:after, .fa-duotone.fa-wheat:after {
  content: "";
}

.fad.fa-file-spreadsheet:after, .fa-duotone.fa-file-spreadsheet:after {
  content: "";
}

.fad.fa-audio-description-slash:after, .fa-duotone.fa-audio-description-slash:after {
  content: "";
}

.fad.fa-bell-ring:after, .fa-duotone.fa-bell-ring:after {
  content: "";
}

.fad.fa-calendar-day:after, .fa-duotone.fa-calendar-day:after {
  content: "";
}

.fad.fa-water-ladder:after, .fa-duotone.fa-water-ladder:after, .fad.fa-ladder-water:after, .fa-duotone.fa-ladder-water:after, .fad.fa-swimming-pool:after, .fa-duotone.fa-swimming-pool:after {
  content: "";
}

.fad.fa-arrows-up-down:after, .fa-duotone.fa-arrows-up-down:after, .fad.fa-arrows-v:after, .fa-duotone.fa-arrows-v:after {
  content: "";
}

.fad.fa-chess-pawn-piece:after, .fa-duotone.fa-chess-pawn-piece:after, .fad.fa-chess-pawn-alt:after, .fa-duotone.fa-chess-pawn-alt:after {
  content: "";
}

.fad.fa-face-grimace:after, .fa-duotone.fa-face-grimace:after, .fad.fa-grimace:after, .fa-duotone.fa-grimace:after {
  content: "";
}

.fad.fa-wheelchair-move:after, .fa-duotone.fa-wheelchair-move:after, .fad.fa-wheelchair-alt:after, .fa-duotone.fa-wheelchair-alt:after {
  content: "";
}

.fad.fa-turn-down:after, .fa-duotone.fa-turn-down:after, .fad.fa-level-down-alt:after, .fa-duotone.fa-level-down-alt:after {
  content: "";
}

.fad.fa-square-s:after, .fa-duotone.fa-square-s:after {
  content: "";
}

.fad.fa-rectangle-barcode:after, .fa-duotone.fa-rectangle-barcode:after, .fad.fa-barcode-alt:after, .fa-duotone.fa-barcode-alt:after {
  content: "";
}

.fad.fa-person-walking-arrow-right:after, .fa-duotone.fa-person-walking-arrow-right:after {
  content: "";
}

.fad.fa-square-envelope:after, .fa-duotone.fa-square-envelope:after, .fad.fa-envelope-square:after, .fa-duotone.fa-envelope-square:after {
  content: "";
}

.fad.fa-dice:after, .fa-duotone.fa-dice:after {
  content: "";
}

.fad.fa-unicorn:after, .fa-duotone.fa-unicorn:after {
  content: "";
}

.fad.fa-bowling-ball:after, .fa-duotone.fa-bowling-ball:after {
  content: "";
}

.fad.fa-pompebled:after, .fa-duotone.fa-pompebled:after {
  content: "";
}

.fad.fa-brain:after, .fa-duotone.fa-brain:after {
  content: "";
}

.fad.fa-watch-smart:after, .fa-duotone.fa-watch-smart:after {
  content: "";
}

.fad.fa-book-user:after, .fa-duotone.fa-book-user:after {
  content: "";
}

.fad.fa-sensor-cloud:after, .fa-duotone.fa-sensor-cloud:after, .fad.fa-sensor-smoke:after, .fa-duotone.fa-sensor-smoke:after {
  content: "";
}

.fad.fa-clapperboard-play:after, .fa-duotone.fa-clapperboard-play:after {
  content: "";
}

.fad.fa-bandage:after, .fa-duotone.fa-bandage:after, .fad.fa-band-aid:after, .fa-duotone.fa-band-aid:after {
  content: "";
}

.fad.fa-calendar-minus:after, .fa-duotone.fa-calendar-minus:after {
  content: "";
}

.fad.fa-circle-xmark:after, .fa-duotone.fa-circle-xmark:after, .fad.fa-times-circle:after, .fa-duotone.fa-times-circle:after, .fad.fa-xmark-circle:after, .fa-duotone.fa-xmark-circle:after {
  content: "";
}

.fad.fa-circle-4:after, .fa-duotone.fa-circle-4:after {
  content: "";
}

.fad.fa-gifts:after, .fa-duotone.fa-gifts:after {
  content: "";
}

.fad.fa-album-collection:after, .fa-duotone.fa-album-collection:after {
  content: "";
}

.fad.fa-hotel:after, .fa-duotone.fa-hotel:after {
  content: "";
}

.fad.fa-earth-asia:after, .fa-duotone.fa-earth-asia:after, .fad.fa-globe-asia:after, .fa-duotone.fa-globe-asia:after {
  content: "";
}

.fad.fa-id-card-clip:after, .fa-duotone.fa-id-card-clip:after, .fad.fa-id-card-alt:after, .fa-duotone.fa-id-card-alt:after {
  content: "";
}

.fad.fa-magnifying-glass-plus:after, .fa-duotone.fa-magnifying-glass-plus:after, .fad.fa-search-plus:after, .fa-duotone.fa-search-plus:after {
  content: "";
}

.fad.fa-thumbs-up:after, .fa-duotone.fa-thumbs-up:after {
  content: "";
}

.fad.fa-cloud-showers:after, .fa-duotone.fa-cloud-showers:after {
  content: "";
}

.fad.fa-user-clock:after, .fa-duotone.fa-user-clock:after {
  content: "";
}

.fad.fa-onion:after, .fa-duotone.fa-onion:after {
  content: "";
}

.fad.fa-clock-twelve-thirty:after, .fa-duotone.fa-clock-twelve-thirty:after {
  content: "";
}

.fad.fa-arrow-down-to-dotted-line:after, .fa-duotone.fa-arrow-down-to-dotted-line:after {
  content: "";
}

.fad.fa-hand-dots:after, .fa-duotone.fa-hand-dots:after, .fad.fa-allergies:after, .fa-duotone.fa-allergies:after {
  content: "";
}

.fad.fa-file-invoice:after, .fa-duotone.fa-file-invoice:after {
  content: "";
}

.fad.fa-window-minimize:after, .fa-duotone.fa-window-minimize:after {
  content: "";
}

.fad.fa-rectangle-wide:after, .fa-duotone.fa-rectangle-wide:after {
  content: "";
}

.fad.fa-comment-arrow-up:after, .fa-duotone.fa-comment-arrow-up:after {
  content: "";
}

.fad.fa-garlic:after, .fa-duotone.fa-garlic:after {
  content: "";
}

.fad.fa-mug-saucer:after, .fa-duotone.fa-mug-saucer:after, .fad.fa-coffee:after, .fa-duotone.fa-coffee:after {
  content: "";
}

.fad.fa-brush:after, .fa-duotone.fa-brush:after {
  content: "";
}

.fad.fa-tree-decorated:after, .fa-duotone.fa-tree-decorated:after {
  content: "";
}

.fad.fa-mask:after, .fa-duotone.fa-mask:after {
  content: "";
}

.fad.fa-calendar-heart:after, .fa-duotone.fa-calendar-heart:after {
  content: "";
}

.fad.fa-magnifying-glass-minus:after, .fa-duotone.fa-magnifying-glass-minus:after, .fad.fa-search-minus:after, .fa-duotone.fa-search-minus:after {
  content: "";
}

.fad.fa-flower:after, .fa-duotone.fa-flower:after {
  content: "";
}

.fad.fa-arrow-down-from-arc:after, .fa-duotone.fa-arrow-down-from-arc:after {
  content: "";
}

.fad.fa-right-left-large:after, .fa-duotone.fa-right-left-large:after {
  content: "";
}

.fad.fa-ruler-vertical:after, .fa-duotone.fa-ruler-vertical:after {
  content: "";
}

.fad.fa-circles-overlap:after, .fa-duotone.fa-circles-overlap:after {
  content: "";
}

.fad.fa-user-large:after, .fa-duotone.fa-user-large:after, .fad.fa-user-alt:after, .fa-duotone.fa-user-alt:after {
  content: "";
}

.fad.fa-starship-freighter:after, .fa-duotone.fa-starship-freighter:after {
  content: "";
}

.fad.fa-train-tram:after, .fa-duotone.fa-train-tram:after {
  content: "";
}

.fad.fa-bridge-suspension:after, .fa-duotone.fa-bridge-suspension:after {
  content: "";
}

.fad.fa-trash-check:after, .fa-duotone.fa-trash-check:after {
  content: "";
}

.fad.fa-user-nurse:after, .fa-duotone.fa-user-nurse:after {
  content: "";
}

.fad.fa-boombox:after, .fa-duotone.fa-boombox:after {
  content: "";
}

.fad.fa-syringe:after, .fa-duotone.fa-syringe:after {
  content: "";
}

.fad.fa-cloud-sun:after, .fa-duotone.fa-cloud-sun:after {
  content: "";
}

.fad.fa-shield-exclamation:after, .fa-duotone.fa-shield-exclamation:after {
  content: "";
}

.fad.fa-stopwatch-20:after, .fa-duotone.fa-stopwatch-20:after {
  content: "";
}

.fad.fa-square-full:after, .fa-duotone.fa-square-full:after {
  content: "";
}

.fad.fa-grip-dots:after, .fa-duotone.fa-grip-dots:after {
  content: "";
}

.fad.fa-comment-exclamation:after, .fa-duotone.fa-comment-exclamation:after {
  content: "";
}

.fad.fa-pen-swirl:after, .fa-duotone.fa-pen-swirl:after {
  content: "";
}

.fad.fa-falafel:after, .fa-duotone.fa-falafel:after {
  content: "";
}

.fad.fa-circle-2:after, .fa-duotone.fa-circle-2:after {
  content: "";
}

.fad.fa-magnet:after, .fa-duotone.fa-magnet:after {
  content: "";
}

.fad.fa-jar:after, .fa-duotone.fa-jar:after {
  content: "";
}

.fad.fa-gramophone:after, .fa-duotone.fa-gramophone:after {
  content: "";
}

.fad.fa-dice-d12:after, .fa-duotone.fa-dice-d12:after {
  content: "";
}

.fad.fa-note-sticky:after, .fa-duotone.fa-note-sticky:after, .fad.fa-sticky-note:after, .fa-duotone.fa-sticky-note:after {
  content: "";
}

.fad.fa-down:after, .fa-duotone.fa-down:after, .fad.fa-arrow-alt-down:after, .fa-duotone.fa-arrow-alt-down:after {
  content: "";
}

.fad.fa-hundred-points:after, .fa-duotone.fa-hundred-points:after, .fad.fa-100:after, .fa-duotone.fa-100:after {
  content: "";
}

.fad.fa-paperclip-vertical:after, .fa-duotone.fa-paperclip-vertical:after {
  content: "";
}

.fad.fa-wind-warning:after, .fa-duotone.fa-wind-warning:after, .fad.fa-wind-circle-exclamation:after, .fa-duotone.fa-wind-circle-exclamation:after {
  content: "";
}

.fad.fa-location-pin-slash:after, .fa-duotone.fa-location-pin-slash:after, .fad.fa-map-marker-slash:after, .fa-duotone.fa-map-marker-slash:after {
  content: "";
}

.fad.fa-face-sad-sweat:after, .fa-duotone.fa-face-sad-sweat:after {
  content: "";
}

.fad.fa-bug-slash:after, .fa-duotone.fa-bug-slash:after {
  content: "";
}

.fad.fa-cupcake:after, .fa-duotone.fa-cupcake:after {
  content: "";
}

.fad.fa-light-switch-off:after, .fa-duotone.fa-light-switch-off:after {
  content: "";
}

.fad.fa-toggle-large-off:after, .fa-duotone.fa-toggle-large-off:after {
  content: "";
}

.fad.fa-pen-fancy-slash:after, .fa-duotone.fa-pen-fancy-slash:after {
  content: "";
}

.fad.fa-truck-container:after, .fa-duotone.fa-truck-container:after {
  content: "";
}

.fad.fa-boot:after, .fa-duotone.fa-boot:after {
  content: "";
}

.fad.fa-arrow-up-from-water-pump:after, .fa-duotone.fa-arrow-up-from-water-pump:after {
  content: "";
}

.fad.fa-file-check:after, .fa-duotone.fa-file-check:after {
  content: "";
}

.fad.fa-bone:after, .fa-duotone.fa-bone:after {
  content: "";
}

.fad.fa-cards-blank:after, .fa-duotone.fa-cards-blank:after {
  content: "";
}

.fad.fa-circle-3:after, .fa-duotone.fa-circle-3:after {
  content: "";
}

.fad.fa-bench-tree:after, .fa-duotone.fa-bench-tree:after {
  content: "";
}

.fad.fa-keyboard-brightness-low:after, .fa-duotone.fa-keyboard-brightness-low:after {
  content: "";
}

.fad.fa-ski-boot-ski:after, .fa-duotone.fa-ski-boot-ski:after {
  content: "";
}

.fad.fa-brain-circuit:after, .fa-duotone.fa-brain-circuit:after {
  content: "";
}

.fad.fa-user-injured:after, .fa-duotone.fa-user-injured:after {
  content: "";
}

.fad.fa-block-brick-fire:after, .fa-duotone.fa-block-brick-fire:after, .fad.fa-firewall:after, .fa-duotone.fa-firewall:after {
  content: "";
}

.fad.fa-face-sad-tear:after, .fa-duotone.fa-face-sad-tear:after, .fad.fa-sad-tear:after, .fa-duotone.fa-sad-tear:after {
  content: "";
}

.fad.fa-plane:after, .fa-duotone.fa-plane:after {
  content: "";
}

.fad.fa-tent-arrows-down:after, .fa-duotone.fa-tent-arrows-down:after {
  content: "";
}

.fad.fa-exclamation:after, .fa-duotone.fa-exclamation:after {
  content: "!!";
}

.fad.fa-arrows-spin:after, .fa-duotone.fa-arrows-spin:after {
  content: "";
}

.fad.fa-face-smile-relaxed:after, .fa-duotone.fa-face-smile-relaxed:after {
  content: "";
}

.fad.fa-comment-xmark:after, .fa-duotone.fa-comment-xmark:after, .fad.fa-comment-times:after, .fa-duotone.fa-comment-times:after {
  content: "";
}

.fad.fa-print:after, .fa-duotone.fa-print:after {
  content: "";
}

.fad.fa-turkish-lira-sign:after, .fa-duotone.fa-turkish-lira-sign:after, .fad.fa-try:after, .fa-duotone.fa-try:after, .fad.fa-turkish-lira:after, .fa-duotone.fa-turkish-lira:after {
  content: "";
}

.fad.fa-face-nose-steam:after, .fa-duotone.fa-face-nose-steam:after {
  content: "";
}

.fad.fa-circle-waveform-lines:after, .fa-duotone.fa-circle-waveform-lines:after, .fad.fa-waveform-circle:after, .fa-duotone.fa-waveform-circle:after {
  content: "";
}

.fad.fa-dollar-sign:after, .fa-duotone.fa-dollar-sign:after, .fad.fa-dollar:after, .fa-duotone.fa-dollar:after, .fad.fa-usd:after, .fa-duotone.fa-usd:after {
  content: "$$";
}

.fad.fa-ferris-wheel:after, .fa-duotone.fa-ferris-wheel:after {
  content: "";
}

.fad.fa-computer-speaker:after, .fa-duotone.fa-computer-speaker:after {
  content: "";
}

.fad.fa-skull-cow:after, .fa-duotone.fa-skull-cow:after {
  content: "";
}

.fad.fa-x:after, .fa-duotone.fa-x:after {
  content: "XX";
}

.fad.fa-magnifying-glass-dollar:after, .fa-duotone.fa-magnifying-glass-dollar:after, .fad.fa-search-dollar:after, .fa-duotone.fa-search-dollar:after {
  content: "";
}

.fad.fa-users-gear:after, .fa-duotone.fa-users-gear:after, .fad.fa-users-cog:after, .fa-duotone.fa-users-cog:after {
  content: "";
}

.fad.fa-person-military-pointing:after, .fa-duotone.fa-person-military-pointing:after {
  content: "";
}

.fad.fa-building-columns:after, .fa-duotone.fa-building-columns:after, .fad.fa-bank:after, .fa-duotone.fa-bank:after, .fad.fa-institution:after, .fa-duotone.fa-institution:after, .fad.fa-museum:after, .fa-duotone.fa-museum:after, .fad.fa-university:after, .fa-duotone.fa-university:after {
  content: "";
}

.fad.fa-circle-t:after, .fa-duotone.fa-circle-t:after {
  content: "";
}

.fad.fa-sack:after, .fa-duotone.fa-sack:after {
  content: "";
}

.fad.fa-grid-2:after, .fa-duotone.fa-grid-2:after {
  content: "";
}

.fad.fa-camera-cctv:after, .fa-duotone.fa-camera-cctv:after, .fad.fa-cctv:after, .fa-duotone.fa-cctv:after {
  content: "";
}

.fad.fa-umbrella:after, .fa-duotone.fa-umbrella:after {
  content: "";
}

.fad.fa-trowel:after, .fa-duotone.fa-trowel:after {
  content: "";
}

.fad.fa-horizontal-rule:after, .fa-duotone.fa-horizontal-rule:after {
  content: "";
}

.fad.fa-bed-front:after, .fa-duotone.fa-bed-front:after, .fad.fa-bed-alt:after, .fa-duotone.fa-bed-alt:after {
  content: "";
}

.fad.fa-d:after, .fa-duotone.fa-d:after {
  content: "DD";
}

.fad.fa-stapler:after, .fa-duotone.fa-stapler:after {
  content: "";
}

.fad.fa-masks-theater:after, .fa-duotone.fa-masks-theater:after, .fad.fa-theater-masks:after, .fa-duotone.fa-theater-masks:after {
  content: "";
}

.fad.fa-file-gif:after, .fa-duotone.fa-file-gif:after {
  content: "";
}

.fad.fa-kip-sign:after, .fa-duotone.fa-kip-sign:after {
  content: "";
}

.fad.fa-face-woozy:after, .fa-duotone.fa-face-woozy:after {
  content: "";
}

.fad.fa-cloud-question:after, .fa-duotone.fa-cloud-question:after {
  content: "";
}

.fad.fa-pineapple:after, .fa-duotone.fa-pineapple:after {
  content: "";
}

.fad.fa-hand-point-left:after, .fa-duotone.fa-hand-point-left:after {
  content: "";
}

.fad.fa-gallery-thumbnails:after, .fa-duotone.fa-gallery-thumbnails:after {
  content: "";
}

.fad.fa-circle-j:after, .fa-duotone.fa-circle-j:after {
  content: "";
}

.fad.fa-eyes:after, .fa-duotone.fa-eyes:after {
  content: "";
}

.fad.fa-handshake-simple:after, .fa-duotone.fa-handshake-simple:after, .fad.fa-handshake-alt:after, .fa-duotone.fa-handshake-alt:after {
  content: "";
}

.fad.fa-page-caret-up:after, .fa-duotone.fa-page-caret-up:after, .fad.fa-file-caret-up:after, .fa-duotone.fa-file-caret-up:after {
  content: "";
}

.fad.fa-jet-fighter:after, .fa-duotone.fa-jet-fighter:after, .fad.fa-fighter-jet:after, .fa-duotone.fa-fighter-jet:after {
  content: "";
}

.fad.fa-comet:after, .fa-duotone.fa-comet:after {
  content: "";
}

.fad.fa-square-share-nodes:after, .fa-duotone.fa-square-share-nodes:after, .fad.fa-share-alt-square:after, .fa-duotone.fa-share-alt-square:after {
  content: "";
}

.fad.fa-reflect-vertical:after, .fa-duotone.fa-reflect-vertical:after {
  content: "";
}

.fad.fa-shield-keyhole:after, .fa-duotone.fa-shield-keyhole:after {
  content: "";
}

.fad.fa-file-mp4:after, .fa-duotone.fa-file-mp4:after {
  content: "";
}

.fad.fa-barcode:after, .fa-duotone.fa-barcode:after {
  content: "";
}

.fad.fa-bulldozer:after, .fa-duotone.fa-bulldozer:after {
  content: "";
}

.fad.fa-plus-minus:after, .fa-duotone.fa-plus-minus:after {
  content: "";
}

.fad.fa-square-sliders-vertical:after, .fa-duotone.fa-square-sliders-vertical:after, .fad.fa-sliders-v-square:after, .fa-duotone.fa-sliders-v-square:after {
  content: "";
}

.fad.fa-video:after, .fa-duotone.fa-video:after, .fad.fa-video-camera:after, .fa-duotone.fa-video-camera:after {
  content: "";
}

.fad.fa-message-middle:after, .fa-duotone.fa-message-middle:after, .fad.fa-comment-middle-alt:after, .fa-duotone.fa-comment-middle-alt:after {
  content: "";
}

.fad.fa-graduation-cap:after, .fa-duotone.fa-graduation-cap:after, .fad.fa-mortar-board:after, .fa-duotone.fa-mortar-board:after {
  content: "";
}

.fad.fa-hand-holding-medical:after, .fa-duotone.fa-hand-holding-medical:after {
  content: "";
}

.fad.fa-person-circle-check:after, .fa-duotone.fa-person-circle-check:after {
  content: "";
}

.fad.fa-square-z:after, .fa-duotone.fa-square-z:after {
  content: "";
}

.fad.fa-message-text:after, .fa-duotone.fa-message-text:after, .fad.fa-comment-alt-text:after, .fa-duotone.fa-comment-alt-text:after {
  content: "";
}

.fad.fa-turn-up:after, .fa-duotone.fa-turn-up:after, .fad.fa-level-up-alt:after, .fa-duotone.fa-level-up-alt:after {
  content: "";
}

:root, :host {
  --fa-style-family-sharp: "Font Awesome 6 Sharp";
  --fa-font-sharp-light: normal 300 1em / 1 "Font Awesome 6 Sharp";
}

@font-face {
  font-family: "Font Awesome 6 Sharp";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../fonts/fa-sharp-light-300.woff2") format("woff2"), url("../fonts/fa-sharp-light-300.ttf") format("truetype");
}

.fasl, .fa-light {
  font-weight: 300;
}

:root, :host {
  --fa-style-family-sharp: "Font Awesome 6 Sharp";
  --fa-font-sharp-regular: normal 400 1em / 1 "Font Awesome 6 Sharp";
}

@font-face {
  font-family: "Font Awesome 6 Sharp";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-sharp-regular-400.woff2") format("woff2"), url("../fonts/fa-sharp-regular-400.ttf") format("truetype");
}

.fasr, .fa-regular {
  font-weight: 400;
}

:root, :host {
  --fa-style-family-sharp: "Font Awesome 6 Sharp";
  --fa-font-sharp-solid: normal 900 1em / 1 "Font Awesome 6 Sharp";
}

@font-face {
  font-family: "Font Awesome 6 Sharp";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-sharp-solid-900.woff2") format("woff2"), url("../fonts/fa-sharp-solid-900.ttf") format("truetype");
}

.fass, .fa-solid {
  font-weight: 900;
}

:root, :host {
  --fa-style-family-sharp: "Font Awesome 6 Sharp";
  --fa-font-sharp-thin: normal 100 1em / 1 "Font Awesome 6 Sharp";
}

@font-face {
  font-family: "Font Awesome 6 Sharp";
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../fonts/fa-sharp-thin-100.woff2") format("woff2"), url("../fonts/fa-sharp-thin-100.ttf") format("truetype");
}

.fast, .fa-thin {
  font-weight: 100;
}

.purpletext {
  color: #0000c4;
}

table {
  border: 1px solid red;
}
/*# sourceMappingURL=index.f651e145.css.map */
